import * as listingService from '../../../services/ListingService';

import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import React, { useContext, useState } from 'react';

import { LinstingAdmin } from '../../../dtos/admin';
import Tooltip from 'antd/es/tooltip';
import { UserContext } from '../../../contexts/UserContext';
import styled from 'styled-components';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';

export const DeleteAction = (props: LinstingAdmin): JSX.Element => {
  const { tokenErrorHandler } = useContext(UserContext),
    axiosMiddleware = useAxiosMiddleware(),
    [deleted, setDeleted] = useState(false);

  const deleteMutation = useMutation(listingService.deleteListing, {
    onSuccess: () => {
      setDeleted(true);
    },
    onError: tokenErrorHandler,
  });

  const deleteListingAction = (id: string) => {
    Modal.error({
      content: 'Êtes-vous sur de vouloir supprimer cette annnonce?',
      onOk: () => deleteMutation.mutate({ listingid: id, axiosMid: axiosMiddleware }),
      closable: true,
    });
  };

  return deleted ? (
    <NotAllowedDiv>
      <Tooltip title={'Cette annonce a été supprimée'}>
        <CloseCircleOutlined style={{ color: 'red' }} />
      </Tooltip>
    </NotAllowedDiv>
  ) : deleteMutation.isLoading ? (
    <Spin />
  ) : (
    <div>
      <Tooltip title={"Supprimer l'annonce"}>
        <DeleteOutlined onClick={() => deleteListingAction(props._id)} />
      </Tooltip>
    </div>
  );
};

export const NotAllowedDiv = styled.div`
  cursor: not-allowed;
`;
