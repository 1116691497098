import { Form, Input, Select } from 'antd';
import { OfferFinancing, SellingReason } from '../../../dtos/listingCreationFields';

import { FieldLength } from '../../../dtos/fields';
import React from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';

export const FormSale = (props: {
  bilingual: boolean;
  setBilingual: React.Dispatch<React.SetStateAction<boolean>>;
  fr: false | JSX.Element;
  en: false | JSX.Element;
}): JSX.Element => {
  const { t } = useTranslation(['createListing']);

  const { Option } = Select;

  return (
    <>
      <h4>{t('createListing:formDetails.sectionTitle')}</h4>
      <Form.Item name={'selling_reason'} label={<label>{t('createListing:formDetails.selling_reason.title')}</label>}>
        <Select>
          {SellingReason.map((itemField) => (
            <Option key={itemField} value={itemField}>
              {t(`createListing:formDetails.selling_reason.${itemField}`)}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={'financing_option'}
        label={<label>{t('createListing:formDetails.financing_option.title')}</label>}
      >
        <Select>
          {OfferFinancing.map((itemField) => (
            <Option key={itemField} value={itemField}>
              {t(`createListing:formDetails.financing_option.${itemField}`)}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={'offered_support_def'}
        label={
          <label>
            {t('createListing:formDetails.offered_support.title')}
            {props.fr}
          </label>
        }
        rules={[
          {
            max: FieldLength.LONG,
            message: t('createListing:errors.ruleTooLong', { length: FieldLength.LONG }),
          },
          { whitespace: true, message: t('createListing:errors.whitespace') },
        ]}
        tooltip={t('createListing:formDetails.offered_support.tooltip')}
      >
        <TextArea rows={2} placeholder={t('createListing:formDetails.offered_support.placeholder')} />
      </Form.Item>
      {props.bilingual && (
        <Form.Item
          name={'offered_support_add'}
          label={
            <label>
              {t('createListing:formDetails.offered_support.title')}
              {props.en}
            </label>
          }
          rules={[
            {
              max: FieldLength.LONG,
              message: t('createListing:errors.ruleTooLong', { length: FieldLength.LONG }),
            },
            { whitespace: true, message: t('createListing:errors.whitespace') },
          ]}
          tooltip={t('createListing:formDetails.offered_support.tooltip')}
        >
          <TextArea rows={2} placeholder={t('createListing:formDetails.offered_support.placeholder')} />
        </Form.Item>
      )}
      <h6>{t('createListing:formDetails.contantInformation')}</h6>
      <p>{t('createListing:formDetails.contantInformationSub')}</p>
      <Form.Item
        name={'first_name_contact'}
        label={<label>{t('createListing:formDetails.first_name.title')}</label>}
        rules={[
          {
            max: FieldLength.SHORT,
            message: t('createListing:errors.ruleTooLong', { length: FieldLength.SHORT }),
          },
          { whitespace: true, message: t('createListing:errors.whitespace') },
        ]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        name={'last_name_contact'}
        label={<label>{t('createListing:formDetails.last_name.title')}</label>}
        rules={[
          {
            max: FieldLength.SHORT,
            message: t('createListing:errors.ruleTooLong', { length: FieldLength.SHORT }),
          },
          { whitespace: true, message: t('createListing:errors.whitespace') },
        ]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        name={'email_contact'}
        label={<label>{t('createListing:formDetails.email.title')}</label>}
        rules={[
          {
            max: FieldLength.SHORT,
            message: t('createListing:errors.ruleTooLong', { length: FieldLength.SHORT }),
          },
          { whitespace: true, message: t('createListing:errors.whitespace') },
        ]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        name={'telephone_contact'}
        label={<label>{t('createListing:formDetails.phone.title')}</label>}
        rules={[
          {
            max: FieldLength.SHORT,
            message: t('createListing:errors.ruleTooLong', { length: FieldLength.SHORT }),
          },
          { whitespace: true, message: t('createListing:errors.whitespace') },
        ]}
      >
        <Input></Input>
      </Form.Item>
    </>
  );
};
