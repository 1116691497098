import { ButtonSmall, ButtonStatus } from '../../../common/button/ButtonSmall';
import { Form, Modal, Radio, Spin } from 'antd';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Store } from 'rc-field-form/lib/interface';
import { UserContext } from '../../../contexts/UserContext';
import { ViewCentered } from '../../../common/views/ViewCentered';
import styled from 'styled-components';
import { updateDiLanguageSetting } from '../../../services/NotificationService';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const UpdateLanguage = (): JSX.Element => {
  const { tokenErrorHandler } = useContext(UserContext),
    axiosMiddleware = useAxiosMiddleware(),
    navigate = useNavigate(),
    location = useLocation(),
    { t } = useTranslation(['profileForm']);

  const onSuccess = () => {
    Modal.success({
      content: t(`profileForm:update_user.success`),
      onOk: () => navigate(-1),
      okText: t('profileForm:back'),
    });
  };

  const updateUserMutation = useMutation(updateDiLanguageSetting, {
    onSuccess: onSuccess,
    onError: tokenErrorHandler,
  });

  const onFinish = (data: { language: string }) => {
    updateUserMutation.mutate({ language: data.language, axiosMid: axiosMiddleware });
  };

  return (
    <ViewCentered maxWidth={'350px'}>
      <Spin size="large" spinning={updateUserMutation.isLoading}>
        <Form onFinish={onFinish} initialValues={location.state as Store} layout="vertical" requiredMark={false}>
          <StyledH4>{t('updateLanguage.title')}</StyledH4>
          <Form.Item
            name="language"
            label={<label>{t('updateLanguage.sub')}</label>}
            rules={[
              {
                required: true,
                message: t('updateLanguage.error'),
              },
            ]}
          >
            <Radio.Group>
              <Radio value="fr">{t('updateLanguage.fr')}</Radio>
              <Radio value="en">{t('updateLanguage.en')}</Radio>
            </Radio.Group>
          </Form.Item>
          <ButtonSmall status={ButtonStatus.PRIMARY} text={t('updateRole.submit')} />
        </Form>
      </Spin>
    </ViewCentered>
  );
};

const StyledH4 = styled.h4`
  margin-top: 32px;
`;
