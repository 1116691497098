import * as UserService from '../services/UserService';

import { privateRoutes, publicRoutes } from '../router/routes';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { HttpError } from '../dtos/error';
import { Modal } from 'antd';
import React from 'react';
import { UserAndTokens } from '../dtos/user';
import { UserContext } from './UserContext';
import i18next from 'i18next';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type UserProviderProps = {
  children: React.ReactNode;
};

export const UserProvider = ({ children }: UserProviderProps): JSX.Element => {
  const { t } = useTranslation('userProvider');
  const queryClient = useQueryClient();
  const location = useLocation();
  const [authTokens, setTokens] = useState(() =>
    // eslint-disable-next-line
    localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')!) : undefined,
  );
  const [userFavorites, setUserFavorites] = useState(() =>
    // eslint-disable-next-line
    localStorage.getItem('userFavorites') ? JSON.parse(localStorage.getItem('userFavorites')!) : undefined,
  );
  const [userType, setUserType] = useState(() =>
    // eslint-disable-next-line
    localStorage.getItem('userType') ? JSON.parse(localStorage.getItem('userType')!) : undefined,
  );

  const navigate = useNavigate();

  const setCacheOnSignin = (result: UserAndTokens) => {
    let route;
    switch (result.user_type) {
      case 'buyer':
        route = publicRoutes.businessesForSale;
        break;
      case 'seller':
        route = privateRoutes.myListings;
        break;
      case 'broker':
        route = privateRoutes.myListings;
        break;
      default:
        route = privateRoutes.onbarding;
        break;
    }
    const prevRoute = location.state?.from;

    if (
      // if a user has never logged in he must specify his alerts and user type (buyer, seller, broker)
      route !== privateRoutes.onbarding &&
      (prevRoute?.pathname?.includes('/fr/fl/') || prevRoute?.pathname?.includes('/en/fl/'))
    ) {
      navigate(prevRoute, { replace: true });
    } else {
      navigate(route(i18next.language));
    }

    setTokens({
      access_token: result.access_token,
      refresh_token: result.refresh_token,
    });
    setUserFavorites(result.favorite_listings);
    setUserType(result.user_type);
    localStorage.setItem(
      'authTokens',
      JSON.stringify({
        access_token: result.access_token,
        refresh_token: result.refresh_token,
      }),
    );
    localStorage.setItem('userFavorites', JSON.stringify(result.favorite_listings || []));
    localStorage.setItem('userType', JSON.stringify(result.user_type || ''));
  };

  const signInMutation = useMutation(UserService.signin, {
    onSuccess: setCacheOnSignin,
  });

  const setCacheOnUpdateToken = (result: { access_token: string; refresh_token: string }) => {
    setTokens({
      access_token: result.access_token,
      refresh_token: result.refresh_token,
    });
    localStorage.setItem(
      'authTokens',
      JSON.stringify({
        access_token: result.access_token,
        refresh_token: result.refresh_token,
      }),
    );
  };

  const setUserTypeOnUpdate = (type: string): void => {
    setUserType(type);
    localStorage.setItem('userType', JSON.stringify(type));
  };

  const signOut = (): void => {
    localStorage.removeItem('authTokens');
    setTokens(undefined);
    localStorage.removeItem('userFavorites');
    setUserFavorites(undefined);
    localStorage.removeItem('userType');
    setUserType(undefined);
    localStorage.clear();
    queryClient.clear();
  };

  const tokenErrorHandler = (error: HttpError): void => {
    signOut();
    if (error?.response?.status === 401 || error?.response?.status === 412) {
      Modal.error({
        title: t('401title'),
        content: t('content'),
        onOk: () => {
          navigate(publicRoutes.signin(i18next.language));
          window.location.reload();
        },
      });
    } else {
      Modal.error({
        title: t('otherTitle'),
        content: t('content'),
        onOk: () => {
          navigate(publicRoutes.signin(i18next.language));
          // refresh the source of the app on error as the client might be using an old version of the single page app
          window.location.reload();
        },
      });
    }
  };

  const contextData = {
    authTokens: authTokens === null ? undefined : authTokens,
    userFavorites: userFavorites === null ? undefined : userFavorites,
    userType: userType === null ? undefined : userType,
    signInMutation: signInMutation,
    signOut: signOut,
    tokenErrorHandler: tokenErrorHandler,
    setUserFavorites: setUserFavorites,
    setUserTypeOnUpdate: setUserTypeOnUpdate,
    setCacheOnUpdateToken: setCacheOnUpdateToken,
  };

  return <UserContext.Provider value={contextData}>{children}</UserContext.Provider>;
};
