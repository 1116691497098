import * as paymentService from '../../services/PaymentService';

import { Alert, Form, Radio, Select, Spin } from 'antd';
import {
  BrokerDuration,
  BrokerLevel,
  BrokerPlan,
  monthlyElite,
  monthlyPrivilege,
  yearlyElite,
  yearlyPrivilege,
} from '../../dtos/subscription';
import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import React, { useEffect, useState } from 'react';
import { UseMutationResult, UseQueryResult, useMutation } from '@tanstack/react-query';

import { AxiosInstance } from 'axios';
import { Feature } from './Feature';
import { HttpError } from '../../dtos/error';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useAxiosMiddleware } from '../../services/useAxiosMiddleware';
import { useTranslation } from 'react-i18next';

type BrokerSubscriptionProps = {
  redirectToCheckout: (priceId: string) => Promise<void>;
  checkoutMutation: UseMutationResult<
    {
      sessionId: string;
    },
    unknown,
    {
      axiosMid: AxiosInstance;
      priceId: string;
      baseUrl: string;
      locale: string;
    },
    unknown
  >;
  subscriptionQuery: UseQueryResult<boolean | undefined, unknown>;
};

export const SubscriptionBroker = (props: BrokerSubscriptionProps) => {
  const { t } = useTranslation('subscription'),
    [duration, setDuration] = useState(),
    [level, setLevel] = useState(),
    [plan, setPlan] = useState<string>(),
    [subscriptionForm] = Form.useForm(),
    [options, setOptions] = useState<BrokerPlan[]>(),
    axiosMid = useAxiosMiddleware();

  useEffect(() => {
    setPlan(undefined);
    subscriptionForm.setFieldValue('plan', undefined);
    if (duration === BrokerDuration.MONTHLY && level === BrokerLevel.PRIVILEGE) {
      setOptions(monthlyPrivilege);
    } else if (duration === BrokerDuration.MONTHLY && level === BrokerLevel.ELITE) {
      setOptions(monthlyElite);
    } else if (duration === BrokerDuration.YEARLY && level === BrokerLevel.PRIVILEGE) {
      setOptions(yearlyPrivilege);
    } else if (duration === BrokerDuration.YEARLY && level === BrokerLevel.ELITE) {
      setOptions(yearlyElite);
    } else {
      setOptions(undefined);
    }
  }, [duration, level]);

  const getPriceMutation = useMutation(paymentService.getPrices);

  const goToCheckOut = () => {
    if (plan) {
      getPriceMutation.mutate(
        {
          axiosMid: axiosMid,
          lookupKeys: [plan],
        },
        {
          onSuccess: (event) => {
            if (event.data.length > 0) {
              props.redirectToCheckout(event.data[0].id);
            }
          },
        },
      );
    }
  };

  return (
    <Spin size={'large'} spinning={props.checkoutMutation.isLoading || getPriceMutation.isLoading}>
      <StyledH3>{t('broker.choosePlan')}</StyledH3>
      {(props.subscriptionQuery?.error as HttpError)?.response?.data.detail === 'billingError' && (
        <Alert
          message={t(`subscription:${(props.subscriptionQuery?.error as HttpError)?.response?.data.detail}`)}
          type="error"
          showIcon
        />
      )}
      <DivRow>
        <DivCol>
          <Card isElite={false}>
            <h4>{t('broker.brokerPrivilege.title')}</h4>
            <SubP>{t('broker.brokerPrivilege.anualOrMonthly')}</SubP>
            <h6>{t('broker.brokerPrivilege.includes')}</h6>
            <Feature content={t('broker.brokerPrivilege.email')} tooltip={t('broker.brokerPrivilege.emailHelp')} />
            <Feature
              content={t('broker.brokerPrivilege.brokerSection')}
              tooltip={t('broker.brokerPrivilege.brokerSectionHelp')}
            />
            <Feature
              content={t('broker.brokerPrivilege.visibility')}
              tooltip={t('broker.brokerPrivilege.visibilityHelp')}
            />
            <Feature content={t('broker.brokerPrivilege.pictures')} />
            <Feature content={t('broker.brokerPrivilege.logoListing')} />
          </Card>
          <TableDiv>
            <TitleTable>
              <h5>{t('broker.privilegeMonthly')}</h5>
            </TitleTable>
            <StyledTable isElite={false}>
              <thead>
                <tr>
                  <th>{t('broker.listingName')}</th>
                  <th>{t('broker.price')}</th>
                  <th>{t('broker.standardListings')}</th>
                  <th>{t('broker.premiumListings')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('broker.broker_privilege_2listings_monthly')}</td>
                  <td>180{t('broker.$months')}</td>
                  <td>2{t('broker.listings')}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>{t('broker.broker_privilege_6listings_monthly')}</td>
                  <td>300{t('broker.$months')}</td>
                  <td>5{t('broker.listings')}</td>
                  <td>1{t('broker.listings')}</td>
                </tr>
                <tr>
                  <td>{t('broker.broker_privilege_12listings_monthly')}</td>
                  <td>480{t('broker.$months')}</td>
                  <td>10{t('broker.listings')}</td>
                  <td>2{t('broker.listings')}</td>
                </tr>
              </tbody>
            </StyledTable>
          </TableDiv>
          <TableDiv>
            <TitleTable>
              <h5>{t('broker.privilegeYearly')}</h5>
            </TitleTable>
            <StyledTable isElite={false}>
              <thead>
                <tr>
                  <th>{t('broker.listingName')}</th>
                  <th>{t('broker.price')}</th>
                  <th>{t('broker.standardListings')}</th>
                  <th>{t('broker.premiumListings')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('broker.broker_privilege_2listings_yearly')}</td>
                  <td>1750{t('broker.$year')}</td>
                  <td>2{t('broker.listings')}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>{t('broker.broker_privilege_6listings_yearly')}</td>
                  <td>2640{t('broker.$year')}</td>
                  <td>5{t('broker.listings')}</td>
                  <td>1{t('broker.listings')}</td>
                </tr>
                <tr>
                  <td>{t('broker.broker_privilege_12listings_yearly')}</td>
                  <td>3120{t('broker.$year')}</td>
                  <td>10{t('broker.listings')}</td>
                  <td>2{t('broker.listings')}</td>
                </tr>
                <tr>
                  <td>{t('broker.broker_privilege_24listings_yearly')}</td>
                  <td>3600{t('broker.$year')}</td>
                  <td>21{t('broker.listings')}</td>
                  <td>3{t('broker.listings')}</td>
                </tr>
                <tr>
                  <td>{t('broker.broker_privilege_48listings_yearly')}</td>
                  <td>5150{t('broker.$year')}</td>
                  <td>44{t('broker.listings')}</td>
                  <td>4{t('broker.listings')}</td>
                </tr>
              </tbody>
            </StyledTable>
          </TableDiv>
        </DivCol>
        <DivCol>
          <Card isElite={true}>
            <h4>{t('broker.brokerElite.title')}</h4>
            <SubP>{t('broker.brokerElite.anualOrMonthly')}</SubP>
            <h6>{t('broker.brokerElite.includes')}</h6>
            <Feature content={t('broker.brokerElite.email')} tooltip={t('broker.brokerElite.emailHelp')} />
            <Feature
              content={t('broker.brokerElite.brokerSection')}
              tooltip={t('broker.brokerElite.brokerSectionHelp')}
            />
            <Feature content={t('broker.brokerElite.visibility')} tooltip={t('broker.brokerElite.visibilityHelp')} />
            <Feature content={t('broker.brokerElite.featured')} tooltip={t('broker.brokerElite.featuredHelp')} />

            <Feature content={t('broker.brokerElite.pictures')} />
            <Feature content={t('broker.brokerElite.vidéo')} />
            <Feature
              content={t('broker.brokerElite.courtierElite')}
              tooltip={t('broker.brokerElite.courtierEliteHelp')}
            />
            <Feature content={t('broker.brokerElite.logoListing')} />
            {/* <Feature content={t('broker.brokerElite.logoEmail')} /> */}
          </Card>
          <TableDiv>
            <TitleTable>
              <h5>{t('broker.eliteMonthly')}</h5>
            </TitleTable>
            <StyledTable isElite={true}>
              <thead>
                <tr>
                  <th>{t('broker.listingName')}</th>
                  <th>{t('broker.price')}</th>
                  <th>{t('broker.standardListings')}</th>
                  <th>{t('broker.premiumListings')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('broker.broker_elite_2listings_monthly')}</td>
                  <td>200{t('broker.$months')}</td>
                  <td>1{t('broker.listings')}</td>
                  <td>1{t('broker.listings')}</td>
                </tr>
                <tr>
                  <td>{t('broker.broker_elite_6listings_monthly')}</td>
                  <td>350{t('broker.$months')}</td>
                  <td>4{t('broker.listings')}</td>
                  <td>2{t('broker.listings')}</td>
                </tr>
                <tr>
                  <td>{t('broker.broker_elite_12listings_monthly')}</td>
                  <td>530{t('broker.$months')}</td>
                  <td>9{t('broker.listings')}</td>
                  <td>3{t('broker.listings')}</td>
                </tr>
              </tbody>
            </StyledTable>
          </TableDiv>
          <TableDiv>
            <TitleTable>
              <h5>{t('broker.eliteYearly')}</h5>
            </TitleTable>
            <StyledTable isElite={true}>
              <thead>
                <tr>
                  <th>{t('broker.listingName')}</th>
                  <th>{t('broker.price')}</th>
                  <th>{t('broker.standardListings')}</th>
                  <th>{t('broker.premiumListings')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('broker.broker_elite_2listings_yearly')}</td>
                  <td>1950{t('broker.$year')}</td>
                  <td>1{t('broker.listings')}</td>
                  <td>1{t('broker.listings')}</td>
                </tr>
                <tr>
                  <td>{t('broker.broker_elite_6listings_yearly')}</td>
                  <td>2920{t('broker.$year')}</td>
                  <td>4{t('broker.listings')}</td>
                  <td>2{t('broker.listings')}</td>
                </tr>
                <tr>
                  <td>{t('broker.broker_elite_12listings_yearly')}</td>
                  <td>3400{t('broker.$year')}</td>
                  <td>9{t('broker.listings')}</td>
                  <td>3{t('broker.listings')}</td>
                </tr>
                <tr>
                  <td>{t('broker.broker_elite_24listings_yearly')}</td>
                  <td>3880{t('broker.$year')}</td>
                  <td>20{t('broker.listings')}</td>
                  <td>4{t('broker.listings')}</td>
                </tr>
                <tr>
                  <td>{t('broker.broker_elite_48listings_yearly')}</td>
                  <td>5500{t('broker.$year')}</td>
                  <td>43{t('broker.listings')}</td>
                  <td>5{t('broker.listings')}</td>
                </tr>
              </tbody>
            </StyledTable>
          </TableDiv>
        </DivCol>
      </DivRow>
      <StyledFormDiv>
        <Container>
          <h4>{t('broker.chooseMyPlan')}</h4>
          <Form form={subscriptionForm}>
            <Form.Item name={'level'} label={t('broker.planType')}>
              <Radio.Group
                onChange={(event) => {
                  setLevel(event.target.value);
                }}
              >
                <Radio value={BrokerLevel.PRIVILEGE}>{t('broker.privilege')}</Radio>
                <Radio value={BrokerLevel.ELITE}>{t('broker.elite')}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name={'duration'} label={t('broker.duration')}>
              <Radio.Group
                onChange={(event) => {
                  setDuration(event.target.value);
                }}
              >
                <Radio value={BrokerDuration.MONTHLY}>{t('broker.monthly')}</Radio>
                <Radio value={BrokerDuration.YEARLY}>{t('broker.yearly')}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name={'plan'} label={t('broker.plan')}>
              <Select
                onChange={(value) => {
                  setPlan(value);
                }}
                disabled={level === undefined || duration === undefined}
                options={options?.map((option) => {
                  return {
                    value: option,
                    label: t(`broker.${option}`),
                  };
                })}
              />
            </Form.Item>
            <ButtonSmall
              disabled={plan === undefined}
              status={ButtonStatus.SECONDARY}
              block={true}
              text={t('broker.buyNow')}
              onClick={goToCheckOut}
            />
          </Form>
          <br />
          {duration === BrokerDuration.MONTHLY && <p>{t('broker.3monthsMin')}</p>}
          {duration === BrokerDuration.YEARLY && <p>{t('broker.30penalty')}</p>}
        </Container>
      </StyledFormDiv>
    </Spin>
  );
};

const DivRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1200px) {
    flex-flow: wrap;
  }
  gap: 24px;
`;

const DivCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
`;

const Card = styled.div<{ isElite: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: 614px;
  @media only screen and (max-width: 800px) {
    height: 736px;
  }
  width: 100%;
  max-width: 464px;

  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 5px;
  border: 0.5px solid #7b7b7d;

  border-top: ${(props) => (props.isElite ? `10px solid ${theme.color.gold}` : `10px solid ${theme.color.silver}`)};
`;

const TitleTable = styled.div`
  h5 {
    margin: 0;
    padding: 0;
  }
  text-align: center;
`;

const TableDiv = styled.div`
  @media only screen and (max-width: 800px) {
    width: 96vw;
    overflow-x: auto;
  }
`;

const StyledTable = styled.table<{ isElite: boolean }>`
  border-radius: 5px;
  border-collapse: collapse;

  min-width: 400px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  thead tr {
    background-color: ${(props) => (props.isElite ? theme.color.gold : theme.color.silver)};
    color: #ffffff;
    text-align: left;
  }
  th,
  td {
    padding: 12px 15px;
    font-size: 12px !important;
  }

  tbody tr {
    border-bottom: 1px solid #dddddd;
  }

  tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  tbody tr:last-of-type {
    border-bottom: 2px solid ${(props) => (props.isElite ? theme.color.gold : theme.color.silver)};
  }
  tbody tr.active-row {
    font-weight: bold;
    color: #009879;
  }
`;

const SubP = styled.p`
  color: ${theme.color.darkGrey} !important;
  margin-bottom: 32px;
`;

const StyledFormDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 64px;
`;

const Container = styled.div`
  max-width: 480px;
  width: 100%;
`;

const StyledH3 = styled.h3`
  margin-bottom: 24px !important;
`;
