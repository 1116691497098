import * as paymentService from '../../services/PaymentService';

import { Alert, Spin } from 'antd';
import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { OwnerSubscription, OwnerSubscriptions, parseOwnerPrices } from './helper';
import React, { useEffect, useState } from 'react';
import { UseMutationResult, UseQueryResult, useQuery } from '@tanstack/react-query';

import { AxiosInstance } from 'axios';
import { Feature } from './Feature';
import { HttpError } from '../../dtos/error';
import { OwnerSubscriptionEnum } from '../../dtos/subscription';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useAxiosMiddleware } from '../../services/useAxiosMiddleware';
import { useTranslation } from 'react-i18next';

type OwnerSubscriptionProps = {
  redirectToCheckout: (priceId: string) => Promise<void>;
  checkoutMutation: UseMutationResult<
    {
      sessionId: string;
    },
    unknown,
    {
      axiosMid: AxiosInstance;
      priceId: string;
      baseUrl: string;
      locale: string;
    },
    unknown
  >;
  subscriptionQuery: UseQueryResult<boolean | undefined, unknown>;
};
export const SubscriptionOwner = (props: OwnerSubscriptionProps): JSX.Element => {
  const { t } = useTranslation('subscription'),
    axiosMid = useAxiosMiddleware();

  const [prices, setPrices] = useState<undefined | OwnerSubscriptions>();
  const pricesQuery = useQuery(['getPrices'], () =>
    paymentService.getPrices({
      axiosMid: axiosMid,
      lookupKeys: [OwnerSubscriptionEnum.owner_1, OwnerSubscriptionEnum.owner_2, OwnerSubscriptionEnum.owner_3],
    }),
  );

  useEffect(() => {
    if (pricesQuery.data) {
      setPrices(parseOwnerPrices(pricesQuery.data.data as unknown as OwnerSubscription[]));
    }
  }, [pricesQuery.data]);

  return (
    <Spin
      size={'large'}
      spinning={pricesQuery.isLoading || props.checkoutMutation.isLoading || props.subscriptionQuery.isLoading}
    >
      <h3>{t('subscription:owner.title')}</h3>
      {(props.subscriptionQuery?.error as HttpError)?.response?.data.detail === 'billingError' && (
        <Alert
          message={t(`subscription:${(props.subscriptionQuery?.error as HttpError)?.response?.data.detail}`)}
          type="error"
          showIcon
        />
      )}
      <DivRow>
        {prices?.owner_1 && (
          <Card>
            <div>
              <Standard>{t('subscription:owner.ownerBasic.title')}</Standard>
              <p>
                <StyledPrice>{prices?.owner_1.unit_amount / 100}$</StyledPrice>
                <StyledMonth>{t('subscription:owner.ownerBasic.months')}</StyledMonth>
              </p>
              <SubP>
                {t('subscription:owner.ownerBasic.minPeriod', { price: (prices?.owner_1.unit_amount / 100) * 3 })}
              </SubP>
              <Feature
                content={'subscription:owner.ownerBasic.display'}
                tooltip={'subscription:owner.ownerBasic.displayHelp'}
              />
              <Feature content={'subscription:owner.ownerBasic.email'} />
              <Feature
                content={'subscription:owner.ownerBasic.visibility'}
                tooltip={'subscription:owner.ownerBasic.visibilityHelp'}
              />
              <Feature
                content={'subscription:owner.ownerBasic.legal'}
                tooltip={'subscription:owner.ownerBasic.legalHelp'}
              />
              <Feature content={'subscription:owner.ownerBasic.pictures'} />
            </div>
            <ButtonSmall
              status={ButtonStatus.SECONDARY}
              text={t('subscription:owner.buyNow')}
              block={true}
              onClick={() => {
                props.redirectToCheckout(prices?.owner_1.id || '');
              }}
            />
          </Card>
        )}
        {prices?.owner_2 && (
          <Card>
            <div>
              <Privilege>{t('subscription:owner.ownerPrivilege.title')}</Privilege>
              <p>
                <StyledPrice>{prices?.owner_2.unit_amount / 100}$</StyledPrice>
                <StyledMonth>{t('subscription:owner.ownerPrivilege.months')}</StyledMonth>
              </p>
              <SubP>
                {t('subscription:owner.ownerPrivilege.minPeriod', { price: (prices?.owner_2.unit_amount / 100) * 3 })}
              </SubP>
              <Feature
                content={'subscription:owner.ownerPrivilege.display'}
                tooltip={'subscription:owner.ownerPrivilege.displayHelp'}
              />

              <Feature content={t('subscription:owner.ownerPrivilege.email')} />
              <Feature
                content={t('subscription:owner.ownerPrivilege.visibility')}
                tooltip={t('subscription:owner.ownerPrivilege.visibilityHelp')}
              />
              <Feature
                content={t('subscription:owner.ownerPrivilege.legal')}
                tooltip={'subscription:owner.ownerBasic.legalHelp'}
              />
              <Feature content={t('subscription:owner.ownerPrivilege.pictures')} />
            </div>

            <ButtonSmall
              status={ButtonStatus.SECONDARY}
              text={t('subscription:owner.buyNow')}
              block={true}
              onClick={() => {
                props.redirectToCheckout(prices?.owner_2.id || '');
              }}
            />
          </Card>
        )}
        {prices?.owner_3 && (
          <Card>
            <div>
              <Elite>{t('subscription:owner.ownerElite.title')}</Elite>
              <p>
                <StyledPrice>{prices?.owner_3.unit_amount / 100}$</StyledPrice>
                <StyledMonth>{t('subscription:owner.ownerElite.months')}</StyledMonth>
              </p>
              <SubP>
                {t('subscription:owner.ownerElite.minPeriod', { price: (prices?.owner_3.unit_amount / 100) * 3 })}
              </SubP>
              <Feature
                content={'subscription:owner.ownerElite.display'}
                tooltip={'subscription:owner.ownerElite.displayHelp'}
              />

              <Feature content={t('subscription:owner.ownerElite.email')} />
              <Feature
                content={t('subscription:owner.ownerElite.visibility')}
                tooltip={t('subscription:owner.ownerElite.visibilityHelp')}
              />
              <Feature
                content={t('subscription:owner.ownerElite.legal')}
                tooltip={t('subscription:owner.ownerElite.legalHelp')}
              />

              <Feature content={t('subscription:owner.ownerElite.pictures')} />
              <Feature
                content={t('subscription:owner.ownerElite.video')}
                tooltip={t('subscription:owner.ownerElite.videoHelp')}
              />
              <Feature
                content={t('subscription:owner.ownerElite.featured')}
                tooltip={t('subscription:owner.ownerElite.featuredHelp')}
              />
            </div>
            <ButtonSmall
              status={ButtonStatus.SECONDARY}
              text={t('subscription:owner.buyNow')}
              block={true}
              onClick={() => {
                props.redirectToCheckout(prices?.owner_3.id || '');
              }}
            />
          </Card>
        )}
      </DivRow>
    </Spin>
  );
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  height: 596px;
  width: 100%;
  max-width: 400px;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 0.5px solid #7b7b7d;
`;

const DivRow = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1200px) {
    flex-flow: wrap;
  }
  gap: 32px;
`;

const StyledPrice = styled.span`
  font-size: ${theme.fontsize.h2} !important;
  font-weight: bold;
`;

const StyledMonth = styled.span`
  color: ${theme.color.darkGrey} !important;
  font-weight: bold;
`;

const SubP = styled.p`
  color: ${theme.color.darkGrey} !important;
`;

const Elite = styled.h5`
  color: ${theme.color.gold};
  font-weight: bold;
`;

const Privilege = styled.h5`
  color: ${theme.color.silver};
  font-weight: bold;
`;

const Standard = styled.h5`
  color: #cd7f32;
  font-weight: bold;
`;
