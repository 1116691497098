import { UploadFile } from 'antd';

export const franchiseTypeOptions = [
  { value: 110, label: 'Automobile' },
  { value: 130, label: 'Construction' },
  { value: 140, label: 'Entretien et nettoyage' },
  { value: 160, label: 'Hébergement et restauration' },
  { value: 115, label: 'Commerce de détail' },
  { value: 190, label: 'Services personnels et résidentiels' },
  { value: 185, label: 'Services professionnels et techniques' },
  { value: 100, label: 'Autres franchises' },
];

export type FranchiseDTO = {
  _id?: string;
  title: string;
  franchisor_email: string;
  plus_subscription: boolean;
  logo?: string;
  picture1?: string;
  picture1_en?: string;
  picture2?: string;
  picture2_en?: string;
  picture3?: string;
  picture3_en?: string;
  picture4?: string;
  picture4_en?: string;
  picture5?: string;
  picture5_en?: string;
  picture6?: string;
  picture6_en?: string;
  picture7?: string;
  picture7_en?: string;
  picture8?: string;
  picture8_en?: string;
  text1?: string[];
  text2?: string[];
  text3?: string[];
  text4?: string[];
  text5?: string[];
  text6?: string[];
  text7?: string[];
  text8?: string[];
  franchise_type?: number;
  cash_down?: number;
  total_investment_min?: number;
  total_investment_max?: number;
  video?: string;
  nb_franchise_ww?: number;
  nb_franchise_canada?: number;
  nb_franchise_quebec?: number;
  year_founded?: number;
  is_published?: boolean;
};

export type FranchiseForm = {
  title: string;
  franchisor_email: string;
  plus_subscription: boolean;
  logo?: { fileList: UploadFile[] };
  picture1?: { fileList: UploadFile[] };
  picture1_en?: { fileList: UploadFile[] };
  picture2?: { fileList: UploadFile[] };
  picture2_en?: { fileList: UploadFile[] };
  picture3?: { fileList: UploadFile[] };
  picture3_en?: { fileList: UploadFile[] };
  picture4?: { fileList: UploadFile[] };
  picture4_en?: { fileList: UploadFile[] };
  picture5?: { fileList: UploadFile[] };
  picture5_en?: { fileList: UploadFile[] };
  picture6?: { fileList: UploadFile[] };
  picture6_en?: { fileList: UploadFile[] };
  picture7?: { fileList: UploadFile[] };
  picture7_en?: { fileList: UploadFile[] };
  picture8?: { fileList: UploadFile[] };
  picture8_en?: { fileList: UploadFile[] };
  text1_fr?: string;
  text1_en?: string;
  text2_fr?: string;
  text2_en?: string;
  text3_fr?: string;
  text3_en?: string;
  text4_fr?: string;
  text4_en?: string;
  text5_fr?: string;
  text5_en?: string;
  text6_fr?: string;
  text6_en?: string;
  text7_fr?: string;
  text7_en?: string;
  text8_fr?: string;
  text8_en?: string;
  franchise_type?: number;
  cash_down?: number;
  total_investment_min?: number;
  total_investment_max?: number;
  video?: string;
  nb_franchise_ww?: number;
  nb_franchise_canada?: number;
  nb_franchise_quebec?: number;
  year_founded?: number;
  is_published?: boolean;
};

export type FranchiseInCache = {
  _id?: string;
  title: string;
  franchisor_email: string;
};

export type ContactFranchise = {
  franchises: FranchiseInCache[];
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  location: string;
  cash_down: string;
  availabilities: string[];
};
