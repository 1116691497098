import * as paymentService from '../../../services/PaymentService';
import * as userService from '../../../services/UserService';

import { Alert, Col, Divider, Row, Spin, Tooltip } from 'antd';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { privateRoutes, publicRoutes } from '../../../router/routes';
import { useMutation, useQuery } from '@tanstack/react-query';

import { HttpError } from '../../../dtos/error';
import { InfoCircleOutlined } from '@ant-design/icons';
import { LocaleContext } from '../../../router/Router';
import { UserContext } from '../../../contexts/UserContext';
import { ViewDashboard } from '../../../common/views/ViewDashboard';
import { getDiLanguageSettings } from '../../../services/NotificationService';
import styled from 'styled-components';
import { theme } from '../../../styles/theme';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useNavigate } from 'react-router-dom';

export const Profile = (): JSX.Element => {
  const { tokenErrorHandler, userType } = useContext(UserContext),
    { locale } = useContext(LocaleContext),
    { t } = useTranslation('profile'),
    navigate = useNavigate(),
    axiosMiddleware = useAxiosMiddleware();

  const userQuery = useQuery(['getUserData'], () => userService.getUserProfile(axiosMiddleware), {
    onError: tokenErrorHandler,
  });

  const languageQuery = useQuery(
    ['getDiUserLanguagetData'],
    () => getDiLanguageSettings({ axiosMid: axiosMiddleware }),
    {
      onError: tokenErrorHandler,
    },
  );

  const subscriptionActiveQuery = useQuery(['userHasActiveSubscription'], {
    queryFn: () => paymentService.userHasActiveSubscription({ axiosMid: axiosMiddleware }),
    refetchOnWindowFocus: false,
  });

  const portalMutation = useMutation(paymentService.stripePortalSession, {
    onSuccess: (res) => {
      window.location.assign(res.url);
    },
  });

  return (
    <ViewDashboard>
      <Spin
        size="large"
        spinning={
          userQuery.isLoading ||
          languageQuery.isLoading ||
          subscriptionActiveQuery.isLoading ||
          portalMutation.isLoading
        }
      >
        <h3>{t('title')}</h3>
        {(subscriptionActiveQuery?.error as HttpError)?.response?.data.detail === 'billingError' && (
          <Alert
            message={t(`${(subscriptionActiveQuery?.error as HttpError)?.response?.data.detail}`)}
            type="error"
            showIcon
          />
        )}
        <Divider />
        <Row align="middle">
          <Col span={8}>
            <PPrelineBold>{t('email')}</PPrelineBold>
          </Col>
          <Col span={8}>
            <p>{userQuery.data?.email}</p>
          </Col>
        </Row>
        <Divider />
        {!subscriptionActiveQuery.isLoading && subscriptionActiveQuery.data && (
          <>
            <Row>
              <Col span={8}>
                <PPrelineBold>{t('subscription')}</PPrelineBold>
              </Col>
              <Col span={8}>
                <PPreline>{t('subscriptionActive')}</PPreline>
              </Col>

              <Col span={8}>
                {userType === 'broker' ? (
                  <Trans i18nKey={'profile:editPlanTooltipBroker'} components={{ under: <u /> }} />
                ) : (
                  <StyledLink
                    onClick={() =>
                      portalMutation.mutate({
                        axiosMid: axiosMiddleware,
                        baseUrl: window.location.origin,
                        locale: locale,
                      })
                    }
                  >
                    {t('edit')}
                  </StyledLink>
                )}
              </Col>
              {userType !== 'broker' && (
                <Tooltip title={t('editPlanTooltipUser')}>
                  <PGrey>
                    {t('editPlan')} <InfoCircleOutlined />
                  </PGrey>
                </Tooltip>
              )}
            </Row>
            <Divider />
          </>
        )}

        <Row>
          <Col span={8}>
            <PPrelineBold>{t('role')}</PPrelineBold>
          </Col>
          <Col span={8}>
            <PPreline>{t(`${userQuery.data?.user_type}`)}</PPreline>
          </Col>
          <Col span={8}>
            {subscriptionActiveQuery.data ? (
              <Tooltip placement="topLeft" title={t('cantEdit')}>
                <StyledLinkNotAllowed>{t('edit')}</StyledLinkNotAllowed>
              </Tooltip>
            ) : (
              <StyledLink onClick={() => navigate(privateRoutes.updateRole(locale), { state: userQuery.data })}>
                {t('edit')}
              </StyledLink>
            )}
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={8}>
            <PPrelineBold>{t('update_user')}</PPrelineBold>
          </Col>
          <Col span={8}>
            <PPreline>{!userQuery.isLoading && userQuery.data?.first_name + '\n' + userQuery.data?.last_name}</PPreline>
          </Col>
          <Col span={8}>
            <StyledLink onClick={() => navigate(privateRoutes.updateUser(locale), { state: userQuery.data })}>
              {t('edit')}
            </StyledLink>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={8}>
            <PPrelineBold>{t('language')}</PPrelineBold>
          </Col>
          <Col span={8}>
            <PPreline>{t(`${languageQuery.data || '-'}`)}</PPreline>
          </Col>
          <Col span={8}>
            <StyledLink
              onClick={() =>
                navigate(privateRoutes.updateLanguage(locale), { state: { language: languageQuery.data } })
              }
            >
              {t('edit')}
            </StyledLink>
          </Col>
        </Row>
        <Divider />

        <Row align="middle">
          <Col span={8}>
            <PPrelineBold>{t('update_password')}</PPrelineBold>
          </Col>
          <Col span={8}>
            <p>********</p>
          </Col>
          <Col span={8}>
            <StyledLink onClick={() => navigate(publicRoutes.updatePassword(locale))}>{t('edit')}</StyledLink>
          </Col>
        </Row>
        <Divider />
      </Spin>
    </ViewDashboard>
  );
};

const PPrelineBold = styled.p`
  white-space: pre-line;
  font-weight: bold;
`;

const PPreline = styled.p`
  white-space: pre-line;
`;

const StyledLink = styled.p`
  text-decoration: underline;
  cursor: pointer;
  color: ${theme.color.electricBlue};
`;

const StyledLinkNotAllowed = styled.p`
  text-decoration: underline;
  cursor: not-allowed;
  color: ${theme.color.electricBlue};
`;

const PGrey = styled.p`
  color: ${theme.color.darkGrey};
`;
