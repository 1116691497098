import { Form, Select } from 'antd';

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export enum SortOptions {
  A_FIRST = 'a_first',
  Z_FIRST = 'z_first',
  HIGHEST_PRICE_FIRST = 'highest_price_first',
  LOWEST_PRICE_FIRST = 'lowest_price_first',
}

const Sort = [
  SortOptions.A_FIRST,
  SortOptions.Z_FIRST,
  SortOptions.HIGHEST_PRICE_FIRST,
  SortOptions.LOWEST_PRICE_FIRST,
];

export const FranchiseSort = (props: { setSortOption: React.Dispatch<React.SetStateAction<SortOptions>> }) => {
  const { t } = useTranslation('franchiseSection');
  const onChange = (formSort: SortOptions) => {
    props.setSortOption(formSort);
  };
  return (
    <StyledDiv>
      <Form>
        <Form.Item
          rules={[
            {
              required: false,
            },
          ]}
          name={'sort'}
          initialValue={Sort[0]}
          style={{ margin: 0 }}
        >
          <Select bordered={false} onChange={onChange}>
            {Sort.map((item: string) => (
              <Option value={item} key={item}>
                <StyledP>{t(`franchiseSort.${item}`)}</StyledP>
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </StyledDiv>
  );
};

const StyledP = styled.p`
  margin: 0;
  width: 208px;
  text-align: right;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;
