import './styles-antd-theme/antd-customized.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AppRouter } from './router/Router';
import { BrowserRouter } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import React from 'react';
import { StyledApp } from './styles/StyledApp';
import { UserProvider } from './contexts/UserProvider';
import i18n from './i18n';
import { loadStripe } from '@stripe/stripe-js';

const queryClient = new QueryClient();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_ACCESS_KEY || '');

export const App = (): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <StyledApp>
          <BrowserRouter>
            <I18nextProvider i18n={i18n}>
              <Elements stripe={stripePromise}>
                <UserProvider>
                  <AppRouter />
                </UserProvider>
              </Elements>
            </I18nextProvider>
          </BrowserRouter>
        </StyledApp>
      </HelmetProvider>
    </QueryClientProvider>
  );
};
