import { CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';

import React from 'react';
import { Tooltip } from 'antd';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

export const Feature = (props: { content: string; tooltip?: string }): JSX.Element => {
  return (
    <DivFlex>
      <StyledCheckOutlined />
      <p>
        <Trans i18nKey={props.content} components={{ bold: <strong /> }} />
        {props.tooltip && (
          <Tooltip
            title={
              <StyledPBreak>
                <Trans i18nKey={props.tooltip} components={{ bold: <strong /> }} />
              </StyledPBreak>
            }
          >
            <StyledInfoCircleOutlined />
          </Tooltip>
        )}
      </p>
    </DivFlex>
  );
};

const StyledCheckOutlined = styled(CheckOutlined)`
  padding-top: 6px;
  padding-right: 8px;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  padding-left: 8px;
`;

const StyledPBreak = styled.p`
  white-space: pre-line;
`;

const DivFlex = styled.div`
  display: flex;
`;
