import React from 'react';
import styled from 'styled-components';

type ViewCenteredProps = {
  children: React.ReactNode;
  maxWidth: string;
};

export const ViewCentered = (props: ViewCenteredProps): JSX.Element => (
  <Grid>
    <CenteredElement maxWidth={props.maxWidth}>{props.children}</CenteredElement>
  </Grid>
);

const Grid = styled.section`
  grid-area: main;
  margin-left: 4vw;
  margin-right: 4vw;
  @media only screen and (max-width: 1400px) {
    margin-left: 2vw;
    margin-right: 2vw;
  }
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
`;

const CenteredElement = styled.section<{ maxWidth: string }>`
  max-width: ${(props) => props.maxWidth};
  width: 100%;
  padding-bottom: 100px;

  .ant-picker,
  .ant-input-number,
  .ant-space,
  textarea {
    width: 100%;
    justify-content: center;
  }
  .ant-input-group {
    .ant-row {
      width: 50%;
      margin-bottom: 0px;
    }
  }
`;
