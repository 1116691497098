import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import React, { useContext } from 'react';

import { LocaleContext } from '../../router/Router';
import { UserContext } from '../../contexts/UserContext';
import { publicRoutes } from '../../router/routes';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const BottomBar = (props: { favCnt: number; setVisibleForm: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const { t } = useTranslation('franchiseSection');
  const navigate = useNavigate();
  const { authTokens } = useContext(UserContext);
  const { locale } = useContext(LocaleContext);
  return (
    <StyledDiv cnt={props.favCnt}>
      <div>
        <WhiteP>{t('bottomBar.contact', { cnt: props.favCnt })}</WhiteP>
      </div>
      <div>
        <ButtonSmall
          status={ButtonStatus.PRIMARY}
          text={t('bottomBar.button')}
          onClick={() => (authTokens ? props.setVisibleForm(true) : navigate(publicRoutes.signin(locale)))}
        />
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div<{ cnt: number }>`
  bottom: 0;
  position: absolute;
  width: 100%;
  background-color: ${theme.color.darkBlue};
  display: ${(props) => {
    if (props.cnt > 0) {
      return 'flex';
    }
    return 'none';
  }};

  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 0 4vw;
`;

const WhiteP = styled.h6`
  color: ${theme.color.white};
  margin-top: 32px;
  margin-bottom: 32px;
`;
