export const industriesFr = [
  {
    '105': ['10505', '10510', '10515', '10520', '10525', '10595'],
  },
  { '110': ['11005', '11010', '11015', '11020', '11025', '11030', '11035', '11040', '11045', '11050', '11095'] },
  {
    '115': [
      '11503',
      '11506',
      '11566',
      '11560',
      '11509',
      '11512',
      '11515',
      '11569',
      '11572',
      '11518',
      '11521',
      '11524',
      '11527',
      '11530',
      '11533',
      '11581',
      '11578',
      '11563',
      '11536',
      '11539',
      '11542',
      '11545',
      '11548',
      '11551',
      '11554',
      '11575',
      '11557',
      '11595',
    ],
  },
  {
    '120': ['12005', '12010', '12015', '12025', '12020', '12030', '12035', '12040', '12095'],
  },
  {
    '125': [
      '12505',
      '12510',
      '12515',
      '12520',
      '12525',
      '12530',
      '12535',
      '12540',
      '12545',
      '12550',
      '12555',
      '12560',
      '12595',
    ],
  },
  {
    '130': ['13005', '13010', '13015', '13020', '13025', '13030', '13095'],
  },
  {
    '135': ['13505', '13510', '13515', '13520', '13525', '13595'],
  },
  {
    '140': ['14005', '14010', '14015', '14020', '14025', '14030', '14035', '14040', '14045', '14050', '14055', '14095'],
  },
  {
    '145': [
      '14503',
      '14506',
      '14509',
      '14512',
      '14515',
      '14518',
      '14521',
      '14524',
      '14527',
      '14530',
      '14533',
      '14536',
      '14539',
      '14542',
      '14545',
      '14548',
      '14551',
      '14554',
      '14563',
      '14565',
      '14557',
      '14560',
      '14595',
    ],
  },
  {
    '150': ['15005', '15010', '15015', '15020', '15025', '15030', '15095'],
  },
  {
    '155': ['15505', '15510', '15515', '15520', '15525', '15530', '15535', '15545', '15550', '15540', '15595'],
  },
  {
    '160': ['16005', '16010', '16015', '16020', '16025', '16035', '16030', '16040', '16050', '16045', '16095'],
  },
  {
    '163': [
      '16305',
      '16310',
      '16315',
      '16320',
      '16325',
      '16330',
      '16335',
      '16340',
      '16345',
      '16350',
      '16355',
      '16360',
      '16365',
      '16370',
      '16375',
      '16380',
      '16383',
      '16385',
      '16395',
    ],
  },
  {
    '164': ['16405', '16410', '16415', '16495'],
  },
  {
    '165': ['16505', '16510', '16515', '16520', '16595'],
  },
  {
    '170': ['17005', '17010', '17015', '17020', '17025', '17030', '17095'],
  },
  {
    '180': [
      '18005',
      '18010',
      '18015',
      '18020',
      '18025',
      '18030',
      '18035',
      '18040',
      '18050',
      '18045',
      '18055',
      '18060',
      '18065',
      '18095',
    ],
  },
  {
    '190': [
      '19005',
      '19010',
      '19015',
      '19065',
      '19020',
      '19060',
      '19025',
      '19030',
      '19035',
      '19040',
      '19045',
      '19050',
      '19055',
      '19070',
      '19095',
    ],
  },
  {
    '185': [
      '18503',
      '18506',
      '18509',
      '18512',
      '18515',
      '18518',
      '18521',
      '18562',
      '18527',
      '18530',
      '18533',
      '18536',
      '18524',
      '18539',
      '18542',
      '18545',
      '18548',
      '18551',
      '18554',
      '18557',
      '18560',
      '18565',
      '18595',
    ],
  },
  {
    '195': [
      '19505',
      '19510',
      '19515',
      '19520',
      '19525',
      '19530',
      '19535',
      '19540',
      '19545',
      '19550',
      '19555',
      '19560',
      '19565',
      '19570',
      '19595',
    ],
  },
  {
    '196': [
      '19605',
      '19610',
      '19615',
      '19620',
      '19625',
      '19630',
      '19635',
      '19640',
      '19650',
      '19655',
      '19660',
      '19645',
      '19695',
    ],
  },
  {
    '198': ['19810', '19815', '19820', '19825', '19830', '19835', '19840', '19845', '19850', '19895'],
  },
];

export const industriesEn = [
  {
    '105': ['10515', '10520', '10525', '10510', '10505', '10595'],
  },
  {
    '110': ['11005', '11030', '11010', '11025', '11020', '11045', '11015', '11035', '11050', '11040', '11095'],
  },
  {
    '163': [
      '16365',
      '16355',
      '16340',
      '16360',
      '16335',
      '16310',
      '16315',
      '16320',
      '16325',
      '16350',
      '16345',
      '16305',
      '16330',
      '16370',
      '16375',
      '16380',
      '16383',
      '16385',
      '16395',
    ],
  },
  {
    '125': [
      '12510',
      '12505',
      '12560',
      '12535',
      '12540',
      '12545',
      '12530',
      '12525',
      '12515',
      '12550',
      '12555',
      '12520',
      '12595',
    ],
  },
  {
    '130': ['13005', '13010', '13015', '13025', '13020', '13030', '13095'],
  },
  {
    '135': ['13520', '13510', '13515', '13505', '13525', '13595'],
  },
  {
    '150': ['15010', '15005', '15025', '15030', '15015', '15020', '15095'],
  },
  {
    '180': [
      '18020',
      '18015',
      '18010',
      '18005',
      '18025',
      '18035',
      '18030',
      '18045',
      '18060',
      '18040',
      '18050',
      '18055',
      '18065',
      '18095',
    ],
  },
  {
    '155': ['15505', '15510', '15545', '15550', '15535', '15515', '15520', '15525', '15530', '15540', '15595'],
  },
  {
    '164': ['16410', '16415', '16405', '16495'],
  },
  {
    '165': ['16505', '16520', '16515', '16510', '16595'],
  },
  {
    '160': ['16005', '16020', '16010', '16015', '16025', '16035', '16030', '16045', '16040', '16050', '16095'],
  },
  {
    '140': ['14020', '14025', '14030', '14035', '14040', '14050', '14005', '14055', '14015', '14010', '14045', '14095'],
  },
  {
    '145': [
      '14536',
      '14560',
      '14509',
      '14515',
      '14521',
      '14503',
      '14524',
      '14542',
      '14518',
      '14548',
      '14551',
      '14530',
      '14506',
      '14533',
      '14512',
      '14527',
      '14554',
      '14545',
      '14563',
      '14565',
      '14557',
      '14539',
      '14595',
    ],
  },
  {
    '190': [
      '19010',
      '19060',
      '19025',
      '19055',
      '19030',
      '19015',
      '19005',
      '19050',
      '19035',
      '19045',
      '19065',
      '19020',
      '19070',
      '19040',
      '19095',
    ],
  },
  {
    '185': [
      '18512',
      '18554',
      '18503',
      '18565',
      '18509',
      '18539',
      '18562',
      '18542',
      '18530',
      '18518',
      '18527',
      '18506',
      '18545',
      '18515',
      '18548',
      '18533',
      '18551',
      '18524',
      '18536',
      '18521',
      '18560',
      '18557',
      '18595',
    ],
  },
  {
    '170': ['17005', '17015', '17020', '17025', '17030', '17010', '17095'],
  },
  {
    '115': [
      '11560',
      '11533',
      '11557',
      '11521',
      '11518',
      '11542',
      '11572',
      '11578',
      '11563',
      '11509',
      '11524',
      '11506',
      '11536',
      '11530',
      '11569',
      '11548',
      '11545',
      '11515',
      '11539',
      '11527',
      '11566',
      '11551',
      '11554',
      '11512',
      '11575',
      '11503',
      '11581',
      '11595',
    ],
  },
  {
    '195': [
      '19540',
      '19555',
      '19520',
      '19525',
      '19535',
      '19550',
      '19565',
      '19545',
      '19570',
      '19560',
      '19510',
      '19515',
      '19530',
      '19505',
      '19595',
    ],
  },
  {
    '196': [
      '19650',
      '19620',
      '19640',
      '19625',
      '19660',
      '19655',
      '19635',
      '19615',
      '19610',
      '19645',
      '19605',
      '19630',
      '19695',
    ],
  },
  {
    '198': ['19840', '19845', '19820', '19830', '19850', '19810', '19815', '19835', '19825', '19895'],
  },
  {
    '120': ['12005', '12040', '12020', '12010', '12015', '12025', '12030', '12035', '12095'],
  },
];
