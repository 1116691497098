import React, { useContext, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { Listing } from '../../dtos/listingCreation';
import { LocaleContext } from '../../router/Router';
import fallback from './../../img/fallbackimg.webp';
import { getCarouselListing } from '../../services/ListingService';
import styled from 'styled-components';
import { tCustom } from '../../services/TranslationService';
import { theme } from '../../styles/theme';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

const formatter = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const BusinessCarousel = () => {
  const { locale } = useContext(LocaleContext),
    { t } = useTranslation('home');
  const [businesses, setBusinesses] = useState<Listing[]>([]);
  const NUMBER_OF_LISTINGS = 4;

  const getCarouselQuery = useQuery({
    queryKey: ['getCarouselListing'],
    queryFn: () =>
      getCarouselListing({
        numberOfListings: NUMBER_OF_LISTINGS,
      }),
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    setBusinesses(() => {
      const tempBusiness: Listing[] = [];
      let currBusiness = 0;
      if (getCarouselQuery.data === undefined) {
        return [];
      }
      while (tempBusiness.length < NUMBER_OF_LISTINGS) {
        const business = getCarouselQuery.data?.[currBusiness] ?? undefined;
        if (business) {
          tempBusiness.push(business);
        }
        currBusiness = (currBusiness + 1) % NUMBER_OF_LISTINGS;
      }
      return tempBusiness;
    });
  }, [getCarouselQuery.data]);

  return getCarouselQuery.isError ? (
    <></>
  ) : (
    <>
      <FlexDiv>
        {businesses?.map((listing, index) => (
          <Link key={`${index}`} to={`../fl/${listing._id}`}>
            <Container>
              <StyledImg src={listing.additional_info.picture_primary || fallback} height={240} width={240} />
              <FilterOnHover />
              <Content>
                <Title>{tCustom(listing.basic_info?.listing_title, locale)}</Title>
                {listing.basic_info.asking_price ? (
                  <Price>{formatter.format(listing.basic_info.asking_price)}</Price>
                ) : (
                  <Price>{t('carrousels.openToOffers')}</Price>
                )}
              </Content>
            </Container>
          </Link>
        ))}
      </FlexDiv>
    </>
  );
};

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 16px;
  justify-content: space-between;
  justify-content: center;
  overflow: hidden;
  gap: 24px;
`;

const Container = styled.div`
  width: 240px;
  height: 240px;
  display: block;
  position: relative;
`;

const Content = styled.div`
  background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.65) 40%, rgba(0, 0, 0, 0.65) 50%);
  border-radius: 16px;

  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  padding: 8px;
`;

const FilterOnHover = styled.div`
  :hover {
    background: ${theme.color.darkBlue + '40'};
  }
  border-radius: 16px;

  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const StyledImg = styled.img`
  object-fit: cover;
  border-radius: 16px;
  border: 1px solid #d4d4d4;
`;

const Price = styled.p`
  margin-bottom: 0;
  font-size: 18px !important;
  color: ${theme.color.white};
`;

const Title = styled.p`
  font-size: 16px !important;
  margin-bottom: 0;
  color: ${theme.color.white};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
