import { AxiosInstance } from 'axios';

export const addToFavorites = async (params: { listingid: string; axiosMid: AxiosInstance }): Promise<string[]> => {
  const res = await params.axiosMid({
    method: 'PUT',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `listingfavorite/add/${params.listingid}`,
  });
  return res.data;
};

export const removeFromFavorites = async (params: {
  listingid: string;
  axiosMid: AxiosInstance;
}): Promise<string[]> => {
  const res = await params.axiosMid({
    method: 'PUT',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `listingfavorite/remove/${params.listingid}`,
  });
  return res.data;
};
