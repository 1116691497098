import React, { useRef } from 'react';

import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const FaqFR = () => {
  const t1 = useRef(null);
  const t2 = useRef(null);
  const t3 = useRef(null);
  const t4 = useRef(null);

  // eslint-disable-next-line
  const executeScroll = (ref: any) => {
    ref.current.scrollIntoView();
  };

  return (
    <div>
      <StyledTitle>Sections</StyledTitle>
      <Sections onClick={() => executeScroll(t1)}>Informations g&eacute;n&eacute;rales</Sections>
      <Sections onClick={() => executeScroll(t2)}>Services pour les membres</Sections>
      <Sections onClick={() => executeScroll(t3)}>Services pour les propri&eacute;taires</Sections>
      <Sections onClick={() => executeScroll(t4)}>Services pour les courtiers</Sections>
      <br />
      <br />

      <StyledTitle ref={t1}>Informations g&eacute;n&eacute;rales</StyledTitle>
      <p>
        <strong>Qui sont vos membres?</strong>
      </p>
      <p>
        Nos membres sont de potentiels acqu&eacute;reurs et vendeurs qualifi&eacute;s d&apos;entreprises et de
        franchises et des professionnels offrant des services d&rsquo;accompagnement.
      </p>
      <p>
        <strong>Acquisition.biz agit-elle &agrave; titre d&apos;interm&eacute;diaire dans la transaction?</strong>
      </p>
      <p>
        Non. Nous fournissons seulement la plateforme qui vous met en lien avec les acqu&eacute;reurs et les vendeurs
        commerciaux potentiels.
      </p>
      <StyledTitle ref={t2}>Services pour les membres</StyledTitle>
      <p>
        <strong>Combien en co&ucirc;te-t-il pour devenir membre de Acquizition.biz?</strong>
      </p>
      <p>
        Rien, en effet, la consultation des inscriptions, l&apos;abonnement aux alertes personnalis&eacute;es et la
        prise de contact avec les annonceurs sont gratuits.
      </p>
      <p>
        <strong>Comment puis-je trouver des occasions d&apos;affaires?</strong>
      </p>
      <p>
        Vous pouvez cr&eacute;er votre profil de recherche sur le site &agrave; l&apos;aide des filtres dans la section
        &laquo; Entreprises &agrave; vendre &raquo;. Vous n&apos;avez qu&apos;&agrave; indiquer l&rsquo;emplacement qui
        vous int&eacute;resse, le prix, et le secteur d&apos;activit&eacute;. Vous pouvez directement consulter les
        possibilit&eacute;s qui correspondent &agrave; vos crit&egrave;res ou demander de les recevoir par courriel au
        fur et &agrave; mesure qu&apos;elles se pr&eacute;sentent, ou une fois par semaine.
      </p>
      <p>
        <strong>
          Comment puis-je &ecirc;tre avis&eacute; de nouvelles inscriptions correspondant &agrave; ce que je recherche?
        </strong>
      </p>
      <p>
        C&apos;est tr&egrave;s simple. Vous n&apos;avez qu&apos;&agrave; vous diriger vers la section &laquo; Alertes et
        courriels &raquo; dans votre compte et y d&eacute;finir ce que vous recherchez. Les nouvelles inscriptions
        correspondant &agrave; ces crit&egrave;res vous seront automatiquement achemin&eacute;es par courriel.
      </p>
      <StyledTitle ref={t3}>Services pour les propri&eacute;taires</StyledTitle>
      <StyledSubtitle>Paiement et inscription pour des propri&eacute;taires</StyledSubtitle>
      <p>
        <strong>Combien en co&ucirc;te-t-il pour annoncer une occasion d&rsquo;affaires sur Acquizition.biz?</strong>
      </p>
      <p>
        Une annonce de base co&ucirc;te 160 $/mois, une annonce privil&egrave;ge co&ucirc;te 215 $/mois et une annonce
        &eacute;lite co&ucirc;te 245 $/mois. Plus le niveau d&apos;inscription est &eacute;lev&eacute;, plus
        l&apos;exposition et les fonctionnalit&eacute;s sont importantes, ce qui suscite l&apos;int&eacute;r&ecirc;t des
        acheteurs. Svp, veuillez noter que la p&eacute;riode minimale d&rsquo;inscription est de trois mois.
      </p>
      <p>
        <strong>Puis-je acheter une annonce pour un mois seulement ?</strong>
      </p>
      <p>
        Non, nos conditions d&apos;inscription sont de 3 mois. Cette dur&eacute;e garantit que vous disposez du temps
        n&eacute;cessaire pour commercialiser votre entreprise de mani&egrave;re appropri&eacute;e, car trouver le bon
        acheteur prend g&eacute;n&eacute;ralement plusieurs mois.
      </p>
      <p>
        <strong>
          Si je trouve un potentiel acqu&eacute;reur avant que la p&eacute;riode minimale de 3 mois se termine, est-ce
          que je peux avoir un remboursement pour la diff&eacute;rence?
        </strong>
      </p>
      <p>
        Non, nous n&rsquo;offrons pas de remboursement si le contrat est combl&eacute; avant
        l&rsquo;&eacute;ch&eacute;ance de la p&eacute;riode minimale de 3 mois.
      </p>
      <p>
        <strong>Que se passe-t-il &agrave; la fin de ma p&eacute;riode d&apos;inscription de 3 mois ?</strong>
      </p>
      <p>
        &Agrave; la fin de votre mandat de 3 mois, votre annonce se poursuivra &agrave; votre tarif mensuel afin que
        vous puissiez continuer &agrave; atteindre le plus grand nombre d&apos;acheteurs potentiels avec une
        flexibilit&eacute; mensuelle. Vous pouvez annuler &agrave; tout moment, mais vous devrez acheter une nouvelle
        annonce de 3 mois si vous d&eacute;cidez de renouveler apr&egrave;s l&apos;expiration de votre annonce. Svp,
        veuillez noter qu&apos;il n&apos;est pas rare que certaines entreprises prennent un an ou plus pour trouver le
        bon acheteur.
      </p>
      <p>
        <strong>Puis-je d&eacute;sactiver le renouvellement mensuel ?</strong>
      </p>
      <p>
        Oui. Votre annonce expirera &agrave; la fin de votre mandat et sera supprim&eacute;e de la plateforme. Une fois
        annul&eacute;, vous ne pourrez pas renouveler cette annonce avec une flexibilit&eacute; mensuelle et devrez
        acheter un autre terme de 3 mois et cr&eacute;er une nouvelle annonce si vous d&eacute;cidez de faire de la
        publicit&eacute; plus tard.
      </p>
      <p>
        <strong>Par quel moyen puis-je payer pour m&rsquo;inscrire &agrave; un forfait? </strong>
      </p>
      <p>
        Tout paiement se fait par une carte de cr&eacute;dit. Nous acceptons Visa, MasterCard, et American Express pour
        le paiement. Nous n&rsquo;acceptons pas de ch&egrave;que ou de virements bancaires.
      </p>
      <StyledSubtitle>Cr&eacute;ation de l&rsquo;annonce</StyledSubtitle>
      <p>
        <strong>Quels renseignements dois-je fournir lorsque j&apos;inscris mon entreprise?</strong>
      </p>
      <p>
        Une fois inscrit &agrave; un forfait de votre choix, vous n&apos;avez que vous dirigez vers la section &laquo;
        Vendre une entreprise &raquo; dans votre compte, cr&eacute;er une nouvelle annonce et indiquer les
        renseignements que vous voulez r&eacute;v&eacute;ler. L&apos;information que vous voulez divulguer est &agrave;
        votre enti&egrave;re discr&eacute;tion. Vous pouvez inclure votre secteur d&apos;activit&eacute;, une
        description de votre entreprise, le cas &eacute;ch&eacute;ant, le type de transaction recherch&eacute;e et vos
        coordonn&eacute;es. De notre exp&eacute;rience, une annonce avec une description compl&egrave;te et
        d&eacute;taill&eacute;e, qui pr&eacute;sente bien le parcours, les valeurs et les &eacute;tats financiers de
        l&rsquo;entreprise &agrave; plus de chances d&rsquo;attirer les potentiels acqu&eacute;reurs. Notez bien : vous
        &ecirc;tes seul &agrave; d&eacute;cider de ce que vous voulez divulguer.
      </p>
      <p>
        <strong>
          Est-il possible de pr&eacute;senter mon dossier aussi bien en fran&ccedil;ais qu&apos;en anglais?
        </strong>
      </p>
      <p>
        Oui, pour ce faire, vous n&apos;avez qu&apos;&agrave; activer la fonctionnalit&eacute; &laquo; Compl&eacute;ter
        cette annonce en fran&ccedil;ais et en anglais &raquo; pendant la cr&eacute;ation de l&rsquo;annonce.
      </p>
      <p>
        <strong>Puis-je vous envoyer mon annonce par courriel ?</strong>
      </p>
      <p>
        Non. Toutes les annonces sont plac&eacute;es via le formulaire en ligne sur notre site Web. Si toutefois, vous
        avez besoin d&apos;aide pour remplir la demande, n&apos;h&eacute;sitez pas &agrave; nous contacter au 514
        499-0334 ou 1-866-499-0334.
      </p>
      <StyledSubtitle>Confidentialit&eacute;</StyledSubtitle>
      <p>
        <strong>
          Comment prot&eacute;gez-vous mon identit&eacute; lorsque je communique avec un client potentiel?
        </strong>
      </p>
      <p>
        Notre syst&egrave;me est express&eacute;ment con&ccedil;u pour prot&eacute;ger votre confidentialit&eacute; du
        premier contact avec les potentiels acqu&eacute;reurs ou vendeurs. Nous mettons &agrave; votre disposition le
        formulaire de contact anonyme pour recevoir la premi&egrave;re demande de la part des membres. Votre
        correspondant ne voit pas votre nom, votre adresse &eacute;lectronique ni tout autre renseignement ne pouvant
        servir &agrave; vous identifier. Suite &agrave; ce premier contact, vous pourrez poursuivre la communication
        selon votre discr&eacute;tion.
      </p>
      <StyledSubtitle>Accompagnement de la vente</StyledSubtitle>
      <p>
        <strong>
          Est-ce que vous offrez des services professionnels d&rsquo;accompagnement de la vente et de l&rsquo;achat des
          entreprises?
        </strong>
      </p>
      <p>
        Une inscription &agrave; un de nos forfaits vous donne un acc&egrave;s &agrave; une trousse transactionnelle qui
        inclut des documents de base tels qu&rsquo;un engagement de confidentialit&eacute;, une lettre
        d&rsquo;intention, une entente de n&eacute;gociation exclusive. Vous y trouverez &eacute;galement une
        description de la d&eacute;marche de la vente. En cr&eacute;ant la trousse, notre objectif &eacute;tait de vous
        offrir des mod&egrave;les de documents qui pourront vous &ecirc;tre utiles pendant la premi&egrave;re
        &eacute;tape de la vente de votre entreprise. Nous n&rsquo;offrons pas d&rsquo;accompagnement professionnel
        au-del&agrave; de la trousse transactionnelle.
      </p>
      <p>
        Nous vous offrons &eacute;galement un acc&egrave;s &agrave; un r&eacute;pertoire des courtiers qui pourront
        faciliter la r&eacute;ussite de la vente de votre entreprise.
      </p>
      <StyledTitle ref={t4}>Services pour les courtiers</StyledTitle>
      <StyledSubtitle>Paiement et inscription</StyledSubtitle>
      <p>
        <strong>
          Combien en co&ucirc;te-t-il pour annoncer des occasions d&rsquo;affaires sur Acquizition.biz en &eacute;tant
          un repr&eacute;sentant ou un courtier?
        </strong>
      </p>
      <p>
        Nous proposons des forfaits con&ccedil;us en fonction de nombre d&rsquo;annonces et de la dur&eacute;e. Vous
        pouvez consulter nos tarifs en cr&eacute;ant un compte comme courtier ou en nous envoyant une demande
        d&rsquo;information sur <a href="mailto:info@acquizition.biz">info@acquizition.biz</a>.
      </p>
      <p>
        <strong>Par quel moyen puis-je payer pour m&rsquo;inscrire &agrave; un forfait? </strong>
      </p>
      <p>
        Tout paiement se fait par une carte de cr&eacute;dit. Nous acceptons Visa, MasterCard, et American Express pour
        le paiement. Nous n&rsquo;acceptons pas de ch&egrave;que ou de virements bancaires.
      </p>
      <StyledSubtitle>Forfaits mensuels&nbsp;</StyledSubtitle>
      <p>
        *Veuillez noter que tous nos forfaits mensuels sont offerts avec une p&eacute;riode minimale d&rsquo;inscription
        de 3 mois
      </p>
      <p>
        <strong>
          Si je comble mes mandats avant que la p&eacute;riode minimale de 3 mois se termine, est-ce que je peux avoir
          un remboursement pour la diff&eacute;rence?
        </strong>
      </p>
      <p>
        Non, nous n&rsquo;offrons pas de remboursement si les mandats sont combl&eacute;s avant
        l&rsquo;&eacute;ch&eacute;ance de la p&eacute;riode minimale de 3 mois.
      </p>
      <p>
        <strong>Que se passe-t-il &agrave; la fin de la p&eacute;riode d&apos;inscription de 3 mois ?</strong>
      </p>
      <p>
        &Agrave; la fin de votre mandat de 3 mois, vos annonces se poursuivront &agrave; votre tarif mensuel afin que
        vous puissiez continuer &agrave; atteindre le plus grand nombre d&apos;acheteurs potentiels avec une
        flexibilit&eacute; mensuelle. Vous pouvez annuler &agrave; tout moment, mais vous devrez acheter un nouveau
        contrat de 3 mois si vous d&eacute;cidez de renouveler apr&egrave;s l&apos;expiration de vos annonces.
      </p>
      <p>
        <strong>Puis-je d&eacute;sactiver le renouvellement mensuel ?</strong>
      </p>
      <p>
        Oui. Vos annonces expireront &agrave; la fin de votre mandat et seront supprim&eacute;es de la plateforme. Une
        fois annul&eacute;, vous ne pourrez pas renouveler ces annonces avec une flexibilit&eacute; mensuelle et devrez
        acheter un autre terme de 3 mois et cr&eacute;er de nouvelles annonces si vous d&eacute;cidez de faire de la
        publicit&eacute; plus tard.
      </p>
      <StyledSubtitle>Forfaits annuels</StyledSubtitle>
      <p>
        <strong>
          Si je comble mes mandats avant que la p&eacute;riode d&rsquo;inscription se termine, est-ce que je peux avoir
          un remboursement pour la diff&eacute;rence?
        </strong>
      </p>
      <p>
        Vu que les forfaits annuels sont con&ccedil;us afin de vous offrir les meilleurs prix, nous n&rsquo;offrons pas
        de remboursement si les mandats sont combl&eacute;s avant l&rsquo;&eacute;ch&eacute;ance de la p&eacute;riode
        d&rsquo;inscription.
      </p>
      <StyledSubtitle>Offre de services professionnels</StyledSubtitle>
      <p>
        <strong>
          J&apos;offre des services professionnels reli&eacute;s aux transactions d&apos;entreprises, mais je ne suis
          pas un courtier. Est-ce que je peux promouvoir mes services sur votre plateforme?
        </strong>
      </p>
      <p>
        Nos membres qui ach&egrave;tent ou vendent des entreprises ont souvent besoin de s&apos;adjoindre les services
        de professionnels. Nous sommes ravis de vous proposer diff&eacute;rents formats de publicit&eacute;s afin
        d&rsquo;offrir un maximum de visibilit&eacute; aupr&egrave;s d&apos;une client&egrave;le tr&egrave;s
        cibl&eacute;e.
      </p>
    </div>
  );
};

export const StyledTitle = styled.h4`
  color: ${theme.color.darkBlue};
`;

export const StyledSubtitle = styled.h5`
  color: ${theme.color.darkBlue};
`;

export const Sections = styled.p`
  text-decoration: underline;
  color: ${theme.color.electricBlue};
  cursor: pointer;
`;
