import { ActionSearchBusinessEnum, ActionSearchBusinesses } from '../BusinessesForSale';
import { BellOutlined, FilterOutlined } from '@ant-design/icons';
import { ButtonSmall, ButtonStatus } from '../../../common/button/ButtonSmall';
import { Form, InputNumber } from 'antd';
import { FormSearchParameters, SearchParameters } from '../../../dtos/businessesForSale';
import React, { useContext } from 'react';

import { LocaleContext } from '../../../router/Router';
import { NavLink } from 'react-router-dom';
import { SearchIndustries } from './SearchIndustries';
import { SearchRegions } from './SearchRegions';
import { privateRoutes } from '../../../router/routes';
import styled from 'styled-components';
import { theme } from '../../../styles/theme';
import { useTranslation } from 'react-i18next';

const toForm = (dispState: SearchParameters) => {
  return {
    price_min: dispState.price?.min,
    price_max: dispState.price?.max,
    regions: dispState.regions?.map((reg) => reg.toString()) || [],
    industries: dispState.industries?.map((ind) => ind.toString()) || [],
  };
};

type FilterPanelProps = {
  dispatcher: [SearchParameters, React.Dispatch<ActionSearchBusinesses>];
};

const formatNumber = (value: string | undefined) => {
  return value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '';
};

export const FilterPanel = (props: FilterPanelProps): JSX.Element => {
  const { t } = useTranslation('businessesForSale');
  const [dispState, dispatch] = props.dispatcher;
  const [filterForm] = Form.useForm();
  const { locale } = useContext(LocaleContext);
  const onValuesChange = () => {
    const formData: FormSearchParameters = filterForm.getFieldsValue();
    if (
      filterForm.getFieldValue('price_min') &&
      filterForm.getFieldValue('price_max') &&
      filterForm.getFieldValue('price_min') >= filterForm.getFieldValue('price_max')
    ) {
      return;
    }
    formData.industries = formData.industries || [];
    formData.industries = formData.industries.map((ind) => Number(ind));
    formData.regions = formData.regions || [];
    formData.regions = formData.regions.map((reg) => Number(reg));
    const searchParameters: SearchParameters = {
      regions: formData.regions,
      price: {
        min: formData.price_min,
        max: formData.price_max,
      },
      industries: formData.industries,
    };
    dispatch({ type: ActionSearchBusinessEnum.FILTER, searchParameters });
  };

  return (
    <StyledDiv>
      <TopDiv>
        <GrayP>
          <StyledFilterIcon />
          {t('businessesForSale:search.title')}
        </GrayP>

        <ClearP
          onClick={() => {
            filterForm.setFields([
              { name: 'industries', value: [] },
              { name: 'regions', value: [] },
              { name: 'price_min', value: undefined },
              { name: 'price_max', value: undefined },
            ]);
            onValuesChange();
          }}
        >
          {t('businessesForSale:search.clear')}
        </ClearP>
      </TopDiv>
      <Form form={filterForm} onValuesChange={onValuesChange} initialValues={toForm(dispState)}>
        <SearchRegions />
        <BoldP>{t('businessesForSale:search.price')}</BoldP>
        <HorizontalDiv>
          <Form.Item
            name={'price_min'}
            rules={[
              {
                type: 'number',
                min: 0,
                max: 100000000,
                message: t('businessesForSale:search.invalidPrice'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('price_max') > value || !getFieldValue('price_max')) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('businessesForSale:search.minMaxRule')));
                },
              }),
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              formatter={formatNumber}
              placeholder={t('businessesForSale:search.min')}
            />
          </Form.Item>
          <NormalP style={{ paddingLeft: '0.5vw', paddingRight: '0.5vw' }}>{t('businessesForSale:search.to')}</NormalP>
          <Form.Item
            name={'price_max'}
            rules={[
              {
                type: 'number',
                min: 0,
                max: 100000000,
                message: t('businessesForSale:search.invalidPrice'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('price_min') < value || !getFieldValue('price_min')) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('businessesForSale:search.maxMinRule')));
                },
              }),
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              formatter={formatNumber}
              placeholder={t('businessesForSale:search.max')}
            />
          </Form.Item>
        </HorizontalDiv>
        <SearchIndustries />
      </Form>
      <NavLink to={privateRoutes.alertsAndMails(locale)}>
        <ButtonSmall
          status={ButtonStatus.PRIMARY}
          icon={<BellOutlined />}
          block={true}
          text={t('businessesForSale:search.createAlert')}
        />
      </NavLink>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  grid-area: sidebar;
  padding-bottom: 16px;
`;

const ClearP = styled.p`
  margin: 0;
  color: ${theme.color.electricBlue};
  cursor: pointer;
  font-weight: 500;
`;

export const GrayP = styled.p`
  color: #696969;
`;

export const BoldP = styled.p`
  margin-bottom: 8px !important;
  font-weight: 500;
`;

export const NormalP = styled.p`
  margin-bottom: 8px !important;
`;

const TopDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledFilterIcon = styled(FilterOutlined)`
  margin-right: 8px;
`;

const HorizontalDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .ant-input-number-handler-wrap {
    visibility: hidden;
  }
  max-width: 24rem;
`;
