import { Footer } from '../../components/footer/Footer';
import { HelmetTermsOfUse } from '../../helmets/HelmetTermsOfUse';
import { ModaliteDUtilisation } from './ModaliteDUtilisation';
import React from 'react';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useTranslation } from 'react-i18next';

export const TermsOfUse = (): JSX.Element => {
  const { t } = useTranslation('termsOfUse');
  return (
    <>
      <HelmetTermsOfUse />
      <Grid>
        <Spacer>
          <StyledH1>{t('title')}</StyledH1>
          <ModaliteDUtilisation />
        </Spacer>
        <Footer />
      </Grid>
    </>
  );
};

const Grid = styled.section`
  grid-area: main;
  padding-top: 32px;
  overflow: auto;
`;

const Spacer = styled.section`
  padding-left: 4vw;
  padding-right: 4vw;
`;

const StyledH1 = styled.h1`
  font-size: ${theme.fontsize.h3} !important;
`;
