import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../styles/theme';

type IconDescriptionProps = {
  description: string;
  content: string;
  icon?: React.ReactNode;
};

export const IconDescription = (props: IconDescriptionProps): JSX.Element => (
  <StyledDiv>
    <StyledPSmall>{props.description}</StyledPSmall>

    <FlexDiv>
      {props.icon && <StyledIcon>{props.icon}</StyledIcon>}
      <b>
        <StyledP> {props.content} </StyledP>
      </b>
    </FlexDiv>
  </StyledDiv>
);

const StyledIcon = styled.div`
  padding-right: 8px;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 32px;
  padding-bottom: 16px;
  max-width: 320px;
`;

const StyledPSmall = styled.p`
  font-size: ${theme.fontsize.smallp} !important;
  font-weight: bold;
  color: ${theme.color.darkGrey};
  margin: 0;
`;

const StyledP = styled.p`
  margin: 0;
  white-space: pre-line;
`;
