import React, { useContext, useEffect } from 'react';

import { BasicInfo } from './sections/BasicInfo';
import { ContactSeller } from './sections/ContactSeller';
import { FinancialDetails } from './sections/FinancialDetails';
import { FullListingFallback } from './FullListingFallback';
import { HttpError } from '../../dtos/error';
import { SellingDetails } from './sections/SellingReason';
import { Spin } from 'antd';
import { UserContext } from '../../contexts/UserContext';
import { getListing } from '../../services/ListingService';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useAxiosMiddleware } from '../../services/useAxiosMiddleware';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const FullListing = (): JSX.Element => {
  const axiosMiddleware = useAxiosMiddleware();
  const { t } = useTranslation('fullListing');
  const { signOut, authTokens } = useContext(UserContext);
  const param = useParams<{ id: string }>();

  const { isLoading, isRefetching, data, error, isSuccess } = useQuery(
    ['getFullListing'],
    () => getListing({ listingid: param.id || '', axiosMid: axiosMiddleware, tokens: authTokens?.access_token || '' }),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  useEffect(() => {
    if ((error as HttpError)?.response?.status === 401 || (error as HttpError)?.response?.status === 412) {
      signOut();
    }
  }, [error]);

  return (
    <>
      {isLoading || isRefetching ? (
        <Spin size="large" />
      ) : isSuccess ? (
        <StyledDiv>
          <LeftItemDiv>
            {data?.basic_info && <BasicInfo id={data._id || ''} listing={data} />}
            {data?.financial_details && <FinancialDetails listing={data.financial_details} />}
            {(data?.selling_details.selling_reason ||
              data?.selling_details.financing_option ||
              data?.selling_details.offered_support?.[0]) && <SellingDetails {...data.selling_details} />}
            <StyledP>{t('disclamer')}</StyledP>
          </LeftItemDiv>
          {data && <ContactSeller selleingDetails={data.selling_details} id={data._id} isSeller={data.is_seller} />}
        </StyledDiv>
      ) : (
        error && <FullListingFallback />
      )}
    </>
  );
};

const StyledDiv = styled.div`
  grid-area: main;
  padding: 32px 4vw;
  @media only screen and (max-width: 1400px) {
    padding: 32px 2vw;
  }
  overflow-y: overlay;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  column-gap: 64px;
  justify-content: space-between;
`;

const LeftItemDiv = styled.div`
  width: 500px;
  flex-basis: 500px;
  max-width: 1000px;
  flex-grow: 2;
`;

export const StyledBox = styled.div`
  padding: 16px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 32px;
  background-color: #fff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 0.2);
  border: 0.5px solid #a2a0a8;
`;

const StyledP = styled.p`
  color: ${theme.color.darkGrey} !important;
`;
