import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { Col, Divider } from 'antd';
import React, { useContext } from 'react';
import { privateRoutes, publicRoutes } from '../../router/routes';

import { Language } from './Language';
import { LocaleContext } from '../../router/Router';
import { NavLink } from 'react-router-dom';
import { Outline } from './Header';
import { SignedInMenu } from './SignedInMenu';
import { UserContext } from '../../contexts/UserContext';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useTranslation } from 'react-i18next';

export const DrawerContent = (props: { hideDrawer: () => void }) => {
  const { authTokens } = useContext(UserContext);
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation('header');

  return (
    <StyledCol onClick={props.hideDrawer}>
      <StyledDiv>
        <h2>Menu</h2>
        <Outline />
      </StyledDiv>
      <NavLink to={publicRoutes.businessesForSale(locale)}>
        <h6> {t('headerContent.buyABusiness')}</h6>
      </NavLink>
      {authTokens && (
        <NavLink to={privateRoutes.myListings(locale)}>
          <h6> {t('headerContent.sellABusiness')}</h6>
        </NavLink>
      )}
      {!authTokens && (
        <NavLink to={publicRoutes.sellABusiness(locale)}>
          <h6> {t('headerContent.sellABusiness')}</h6>
        </NavLink>
      )}
      <NavLink to={publicRoutes.franchiseSection(locale)}>
        <h6>{t('headerContent.startAFranchise')}</h6>
      </NavLink>
      <NavLink to={publicRoutes.brokerSection(locale)}>
        <h6>{t('headerContent.findABroker')}</h6>
      </NavLink>
      <Divider />

      {authTokens ? (
        <SignedInMenu />
      ) : (
        <NavLink to={publicRoutes.signin(locale)}>
          <ButtonSmall status={ButtonStatus.SECONDARY} text={t('signin')} />
        </NavLink>
      )}
      <Divider />
      <Language />
    </StyledCol>
  );
};

const StyledCol = styled(Col)`
  margin-bottom: 2.5rem;
  h6 {
    font-size: ${theme.fontsize.h6};
    line-height: 2rem;
  }

  .active {
    h6 {
      color: ${theme.color.green} !important;
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
