import { AxiosInstance } from 'axios';
import { StripeProduct } from '../dtos/listingCreation';

const USER_URL = 'user';
const LISTING_URL = 'listing';

export const stripeCheckoutSession = async (params: {
  axiosMid: AxiosInstance;
  priceId: string;
  baseUrl: string;
  locale: string;
}): Promise<{ sessionId: string }> => {
  const res = await params.axiosMid({
    method: 'POST',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/stripe_create_checkout_session`,
    data: { price_id: params.priceId, base_url: params.baseUrl, locale: params.locale },
  });
  return res.data;
};

export const stripePortalSession = async (params: {
  axiosMid: AxiosInstance;
  baseUrl: string;
  locale: string;
}): Promise<{ url: string }> => {
  const res = await params.axiosMid({
    method: 'POST',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/stripe_create_portal_session`,
    data: { base_url: params.baseUrl, locale: params.locale },
  });
  return res.data;
};

export const getSubscription = async (params: {
  axiosMid: AxiosInstance;
  userId?: string;
}): Promise<StripeProduct | undefined> => {
  const res = await params.axiosMid({
    method: 'GET',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${LISTING_URL}/stripe_get_subscription/`,
    params: { user_id_from_admin: params.userId },
  });
  return res.data;
};

export const userHasActiveSubscription = async (params: {
  axiosMid: AxiosInstance;
  user_id_admin?: string;
}): Promise<boolean | undefined> => {
  const res = await params.axiosMid({
    method: 'GET',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${LISTING_URL}/user_has_active_subsciption/`,
    params: { user_id_admin: params.user_id_admin },
  });
  return res.data;
};

export const getPrices = async (params: {
  axiosMid: AxiosInstance;
  lookupKeys: string[];
}): Promise<{ data: { id: string; unit_amout: number; lookup_key: string }[] }> => {
  const res = await params.axiosMid({
    method: 'PUT',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/stripe_price_lookup_keys`,
    data: params.lookupKeys,
  });
  return res.data;
};
