import { Progress } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const ProgressPanel = (props: { activeStep: number }): JSX.Element => {
  const { t } = useTranslation(['createListing']);

  return (
    <CenteredDiv>
      <LeftDiv />
      <RightHeader>
        <Progress type="circle" percent={(props.activeStep + 1) * 25} width={80} />
        <StyledTitle>{t('createListing:progressPanel.completed', { number: (props.activeStep + 1) * 25 })}</StyledTitle>
      </RightHeader>
    </CenteredDiv>
  );
};

const RightHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 600px;
  @media only screen and (max-width: 950px) {
    width: 100%;
  }
`;

const LeftDiv = styled.div`
  width: 250px;
  @media only screen and (max-width: 950px) {
    display: none;
  }
`;

const CenteredDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StyledTitle = styled.p`
  margin-left: 32px !important;
  margin: 0;
  font-weight: bold;
`;
