import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../styles/theme';

type DescriptionProps = {
  title: string;
  content: string;
  isSmallScreen?: boolean;
};

export const Description = (props: DescriptionProps) => {
  return (
    <div style={{ maxWidth: props.isSmallScreen === undefined ? '280px' : props.isSmallScreen ? '' : '280px' }}>
      <StyledPSmall>{props.title}</StyledPSmall>
      <StyledP>{props.content}</StyledP>
    </div>
  );
};

const StyledPSmall = styled.p`
  font-size: 0.88rem;
  font-weight: 400;
  color: ${theme.color.darkGrey};
  margin: 0;
`;

const StyledP = styled.p`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
