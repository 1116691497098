import { CheckOutlined, SwapOutlined } from '@ant-design/icons';
import { Modal, Spin, Tooltip } from 'antd';
import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { UserAdmin } from '../../../dtos/admin';
import { getSubscription } from '../../../services/PaymentService';
import { migrateAllListingsMetaDataToNewSub } from '../../../services/AdminService';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';

export const MigrateListingsToNewSubscriptionAction = (props: UserAdmin): JSX.Element => {
  const axiosMiddleware = useAxiosMiddleware(),
    [isChanged, setIsChanged] = useState(false);

  const getStripeSubscriptionMutation = useMutation({
    mutationFn: getSubscription,
    onError: (err) => {
      const errorDetail = (err as AxiosError).response?.data as { detail: string };
      Modal.error({ content: 'Les metas données des annonces ne peuvent être modifiées car: ' + errorDetail.detail });
    },
    onSuccess: (stripeProduct) => {
      if (stripeProduct && props.id) {
        migrateAllListingMetaDataToNewSubMutation.mutate({
          axiosMid: axiosMiddleware,
          user_id: props.id,
          priority_exposure: stripeProduct[0].priority_exposure,
          featured: stripeProduct[0].featured,
          content: stripeProduct[0].content,
          email_freq: stripeProduct[0].email_freq,
        });
      } else {
        Modal.error({
          content:
            "Les metas données des annonces ne peuvent être modifiées car le forfait ou le id de l'utilisateur sont invalide",
        });
      }
    },
  });

  const migrateAllListingMetaDataToNewSubMutation = useMutation({
    mutationFn: migrateAllListingsMetaDataToNewSub,
    onError: (err) => {
      const errorDetail = (err as AxiosError).response?.data as { detail: string };
      Modal.error({ content: "Les metas données des annonces n'ont pu être modifiées car: " + errorDetail.detail });
    },
    onSuccess: () => {
      setIsChanged(true);
      Modal.success({ content: 'Les metas données des annonces ont été modifiées avec succès' });
    },
  });

  const onClick = () => {
    Modal.confirm({
      content:
        "Êtes-vous sur de vouloir migrer toutes les metas données des annonces de l'utilisateur pour qu'elles soient de type standard selon son forfait actuel?",
      onOk: () => getStripeSubscriptionMutation.mutate({ axiosMid: axiosMiddleware, userId: props.id }),
    });
  };

  return (
    <>
      {isChanged ? (
        <Tooltip title={'Les meta données ont été modifiées selon le forfait actuel'} placement={'left'}>
          <CheckOutlined style={{ color: 'green' }} />
        </Tooltip>
      ) : (
        <Tooltip
          title={
            "Migrer toutes les metas données des annonces de l'utilisateur pour qu'elles soient de type standard selon son forfait actuel"
          }
        >
          {getStripeSubscriptionMutation.isLoading || migrateAllListingMetaDataToNewSubMutation.isLoading ? (
            <Spin />
          ) : (
            <SwapOutlined onClick={onClick} />
          )}
        </Tooltip>
      )}
    </>
  );
};
