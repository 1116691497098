import { UploadFile } from 'antd';
import moment from 'moment';

type AllowedListingsOfASpecificType = {
  allowed_listing_count: number;
  content: number;
  priority_exposure: boolean;
  featured: boolean;
  email_freq: number;
};

export enum CONTENT {
  // 1 : 1 picture
  One = 1,
  // 5 : 5 pictures
  Five = 5,
  // 10 : 10 pictures, 1 video
  Ten = 10,
}

export type StripeProduct = AllowedListingsOfASpecificType[];

export type OptionalTileField = {
  name: string;
  value: string;
};

export type Listing = {
  is_seller?: boolean;
  _id?: string;
  user_id?: string;
  creation_date?: string;
  is_published: boolean;
  is_verified: boolean;
  email_freq: number;
  content: number;
  priority_exposure: boolean;
  featured: boolean;
  basic_info: BasicData;
  additional_info: AdditionalData;
  financial_details: FinancialData;
  selling_details: SellingDetailsData;
};

export type BasicData = {
  listing_title?: string[];
  about_company?: string[];
  region_1?: number[];
  region_2?: number[];
  region_3?: number[];
  industry_1?: number[];
  industry_2?: number[];
  industry_3?: number[];
  asking_price?: number;
  competitive_advantages?: string[];
  customer_base?: string[];
  competitors?: string[];
  patent?: string;
  license?: string;
};

export type AdditionalData = {
  picture_primary?: string;
  pictures_secondary?: string[];
  video?: string;
  date_founded?: number;
  number_employees?: number;
  website?: string;
};

export type FinancialData = {
  revenue?: number;
  ebitda?: number;
  net_income?: number;
  leases?: string[];
  assets?: string[];
};

export type SellingDetailsData = {
  selling_reason?: string;
  financing_option?: boolean;
  offered_support?: string[];
  first_name_contact?: string;
  last_name_contact?: string;
  email_contact?: string;
  telephone_contact?: string;
};

export type ListingForm = {
  // basic
  listing_title_def?: string;
  listing_title_add?: string;
  about_company_def?: string;
  about_company_add?: string;
  region_1?: string[];
  region_2?: string[];
  region_3?: string[];
  industry_1?: string[];
  industry_2?: string[];
  industry_3?: string[];
  asking_price?: number;
  competitive_advantages_def?: string;
  competitive_advantages_add?: string;
  customer_base_def?: string;
  customer_base_add?: string;
  competitors_def?: string;
  competitors_add?: string;
  patent?: string;
  license?: string;
  // additional
  pictures?: UploadFile[];
  video?: string;
  date_founded?: moment.Moment;
  number_employees?: number;
  website?: string;
  // financials
  revenue?: number;
  ebitda?: number;
  net_income?: number;
  leases_def?: string;
  leases_add?: string;
  assets_def?: string;
  assets_add?: string;
  // details
  selling_reason?: string;
  financing_option?: string;
  offered_support_def?: string;
  offered_support_add?: string;
  first_name_contact?: string;
  last_name_contact?: string;
  email_contact?: string;
  telephone_contact?: string;
};
