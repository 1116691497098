import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { FranchiseDTO, FranchiseInCache } from '../../dtos/franchise';
import React, { useState } from 'react';
import { StarFilled, StarOutlined } from '@ant-design/icons';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const isInFranchiseList = (currId: string): boolean => {
  return JSON.parse(localStorage.getItem('franchiseList') || '[]')
    .map((franchise: FranchiseInCache) => franchise._id)
    .includes(currId);
};

export const AddToMyList = (props: {
  franchise: FranchiseDTO;
  setFavCnt?: React.Dispatch<number>;
  isFull?: boolean;
}) => {
  const { t } = useTranslation('franchiseSection');
  const [inInList, setIsInList] = useState(isInFranchiseList(props.franchise._id || ''));

  const onClick = () => {
    if (!isInFranchiseList(props.franchise._id || '')) {
      const prevList = JSON.parse(localStorage.getItem('franchiseList') || '[]');
      prevList.push({
        _id: props.franchise._id,
        title: props.franchise.title,
        franchisor_email: props.franchise.franchisor_email,
      });
      localStorage.setItem('franchiseList', JSON.stringify(prevList));
      setIsInList(true);
      if (props.setFavCnt) {
        props.setFavCnt(prevList.length);
      }
      return prevList;
    } else {
      const prevList = JSON.parse(localStorage.getItem('franchiseList') || '[]');
      const newList = prevList.filter((franchise: FranchiseInCache) => franchise._id !== props.franchise._id);
      localStorage.setItem('franchiseList', JSON.stringify(newList));
      if (props.setFavCnt) {
        props.setFavCnt(newList.length);
      }
      setIsInList(false);
      return newList;
    }
  };

  return (
    <StyledDiv onClick={onClick}>
      {props.isFull ? (
        <ButtonDiv>
          <ButtonSmall
            status={ButtonStatus.TERTIARY}
            text={inInList ? t('addToMyList.removeFormMyList') : t('addToMyList.addToMyList')}
            icon={inInList ? <StyledStarFilled /> : <StyledStarOutlinded />}
            block={true}
          />
        </ButtonDiv>
      ) : (
        <>
          {inInList ? <StyledStarFilled /> : <StyledStarOutlinded />}
          <p>{inInList ? t('addToMyList.removeFormMyList') : t('addToMyList.addToMyList')}</p>
        </>
      )}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  :hover {
    cursor: pointer;
  }
  p {
    margin-bottom: 0;
  }
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledStarOutlinded = styled(StarOutlined)`
  margin-right: 4px;
  font-size: 22px;
`;

const StyledStarFilled = styled(StarFilled)`
  margin-right: 4px;
  font-size: 22px;
`;

const ButtonDiv = styled.div`
  width: 100%;
`;
