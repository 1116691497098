import { MailOutlined, PhoneOutlined, PushpinOutlined } from '@ant-design/icons';

import { Footer } from '../../components/footer/Footer';
import { HelmetContactUs } from '../../helmets/HelmetContactUs';
import React from 'react';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useTranslation } from 'react-i18next';

export const ContactUs = (): JSX.Element => {
  const { t } = useTranslation('contactUs');
  return (
    <>
      <HelmetContactUs />
      <StyledGrid>
        <div>
          <StyledH>{t('contactUs')}</StyledH>
          <GreyP>{t('inquieries')}</GreyP>
        </div>
        <StyledDiv>
          <div>
            <Box>
              <StyledLogo>
                <PushpinOutlined />
              </StyledLogo>
              <p>{t('address')}</p>
            </Box>
          </div>
          <div>
            <Box>
              <StyledLogo>
                <PhoneOutlined />
              </StyledLogo>
              <p>{'(514) 499-0334'}</p>
              <p>{'1 (866) 499-0334'}</p>
            </Box>
          </div>
          <div>
            <Box>
              <StyledLogo>
                <MailOutlined />
              </StyledLogo>
              <p>
                <u>
                  <a href="mailto:info@acquizition.biz">info@acquizition.biz</a>
                </u>
              </p>
            </Box>
          </div>
        </StyledDiv>
      </StyledGrid>
      <Footer />
    </>
  );
};

const StyledH = styled.h1`
  margin-top: 32px;
  font-size: ${theme.fontsize.h2} !important;
`;

const GreyP = styled.p`
  color: ${theme.color.darkGrey};
  font-size: ${theme.fontsize.h5} !important;
`;

const StyledLogo = styled.h1`
  color: ${theme.color.green};
`;

const StyledDiv = styled.div`
  display: flex;
  column-gap: 40px;
  flex-flow: wrap;
  justify-content: center;
  flex-grow: 1;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 320px;
  min-width: 240px;
  border-radius: 16px;
  p {
    margin: 0;
  }
  :hover {
    background-color: #f8f9f9;
  }
`;

const StyledGrid = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 40px;
`;
