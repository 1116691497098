import { Description } from './Description';
import { ListingTileData } from '../../../dtos/businessesForSale';
import React from 'react';
import { currencyFormatter } from '../../fullListing/fullListingHelper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const Financials = (props: ListingTileData) => {
  const { t } = useTranslation('businessesForSale');
  return (
    <FinancialDiv>
      <Description
        title={t('businessesForSale:tile.asking_price')}
        content={
          props.basic_info.asking_price
            ? currencyFormatter(props.basic_info.asking_price, 1)
            : t('businessesForSale:tile.placeholder_price')
        }
      />
      {props.financial_details.revenue && (
        <Description
          title={t('businessesForSale:tile.revenue')}
          content={currencyFormatter(props.financial_details.revenue, 1)}
        />
      )}
      {props.financial_details.ebitda && (
        <Description
          title={t('businessesForSale:tile.ebitda')}
          content={currencyFormatter(props.financial_details.ebitda, 1)}
        />
      )}
    </FinancialDiv>
  );
};

const FinancialDiv = styled.div`
  min-width: 80px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1000px) {
    flex-direction: row;
    column-gap: 32px;
  }
`;
