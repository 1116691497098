import * as userService from '../../../services/UserService';

import { Alert, Form, Input, Modal } from 'antd';
import { ButtonSmall, ButtonStatus } from '../../../common/button/ButtonSmall';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { HttpError } from '../../../dtos/error';
import { LocaleContext } from '../../../router/Router';
import { ViewCentered } from '../../../common/views/ViewCentered';
import { publicRoutes } from '../../../router/routes';
import styled from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const ConfirmEmailForm = (): JSX.Element => {
  const { t } = useTranslation('profileForm'),
    location = useLocation(),
    { locale } = useContext(LocaleContext),
    navigate = useNavigate();

  const resendVerificationCode = useMutation(userService.resendVerificationCode, {
    onSuccess: () => {
      Modal.success({
        content: t('confirmEmailForm.codeResent'),
      });
    },
    onError: () => {
      Modal.success({
        content: t('confirmEmailForm.emailInvalid'),
      });
    },
  });

  const confirmationMutation = useMutation(userService.confirmEmail, {
    onSuccess: () => {
      Modal.success({
        content: t('confirmEmailForm.emailConfirmed'),
        onOk: () => navigate(publicRoutes.signin(locale)),
      });
    },
  });

  const onFinish = (data: { email: string; code: string }) => {
    const params = { email: data.email, code: Number(data.code) };
    confirmationMutation.mutate(params);
  };

  const [confirmEmailForm] = Form.useForm();
  return (
    <ViewCentered maxWidth="464px">
      <H2Styled>{t('confirmEmailForm.title')}</H2Styled>
      <p>{t('confirmEmailForm.subtitle')}</p>
      <Form
        layout="vertical"
        requiredMark={'optional'}
        form={confirmEmailForm}
        initialValues={{ email: location.state?.email }}
        onFinish={onFinish}
      >
        <Form.Item
          name={'email'}
          label={<label>{t('confirmEmailForm.email')}</label>}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={'code'}
          label={<label>{t('confirmEmailForm.code')}</label>}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <ButtonSmall text={t('confirmEmailForm.submit')} status={ButtonStatus.PRIMARY} block={true} />
      </Form>
      <br />
      {(confirmationMutation?.error as HttpError)?.response?.status === 401 && (
        <>
          <Alert message={t('confirmEmailForm.invalidCode')} type="error" showIcon />
          <br />
        </>
      )}
      <ButtonSmall
        text={t('confirmEmailForm.resendCode')}
        status={ButtonStatus.TERTIARY}
        block={true}
        onClick={() => {
          resendVerificationCode.mutate(confirmEmailForm.getFieldValue('email'));
        }}
      />
    </ViewCentered>
  );
};

const H2Styled = styled.h2`
  margin-top: 32px;
`;
