import { FormInstance, UploadFile } from 'antd';
import { Listing, ListingForm } from '../../dtos/listingCreation';

import { OfferFinancingEnum } from '../../dtos/listingCreationFields';
import moment from 'moment';

export const getInitialListing = (params: {
  content: number;
  priority_exposure: boolean;
  featured: boolean;
  email_freq: number;
}): Listing | undefined => {
  return {
    is_published: false,
    is_verified: false,
    content: params.content,
    priority_exposure: params.priority_exposure,
    email_freq: params.email_freq,
    featured: params.featured,
    basic_info: {},
    additional_info: {},
    financial_details: {},
    selling_details: {},
  };
};

export const toForm = (listing: Listing): ListingForm => {
  const ret: ListingForm = {};
  // basic
  [ret.listing_title_def, ret.listing_title_add] = arrToFields(listing.basic_info.listing_title);
  [ret.about_company_def, ret.about_company_add] = arrToFields(listing.basic_info.about_company);
  ret.industry_1 = listing.basic_info.industry_1?.map((ind) => ind.toString()) || [];
  ret.industry_2 = listing.basic_info.industry_2?.map((ind) => ind.toString()) || [];
  ret.industry_3 = listing.basic_info.industry_3?.map((ind) => ind.toString()) || [];
  ret.region_1 = listing.basic_info.region_1?.map((reg) => reg.toString()) || [];
  ret.region_2 = listing.basic_info.region_2?.map((reg) => reg.toString()) || [];
  ret.region_3 = listing.basic_info.region_3?.map((reg) => reg.toString()) || [];
  ret.asking_price = listing.basic_info.asking_price;
  [ret.competitive_advantages_def, ret.competitive_advantages_add] = arrToFields(
    listing.basic_info.competitive_advantages,
  );
  [ret.customer_base_def, ret.customer_base_add] = arrToFields(listing.basic_info.customer_base);
  [ret.competitors_def, ret.competitors_add] = arrToFields(listing.basic_info.competitors);
  ret.patent = listing.basic_info.patent;
  ret.license = listing.basic_info.license;
  // additional
  const secondary = listing.additional_info.pictures_secondary?.map((picture) => {
    return { name: picture, uid: picture, url: picture, status: 'done' };
  }) as UploadFile[];

  if (listing.additional_info.picture_primary) {
    ret.pictures = [
      {
        name: listing.additional_info.picture_primary,
        uid: listing.additional_info.picture_primary,
        url: listing.additional_info.picture_primary,
        status: 'done',
      },
      ...(secondary || []),
    ];
  } else {
    ret.pictures = [];
  }
  ret.video = listing.additional_info.video;

  ret.date_founded = dateToMoment(listing.additional_info?.date_founded as number);
  ret.number_employees = listing.additional_info.number_employees;
  ret.website = listing.additional_info.website;
  // financial
  ret.revenue = listing.financial_details.revenue;
  ret.ebitda = listing.financial_details.ebitda;
  ret.net_income = listing.financial_details.net_income;
  [ret.assets_def, ret.assets_add] = arrToFields(listing.financial_details.assets);
  [ret.leases_def, ret.leases_add] = arrToFields(listing.financial_details.leases);
  // details
  ret.selling_reason = listing.selling_details.selling_reason;
  if (listing.selling_details.financing_option !== undefined && listing.selling_details.financing_option !== null) {
    ret.financing_option = listing.selling_details.financing_option
      ? OfferFinancingEnum.Willing
      : OfferFinancingEnum.NotWilling;
  }

  [ret.offered_support_def, ret.offered_support_add] = arrToFields(listing.selling_details.offered_support);
  ret.first_name_contact = listing.selling_details.first_name_contact;
  ret.last_name_contact = listing.selling_details.last_name_contact;
  ret.email_contact = listing.selling_details.email_contact;
  ret.telephone_contact = listing.selling_details.telephone_contact;
  return ret;
};

const arrToFields = (arr: string[] | undefined): [string | undefined, string | undefined] => {
  let def = undefined;
  let add = undefined;

  if (arr && arr.length > 0) {
    def = arr[0];
  }
  if (arr?.length && arr.length > 1) {
    add = arr[1];
  }

  return [def, add];
};

export const toListing = (listing: Listing, form: ListingForm): Listing => ({
  _id: listing._id,
  is_published: listing.is_published,
  is_verified: listing.is_verified,
  content: listing.content,
  email_freq: listing.email_freq,
  priority_exposure: listing.priority_exposure,
  featured: listing.featured,
  basic_info: {
    listing_title: fieldsToArr(form.listing_title_def, form.listing_title_add),
    about_company: fieldsToArr(form.about_company_def, form.about_company_add),
    region_1: form.region_1?.map((reg) => Number(reg)),
    region_2: form.region_2?.map((reg) => Number(reg)),
    region_3: form.region_3?.map((reg) => Number(reg)),
    industry_1: form.industry_1?.map((ind) => Number(ind)),
    industry_2: form.industry_2?.map((ind) => Number(ind)),
    industry_3: form.industry_3?.map((ind) => Number(ind)),
    asking_price: form.asking_price,
    competitive_advantages: fieldsToArr(form.competitive_advantages_def, form.competitive_advantages_add),
    customer_base: fieldsToArr(form.customer_base_def, form.customer_base_add),
    competitors: fieldsToArr(form.competitors_def, form.competitors_add),
    patent: form.patent || undefined,
    license: form.license || undefined,
  },
  additional_info: {
    picture_primary: form.pictures && form.pictures?.length > 0 ? form.pictures[0].url : undefined,
    pictures_secondary: getSecondaryPicture(form.pictures),
    video: form.video || undefined,
    date_founded: momentToDate(form.date_founded as moment.Moment),
    number_employees: form.number_employees,
    website: form.website || undefined,
  },
  financial_details: {
    revenue: form.revenue,
    ebitda: form.ebitda,
    net_income: form.net_income,
    leases: fieldsToArr(form.leases_def, form.leases_add),
    assets: fieldsToArr(form.assets_def, form.assets_add),
  },
  selling_details: {
    selling_reason: form.selling_reason,
    financing_option:
      form.financing_option === undefined
        ? undefined
        : form.financing_option === OfferFinancingEnum.Willing
        ? true
        : false,
    offered_support: fieldsToArr(form.offered_support_def, form.offered_support_add),
    first_name_contact: form.first_name_contact || undefined,
    last_name_contact: form.last_name_contact || undefined,
    email_contact: form.email_contact || undefined,
    telephone_contact: form.telephone_contact || undefined,
  },
});

const getSecondaryPicture = (files: UploadFile[] | undefined): string[] => {
  let urls = [] as string[];
  if (files && files?.length > 1) {
    urls = files.splice(1).map((file) => {
      return file.url;
    }) as string[];
  }
  return urls;
};

const fieldsToArr = (def: string | undefined, add: string | undefined): string[] => {
  const ret = [];
  if (def) {
    ret.push(def);
  }
  if (add) {
    ret.push(add);
  }
  return ret;
};

const momentToDate = (date?: moment.Moment): number => {
  // eslint-disable-next-line
  const newDate = (date as any)?._d;
  return newDate ? newDate.getFullYear() : undefined;
};

export const dateToMoment = (date?: number): moment.Moment | undefined => {
  return date ? moment(new Date(date, 1, 1)) : undefined;
};

export const formatNumber = (value: string | undefined): string => {
  return value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '';
};

export const calculatePublishable = (stepForm: FormInstance<ListingForm>): boolean => {
  const requiredFields = ['listing_title_def', 'about_company_def'];
  let publishable = 0;
  requiredFields.map((field) => {
    if (stepForm.getFieldValue(field)) {
      publishable += 1;
    }
  });

  if (
    stepForm.getFieldValue('region_1') &&
    (stepForm.getFieldValue('region_1').length > 1 ||
      (stepForm.getFieldValue('region_1').length === 1 && stepForm.getFieldValue('region_1')[0] !== '1800'))
  ) {
    publishable += 1;
  }
  if (stepForm.getFieldValue('industry_1') && stepForm.getFieldValue('industry_1').length > 1) {
    publishable += 1;
  }

  return 4 === publishable;
};

export const isBilingual = (listing: Listing) => {
  if (listing.basic_info.listing_title && listing.basic_info.listing_title.length > 1) {
    return true;
  } else if (listing.basic_info.about_company && listing.basic_info.about_company.length > 1) {
    return true;
  } else if (listing.basic_info.competitive_advantages && listing.basic_info.competitive_advantages.length > 1) {
    return true;
  } else if (listing.basic_info.customer_base && listing.basic_info.customer_base.length > 1) {
    return true;
  } else if (listing.basic_info.competitors && listing.basic_info.competitors.length > 1) {
    return true;
  } else if (listing.financial_details.assets && listing.financial_details.assets.length > 1) {
    return true;
  } else if (listing.financial_details.leases && listing.financial_details.leases.length > 1) {
    return true;
  } else if (listing.selling_details.offered_support && listing.selling_details.offered_support.length > 1) {
    return true;
  }
  return false;
};
