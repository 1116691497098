import React from 'react';
import styled from 'styled-components';

interface ViewFormProps {
  children: React.ReactNode;
  maxWidth: string;
}

export const ViewForm = (props: ViewFormProps): JSX.Element => (
  <Grid>
    <CenteredElement maxWidth={props.maxWidth}>{props.children}</CenteredElement>
  </Grid>
);

const Grid = styled.section`
  grid-area: main;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
`;

const CenteredElement = styled.section<{ maxWidth: string }>`
  max-width: ${(props) => props.maxWidth};

  width: 100%;
  padding-bottom: 24px;

  .ant-picker,
  .ant-input-number,
  .ant-space,
  textarea {
    width: 100%;
  }
  .ant-input-group {
    .ant-row {
      width: 50%;
      margin-bottom: 0px;
    }
  }
`;
