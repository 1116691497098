import React, { useContext } from 'react';

import { BrokerFullProfile } from './BrokerFullProfile';
import { Spin } from 'antd';
import { UserContext } from '../../contexts/UserContext';
import { getBrokerProfile } from '../../services/BrokerService';
import styled from 'styled-components';
import { useAxiosMiddleware } from '../../services/useAxiosMiddleware';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

export const ABrokerInfo = (): JSX.Element => {
  const axiosMiddleware = useAxiosMiddleware();
  const param = useParams<{ id: string }>();
  const { tokenErrorHandler } = useContext(UserContext);
  const { isLoading, data, isSuccess, isRefetching } = useQuery(
    ['getFullBrokerProfile'],
    () => getBrokerProfile({ brokerId: param.id || '', axiosMid: axiosMiddleware }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: tokenErrorHandler,
    },
  );
  return (
    <ProfileWrapper>
      <MaxWidthDiv>
        <Spin size={'large'} spinning={isLoading || isRefetching}>
          {!isRefetching && isSuccess && data && <BrokerFullProfile {...data} />}
        </Spin>
      </MaxWidthDiv>
    </ProfileWrapper>
  );
};

const ProfileWrapper = styled.div`
  grid-area: main;
  padding-top: 32px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 4vw;
  padding-right: 4vw;
  @media only screen and (max-width: 1400px) {
    padding-left: 2vw;
    padding-right: 2vw;
  }
`;

const MaxWidthDiv = styled.div`
  max-width: 1280px;
`;
