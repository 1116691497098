import { CheckOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Form, Modal, Radio, Space, Spin, Tooltip } from 'antd';
import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { UserAdmin } from '../../../dtos/admin';
import { editUserRoleAdmin } from '../../../services/AdminService';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';
import { userHasActiveSubscription } from '../../../services/PaymentService';

export const EditUserRoleAction = (props: UserAdmin) => {
  const axiosMiddleware = useAxiosMiddleware();
  const [visibleForm, setVisibleForm] = useState(false);
  const [editRoleForm] = Form.useForm();
  const [roleHasBeenChanged, setRoleHasBeenChanged] = useState(false);

  const editRoleMutation = useMutation(editUserRoleAdmin, {
    onSuccess: () => {
      setVisibleForm(false);
      setRoleHasBeenChanged(true);
      Modal.success({ content: 'Le type de compte a été modifié avec succès' });
    },
    onError: (err) => {
      const errorDetail = (err as AxiosError).response?.data as { detail: string };
      Modal.error({ content: "Le type de compte n'a pu être modifié car: " + errorDetail.detail });
    },
  });

  const onOk = () => {
    editRoleMutation.mutate({
      axiosMid: axiosMiddleware,
      user_id: props.id || '',
      user_type: editRoleForm.getFieldValue('user_type'),
    });
  };

  const handleCancel = () => {
    setVisibleForm(false);
  };

  const hasActiveSubscriptionMutation = useMutation({
    mutationFn: userHasActiveSubscription,
    onSuccess: (subIsActive) => {
      if (!subIsActive) {
        setVisibleForm(true);
      } else {
        Modal.error({
          content:
            "Le type de compte ne peut être modifié, car l'utilisateur à un forfait actif et cela pourrait créer des incohérences.",
        });
      }
    },
  });

  const onClick = () => {
    hasActiveSubscriptionMutation.mutate({ axiosMid: axiosMiddleware, user_id_admin: props.id });
  };

  return (
    <div>
      <Modal
        title={`Modifier le type de compte de l'utilisateur: ${props.first_name} ${props.last_name}`}
        open={visibleForm}
        onCancel={handleCancel}
        onOk={onOk}
        okText={'Modifier'}
      >
        <Form
          form={editRoleForm}
          layout={'vertical'}
          requiredMark={'optional'}
          initialValues={{ user_type: props.user_type }}
        >
          <Form.Item name={'user_type'}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="buyer">Acheteur</Radio>
                <Radio value="seller">Vendeur</Radio>
                <Radio value="broker">Courtier</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
      <Tooltip title={"Modifier le type de compte (courtier, acheteur, vendeur) de l'utilisateur"}>
        {roleHasBeenChanged ? (
          <CheckOutlined style={{ color: 'green' }} />
        ) : editRoleMutation.isLoading || hasActiveSubscriptionMutation.isLoading ? (
          <Spin />
        ) : (
          <UserSwitchOutlined onClick={onClick} />
        )}
      </Tooltip>
    </div>
  );
};
