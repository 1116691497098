import {
  FileOutlined,
  IdcardOutlined,
  LogoutOutlined,
  MailOutlined,
  SettingOutlined,
  StarOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { privateRoutes, publicRoutes } from '../../router/routes';

import { MenuProps } from 'antd';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { TFunction } from 'react-i18next';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const DropdownMenu = (props: {
  t: TFunction<'header'>;
  signOut: () => void;
  userType: string | undefined;
  locale: string;
}): MenuProps => {
  const profile = [
    {
      key: 'profile',
      label: (
        <NavLink to={privateRoutes.profile(props.locale)}>
          <h6>
            <SyledSpan>
              <UserOutlined />
            </SyledSpan>
            {props.t('dashboardContent.myProfile')}
          </h6>
        </NavLink>
      ),
    },
  ];

  const brokerInfo =
    props.userType !== 'broker'
      ? []
      : [
          {
            key: 'brokerInfo',
            label: (
              <NavLink to={privateRoutes.myBrokerInfo(props.locale)}>
                <h6>
                  <SyledSpan>
                    <IdcardOutlined />
                  </SyledSpan>
                  {props.t('dashboardContent.brokerInfo')}
                </h6>
              </NavLink>
            ),
          },
        ];

  const general = [
    {
      key: 'myListings',
      label: (
        <NavLink to={privateRoutes.myListings(props.locale)}>
          <h6>
            <SyledSpan>
              <FileOutlined />
            </SyledSpan>
            {props.userType === 'broker'
              ? props.t('dashboardContent.myListingsBroker')
              : props.t('dashboardContent.myListingsOwner')}
          </h6>
        </NavLink>
      ),
    },
    {
      key: 'myFavorites',
      label: (
        <NavLink to={privateRoutes.favorites(props.locale)}>
          <h6>
            <SyledSpan>
              <StarOutlined />
            </SyledSpan>
            {props.t('dashboardContent.myFavorites')}
          </h6>
        </NavLink>
      ),
    },
    {
      key: 'myMessages',
      label: (
        <NavLink to={privateRoutes.myMessages(props.locale)}>
          <h6>
            <SyledSpan>
              <MailOutlined />
            </SyledSpan>
            {props.t('dashboardContent.myMessages')}
          </h6>
        </NavLink>
      ),
    },
    {
      key: 'alertsAndMails',
      label: (
        <NavLink to={privateRoutes.alertsAndMails(props.locale)}>
          <h6>
            <SyledSpan>
              <SettingOutlined />
            </SyledSpan>
            {props.t('dashboardContent.alertsAndMails')}
          </h6>
        </NavLink>
      ),
    },
    {
      key: 'signOut',
      label: (
        <NavLink to={publicRoutes.signin(props.locale)} onClick={props.signOut}>
          <h6>
            <SyledSpan>
              <LogoutOutlined />
            </SyledSpan>
            {props.t('dashboardContent.signOut')}
          </h6>
        </NavLink>
      ),
    },
  ];
  return { items: [...profile, ...brokerInfo, ...general] };
};

const SyledSpan = styled.span`
  padding-right: 8px !important;
  font-size: ${theme.fontsize.h5} !important;
`;
