import { ActionSearchBusinessEnum, ActionSearchBusinesses } from '../BusinessesForSale';
import { Form, Select } from 'antd';

import React from 'react';
import { SearchParameters } from '../../../dtos/businessesForSale';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export enum SortOptions {
  NEWEST_FIRST = 'newest_first',
  OLDEST_FIRST = 'oldest_first',
  HIGHEST_PRICE_FIRST = 'highest_price_first',
  LOWEST_PRICE_FIRST = 'lowest_price_first',
}

const Sort = [
  SortOptions.NEWEST_FIRST,
  SortOptions.OLDEST_FIRST,
  SortOptions.HIGHEST_PRICE_FIRST,
  SortOptions.LOWEST_PRICE_FIRST,
];

type SortPanelProps = {
  dispatcher: [SearchParameters, React.Dispatch<ActionSearchBusinesses>];
  total?: number;
};

export const SortPanel = (props: SortPanelProps): JSX.Element => {
  const { t } = useTranslation('businessesForSale');
  const [dispState, dispatch] = props.dispatcher;
  const onChange = (formSort: SortOptions) => {
    dispatch({ type: ActionSearchBusinessEnum.SORT, searchParameters: { sort: formSort } });
  };

  return (
    <StyledDiv>
      <div>
        {props.total && (
          <p>
            {props.total}
            {t('businessesForSale:sort:results')}
          </p>
        )}
      </div>
      <Form>
        <Form.Item
          rules={[
            {
              required: false,
            },
          ]}
          name={'sort'}
          initialValue={dispState.sort}
        >
          <Select bordered={false} onChange={onChange}>
            {Sort.map((item: string) => (
              <Option value={item} key={item}>
                <StyledP>{t(`businessesForSale:sort:${item}`)}</StyledP>
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  grid-area: sort;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledP = styled.p`
  margin: 0;
  width: 160px;
  text-align: right;
`;
