export const theme = {
  color: {
    electricBlue: '#1A2FE7',
    darkBlue: '#19284A',
    green: '#14AE5C',
    lightGrey: '#C4C4C4',
    grey: '#A2A0A8',
    darkGrey: '#7B7B7D',
    white: '#FFF',
    validationGreen: '#00CC00',
    errorRed: '#f20000',
    dark: '#0E1221',
    gold: '#D3A665',
    silver: '#63707E',
    smoke: '#F5F5F5',
  },
  fontsize: {
    h1: '2.5rem',
    h2: '2rem',
    h3: '1.75rem',
    h4: '1.5rem',
    h5: '1.25rem',
    h6: '1rem',
    p: '1rem',
    smallp: '0.75rem',
  },
};
