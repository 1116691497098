import styled from 'styled-components';
import { theme } from './theme';

export const StyledApp = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'main';

  font-family: 'Work Sans', serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    line-height: 1.2;
  }

  h1 {
    font-size: ${theme.fontsize.h1};
  }

  h2 {
    font-size: ${theme.fontsize.h2};
  }

  h3 {
    font-size: ${theme.fontsize.h3};
  }

  h4 {
    font-size: ${theme.fontsize.h4};
  }

  h5 {
    font-size: ${theme.fontsize.h5};
  }

  h6 {
    font-size: ${theme.fontsize.h6};
  }

  p,
  li,
  label,
  td,
  th {
    font-size: ${theme.fontsize.p};
  }
`;
