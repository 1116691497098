import { BankOutlined, FileAddOutlined } from '@ant-design/icons';
import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { NavLink, useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';

import { LocaleContext } from '../../router/Router';
import { StripeProduct } from '../../dtos/listingCreation';
import { getInitialListing } from '../createListing/helpers';
import { privateRoutes } from '../../router/routes';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const OwnerCard = (props: { product: StripeProduct }) => {
  const { t } = useTranslation('myListings'),
    { locale } = useContext(LocaleContext),
    navigate = useNavigate();
  return (
    <StyledColumn>
      <FlexGrow>
        <ButtonSmall
          onClick={() => {
            if (!props.product) {
              return;
            }

            const initialListing = getInitialListing({
              content: props.product[0].content,
              priority_exposure: props.product[0].priority_exposure,
              featured: props.product[0].featured,
              email_freq: props.product[0].email_freq,
            });

            if (!initialListing) {
              return;
            }

            navigate(privateRoutes.createListing(locale), {
              state: initialListing,
            });
          }}
          status={ButtonStatus.PRIMARY}
          icon={<FileAddOutlined />}
          text={t('myListings:createListing')}
          disabled={props.product[0].allowed_listing_count <= 0}
          block={true}
        />
      </FlexGrow>
      <FlexGrow>
        <NavLink to={privateRoutes.legalKit(locale)}>
          <ButtonSmall
            status={ButtonStatus.SECONDARY}
            text={t('myListings:legalKit')}
            icon={<BankOutlined />}
            block={true}
          />
        </NavLink>
      </FlexGrow>
    </StyledColumn>
  );
};

const FlexGrow = styled.div`
  width: 248px;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
