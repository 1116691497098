import { AxiosInstance } from 'axios';
import { NotificationSettings } from '../dtos/alertAndEmails';

const MESSAGE_NOTIFICATION = 'message_notification';

export const updateDiAlertSettings = async (params: {
  axiosMid: AxiosInstance;
  notificationSettings: NotificationSettings;
}): Promise<string> => {
  const res = await params.axiosMid({
    method: 'PUT',
    baseURL: process.env.REACT_APP_NOTIFICATION_BASE_URL || 'http://127.0.0.1:/',
    url: `${MESSAGE_NOTIFICATION}/update_di_alert`,
    data: {
      ...params.notificationSettings,
    },
  });
  return res.data;
};

export const getDiAlertSettings = async (params: { axiosMid: AxiosInstance }): Promise<NotificationSettings> => {
  const res = await params.axiosMid({
    method: 'GET',
    baseURL: process.env.REACT_APP_NOTIFICATION_BASE_URL || 'http://127.0.0.1:/',
    url: `${MESSAGE_NOTIFICATION}/get_di_alert`,
  });
  return res.data;
};

export const getDiLanguageSettings = async (params: { axiosMid: AxiosInstance }): Promise<string> => {
  const res = await params.axiosMid({
    method: 'GET',
    baseURL: process.env.REACT_APP_NOTIFICATION_BASE_URL || 'http://127.0.0.1:/',
    url: `${MESSAGE_NOTIFICATION}/get_di_language`,
  });
  return res.data;
};

export const updateDiLanguageSetting = async (params: {
  axiosMid: AxiosInstance;
  language: string;
}): Promise<string> => {
  const res = await params.axiosMid({
    method: 'PUT',
    baseURL: process.env.REACT_APP_NOTIFICATION_BASE_URL || 'http://127.0.0.1:/',
    url: `${MESSAGE_NOTIFICATION}/update_di_language`,
    data: { language: params.language },
  });
  return res.data;
};
