import axios, { AxiosInstance } from 'axios';

import { BrokerDTO } from '../dtos/broker';
import { ListingTileData } from '../dtos/businessesForSale';
import { PicturePath } from '../dtos/uploadImage';

const USER_URL = 'user';
const LISTINGS_URL = 'listing';

export const updateBrokerInfo = async (params: { axiosMid: AxiosInstance; data: BrokerDTO }): Promise<string> => {
  const res = await params.axiosMid({
    method: 'PUT',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/update_broker_info`,
    data: params.data,
  });
  return res.data;
};

export const getBrokerProfile = async (params: { axiosMid: AxiosInstance; brokerId?: string }): Promise<BrokerDTO> => {
  const res = await params.axiosMid({
    method: 'GET',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/broker_profile`,
    params: {
      broker_id: params.brokerId,
    },
  });
  return res.data;
};

export const getUploadUrl = async (params: { axiosMid: AxiosInstance; picturePath: PicturePath }): Promise<string> => {
  const res = await params.axiosMid({
    method: 'GET',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/uploadUrl`,
    params: {
      picture_path: params.picturePath,
    },
  });
  return res.data;
};

export const postImage = async (data: { file: File; secureUrl: string }): Promise<string> => {
  const res = await axios.put(data.secureUrl, data.file, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const searchBrokers = async (pageParam: number): Promise<{ broker_list: BrokerDTO[]; total: number }> => {
  const res = await axios({
    method: 'GET',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/brokers`,
    params: { page: pageParam, number_of_brokers: 15 },
  });
  return res.data;
};

export const getBrokerListings = async (params: {
  axiosMid: AxiosInstance;
  brokerId?: string;
}): Promise<{ tile_list?: ListingTileData[]; total: number }> => {
  const res = await params.axiosMid({
    method: 'GET',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${LISTINGS_URL}/broker/`,
    params: { broker_id: params.brokerId },
  });
  return res.data;
};
