import { SignUpData, Tokens, UserAndTokens, UserData } from '../dtos/user';
import axios, { AxiosInstance } from 'axios';

const USER_URL = 'user';

export const signin = async (data: string): Promise<UserAndTokens> => {
  const res = await axios({
    method: 'POST',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/token`,
    data,
  });

  return res.data;
};

export const refreshAccessToken = async (params: { access_token: string; refresh_token: string }): Promise<Tokens> => {
  const res = await axios({
    method: 'PUT',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/refresh_access_token`,
    params: params,
  });
  return res.data;
};

export const getUserProfile = async (axiosMid: AxiosInstance): Promise<UserData> => {
  const res = await axiosMid({
    method: 'GET',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/user_profile`,
  });
  return res.data;
};

export const signUp = async (data: SignUpData): Promise<string> => {
  const res = await axios({
    method: 'POST',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/create`,
    data,
  });
  return res.data;
};

export const resendVerifyEmail = async (data: string): Promise<string> => {
  const res = await axios({
    method: 'PUT',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL,
    url: `${USER_URL}/resend_verification`,
    data,
  });
  return res.data;
};

export const updateUser = async (params: { data: UserData; axiosMid: AxiosInstance }): Promise<string> => {
  const res = await params.axiosMid({
    method: 'PUT',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/update_user`,
    data: params.data,
  });
  return res.data;
};

export const passwordForgot = async (data: string): Promise<string> => {
  const res = await axios({
    method: 'PUT',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/password_forgot`,
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return res.data;
};

export const resetPassword = async (data: {
  email: string;
  validation_code: string;
  password: string;
}): Promise<string> => {
  const res = await axios({
    method: 'PUT',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/password_reset/`,
    data,
  });
  return res.data;
};

export const updatePassword = async (params: {
  data: { previous_password: string; new_password: string };
  axiosMid: AxiosInstance;
}): Promise<string> => {
  const res = await params.axiosMid({
    method: 'PUT',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/update_password`,
    data: params.data,
  });
  return res.data;
};

export const confirmEmail = async (params: { email: string; code: number }) => {
  const res = await axios({
    method: 'PUT',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/code_validation`,
    data: params,
  });
  return res;
};

export const resendVerificationCode = async (email: string) => {
  const res = await axios({
    method: 'PUT',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/resend_verification`,
    data: email,
  });
  return res;
};
