import { BoldP, NormalP } from './FilterPanel';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import { industriesEn, industriesFr } from '../../../dtos/industries';

import { Form } from 'antd';
import { LocaleContext } from '../../../router/Router';
import Tree from 'antd/lib/tree/Tree';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const SearchIndustries = () => {
  const { t } = useTranslation(['businessesForSale', 'industries']);
  const { locale } = useContext(LocaleContext);
  const [hidden, setHidden] = useState(false);
  return (
    <>
      <StyledRow>
        <BoldP>{t('businessesForSale:search.industry')}</BoldP>
        <ClickDiv
          onClick={() => {
            setHidden(!hidden);
          }}
        >
          {hidden ? (
            <NormalP>
              <UpOutlined /> {t('businessesForSale:search.showAll')}
            </NormalP>
          ) : (
            <NormalP>
              <DownOutlined /> {t('businessesForSale:search.showLess')}
            </NormalP>
          )}
        </ClickDiv>
      </StyledRow>

      <Form.Item hidden={hidden} name={'industries'} valuePropName="checkedKeys" trigger="onCheck">
        <Tree
          checkable
          treeData={[
            ...(locale === 'en' ? industriesEn : industriesFr).map((ind) => {
              return {
                key: Object.keys(ind)[0],
                title: t(`industries:industries.${Object.keys(ind)[0]}`),
                children: Object.values(ind)[0].map((f: string) => {
                  return { key: f, title: t(`industries:industries.${f}`) };
                }),
              };
            }),
          ]}
        />
      </Form.Item>
    </>
  );
};

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ClickDiv = styled.div`
  cursor: pointer;
`;
