import { AdminListingsMetaQuery, AdminListingsQuery, LinstingAdmin } from '../dtos/admin';

import { AxiosInstance } from 'axios';
import { FranchiseDTO } from '../dtos/franchise';
import { UserData } from '../dtos/user';

const LISTING_URL = 'listing';
const ADMIN_URL = 'admin';
const USER_URL = 'user';

export const searchListingsAdmin = async (
  meta: AdminListingsMetaQuery,
  pageParam: number,
): Promise<{ listing_list: LinstingAdmin[]; total: number }> => {
  const res = await meta.axiosMid({
    method: 'POST',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${LISTING_URL}/${ADMIN_URL}/search`,
    params: { page: pageParam, number_of_listing: 25 },
    data: metaQueryToQueryMapper(meta),
  });
  return res.data;
};

const metaQueryToQueryMapper = (meta: AdminListingsMetaQuery): AdminListingsQuery => {
  const queryData: AdminListingsQuery = {
    email: meta.email,
    listing_id: meta.listing_id,
    user_id: meta.user_id,
    title: meta.title,
    is_published: undefined,
    is_verified: undefined,
    to_be_deleted: meta.to_be_deleted,
  };

  if (meta.status) {
    queryData.is_published = true;
    queryData.is_verified = false;
  }
  return queryData;
};

export const publishUnpublishListing = async (params: {
  axiosMid: AxiosInstance;
  listingid: string;
  state: boolean;
  repost?: boolean;
}): Promise<string> => {
  const res = await params.axiosMid({
    method: 'PUT',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${LISTING_URL}/${ADMIN_URL}/verify`,
    params: { listing_id: params.listingid, state: params.state, repost: params.repost },
  });
  return res.data;
};

export const searchUsersAdmin = async (params: {
  axiosMid: AxiosInstance;
  pageNumber: number;
  userId?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  userType?: string[];
}): Promise<{ user_list: UserData[]; total: number }> => {
  const res = await params.axiosMid({
    method: 'PUT',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/${ADMIN_URL}/search`,
    params: { page: params.pageNumber, number_of_users: 25 },
    data: {
      user_id: params.userId,
      email: params.email,
      first_name: params.firstName,
      last_name: params.lastName,
      user_type: params.userType,
    },
  });
  return res.data;
};

export const updateFranchise = async (params: {
  franchise: FranchiseDTO;
  axiosMid: AxiosInstance;
}): Promise<string> => {
  const res = await params.axiosMid({
    method: 'PUT',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${LISTING_URL}/${ADMIN_URL}/update_franchise/`,
    data: params.franchise,
  });
  return res.data;
};

export const deleteFranchise = async (params: { franchiseId: string; axiosMid: AxiosInstance }): Promise<string> => {
  const res = await params.axiosMid({
    method: 'DELETE',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${LISTING_URL}/${ADMIN_URL}/franchise/${params.franchiseId}`,
  });
  return res.data;
};

export const clearToBeDeletedForUser = async (params: { axiosMid: AxiosInstance; userId: string }): Promise<string> => {
  const res = await params.axiosMid({
    method: 'POST',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${LISTING_URL}/${ADMIN_URL}/clear_to_be_deleted_for_user`,
    params: { user_id: params.userId },
  });
  return res.data;
};

export const editUserRoleAdmin = async (params: {
  axiosMid: AxiosInstance;
  user_id: string;
  user_type: string;
}): Promise<string> => {
  const res = await params.axiosMid({
    method: 'POST',
    baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${USER_URL}/${ADMIN_URL}/edit_user_role_admin`,
    params: { user_id: params.user_id, user_type: params.user_type },
  });
  return res.data;
};

export const editListingMetaData = async (params: {
  axiosMid: AxiosInstance;
  listing_id: string;
  priority_exposure: boolean;
  featured: boolean;
  content: number;
  email_freq: number;
}): Promise<string> => {
  const res = await params.axiosMid({
    method: 'PUT',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${LISTING_URL}/${ADMIN_URL}/edit_listing_meta_data/`,
    data: {
      listing_id: params.listing_id,
      listing_meta: {
        priority_exposure: params.priority_exposure,
        featured: params.featured,
        content: params.content,
        email_freq: params.email_freq,
      },
    },
  });
  return res.data;
};

export const migrateAllListingsMetaDataToNewSub = async (params: {
  axiosMid: AxiosInstance;
  user_id: string;
  priority_exposure: boolean;
  featured: boolean;
  content: number;
  email_freq: number;
}): Promise<string> => {
  const res = await params.axiosMid({
    method: 'PUT',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${LISTING_URL}/${ADMIN_URL}/migrate_all_listings_meta_data/`,
    data: {
      user_id: params.user_id,
      listing_meta: {
        priority_exposure: params.priority_exposure,
        featured: params.featured,
        content: params.content,
        email_freq: params.email_freq,
      },
    },
  });
  return res.data;
};
