export const regionFr = [
  {
    '1800': [
      '1801',
      '1802',
      '1882',
      '1883',
      '1881',
      '1884',
      '1885',
      '1886',
      '1887',
      '1888',
      '1889',
      '1890',
      '1891',
      '1892',
      '1893',
      '1894',
      '1895',
    ],
  },
  { '1700': [] },
  { '1500': [] },
  { '1000': [] },
];

export const regionEn = [
  {
    '1800': [
      '1801',
      '1802',
      '1882',
      '1884',
      '1885',
      '1887',
      '1888',
      '1889',
      '1890',
      '1883',
      '1891',
      '1892',
      '1881',
      '1893',
      '1886',
      '1894',
      '1895',
    ],
  },
  { '1700': [] },
  { '1500': [] },
  { '1000': [] },
];
