import * as BrokerService from '../../services/BrokerService';

import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { Divider, Spin } from 'antd';
import React, { useContext, useEffect } from 'react';

import { BrokerFullProfile } from './BrokerFullProfile';
import { LocaleContext } from '../../router/Router';
import { UserContext } from '../../contexts/UserContext';
import { ViewDashboard } from '../../common/views/ViewDashboard';
import { privateRoutes } from '../../router/routes';
import styled from 'styled-components';
import { useAxiosMiddleware } from '../../services/useAxiosMiddleware';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { userHasActiveSubscription } from '../../services/PaymentService';

export const MyBrokerInfo = (): JSX.Element => {
  const { userType } = useContext(UserContext),
    navigate = useNavigate(),
    { locale } = useContext(LocaleContext);

  useEffect(() => {
    if (userType !== 'broker') {
      navigate(privateRoutes.myListings(locale));
    }
  }, []);

  const { t } = useTranslation('brokerProfile'),
    axiosMid = useAxiosMiddleware();

  const { data, isLoading } = useQuery(['getMyBrokerinfo'], () =>
    BrokerService.getBrokerProfile({ axiosMid: axiosMid }),
  );

  const subscriptionActiveQuery = useQuery(['userHasActiveSubscription'], {
    queryFn: () => userHasActiveSubscription({ axiosMid: axiosMid }),
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    if (!subscriptionActiveQuery.isLoading && subscriptionActiveQuery.data === false) {
      navigate(privateRoutes.subscription(locale));
    }
  }, [subscriptionActiveQuery.data]);

  return (
    <ViewDashboard>
      <Spin size={'large'} spinning={isLoading || subscriptionActiveQuery.isLoading}>
        <StyledFlex>
          <h3>{t('myBrokerInfo.title')}</h3>
          <ButtonSmall
            status={ButtonStatus.PRIMARY}
            text={t('myBrokerInfo.button')}
            onClick={() => navigate(privateRoutes.brokerFileForm(locale), { state: data })}
          />
        </StyledFlex>
        <Divider />
        {data && <BrokerFullProfile {...data} />}
      </Spin>
    </ViewDashboard>
  );
};

const StyledFlex = styled.div`
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
  flex-flow: wrap;
`;
