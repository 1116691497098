import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import React, { useContext } from 'react';

import { BusinessCarousel } from './BusinessCarousel';
import { FranchiseCarousel } from './FranchiseCarousel';
import { LocaleContext } from '../../router/Router';
import { NavLink } from 'react-router-dom';
import { Row } from 'antd';
import { publicRoutes } from '../../router/routes';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const Carrousels = () => {
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation('home');

  return (
    <StyledSection>
      <Row justify="center">
        <Block>
          <FlexSection>
            <FlexDiv>
              <SyledH2>{t('home:carrousels.featuredBusinesses')}</SyledH2>
              <NavLink to={publicRoutes.businessesForSale(locale)}>
                <ButtonDiv>
                  <ButtonSmall status={ButtonStatus.PRIMARY} text={t('home:carrousels.seeMore')} block={true} />
                </ButtonDiv>
              </NavLink>
              <BusinessCarousel />
            </FlexDiv>
            <FlexDiv>
              <SyledH2>{t('home:carrousels.featuredFranchises')}</SyledH2>
              <NavLink to={publicRoutes.franchiseSection(locale)}>
                <ButtonDiv>
                  <ButtonSmall status={ButtonStatus.PRIMARY} text={t('home:carrousels.seeMore')} block={true} />
                </ButtonDiv>
              </NavLink>
              <FranchiseCarousel />
            </FlexDiv>
          </FlexSection>
        </Block>
      </Row>
    </StyledSection>
  );
};

const Block = styled.section`
  padding: 4vw;
  max-width: 1800px;
`;

const StyledSection = styled.div`
  width: 100%;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const FlexSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
`;

const SyledH2 = styled.h2`
  margin-bottom: 0;
`;

const ButtonDiv = styled.div`
  width: 240px;
`;
