export const enum SellingReasonEnum {
  Retirement = 'Retirement',
  OtherBusiness = 'Pursuing another business',
  Relocating = 'Relocating',
  LostInterest = 'Pursuing new interest',
  Personal = 'Personal reasons',
  Other = 'Other',
}

export const SellingReason = [
  SellingReasonEnum.Retirement,
  SellingReasonEnum.OtherBusiness,
  SellingReasonEnum.Relocating,
  SellingReasonEnum.LostInterest,
  SellingReasonEnum.Personal,
  SellingReasonEnum.Other,
];

export const enum OfferFinancingEnum {
  Willing = 'I am willing to discuss financing options',
  NotWilling = 'I am not willing to offer financing options',
}

export const OfferFinancing = [OfferFinancingEnum.Willing, OfferFinancingEnum.NotWilling];
