import React from 'react';
import { Steps } from 'antd';
import styled from 'styled-components';

export const StepPanel = (props: {
  steps: { step: number; title: string }[];
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}): JSX.Element => {
  const onChange = (current: number) => {
    props.setActiveStep(current);
  };

  return (
    <LeftDiv>
      <Steps
        style={{ height: '480px' }}
        current={props.activeStep}
        onChange={onChange}
        direction="vertical"
        items={props.steps}
      ></Steps>
    </LeftDiv>
  );
};

const LeftDiv = styled.div`
  width: 250px;
  @media only screen and (max-width: 950px) {
    display: none;
  }
`;
