import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { Alert, Checkbox, Col, Form, Modal, Radio, RadioChangeEvent, Row, Space, Spin, Tooltip } from 'antd';
import {
  NotificationSettingsForm,
  TOT_INDUSTRIES,
  TOT_REGIONS,
  optionsNotificationDaily,
  optionsNotificationWeekly,
} from '../../dtos/alertAndEmails';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getDiAlertSettings, updateDiAlertSettings } from '../../services/NotificationService';
import { industriesEn, industriesFr } from '../../dtos/industries';
import { toAlertData, toForm } from './helpers';
import { useMutation, useQuery } from '@tanstack/react-query';

import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { InfoCircleOutlined } from '@ant-design/icons';
import { LocaleContext } from '../../router/Router';
import { UserContext } from '../../contexts/UserContext';
import { ViewDashboard } from '../../common/views/ViewDashboard';
import styled from 'styled-components';
import { useAxiosMiddleware } from '../../services/useAxiosMiddleware';

export const AlertsAndEmails = (): JSX.Element => {
  const { tokenErrorHandler } = useContext(UserContext),
    axiosMiddleware = useAxiosMiddleware(),
    { t } = useTranslation(['alertsAndEmails', 'regions', 'industries']),
    { locale } = useContext(LocaleContext);

  const [frequency, setFrequency] = useState<string | undefined>('never');

  const notificationSettingsQuery = useQuery(['getNotificationsSettings'], {
    queryFn: () => getDiAlertSettings({ axiosMid: axiosMiddleware }),
    onError: tokenErrorHandler,
  });

  const [alertForm] = Form.useForm();
  const [allRegionsChecked, setAllRegionsChecked] = useState(
    alertForm.getFieldValue('regions_daily')?.length === TOT_REGIONS,
  );
  const [allIndustriesChecked, setAllIndustriesChecked] = useState(
    alertForm.getFieldValue('industries_daily')?.length === TOT_INDUSTRIES,
  );

  useEffect(() => {
    alertForm.resetFields();
    setFrequency(notificationSettingsQuery.data?.f_email_frequency);
    setAllRegionsChecked(alertForm.getFieldValue('regions_daily')?.length === TOT_REGIONS);
    setAllIndustriesChecked(alertForm.getFieldValue('industries_daily')?.length === TOT_INDUSTRIES);
  }, [notificationSettingsQuery.data]);

  const notificationSettingsMutation = useMutation({
    mutationFn: updateDiAlertSettings,
    onSuccess: () => {
      Modal.success({
        content: t(`alertsAndEmails:success`),
      });
    },
    onError: tokenErrorHandler,
  });

  const changeFreq = (e: RadioChangeEvent) => {
    setFrequency(e.target.value);
  };

  const onFinish = (formData: NotificationSettingsForm) => {
    notificationSettingsMutation.mutate({
      axiosMid: axiosMiddleware,
      notificationSettings: toAlertData(formData),
    });
  };

  const onCheckAllRegions = (e: CheckboxChangeEvent) => {
    alertForm.setFieldsValue({
      regions_daily: e.target.checked ? [...optionsNotificationDaily] : [],
    });
    setAllRegionsChecked(e.target.checked);
  };

  const onCheckAllIndustries = (e: CheckboxChangeEvent) => {
    alertForm.setFieldsValue({
      industries_daily: e.target.checked ? [...industriesFr.map((option) => Object.keys(option)[0])] : [],
    });
    setAllIndustriesChecked(e.target.checked);
  };

  return (
    <ViewDashboard>
      <Spin
        size="large"
        spinning={
          notificationSettingsQuery.isLoading ||
          notificationSettingsQuery.isRefetching ||
          notificationSettingsMutation.isLoading
        }
      >
        <Row justify="center">
          <Col lg={{ span: 12 }}>
            <Form
              form={alertForm}
              layout="vertical"
              onFinish={onFinish}
              initialValues={notificationSettingsQuery.data && toForm(notificationSettingsQuery.data)}
            >
              <h3>{t('title')}</h3>
              <PPrelineBold>{t('content1')}</PPrelineBold>
              <Form.Item name={'consent'}>
                <Checkbox.Group>
                  <Checkbox value="optin_consent_acquizition">
                    <p>{t('consent_acquizition')}</p>
                  </Checkbox>
                  <br />
                  <Checkbox value="optin_consent_contex">
                    <p>{t('consent_contex')}</p>
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item
                name="f_email_frequency"
                label={
                  <h5>
                    <b>{t('consent_listing_for_sale')}</b>
                  </h5>
                }
              >
                <Radio.Group onChange={changeFreq}>
                  <Space direction="vertical">
                    <Radio value="daily">
                      <Tooltip title={t('dailyTooltip')}>
                        {t('daily')}
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Radio>
                    <Radio value="weekly">
                      <Tooltip title={t('weeklyTooltip')}>
                        {t('weekly')}
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Radio>
                    <Radio value="never">{t('na')}</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              {frequency === 'daily' ? (
                <>
                  <Alert message={t('alert')} type="warning" showIcon />
                  <PNopad>{t('interestRegion')}</PNopad>
                  <StyledDiv>
                    <Checkbox checked={allRegionsChecked} onChange={onCheckAllRegions}>
                      {t('allRegions')}
                    </Checkbox>
                  </StyledDiv>
                  <StyledSection>
                    <Form.Item name="regions_daily">
                      <Checkbox.Group>
                        {optionsNotificationDaily.map((option) => (
                          <div key={option}>
                            <Checkbox value={option} onClick={() => setAllRegionsChecked(false)}>
                              <label>{t(`regions:regions.${option}`)}</label>
                            </Checkbox>
                            <br />
                          </div>
                        ))}
                      </Checkbox.Group>
                    </Form.Item>
                  </StyledSection>
                  <PNopad>{t('interestIndustry')}</PNopad>
                  <StyledDiv>
                    <Checkbox checked={allIndustriesChecked} onChange={onCheckAllIndustries}>
                      {t('allIndustries')}
                    </Checkbox>
                  </StyledDiv>
                  <StyledSection>
                    <Form.Item name="industries_daily">
                      <Checkbox.Group>
                        {(locale === 'en' ? industriesEn : industriesFr).map((option) => (
                          <div key={Object.keys(option)[0]}>
                            <Checkbox onClick={() => setAllIndustriesChecked(false)} value={Object.keys(option)[0]}>
                              <label>{t(`industries:industries.${Object.keys(option)[0]}`)}</label>
                            </Checkbox>
                            <br />
                          </div>
                        ))}
                      </Checkbox.Group>
                    </Form.Item>
                  </StyledSection>
                </>
              ) : frequency === 'weekly' ? (
                <Form.Item name="regions_weekly" label={t('interestRegion')}>
                  <Checkbox.Group>
                    {optionsNotificationWeekly.map((option) => (
                      <div key={option}>
                        <Checkbox value={option}>
                          <Trans
                            i18nKey={`regions:regionsGroup.${option}`}
                            components={{ bold: <strong />, br: <br /> }}
                          />
                        </Checkbox>
                        <br />
                        <br />
                      </div>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              ) : (
                <></>
              )}
              <ButtonSmall status={ButtonStatus.PRIMARY} text={t('submit')} />
            </Form>
          </Col>
        </Row>
      </Spin>
    </ViewDashboard>
  );
};

const PPrelineBold = styled.p`
  white-space: pre-line;
`;

const PNopad = styled.p`
  margin-bottom: 0;
`;

const StyledDiv = styled.div`
  margin-bottom: 8px;
`;

const StyledSection = styled.div`
  margin-left: 8px;
`;
