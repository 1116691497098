import {
  NotificationSettings,
  NotificationSettingsForm,
  groupedRegions,
  optionsNotificationWeekly,
} from '../../dtos/alertAndEmails';

import { industriesEn } from '../../dtos/industries';

export const toForm = (data: NotificationSettings): NotificationSettingsForm => {
  const ret: NotificationSettingsForm = {
    consent: [],
    f_email_frequency: 'never',
  };

  if (data.optin_consent_acquizition) {
    ret.consent.push('optin_consent_acquizition');
  }
  if (data.optin_consent_contex) {
    ret.consent.push('optin_consent_contex');
  }

  if (data.f_email_frequency) {
    ret.f_email_frequency = data.f_email_frequency;
  }

  if (data.optin_consent_listing_for_sale) {
    if (ret.f_email_frequency === 'daily') {
      ret.regions_daily = data.email_regions || [];
      ret.industries_daily = data.email_industries?.map((ind) => ind.toString()) || [];
    } else {
      const weeklyOption = [] as string[];

      optionsNotificationWeekly.map((weeklyRegion) => {
        if (groupedRegions[weeklyRegion].some((sub_region) => data.email_regions?.includes(sub_region))) {
          weeklyOption.push(weeklyRegion);
        }
      });

      ret.regions_weekly = weeklyOption;
    }
  }

  return ret;
};

export const toAlertData = (data: NotificationSettingsForm): NotificationSettings => {
  const res: NotificationSettings = {
    optin_consent_listing_for_sale: data.f_email_frequency !== 'never',
    optin_consent_contex: data.consent.includes('optin_consent_contex'),
    optin_consent_acquizition: data.consent.includes('optin_consent_acquizition'),
    f_email_frequency: data.f_email_frequency !== 'never' ? data.f_email_frequency : undefined,
    email_regions: [] as number[],
    email_industries: [] as number[],
  };

  if (res.optin_consent_listing_for_sale) {
    if (res.f_email_frequency === 'daily') {
      res.email_regions = data.regions_daily || [];
      res.email_industries = data.industries_daily?.map((val) => Number(val)) || [];
    } else {
      let emailRegions = [] as number[];
      data.regions_weekly?.map((reg) => {
        emailRegions = emailRegions.concat(groupedRegions[reg]);
      });
      res.email_regions = emailRegions;
      res.email_industries = industriesEn.map((ind) => Number(Object.keys(ind)[0]));
    }
  }

  return res;
};
