import { FranchiseDTO, FranchiseForm } from '../../../dtos/franchise';

import { UploadFile } from 'antd';

export const toFranchise = (franchiseForm: FranchiseForm): FranchiseDTO => {
  const ret: FranchiseDTO = {
    title: franchiseForm.title,
    franchisor_email: franchiseForm.franchisor_email,
    plus_subscription: franchiseForm.plus_subscription,
    logo: pictureToString(franchiseForm.logo),
    picture1: pictureToString(franchiseForm.picture1),
    picture1_en: pictureToString(franchiseForm.picture1_en),
    picture2: pictureToString(franchiseForm.picture2),
    picture2_en: pictureToString(franchiseForm.picture2_en),
    picture3: pictureToString(franchiseForm.picture3),
    picture3_en: pictureToString(franchiseForm.picture3_en),
    picture4: pictureToString(franchiseForm.picture4),
    picture4_en: pictureToString(franchiseForm.picture4_en),
    picture5: pictureToString(franchiseForm.picture5),
    picture5_en: pictureToString(franchiseForm.picture5_en),
    picture6: pictureToString(franchiseForm.picture6),
    picture6_en: pictureToString(franchiseForm.picture6_en),
    picture7: pictureToString(franchiseForm.picture7),
    picture7_en: pictureToString(franchiseForm.picture7_en),
    picture8: pictureToString(franchiseForm.picture8),
    picture8_en: pictureToString(franchiseForm.picture8_en),
    text1: fieldsToArr(franchiseForm.text1_fr, franchiseForm.text1_en),
    text2: fieldsToArr(franchiseForm.text2_fr, franchiseForm.text2_en),
    text3: fieldsToArr(franchiseForm.text3_fr, franchiseForm.text3_en),
    text4: fieldsToArr(franchiseForm.text4_fr, franchiseForm.text4_en),
    text5: fieldsToArr(franchiseForm.text5_fr, franchiseForm.text5_en),
    text6: fieldsToArr(franchiseForm.text6_fr, franchiseForm.text6_en),
    text7: fieldsToArr(franchiseForm.text7_fr, franchiseForm.text7_en),
    text8: fieldsToArr(franchiseForm.text8_fr, franchiseForm.text8_en),
    franchise_type: franchiseForm.franchise_type,
    cash_down: franchiseForm.cash_down,
    total_investment_max: franchiseForm.total_investment_max,
    total_investment_min: franchiseForm.total_investment_min,
    video: franchiseForm.video,
    nb_franchise_ww: franchiseForm.nb_franchise_ww,
    nb_franchise_canada: franchiseForm.nb_franchise_canada,
    nb_franchise_quebec: franchiseForm.nb_franchise_quebec,
    year_founded: franchiseForm.year_founded,
    is_published: franchiseForm.is_published,
  };

  return ret;
};

const fieldsToArr = (def: string | undefined, add: string | undefined): string[] => {
  const ret = [];
  if (def) {
    ret.push(def);
  }
  if (add) {
    ret.push(add);
  }
  return ret;
};

const pictureToString = (
  field:
    | {
        fileList: UploadFile[];
      }
    | undefined,
): string | undefined => {
  return field?.fileList.length === 1 ? field?.fileList[0].url : undefined;
};

export const toFranchiseForm = (franchise: FranchiseDTO): FranchiseForm => {
  if (!franchise) {
    return {
      franchisor_email: '',
      title: '',
      plus_subscription: false,
    };
  }
  const ret: FranchiseForm = {
    title: franchise.title,
    franchisor_email: franchise.franchisor_email,
    plus_subscription: franchise.plus_subscription,
    logo: stringToField(franchise.logo),
    picture1: stringToField(franchise.picture1),
    picture1_en: stringToField(franchise.picture1_en),
    picture2: stringToField(franchise.picture2),
    picture2_en: stringToField(franchise.picture2_en),
    picture3: stringToField(franchise.picture3),
    picture3_en: stringToField(franchise.picture3_en),
    picture4: stringToField(franchise.picture4),
    picture4_en: stringToField(franchise.picture4_en),
    picture5: stringToField(franchise.picture5),
    picture5_en: stringToField(franchise.picture5_en),
    picture6: stringToField(franchise.picture6),
    picture6_en: stringToField(franchise.picture6_en),
    picture7: stringToField(franchise.picture7),
    picture7_en: stringToField(franchise.picture7_en),
    picture8: stringToField(franchise.picture8),
    picture8_en: stringToField(franchise.picture8_en),
    text1_fr: franchise.text1?.[0],
    text1_en: franchise.text1?.[1],
    text2_fr: franchise.text2?.[0],
    text2_en: franchise.text2?.[1],
    text3_fr: franchise.text3?.[0],
    text3_en: franchise.text3?.[1],
    text4_fr: franchise.text4?.[0],
    text4_en: franchise.text4?.[1],
    text5_fr: franchise.text5?.[0],
    text5_en: franchise.text5?.[1],
    text6_fr: franchise.text6?.[0],
    text6_en: franchise.text6?.[1],
    text7_fr: franchise.text7?.[0],
    text7_en: franchise.text7?.[1],
    text8_fr: franchise.text8?.[0],
    text8_en: franchise.text8?.[1],
    franchise_type: franchise.franchise_type,
    cash_down: franchise.cash_down,
    total_investment_max: franchise.total_investment_max,
    total_investment_min: franchise.total_investment_min,
    video: franchise.video,
    nb_franchise_ww: franchise.nb_franchise_ww,
    nb_franchise_canada: franchise.nb_franchise_canada,
    nb_franchise_quebec: franchise.nb_franchise_quebec,
    year_founded: franchise.year_founded,
    is_published: franchise.is_published,
  };
  return ret;
};

const stringToField = (picture?: string): { fileList: UploadFile[] } => {
  return picture
    ? {
        fileList: [
          {
            uid: picture,
            name: picture,
            url: picture,
            status: 'done',
          },
        ],
      }
    : { fileList: [] };
};
