import { Avatar, Drawer, Dropdown } from 'antd';
import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { MenuOutlined, UserOutlined } from '@ant-design/icons';
import { NavLink, useLocation } from 'react-router-dom';
import React, { useContext, useState } from 'react';

import { DrawerContent } from './DrawerContent';
import { DropdownMenu } from './DropdownMenu';
import { HeaderSiteSections } from './HeaderSiteSections';
import { Language } from './Language';
import { LocaleContext } from '../../router/Router';
import { UserContext } from '../../contexts/UserContext';
import logo from '../../img/LOGO-Acquizition-HR.webp';
import { publicRoutes } from '../../router/routes';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useTranslation } from 'react-i18next';

export const Header = (): JSX.Element => {
  const { t } = useTranslation('header');
  const { authTokens, signOut, userType } = useContext(UserContext);
  const { locale } = useContext(LocaleContext);

  const location = useLocation();

  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const hideDrawer = () => {
    setVisibility(!visible);
  };

  return (
    <StyledContainer location={location.pathname}>
      <LeftDiv>
        <NavLink to={publicRoutes.home(locale)}>
          <img src={logo} alt="Acquizition logo" height={32} />
        </NavLink>
        <NotHidden>
          <HeaderSiteSections />
        </NotHidden>
      </LeftDiv>
      <div>
        <NotHidden>
          <Language />
          {authTokens && (
            <Dropdown
              dropdownRender={(originNode) => <StyledMenu>{originNode}</StyledMenu>}
              menu={DropdownMenu({ t, signOut, userType, locale })}
              placement="bottom"
              trigger={['click']}
            >
              <StyledAvatar size={40} icon={<UserOutlined />} />
            </Dropdown>
          )}
          {!authTokens && (
            <NavLink to={publicRoutes.signin(locale)}>
              <ButtonSmall status={ButtonStatus.SECONDARY} text={t('signin')} />
            </NavLink>
          )}
        </NotHidden>
        <Burger onClick={showDrawer}>
          <Outline />
        </Burger>
      </div>
      <Drawer closable={false} open={visible} onClose={hideDrawer}>
        <DrawerContent hideDrawer={hideDrawer} />
      </Drawer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ location: string }>`
  grid-area: header;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: row;
  justify-content: space-between;
  height: 72px;
  padding-left: 4vw;
  padding-right: 4vw;
  @media only screen and (max-width: 1400px) {
    padding-left: 2vw;
    padding-right: 2vw;
  }
  border-bottom: 1px solid ${theme.color.lightGrey};
  box-shadow: 0px 2px 2px 0px #c4c4c4a8;
  background-color: ${(props) => (props.location.substring(3, 8) === '/home' ? theme.color.smoke : theme.color.white)};
  display: ${(props) => (props.location.substring(3, 14) === '/onboarding' ? 'none' : 'flex')};
`;

const LeftDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledAvatar = styled(Avatar)`
  cursor: pointer;
  :hover {
    background-color: ${theme.color.grey};
  }
`;

const Burger = styled.div`
  @media only screen and (max-width: 1200px) {
    display: block;
  }
  display: none;
`;

const NotHidden = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export const Outline = styled(MenuOutlined)`
  font-size: 22px;
  cursor: pointer;
`;

const StyledMenu = styled.div`
  border: 1px solid ${theme.color.lightGrey};
  border-radius: 10px;
  background-color: white;
  margin: 16px;
  padding: 16px;
  width: 296px;

  .ant-dropdown-menu {
    box-shadow: none !important;
  }

  h6 {
    font-size: ${theme.fontsize.h6};
    font-weight: bold;
    line-height: 2rem;
  }

  .active {
    h6 {
      color: ${theme.color.green} !important;
    }
  }
`;
