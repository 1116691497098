import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Footer } from '../../components/footer/Footer';
import { HelmetSellABusiness } from '../../helmets/HelmetSellABusiness';
import { Image } from 'antd';
import { LocaleContext } from '../../router/Router';
import { NavLink } from 'react-router-dom';
import { ViewNarrowColumn } from '../../common/views/ViewNarrowColumn';
import entrepreneur from './../../img/businesses.webp';
import experts from './../../img/experts.webp';
import { publicRoutes } from '../../router/routes';
import styled from 'styled-components';

export const SellABusiness = (): JSX.Element => {
  const { t } = useTranslation('sellABusiness');
  const { locale } = useContext(LocaleContext);

  return (
    <ViewNarrowColumn>
      <HelmetSellABusiness />
      <FlexDiv>
        <Block>
          <Row justify="space-around" align="middle">
            <Col lg={10}>
              <h2 style={{ fontWeight: 'bolder' }}>{t('sellABusiness:title')}</h2>
              <PBreak>
                <Trans i18nKey={'sellABusiness:desc'} components={{ bold: <strong /> }} />
              </PBreak>
              <FlexGrow>
                <NavLink to={publicRoutes.signup(locale)}>
                  <ButtonSmall status={ButtonStatus.PRIMARY} text={t('sellABusiness:button')} block={true} />
                </NavLink>
              </FlexGrow>
              <PBreak>
                {t('sellABusiness:convinced')}
                <a
                  href={
                    locale == 'fr'
                      ? 'https://acquizition-prod.s3.ca-central-1.amazonaws.com/media_kit/Acquizition_biz_trousse_medias_proprietaires_vendeurs.pdf'
                      : 'https://acquizition-prod.s3.ca-central-1.amazonaws.com/media_kit/Acquizition_biz_media_kit_owners_sellers.pdf'
                  }
                >
                  <u>
                    <b>{t('mediaKit')}</b>
                  </u>
                </a>
                {t('download')}
              </PBreak>
            </Col>
            <Col lg={11}>
              <Row justify={'end'} align={'middle'}>
                <Hidden>
                  <Image src={entrepreneur} preview={false} alt="Business owner" />
                </Hidden>
              </Row>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row justify="space-around" align="middle">
            <Col lg={11}>
              <Row justify={'end'} align={'middle'}>
                <Hidden>
                  <Image src={experts} preview={false} alt="Business owner" />
                </Hidden>
              </Row>
            </Col>
            <Col lg={10}>
              <H2Break>{t('sellABusiness:titleExpert')}</H2Break>
              <PBreak>{t('descExpert')}</PBreak>
              <FlexGrow>
                <NavLink to={publicRoutes.signup(locale)}>
                  <ButtonSmall status={ButtonStatus.PRIMARY} text={t('sellABusiness:button')} block={true} />
                </NavLink>
              </FlexGrow>
              <PBreak>
                {t('sellABusiness:convinced')}
                <a
                  href={
                    locale == 'fr'
                      ? 'https://acquizition-prod.s3.ca-central-1.amazonaws.com/media_kit/Acquizition_biz_trousse_media_experts.pdf'
                      : 'https://acquizition-prod.s3.ca-central-1.amazonaws.com/media_kit/Acquizition_biz_media_kit_experts.pdf'
                  }
                >
                  <u>
                    <b>{t('mediaKit')}</b>
                  </u>
                </a>
                {t('download')}
              </PBreak>
            </Col>
          </Row>
        </Block>
      </FlexDiv>
      <Footer />
    </ViewNarrowColumn>
  );
};

const Block = styled.section`
  padding-left: 4vw;
  padding-right: 4vw;

  padding-top: 32px;
  padding-bottom: 32px;
  max-width: 1800px;
  width: 100%;
`;

const PBreak = styled.p`
  white-space: pre-line;
`;

const H2Break = styled.h2`
  white-space: pre-line;
  font-weight: bolder;
`;

const Hidden = styled.div`
  display: block;
  @media only screen and (max-width: 1000px) {
    display: none;
  } ;
`;

const FlexGrow = styled.div`
  width: 400px;
  padding-bottom: 16px;
  @media only screen and (max-width: 1000px) {
    width: 280px;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2vw;
  margin-bottom: 32px;
`;
