import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const Count = (props: { value?: number; duration: number; jump: number }) => {
  const { value, duration, jump } = props;
  if (!value) return <></>;
  const [count, setCount] = useState('0');

  useEffect(() => {
    let start = 0;
    if (start === value) return;

    const totalMilSecDur = duration;
    const incrrementTime = ((totalMilSecDur / value) * 1000) / jump;

    const timer = setInterval(() => {
      start += jump;
      setCount(String(start));
      if (start === value) clearInterval(timer);
    }, incrrementTime);
  }, [value, duration]);

  return <StylesH2>{count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</StylesH2>;
};

const StylesH2 = styled.h2`
  color: ${theme.color.green};
  font-size: 40px !important;
`;
