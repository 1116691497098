import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { Image, Tag } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import { MailOutlined, PhoneOutlined, PushpinOutlined, UserOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';

import { BrokerDTO } from '../../dtos/broker';
import { LocaleContext } from '../../router/Router';
import { formatPhoneNumber } from '../brokerProfile/BrokerFullProfile';
import styled from 'styled-components';
import { tCustom } from '../../services/TranslationService';
import { theme } from '../../styles/theme';
import { useTranslation } from 'react-i18next';

export const BrokerTile = (props: BrokerDTO) => {
  const { locale } = useContext(LocaleContext),
    { t } = useTranslation('brokerSection');
  const setSearchParams = useSearchParams()[1];
  const eliteTag = props.is_elite && <StyledTag color={theme.color.gold}>{t('elite')}</StyledTag>;

  const onClick = () => {
    setSearchParams({
      prev_id: props._id || '',
    });
  };
  return (
    <Card>
      <Section1>
        <div>
          {props.profile_picture ? (
            <StyledImg
              height={104}
              width={96}
              src={props.profile_picture !== null ? props.profile_picture : ''}
              preview={false}
            />
          ) : (
            <FallbackUser>
              <UserOutlined />
            </FallbackUser>
          )}
        </div>
        <DivMobile>
          <div>
            <Link onClick={onClick} to={`../brokerProfile/${props._id}`}>
              {props.first_name && (
                <Title>
                  <h6>
                    {props.first_name}
                    <span> </span>
                    {props.last_name}
                  </h6>
                  {eliteTag}
                </Title>
              )}
            </Link>
            <StyledTitle>{tCustom(props.broker_title, locale)}</StyledTitle>
          </div>
          {props.company_logo && (
            <div>
              <StyledLogo height={24} src={props.company_logo !== null ? props.company_logo : ''} preview={false} />
            </div>
          )}
        </DivMobile>
      </Section1>
      <SubDiv>
        <Div1>
          <div>
            <Link onClick={onClick} to={`../brokerProfile/${props._id}`}>
              {props.first_name && (
                <Title>
                  <h6>
                    {props.first_name}
                    <span> </span>
                    {props.last_name}
                  </h6>
                  {eliteTag}
                </Title>
              )}
            </Link>
            <StyledTitle>{tCustom(props.broker_title, locale)}</StyledTitle>
          </div>
          {props.company_logo && (
            <div>
              <StyledLogo height={24} src={props.company_logo !== null ? props.company_logo : ''} preview={false} />
            </div>
          )}
        </Div1>
        <Div2>
          {props.broker_phone_number && (
            <ContactDiv>
              <IconSpan>
                <PhoneOutlined />
              </IconSpan>
              <PContact>{formatPhoneNumber(props.broker_phone_number.toString())}</PContact>
            </ContactDiv>
          )}
          <ContactDiv>
            <IconSpan>
              <MailOutlined />
            </IconSpan>
            <PContact>{props.broker_email}</PContact>
          </ContactDiv>
          {props.region && (
            <ContactDiv>
              <IconSpan>
                <PushpinOutlined />
              </IconSpan>
              <PContact>{props.region}</PContact>
            </ContactDiv>
          )}
        </Div2>
        <Div3>
          <TwolineNoWrap>{<p>{tCustom(props.broker_expertise, locale)}</p>}</TwolineNoWrap>
          <ButtonDiv>
            <Link onClick={onClick} to={`../brokerProfile/${props._id}`}>
              <ButtonSmall status={ButtonStatus.TERTIARY} text={t('seeDetails')} />
            </Link>
          </ButtonDiv>
        </Div3>
      </SubDiv>
    </Card>
  );
};
const Card = styled.div`
  margin-bottom: 16px;
  border-radius: 5px;
  border: 1px solid #a2a0a8;
  padding: 1rem;
  :hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  display: flex;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
  gap: 16px;
`;

const StyledImg = styled(Image)`
  object-fit: cover !important;
  border-radius: 5px;
`;

const StyledLogo = styled(Image)`
  object-fit: scale-down !important;
  width: inherit;
`;

const FallbackUser = styled.div`
  width: 96px;
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eae9e9;
  border-radius: 5px;
  color: #fff;
  font-size: 72px;
`;

const StyledTitle = styled.p`
  font-weight: bold;
  font-size: 14px !important;
  color: ${theme.color.darkGrey};
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const SubDiv = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  @media only screen and (max-width: 800px) {
    flex-flow: wrap;
    gap: 16px;
  }
`;

const Div1 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const Div2 = styled.div`
  flex: 1;
  p {
    margin-bottom: 4px;
  }
`;

const Div3 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
`;

const TwolineNoWrap = styled.div`
  p {
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const IconSpan = styled.span`
  font-size: 16px;
  margin-right: 8px;
  span,
  p {
    white-space: nowrap !important;
  }
`;

const ContactDiv = styled.div`
  display: flex;
`;

const PContact = styled.p`
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const DivMobile = styled.div`
  display: none;
  @media only screen and (max-width: 800px) {
    display: block;
  }
`;

const Section1 = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledTag = styled(Tag)`
  font-size: ${theme.fontsize.smallp};
  font-weight: bold !important;
  border-radius: 4px;
  line-height: 14px;
`;

const Title = styled.div`
  h6 {
    margin: 0 !important;
  }
  display: flex;
  align-items: center;
  column-gap: 8px;
`;
