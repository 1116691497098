import { ButtonSmall, ButtonStatus } from '../../../common/button/ButtonSmall';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Table, Tag, Tooltip } from 'antd';

import { ColumnsType } from 'antd/lib/table';
import { DeleteAction } from './DeleteAction';
import { EditAction } from './EditAction';
import { EyeOutlined } from '@ant-design/icons';
import { FranchiseDTO } from '../../../dtos/franchise';
import { SortOptions } from '../../franchiseSection/FranchiseSort';
import { ViewAdmin } from '../../../common/views/ViewAdmin';
import { adminRoutes } from '../../../router/routes';
import { searchFranchise } from '../../../services/FranchiseService';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';

const columns: ColumnsType<FranchiseDTO> = [
  { title: 'ID Franchise', key: '_id', dataIndex: '_id' },
  {
    title: 'Nom de la franchise',
    key: 'title',
    render: (_, franchise) => <p>{franchise.title}</p>,
  },
  {
    title: 'Email du franchiseur',
    key: 'title',
    render: (_, franchise) => <p>{franchise.franchisor_email}</p>,
  },
  {
    title: 'Publiée',
    key: 'is_published',
    render: (_, franchise) =>
      franchise.is_published ? <Tag color={'green'}>Publiée</Tag> : <Tag color={'orange'}>Brouillon</Tag>,
  },
  {
    title: 'Forfait',
    key: 'plus_subscription',
    render: (_, franchise) =>
      franchise.plus_subscription ? <Tag color={'gold'}>Forfait plus</Tag> : <Tag color={'grey'}>Forfait standard</Tag>,
  },
  {
    title: 'Actions',
    key: 'action',
    render: (_, franchise) => (
      <HorizontalDiv>
        <Link to={`../fr/franchise/${franchise._id}`}>
          <Tooltip title={'Voir la franchise complète'}>
            <EyeOutlined />
          </Tooltip>
        </Link>
        <EditAction {...franchise} />
        <DeleteAction {...franchise} />
      </HorizontalDiv>
    ),
  },
];

export const FranchisesAdmin = () => {
  const navigate = useNavigate();
  const [currPage, setCurrPage] = useState<number>(1);
  const [tableData, setTableData] = useState<FranchiseDTO[]>([]);
  const [totalFranchises, setTotalFranchises] = useState<number>(0);

  const query = useQuery(
    ['franchiseAdminSearch', currPage],
    () =>
      searchFranchise({
        page: currPage - 1,
        show_only_publish: false,
        sort_parameters: SortOptions.A_FIRST,
      }),
    {
      refetchOnWindowFocus: false,
      onError: () => {
        setTableData([]);
        setTotalFranchises(1);
      },
    },
  );

  useEffect(() => {
    if (query.data) {
      setTableData(query.data.franchises);
      setTotalFranchises(query.data.total);
    } else {
      setTableData([]);
      setTotalFranchises(0);
    }
  }, [query.data]);

  const getNextPage = (page: number) => {
    setCurrPage(page);
  };

  return (
    <ViewAdmin>
      <FlexDiv>
        <h3>Franchises</h3>
        <ButtonSmall
          status={ButtonStatus.PRIMARY}
          text={'Nouvelle franchise'}
          onClick={() => navigate(adminRoutes.franchiseFormAdmin)}
        />
      </FlexDiv>
      <StyledP>
        <Table
          loading={query.isFetching}
          columns={columns}
          dataSource={tableData}
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
            total: totalFranchises,
            current: currPage,
            onChange: (page) => {
              getNextPage(page);
            },
          }}
          rowKey={(franchise) => franchise._id || ''}
        />
      </StyledP>
    </ViewAdmin>
  );
};

const StyledP = styled.div`
  p {
    margin-bottom: 0;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const HorizontalDiv = styled.div`
  display: flex;
  gap: 16px;
`;
