import { ButtonSmall, ButtonStatus } from '../../../common/button/ButtonSmall';
import { SaveOutlined, SendOutlined } from '@ant-design/icons';

import { Listing } from '../../../dtos/listingCreation';
import React from 'react';
import { Tooltip } from 'antd';
import { ViewForm } from '../../../common/views/ViewForm';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const ActionPanel = (props: {
  steps: { step: number; title: string }[];
  activeStep: number;
  listing: Listing;
  next: () => void;
  prev: () => void;
  onSave: () => void;
  onPublish: () => void;
  publishable: boolean;
  isPublished: boolean;
}): JSX.Element => {
  const { t } = useTranslation(['createListing']);

  return (
    <ViewForm maxWidth={'600px'}>
      <ButtonDiv>
        <div>
          {props.activeStep > 0 && (
            <ButtonSmall
              text={t('createListing:actionPannel.goBack')}
              status={ButtonStatus.TERTIARY}
              onClick={() => props.prev()}
            />
          )}
        </div>
        <div>
          {props.activeStep < props.steps.length - 1 && (
            <ButtonSmall
              text={t('createListing:actionPannel.next')}
              status={ButtonStatus.SECONDARY}
              onClick={() => props.next()}
            />
          )}
        </div>
      </ButtonDiv>
      <StyledDiv>
        <Tooltip title={t('createListing:actionPannel.saveChanges')}>
          <div>
            <ButtonSmall
              status={ButtonStatus.SECONDARY}
              text={t('createListing:actionPannel.save')}
              icon={<SaveOutlined />}
              onClick={props.onSave}
            />
          </div>
        </Tooltip>

        <Tooltip
          title={
            !props.isPublished
              ? props.publishable
                ? t('createListing:actionPannel.publishYourListing')
                : t('createListing:actionPannel.mandatory')
              : t('createListing:actionPannel.alreadyPublished')
          }
        >
          <div>
            <ButtonSmall
              status={ButtonStatus.PRIMARY}
              text={t('createListing:actionPannel.publish')}
              icon={<SendOutlined />}
              onClick={props.onPublish}
              disabled={!props.publishable || props.listing._id === undefined || props.listing.is_published}
            />
          </div>
        </Tooltip>
      </StyledDiv>
    </ViewForm>
  );
};

const StyledDiv = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  flex-flow: wrap;
  gap: 32px;
  button {
    border-radius: 5px;
    border-width: 2px;
  }
  margin-bottom: 32px;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
