import { ButtonSmall, ButtonStatus } from '../../../common/button/ButtonSmall';
import { Alert, Checkbox, Form, Radio, RadioChangeEvent, Space, Spin, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { industriesEn, industriesFr } from '../../../dtos/industries';
import { optionsNotificationDaily, optionsNotificationWeekly } from '../../../dtos/alertAndEmails';
import { privateRoutes, publicRoutes } from '../../../router/routes';
import { updateDiAlertSettings, updateDiLanguageSetting } from '../../../services/NotificationService';

import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { InfoCircleOutlined } from '@ant-design/icons';
import { LocaleContext } from '../../../router/Router';
import { UserContext } from '../../../contexts/UserContext';
import { ViewForm } from '../../../common/views/ViewForm';
import logo from '../../../img/LOGO-Acquizition-HR.webp';
import styled from 'styled-components';
import { theme } from '../../../styles/theme';
import { toAlertData } from '../../AlertsAndEmails/helpers';
import { updateUser } from '../../../services/UserService';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export const Onboarding = (): JSX.Element => {
  const { tokenErrorHandler, setUserTypeOnUpdate, userType } = useContext(UserContext),
    { locale } = useContext(LocaleContext),
    axiosMiddleware = useAxiosMiddleware(),
    { t } = useTranslation(['onboarding', 'regions', 'industries']),
    [onboardingForm] = Form.useForm(),
    [allRegionsChecked, setAllRegionsChecked] = useState(true),
    [allIndustriesChecked, setAllIndustriesChecked] = useState(true),
    [frequency, setFrequency] = useState<string | undefined>('weekly');

  const changeFreq = (e: RadioChangeEvent) => {
    setFrequency(e.target.value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (userType !== '' && userType !== undefined) {
      navigate(privateRoutes.profile(locale));
    }
  }, []);

  const onboardingMutation = useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      const newUserType = onboardingForm.getFieldValue('user_type');
      if (setUserTypeOnUpdate) {
        setUserTypeOnUpdate(newUserType);
      }
      if (newUserType === 'buyer') {
        navigate(publicRoutes.businessesForSale(locale));
      } else {
        navigate(privateRoutes.myListings(locale));
      }
    },
    onError: tokenErrorHandler,
  });

  const notificationSettingsMutation = useMutation({
    mutationFn: updateDiAlertSettings,
    onError: tokenErrorHandler,
    onSuccess: () =>
      updateLanguageMutation.mutate({
        axiosMid: axiosMiddleware,
        language: onboardingForm.getFieldValue('language'),
      }),
  });

  const updateLanguageMutation = useMutation({
    mutationFn: updateDiLanguageSetting,
    onError: tokenErrorHandler,
  });

  const onFinish = (formData: {
    user_type: string;
    language: string;
    consent: string[];
    f_email_frequency: string;
    regions_daily?: number[];
    regions_weekly?: string[];
  }) => {
    if (formData.f_email_frequency !== 'never') {
      formData.consent = ['optin_consent_contex', 'optin_consent_acquizition'];
    } else {
      formData.consent = [];
    }
    notificationSettingsMutation.mutate({
      axiosMid: axiosMiddleware,
      notificationSettings: toAlertData(formData),
    });
    onboardingMutation.mutate({ data: { user_type: formData.user_type }, axiosMid: axiosMiddleware });
  };

  const onCheckAllRegions = (e: CheckboxChangeEvent) => {
    onboardingForm.setFieldsValue({
      regions_daily: e.target.checked ? [...optionsNotificationDaily] : [],
    });
    setAllRegionsChecked(e.target.checked);
  };

  const onCheckAllIndustries = (e: CheckboxChangeEvent) => {
    onboardingForm.setFieldsValue({
      industries_daily: e.target.checked ? [...industriesFr.map((option) => Object.keys(option)[0])] : [],
    });
    setAllIndustriesChecked(e.target.checked);
  };

  return (
    <>
      <Spin
        spinning={
          onboardingMutation.isLoading || notificationSettingsMutation.isLoading || updateLanguageMutation.isLoading
        }
        size={'large'}
      >
        <StyledContainer>
          <img src={logo} alt="Acquizition logo" height={32} />
        </StyledContainer>
        <ViewForm maxWidth={'600px'}>
          <StyledH4>{t('onboarding:user_type.title')}</StyledH4>
          <Form
            scrollToFirstError={true}
            initialValues={{
              f_email_frequency: 'weekly',
              regions_daily: [...optionsNotificationDaily],
              industries_daily: [...industriesFr.map((option) => Object.keys(option)[0])],
              regions_weekly: [...optionsNotificationWeekly],
            }}
            form={onboardingForm}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              name="user_type"
              rules={[
                {
                  required: true,
                  message: t('onboarding:error'),
                },
              ]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value="buyer">
                    {t('onboarding:user_type.iWantTo')}
                    <b>{t('onboarding:user_type.buyer')}</b>
                  </Radio>
                  <Radio value="seller">
                    {t('onboarding:user_type.iWantTo')}
                    <b>{t('onboarding:user_type.seller')}</b>
                  </Radio>
                  <Radio value="broker">
                    <Tooltip title={t('onboarding:user_type.tooltip')}>
                      {t('onboarding:user_type.iAm')}
                      <b>{t('onboarding:user_type.broker')}</b> <InfoCircleOutlined />
                    </Tooltip>
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <label>{t('onboarding:language.title')}</label>
            <Form.Item
              name="language"
              rules={[
                {
                  required: true,
                  message: t('onboarding:error'),
                },
              ]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value="fr">{t('onboarding:language.french')}</Radio>
                  <Radio value="en">{t('onboarding:language.english')}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <h5>{t('onboarding:consent.content')}</h5>
            <Form.Item
              name="f_email_frequency"
              rules={[
                {
                  required: true,
                  message: t('onboarding:error'),
                },
              ]}
            >
              <Radio.Group onChange={changeFreq}>
                <Space direction="vertical">
                  <Radio value="daily">
                    <Tooltip title={t('onboarding:frequency.dailyTooltip')}>
                      {t('onboarding:frequency.daily')}
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Radio>
                  <Radio value="weekly">
                    <Tooltip title={t('onboarding:frequency.weeklyTooltip')}>
                      {t('onboarding:frequency.weekly')}
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Radio>
                  <Radio value="never">{t('onboarding:frequency.na')}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {frequency === 'daily' ? (
              <>
                <Alert message={t('onboarding:alert')} type="warning" showIcon />
                <PNopad>{t('onboarding:regions.interestRegion')}</PNopad>
                <StyledDiv>
                  <Checkbox checked={allRegionsChecked} onChange={onCheckAllRegions}>
                    {t('onboarding:regions.allRegions')}
                  </Checkbox>
                </StyledDiv>
                <StyledSection>
                  <Form.Item name="regions_daily">
                    <Checkbox.Group>
                      {optionsNotificationDaily.map((option) => (
                        <div key={option}>
                          <Checkbox value={option} onClick={() => setAllRegionsChecked(false)}>
                            <label>{t(`regions:regions.${option}`)}</label>
                          </Checkbox>
                          <br />
                        </div>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </StyledSection>

                <PNopad>{t('onboarding:industries.interestIndustry')}</PNopad>
                <StyledDiv>
                  <Checkbox checked={allIndustriesChecked} onChange={onCheckAllIndustries}>
                    {t('onboarding:industries.allIndustries')}
                  </Checkbox>
                </StyledDiv>
                <StyledSection>
                  <Form.Item name="industries_daily">
                    <Checkbox.Group>
                      {(locale === 'en' ? industriesEn : industriesFr).map((option) => (
                        <div key={Object.keys(option)[0]}>
                          <Checkbox onClick={() => setAllIndustriesChecked(false)} value={Object.keys(option)[0]}>
                            <label>{t(`industries:industries.${Object.keys(option)[0]}`)}</label>
                          </Checkbox>
                          <br />
                        </div>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </StyledSection>
              </>
            ) : frequency === 'weekly' ? (
              <Form.Item name="regions_weekly" label={t('onboarding:regions.interestRegion')}>
                <Checkbox.Group>
                  {optionsNotificationWeekly.map((option) => (
                    <div key={option}>
                      <Checkbox value={option}>
                        <Trans
                          i18nKey={`regions:regionsGroup.${option}`}
                          components={{ bold: <strong />, br: <br /> }}
                        />
                      </Checkbox>
                      <br />
                      <br />
                    </div>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            ) : (
              <></>
            )}
            <p>{t('onboarding:consent.subTitle')}</p>
            <ButtonSmall text={t('onboarding:submit')} status={ButtonStatus.SECONDARY} />
          </Form>
        </ViewForm>
      </Spin>
    </>
  );
};

const StyledH4 = styled.h4`
  margin-top: 32px;
`;

const StyledContainer = styled.div`
  grid-area: header;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: row;
  justify-content: space-between;
  height: 72px;
  padding-left: 4vw;
  padding-right: 4vw;
  @media only screen and (max-width: 1400px) {
    padding-left: 2vw;
    padding-right: 2vw;
  }
  border-bottom: 1px solid ${theme.color.lightGrey};
  box-shadow: 0px 2px 2px 0px #c4c4c4a8;
  background-color: ${theme.color.white};
`;

const PNopad = styled.p`
  margin-bottom: 0;
`;

const StyledDiv = styled.div`
  margin-bottom: 8px;
`;

const StyledSection = styled.div`
  margin-left: 8px;
`;
