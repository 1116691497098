import * as FavoritesService from '../../../services/FavoritesService';

import React, { useContext } from 'react';
import { StarFilled, StarTwoTone } from '@ant-design/icons';

import { UserContext } from '../../../contexts/UserContext';
import styled from 'styled-components';
import { theme } from '../../../styles/theme';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type FavoriteButtonProps = {
  listingId: string;
};

export const FavoriteButton = (props: FavoriteButtonProps): JSX.Element => {
  const { authTokens, userFavorites, setUserFavorites } = useContext(UserContext);
  const axiosMiddleware = useAxiosMiddleware();
  const { t } = useTranslation('businessesForSale');
  const [isLoading, setIsLoading] = useState(false);

  const getFavoriteListFromUser = (): string[] => {
    return userFavorites || [];
  };

  const [isFavorited, setIsFavorited] = useState(getFavoriteListFromUser().includes(props.listingId));

  const setNewFavoritesListInCache = (list: string[]): void => {
    if (setUserFavorites) {
      setUserFavorites(list || []);
    }
    localStorage.setItem('userFavorites', JSON.stringify(list));
  };

  const removeFavoriteMutation = useMutation(FavoritesService.removeFromFavorites, {
    onSuccess: (favorites) => {
      setNewFavoritesListInCache(favorites);
      setIsFavorited((prevIsFavorited) => !prevIsFavorited);
      setIsLoading(false);
    },
  });

  const addFavoriteMutation = useMutation(FavoritesService.addToFavorites, {
    onSuccess: (favorites) => {
      setNewFavoritesListInCache(favorites);
      setIsFavorited((prevIsFavorited) => !prevIsFavorited);
      setIsLoading(false);
    },
  });

  const onClick = () => {
    if (!isLoading && authTokens) {
      setIsLoading(true);
      if (isFavorited) {
        removeFavoriteMutation.mutate({ listingid: props.listingId, axiosMid: axiosMiddleware });
      } else {
        addFavoriteMutation.mutate({ listingid: props.listingId, axiosMid: axiosMiddleware });
      }
    }
  };

  return (
    <StyledSpan onClick={onClick}>
      {isFavorited ? (
        <StyledStarFilled twoToneColor={theme.color.darkBlue} />
      ) : (
        <StyledStarTwoTone twoToneColor={theme.color.darkBlue} />
      )}
      <>{t('businessesForSale:tile.fav')}</>
    </StyledSpan>
  );
};

const StyledSpan = styled.p`
  font-size: 18px;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledStarFilled = styled(StarFilled)`
  padding-right: 4px;
  font-size: 22px;
`;

const StyledStarTwoTone = styled(StarTwoTone)`
  padding-right: 4px;
  font-size: 22px;
`;
