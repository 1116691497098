import * as listingService from '../../../services/ListingService';

import React, { useContext } from 'react';
import { Spin, Tooltip } from 'antd';

import { EditOutlined } from '@ant-design/icons';
import { LinstingAdmin } from '../../../dtos/admin';
import { LocaleContext } from '../../../router/Router';
import { UserContext } from '../../../contexts/UserContext';
import { privateRoutes } from '../../../router/routes';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export const EditAction = (props: LinstingAdmin): JSX.Element => {
  const axiosMiddleware = useAxiosMiddleware(),
    { tokenErrorHandler } = useContext(UserContext),
    { locale } = useContext(LocaleContext),
    navigate = useNavigate();

  const getListingMutation = useMutation(listingService.getListing, {
    onSuccess: (listing) => {
      navigate(privateRoutes.createListing(locale), { state: listing });
    },
    onError: tokenErrorHandler,
  });
  const editListingAction = (id: string) => {
    getListingMutation.mutate({ listingid: id, axiosMid: axiosMiddleware, tokens: '' });
  };
  return getListingMutation.isLoading ? (
    <Spin />
  ) : (
    <div>
      <Tooltip title={"Modifier l'annonce"}>
        <EditOutlined onClick={() => editListingAction(props._id)} />
      </Tooltip>
    </div>
  );
};
