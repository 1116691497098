import { Helmet } from 'react-helmet-async';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const HelmetPrivacy = () => {
  const { t } = useTranslation('helmet');

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{t('privacy.title')}</title>
      <meta name="description" content={t('privacy.content')} />
      <link rel="alternate" hrefLang="fr" href="https://www.acquizition.biz/fr/privacy" />
      <link rel="alternate" hrefLang="en" href="https://www.acquizition.biz/en/privacy" />
    </Helmet>
  );
};
