import React, { useContext } from 'react';

import { FinancialData } from '../../../dtos/listingCreation';
import { IncomeStatementTable } from '../descriptions/IncomeStatementTable';
import { LocaleContext } from '../../../router/Router';
import { StyledBox } from '../FullListing';
import { TextDescription } from '../descriptions/TextDescription';
import { tCustom } from '../../../services/TranslationService';
import { useTranslation } from 'react-i18next';

type FinancialDetailsProps = {
  listing: FinancialData;
};

export const FinancialDetails = (props: FinancialDetailsProps): JSX.Element => {
  const { t } = useTranslation('fullListing');
  const { locale } = useContext(LocaleContext);

  return (
    <>
      <h4>{t('fullListing:financial_details.title')}</h4>
      <StyledBox>
        <IncomeStatementTable listing={props.listing} />
        {tCustom(props.listing.assets, locale) && (
          <TextDescription
            title={t('fullListing:financial_details.assets')}
            // eslint-disable-next-line
            content={tCustom(props.listing.assets, locale)!}
          />
        )}
        {tCustom(props.listing.leases, locale) && (
          <TextDescription
            title={t('fullListing:financial_details.leases')}
            // eslint-disable-next-line
            content={tCustom(props.listing.leases, locale)!}
          />
        )}
      </StyledBox>
    </>
  );
};
