import * as adminService from '../../../services/AdminService';

import { ButtonSmall, ButtonStatus } from '../../../common/button/ButtonSmall';
import { Checkbox, Form, Input, Space, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';

import { ClearToBeDeletedOnListingsAction } from './ClearToBeDeletedOnListingsAction';
import { ColumnsType } from 'antd/lib/table';
import { DisplayAction } from './DisplayAction';
import { EditBrokerAction } from './EditBrokerAction';
import { EditUserRoleAction } from './EditUserRoleAction';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { MigrateListingsToNewSubscriptionAction } from './MigrateListingsToNewSubscriptionAction';
import { UserAdmin } from '../../../dtos/admin';
import { UserData } from '../../../dtos/user';
import { ViewAdmin } from '../../../common/views/ViewAdmin';
import { ViewBrokerAction } from './ViewBrokerAction';
import styled from 'styled-components';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useQuery } from '@tanstack/react-query';

const dataToUserAdmin = (user_list: UserData[]): UserAdmin[] => {
  return user_list.map((user) => ({
    id: user._id,
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    user_type: user.user_type,
    last_invoice_paid_log: user.last_invoice_paid_log,
    disabled: false,
  }));
};

const options = [
  { label: 'Acheteur', value: 'buyer' },
  { label: 'Vendeur', value: 'seller' },
  { label: 'Expert', value: 'broker' },
];

const columns: ColumnsType<UserAdmin> = [
  {
    title: 'ID Utilisateur, courriel',
    key: 'id',
    render: (_, user) => (
      <p>
        {user.id}
        <br />
        {user.email}
      </p>
    ),
  },
  {
    title: 'Prénom, nom',
    key: 'name',
    render: (_, user) => (
      <p>
        {user.first_name}
        <br />
        {user.last_name}
      </p>
    ),
  },
  {
    title: 'Type de compte',
    key: 'user_type',
    render: (_, user) => {
      let role = 'N/A';
      switch (user.user_type) {
        case 'buyer':
          role = 'Acheteur';
          break;
        case 'seller':
          role = 'Vendeur';
          break;
        case 'broker':
          role = 'Courtier';
          break;
      }
      return <p>{role}</p>;
    },
  },
  {
    title: (
      <Tooltip title={"CONSULTEZ STRIPE POUR VOUS ASSURER QUE L'UTILISATEUR A UN FORFAIT ACTIF"}>
        {'Historique, actions de paiement '}
        <ExclamationCircleOutlined style={{ color: 'red' }} />
      </Tooltip>
    ),
    key: 'last_invoice_paid_log',
    render: (_, user) => (
      <Tooltip title={"CONSULTEZ STRIPE POUR VOUS ASSURER QUE L'UTILISATEUR A UN FORFAIT ACTIF"}>
        <POneLine>{user.last_invoice_paid_log || 'N/A'}</POneLine>
      </Tooltip>
    ),
  },
  {
    title: 'Actions',
    key: 'action',
    render: (_, user) => (
      <Space size="middle">
        <DisplayAction {...user} />
        <EditUserRoleAction {...user} />
        <ClearToBeDeletedOnListingsAction {...user} />
        <MigrateListingsToNewSubscriptionAction {...user} />
        <ViewBrokerAction {...user} />
        <EditBrokerAction {...user} />
      </Space>
    ),
  },
];

const initialQueryParams: {
  userId?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  userType?: string[];
} = {
  userId: undefined,
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  userType: undefined,
};

export const UsersAdmin = (): JSX.Element => {
  const [adminUsersFrom] = Form.useForm();
  const axiosMiddleware = useAxiosMiddleware();
  const [tableData, setTableData] = useState<UserAdmin[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [currPage, setCurrPage] = useState<number>(1);
  const [currQuery, setCurrQuery] = useState(initialQueryParams);

  const resetFields = () => {
    location.reload();
  };

  const query = useQuery(
    ['userSearchQuery', currPage],
    () =>
      adminService.searchUsersAdmin({
        axiosMid: axiosMiddleware,
        pageNumber: currPage - 1,
        userId: currQuery.userId,
        email: currQuery.email,
        firstName: currQuery.firstName,
        lastName: currQuery.lastName,
        userType: currQuery.userType,
      }),
    {
      refetchOnWindowFocus: false,
      onError: () => {
        setTableData([]);
        setTotalUsers(1);
      },
    },
  );

  useEffect(() => {
    setCurrPage(1);
    query.remove();
    query.refetch();
  }, [currQuery]);

  useEffect(() => {
    if (query.data) {
      setTableData(dataToUserAdmin(query.data.user_list));
      setTotalUsers(query.data.total);
    } else {
      setTableData([]);
      setTotalUsers(0);
    }
  }, [query.data]);

  const searchQuery = () => {
    setCurrQuery({
      userId: adminUsersFrom.getFieldValue('id') || undefined,
      email: adminUsersFrom.getFieldValue('email') || undefined,
      firstName: adminUsersFrom.getFieldValue('first_name') || undefined,
      lastName: adminUsersFrom.getFieldValue('last_name') || undefined,
      userType: adminUsersFrom.getFieldValue('user_type'),
    });
  };

  const getNextPage = (page: number) => {
    setCurrPage(page);
  };

  return (
    <ViewAdmin>
      <h3>Utilisateurs </h3>
      <StyledDiv>
        <FormDiv>
          <Form form={adminUsersFrom} layout="vertical">
            <Form.Item name="id" label="ID Utilisateur">
              <Input></Input>
            </Form.Item>
            <Form.Item name="email" label="Courriel utilisateur">
              <Input></Input>
            </Form.Item>
            <Form.Item name="first_name" label="Prénom">
              <Input></Input>
            </Form.Item>
            <Form.Item name="last_name" label="Nom">
              <Input></Input>
            </Form.Item>
            <Form.Item name="user_type" label="Type d'utilisateur">
              <Checkbox.Group options={options} />
            </Form.Item>
          </Form>
        </FormDiv>

        <ButtonDiv>
          <ButtonSmall onClick={() => resetFields()} status={ButtonStatus.TERTIARY} text={'Réinitialiser'} />
          <ButtonSmall onClick={() => searchQuery()} status={ButtonStatus.PRIMARY} text={'Rechercher'} />
        </ButtonDiv>
      </StyledDiv>
      <StyledP>
        <Table
          loading={query.isFetching}
          columns={columns}
          dataSource={tableData}
          pagination={{
            pageSize: 25,
            showSizeChanger: false,
            total: totalUsers,
            current: currPage,
            onChange: (page) => {
              getNextPage(page);
            },
          }}
          expandable={{
            expandedRowRender: (user) => <PBreak>{user.last_invoice_paid_log}</PBreak>,
            rowExpandable: (record) => record.last_invoice_paid_log !== null,
          }}
          rowKey={(user) => user.id || ''}
        />
      </StyledP>
    </ViewAdmin>
  );
};

const StyledDiv = styled.div`
  display: flex;
  column-gap: 32px;
  align-items: flex-end;
`;

const FormDiv = styled.div`
  width: 560px;
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 24px;
`;

const StyledP = styled.div`
  p {
    margin-bottom: 0;
  }
`;

const PBreak = styled.p`
  white-space: pre-wrap;
`;

const POneLine = styled.p`
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
