import { ABrokerInfo } from '../pages/brokerProfile/ABrokerInfo';
import { AlertsAndEmails } from '../pages/AlertsAndEmails/AlertsAndEmails';
import { BrokerFileForm } from '../pages/brokerFileForm/BrokerFileForm';
import { BrokerSection } from '../pages/brokersSection/BrokerSection';
import { BusinessesForSale } from '../pages/businessesForSale/BusinessesForSale';
import { ConfirmEmailForm } from '../pages/user/confirmEmailForm/ConfirmEmailForm';
import { ContactUs } from '../pages/contactUs/ContactUs';
import { CreateListing } from '../pages/createListing/CreateListing';
import { DummyPage } from '../pages/dummy/DummyPage';
import { EmailPasswordForgot } from '../pages/user/passwordForgot/EmailPasswordForgot';
import { FaqPage } from '../pages/faq/FaqPage';
import { Favorites } from '../pages/favorites/Favorites';
import { FranchiseFormAdmin } from '../pages/admin/franchisesFormAdmin/FranchiseFormAdmin';
import { FranchiseFranchisor } from '../pages/franchisefranchisor/FranchiseFranchisor';
import { FranchiseInfo } from '../pages/franchiseInfo/FranchiseInfo';
import { FranchiseSection } from '../pages/franchiseSection/FranchiseSection';
import { FranchisesAdmin } from '../pages/admin/franchisesAdmin/FranchisesAdmin';
import { FullListing } from '../pages/fullListing/FullListing';
import { Home } from '../pages/home/Home';
import { LegalKit } from '../pages/legalKit/LegalKit';
import { ListingsAdmin } from '../pages/admin/listingsAdmin/ListingsAdmin';
import { MyBrokerInfo } from '../pages/brokerProfile/MyBrokerInfo';
import { MyListings } from '../pages/myListings/MyListings';
import { MyMessages } from '../pages/messages/MyMessages';
import { Onboarding } from '../pages/user/onboarding/Onboarding';
import { Privacy } from '../pages/privacy/Privacy';
import { Profile } from '../pages/user/profile/Profile';
import React from 'react';
import { Report } from '../pages/report/Report';
import { SellABusiness } from '../pages/sellABusiness/SellABusiness';
import { SignIn } from '../pages/user/signin/SignIn';
import { SignUp } from '../pages/user/signup/SignUp';
import { Subscription } from '../pages/subscription/Subscription';
import { TermsOfUse } from '../pages/termsOfUse/TermsOfUse';
import { TransactionSuccessful } from '../pages/transactionSuccessful/TransactionSuccessful';
import { UpdateLanguage } from '../pages/user/profile/UpdateLanguage';
import { UpdatePassword } from '../pages/user/profile/UpdatePassword';
import { UpdateRole } from '../pages/user/profile/UpdateRole';
import { UpdateUser } from '../pages/user/profile/UpdateUser';
import { UsersAdmin } from '../pages/admin/usersAdmin/UsersAdmin';

export const GROUPS = {
  admin: 'admin',
};

export const publicRoutes = {
  mainpage: (locale: string) => `/${locale}/`,
  home: (locale: string) => `/${locale}/home`,
  updatePassword: (locale: string) => `/${locale}/updatePassword`,
  signin: (locale: string) => `/${locale}/signin`,
  signup: (locale: string) => `/${locale}/signup`,
  businessesForSale: (locale: string) => `/${locale}/businessesForSale`,
  verification: (locale: string) => `/${locale}/emailVerification/:token`,
  emailPasswordForgot: (locale: string) => `/${locale}/emailPasswordForgot`,
  placeholder: (locale: string) => `/${locale}/placeholder`,
  termsOfUse: (locale: string) => `/${locale}/termsOfUse`,
  privacy: (locale: string) => `/${locale}/privacy`,
  confirmEmailForm: (locale: string) => `/${locale}/confirmEmailForm`,
  contactUs: (locale: string) => `/${locale}/contactUs`,
  brokerSection: (locale: string) => `/${locale}/brokerSection`,
  franchiseSection: (locale: string) => `/${locale}/franchiseSection`,
  faq: (locale: string) => `/${locale}/faq`,
  franchiseFranchisor: (locale: string) => `/${locale}/franchiseFranchisor`,
  sellABusiness: (locale: string) => `/${locale}/sellABusiness`,
  alertReport: (locale: string) => `/${locale}/alertReport`,
  fullListing: (locale: string) => `/${locale}/fl/:id`,
};

export interface PublicRoutesInterface {
  mainpage: JSX.Element;
  home: JSX.Element;
  updatePassword: JSX.Element;
  signin: JSX.Element;
  signup: JSX.Element;
  businessesForSale: JSX.Element;
  emailPasswordForgot: JSX.Element;
  placeholder: JSX.Element;
  termsOfUse: JSX.Element;
  privacy: JSX.Element;
  confirmEmailForm: JSX.Element;
  contactUs: JSX.Element;
  brokerSection: JSX.Element;
  franchiseSection: JSX.Element;
  faq: JSX.Element;
  franchiseFranchisor: JSX.Element;
  sellABusiness: JSX.Element;
  alertReport: JSX.Element;
  fullListing: JSX.Element;
}

export const publicRoutesComponents: PublicRoutesInterface = {
  mainpage: <Home />,
  home: <Home />,
  updatePassword: <UpdatePassword />,
  signin: <SignIn />,
  signup: <SignUp />,
  businessesForSale: <BusinessesForSale />,
  emailPasswordForgot: <EmailPasswordForgot />,
  placeholder: <DummyPage />,
  termsOfUse: <TermsOfUse />,
  privacy: <Privacy />,
  confirmEmailForm: <ConfirmEmailForm />,
  contactUs: <ContactUs />,
  brokerSection: <BrokerSection />,
  franchiseSection: <FranchiseSection />,
  faq: <FaqPage />,
  franchiseFranchisor: <FranchiseFranchisor />,
  sellABusiness: <SellABusiness />,
  alertReport: <Report />,
  fullListing: <FullListing />,
};

export const privateRoutes = {
  onbarding: (locale: string) => `/${locale}/onboarding`,
  updateRole: (locale: string) => `/${locale}/updateRole`,
  updateUser: (locale: string) => `/${locale}/updateUser`,
  updateLanguage: (locale: string) => `/${locale}/updateLanguage`,
  profile: (locale: string) => `/${locale}/profile`,
  alertsAndMails: (locale: string) => `/${locale}/alertsAndMails`,
  myListings: (locale: string) => `/${locale}/mylistings`,
  favorites: (locale: string) => `/${locale}/favorites`,
  createListing: (locale: string) => `/${locale}/createlisting`,
  subscription: (locale: string) => `/${locale}/subscription`,
  myMessages: (locale: string) => `/${locale}/myMessages`,
  brokerFileForm: (locale: string) => `/${locale}/brokerFileForm`,
  myBrokerInfo: (locale: string) => `/${locale}/myBrokerInfo`,
  aBrokerInfo: (locale: string) => `/${locale}/brokerProfile/:id`,
  aFranchise: (locale: string) => `/${locale}/franchise/:id`,
  transactionSuccessful: (locale: string) => `/${locale}/transactionSuccessful`,
  legalKit: (locale: string) => `/${locale}/legalKit`,
};

export interface PrivateRouteInterface {
  onbarding: JSX.Element;
  updateRole: JSX.Element;
  updateUser: JSX.Element;
  updateLanguage: JSX.Element;
  profile: JSX.Element;
  alertsAndMails: JSX.Element;
  myListings: JSX.Element;
  favorites: JSX.Element;
  createListing: JSX.Element;
  subscription: JSX.Element;
  myMessages: JSX.Element;
  brokerFileForm: JSX.Element;
  myBrokerInfo: JSX.Element;
  aBrokerInfo: JSX.Element;
  aFranchise: JSX.Element;
  transactionSuccessful: JSX.Element;
  legalKit: JSX.Element;
}

export const privateRoutesComponents: PrivateRouteInterface = {
  onbarding: <Onboarding />,
  updateRole: <UpdateRole />,
  updateUser: <UpdateUser />,
  updateLanguage: <UpdateLanguage />,
  profile: <Profile />,
  alertsAndMails: <AlertsAndEmails />,
  myListings: <MyListings />,
  favorites: <Favorites />,
  createListing: <CreateListing />,
  subscription: <Subscription />,
  myMessages: <MyMessages />,
  brokerFileForm: <BrokerFileForm />,
  myBrokerInfo: <MyBrokerInfo />,
  aBrokerInfo: <ABrokerInfo />,
  aFranchise: <FranchiseInfo />,
  transactionSuccessful: <TransactionSuccessful />,
  legalKit: <LegalKit />,
};

export const adminRoutes = {
  listingsAdmin: '/admin',
  usersAdmin: '/adminUser',
  franchisesAdmin: '/franchisesAdmin',
  franchiseFormAdmin: '/franchiseFormAdmin',
};

export interface AdminRouteInterface {
  listingsAdmin: JSX.Element;
  usersAdmin: JSX.Element;
  franchisesAdmin: JSX.Element;
  franchiseFormAdmin: JSX.Element;
}
export const adminRoutesComponents: AdminRouteInterface = {
  listingsAdmin: <ListingsAdmin />,
  usersAdmin: <UsersAdmin />,
  franchisesAdmin: <FranchisesAdmin />,
  franchiseFormAdmin: <FranchiseFormAdmin />,
};
