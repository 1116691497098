import React, { useContext, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { Listing } from '../../../dtos/listingCreation';
import { LocaleContext } from '../../../router/Router';
import fallback from './../../../img/fallbackimg.webp';
import { getCarouselListing } from '../../../services/ListingService';
import styled from 'styled-components';
import { tCustom } from '../../../services/TranslationService';
import { theme } from '../../../styles/theme';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

const formatter = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const BusinessCarousel = (props: { industries?: number[]; regions?: number[] }) => {
  const { locale } = useContext(LocaleContext),
    { t } = useTranslation('businessesForSale');
  const [businesses, setBusinesses] = useState<Listing[]>([]);
  const NUMBER_OF_LISTINGS = 6;

  const getCarouselQuery = useQuery({
    queryKey: ['getCarouselListing'],
    queryFn: () =>
      getCarouselListing({
        numberOfListings: NUMBER_OF_LISTINGS,
        industries: props.industries,
        regions: props.regions,
      }),
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    setBusinesses(() => {
      const tempBusiness: Listing[] = [];
      let currBusiness = 0;
      if (getCarouselQuery.data === undefined) {
        return [];
      }
      while (tempBusiness.length < NUMBER_OF_LISTINGS) {
        const business = getCarouselQuery.data[currBusiness];
        if (business) {
          tempBusiness.push(business);
        }
        currBusiness = (currBusiness + 1) % NUMBER_OF_LISTINGS;
      }
      return tempBusiness;
    });
  }, [getCarouselQuery.data]);

  useEffect(() => {
    getCarouselQuery.refetch();
  }, [props.industries, props.regions]);

  return getCarouselQuery.isError ? (
    <></>
  ) : (
    <>
      <PNoPad>{t('carousel.featured')}</PNoPad>
      <FlexDiv>
        {businesses?.map((listing, index) => (
          <Link key={`${index}`} to={`../fl/${listing._id}`}>
            <Container>
              <StyledImg src={listing.additional_info.picture_primary || fallback} height={120} width={180} />
              <Content>
                <PWhiteSmall>{tCustom(listing.basic_info?.listing_title, locale)}</PWhiteSmall>
                {listing.basic_info.asking_price ? (
                  <PWhite>{formatter.format(listing.basic_info.asking_price)}</PWhite>
                ) : (
                  <PWhite>{t('carousel.openToOffers')}</PWhite>
                )}
              </Content>
            </Container>
          </Link>
        ))}
      </FlexDiv>
    </>
  );
};

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 16px;
  justify-content: space-between;
  @media only screen and (max-width: 500px) {
    justify-content: center;
  }
  height: 120px;
  overflow: hidden;
  gap: 8px;
`;

const Container = styled.div<{ isHomePage?: boolean }>`
  width: 180px;
  display: block;
  position: relative;
`;

const Content = styled.div`
  background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.65) 40%, rgba(0, 0, 0, 0.65) 50%);
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
`;

const StyledImg = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
`;

const PWhite = styled.p`
  margin-bottom: 0;
  color: ${theme.color.white};
`;

const PWhiteSmall = styled.p`
  font-size: 13px !important;
  margin-bottom: 0;
  color: ${theme.color.white};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const PNoPad = styled.p`
  margin-bottom: 4px;
  font-weight: 500;
`;
