import * as userService from '../../../services/UserService';

import { ButtonSmall, ButtonStatus } from '../../../common/button/ButtonSmall';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Form, Input, Modal, Spin } from 'antd';

import { ErrorMessage } from '../../../common/ErrorMessage';
import { HttpError } from '../../../dtos/error';
import React from 'react';
import { ViewCentered } from '../../../common/views/ViewCentered';
import { confirmPasswordRule } from '../confirmPasswordRule';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const UpdatePassword = (): JSX.Element => {
  const axiosMiddleWare = useAxiosMiddleware();
  const navigate = useNavigate();
  const { t } = useTranslation(['profileForm']);

  const updatePasswordMutation = useMutation(userService.updatePassword, {
    onSuccess: () => {
      Modal.success({
        content: t(`profileForm:update_password.success`),
        onOk: () => navigate(-1),
        okText: t('profileForm:back'),
      });
    },
  });

  const onFinish = (data: { previous_password: string; new_password: string }) =>
    updatePasswordMutation.mutate({
      data: {
        previous_password: data.previous_password,
        new_password: data.new_password,
      },
      // eslint-disable-next-line
      axiosMid: axiosMiddleWare,
    });

  return (
    <ViewCentered maxWidth="500px">
      <h4 style={{ marginTop: '32px' }}>{t('profileForm:update_password.title')}</h4>
      {updatePasswordMutation.isLoading ? (
        <Spin size="large" />
      ) : (
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item
            name="previous_password"
            label={<label>{t('profileForm:update_password.previous_password.title')}</label>}
            rules={[
              {
                required: true,
                message: t('profileForm:password'),
              },
            ]}
          >
            <Input.Password
              size="large"
              placeholder={t('profileForm:update_password.previous_password.placeholder')}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            ></Input.Password>
          </Form.Item>

          <Form.Item
            name="new_password"
            label={<label>{t('profileForm:update_password.new_password.title')}</label>}
            rules={[
              {
                required: true,
                pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'),
                message: t('profileForm:passwordRule'),
              },
            ]}
          >
            <Input.Password
              size="large"
              placeholder={t('profileForm:update_password.new_password.placeholder')}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            ></Input.Password>
          </Form.Item>

          <Form.Item
            name="confirm_new_password"
            label={<label>{t('profileForm:update_password.confirm_new_password.title')}</label>}
            rules={[
              {
                required: true,
                message: t('profileForm:confirm_password'),
              },
              confirmPasswordRule(t('profileForm:mustMatch'), 'new_password'),
            ]}
          >
            <Input.Password
              size="large"
              placeholder={t('profileForm:update_password.confirm_new_password.placeholder')}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            ></Input.Password>
          </Form.Item>

          <div style={{ marginTop: '24px', display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonSmall text={t(`profileForm:update_password.update_password`)} status={ButtonStatus.PRIMARY} />
          </div>
        </Form>
      )}

      <>
        {!updatePasswordMutation.isIdle && updatePasswordMutation.error && (
          <div style={{ marginTop: '24px' }}>
            <ErrorMessage error={updatePasswordMutation.error as HttpError} />
          </div>
        )}
      </>
    </ViewCentered>
  );
};
