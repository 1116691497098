import { CheckOutlined, DeleteOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import React, { useContext, useState } from 'react';

import { Link } from 'react-router-dom';
import { LocaleContext } from '../../router/Router';
import { MessageDTO } from '../../dtos/myMessages';
import { UserContext } from '../../contexts/UserContext';
import { deleteMessage } from '../../services/MessageService';
import styled from 'styled-components';
import { tCustom } from '../../services/TranslationService';
import { theme } from '../../styles/theme';
import { useAxiosMiddleware } from '../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

const toDate = (unixDate: number, locale: string) => {
  const lang = locale === 'fr' ? 'fr-FR' : 'en-US';
  return new Date(unixDate * 1000).toLocaleDateString(lang, {
    month: 'short',
    day: 'numeric',
  });
};

export const Message = (props: MessageDTO): JSX.Element => {
  const { tokenErrorHandler } = useContext(UserContext),
    axiosMiddleware = useAxiosMiddleware(),
    [isDeleted, setIsDeleted] = useState(false),
    [isFull, setIsFull] = useState(false),
    { locale } = useContext(LocaleContext),
    { t } = useTranslation('myMessages');

  const deleteMessageMutation = useMutation(deleteMessage, {
    onError: tokenErrorHandler,
    onSuccess: () => setIsDeleted(true),
  });

  const deleteMessageFnc = () => {
    Modal.confirm({
      content: t('message.confirmDelete'),
      onOk: () =>
        deleteMessageMutation.mutate({
          axiosMid: axiosMiddleware,
          message_id: props._id,
        }),
    });
  };

  return (
    <div>
      <Spin size={'large'} spinning={deleteMessageMutation.isLoading}>
        <StyledRow>
          <DivWho>
            {isFull && (
              <p>
                <b>{t('message.business')}</b>
              </p>
            )}
            <Link to={`../fl/${props.listing_id}`}>
              <StyledLink>{tCustom(props.listing_title, locale)}</StyledLink>
            </Link>
            {!isFull && <p>{props.full_name_contact}</p>}
            {isFull && (
              <>
                <p>
                  <b>{t('message.potentialBuyer')}</b>
                </p>
                <p>{props.full_name_contact}</p>
                <p>{props.email}</p>
                <p>{props.phone}</p>
              </>
            )}
          </DivWho>

          <DivWhat>
            <StyledPBreak isFull={isFull}>{props.message}</StyledPBreak>
            {isFull &&
              (!isDeleted ? (
                <StyledDelete onClick={deleteMessageFnc}>
                  <StyledDeleteOutlined />
                  {t('message.delete')}
                </StyledDelete>
              ) : (
                <p>
                  <StyledCheckOutlined />
                  {t('message.deleted')}
                </p>
              ))}
          </DivWhat>

          <DivWhen onClick={() => setIsFull((currDisp) => !currDisp)}>
            <p>{toDate(props.date_sent, locale)}</p>
            <ShowMoreP>
              {isFull ? (
                <>
                  {t('message.seeLess')}
                  <StyledDownOutlined />
                </>
              ) : (
                <>
                  {t('message.seeMore')}
                  <StyledUpOutlined />
                </>
              )}
            </ShowMoreP>
          </DivWhen>
        </StyledRow>
      </Spin>
    </div>
  );
};

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 5fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'who what when';
  @media only screen and (max-width: 1250px) {
    grid-template-areas:
      'who when'
      'what what';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
  column-gap: 2rem;
  row-gap: 1rem;

  p {
    margin-bottom: 0.25rem;
  }
`;

const DivWho = styled.div`
  grid-area: who;
`;

const DivWhat = styled.div`
  grid-area: what;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DivWhen = styled.div`
  grid-area: when;
  justify-self: end;
  text-align: right;
  cursor: pointer;
`;

const StyledLink = styled.p`
  text-decoration: underline;
  cursor: pointer;
  color: ${theme.color.electricBlue};
`;

const StyledDelete = styled.p`
  text-decoration: underline;
  cursor: pointer;
`;

const ShowMoreP = styled.p`
  color: ${theme.color.darkGrey};
`;

const StyledPBreak = styled.p<{ isFull: boolean }>`
  white-space: pre-line;
  overflow: hidden;
  @media only screen and (max-width: 1250px) {
    color: ${theme.color.darkGrey};
  }

  ${(props) => {
    return props.isFull
      ? 'color: black !important;'
      : `
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    
    `;
  }}
`;

const StyledDownOutlined = styled(DownOutlined)`
  margin-left: 4px;
`;

const StyledUpOutlined = styled(UpOutlined)`
  margin-left: 4px;
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  margin-right: 8px;
`;

const StyledCheckOutlined = styled(CheckOutlined)`
  margin-right: 8px;
`;
