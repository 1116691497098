import { HttpError, HttpMsg } from '../dtos/error';

import { Alert } from 'antd';
import React from 'react';

type ErrorMessageProps = {
  error: HttpError;
};

const isErrorIterable = (error: HttpError): boolean =>
  Object.prototype.toString.call(error.response?.data?.detail) === '[object Array]';

export const ErrorMessage = (props: ErrorMessageProps): JSX.Element => {
  const message = (props.error?.response?.data?.detail || props.error.message) as string;
  return (
    <div
      style={{
        width: '100%',
        maxWidth: '600px',
      }}
    >
      {isErrorIterable(props.error) ? (
        <>
          {(props.error?.response?.data?.detail as HttpMsg[]).map((e, index) => (
            <Alert key={`${e.msg}:${index}`} message={e.msg} type="error" showIcon />
          ))}
        </>
      ) : (
        <Alert message={message} type="error" showIcon />
      )}
    </div>
  );
};
