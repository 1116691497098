import React, { useContext } from 'react';
import { LocaleContext } from '../../router/Router';

export const ModaliteDUtilisation = () => {
  const { locale } = useContext(LocaleContext);
  if (locale === 'fr' || location.pathname.indexOf('/fr/') != -1) {
    return (
      <div>
        <p>
          VEUILLEZ LIRE ATTENTIVEMENT CES MODALIT&Eacute;S D&apos;UTILISATION AVANT D&apos;UTILISER LES SITES INTERNET
          DE GROUPE CONTEX INC. CONSID&Eacute;RANT QUE TOUT USAGE DE L&apos;UN DES SITES INTERNET AINSI QUE TOUTE VENTE
          DE PRODUITS ET SERVICES OFFERTS SUR L&apos;UN DES SITES INTERNET (LES &laquo; SERVICES &raquo;), INCLUANT SES
          SERVICES PAYANTS, SONT SUJETS AUX TERMES L&Eacute;GAUX ET MODALIT&Eacute;S D&Eacute;TAILL&Eacute;ES AUX
          PR&Eacute;SENTES (COLLECTIVEMENT LES &laquo; MODALIT&Eacute;S D&apos;UTILISATION &raquo;).
        </p>
        <ul>
          <li>1</li>
        </ul>
        <p>
          <b>ACCEPTATION DES MODALIT&Eacute;S D&apos;UTILISATION</b>
        </p>
        <ul>
          <li>1.1</li>
        </ul>
        <p>
          Bienvenue sur l&apos;un des sites Internet du Groupe Contex Inc.. En naviguant sur l&apos;un des sites
          Internet du Groupe Contex Inc., ou en utilisant un site &agrave; partir d&apos;une communication sans fil,
          d&apos;un m&eacute;dia social ou l&apos;une de ses fonctionnalit&eacute;s, incluant, notamment, le fil de
          nouvelles RSS (collectivement les &laquo; Sites Internet &raquo;), sur tous supports quelque soient les
          formats ou les moyens, qu&apos;ils soient maintenant connus ou appel&eacute;s &agrave; &ecirc;tre
          d&eacute;couverts dans l&apos;avenir, vous reconnaissez avoir lu et compris les pr&eacute;sentes
          Modalit&eacute;s d&apos;utilisation et acceptez par le fait m&ecirc;me de les respecter ainsi que tout
          &eacute;ventuel amendement ult&eacute;rieur. Ces Modalit&eacute;s d&apos;utilisation constituent une entente
          entre vous et le Groupe Contex Inc.
        </p>
        <ul>
          <li>1.2</li>
        </ul>
        <p>
          Groupe Contex Inc. se r&eacute;serve le droit, &agrave; sa discr&eacute;tion, de faire des changements,
          &agrave; tout moment, aux Modalit&eacute;s d&apos;utilisation sans pr&eacute;avis. Tout amendement
          apport&eacute; aux Modalit&eacute;s est affich&eacute; sur cette page. Veuillez consulter
          p&eacute;riodiquement les pr&eacute;sentes Modalit&eacute;s d&apos;utilisation afin de prendre connaissance
          d&apos;&eacute;ventuels changements ult&eacute;rieurs. R&eacute;f&eacute;rez-vous &agrave; la date de la mise
          &agrave; jour indiqu&eacute;e au haut des pr&eacute;sentes afin de v&eacute;rifier la date des derniers
          amendements.
        </p>
        <ul>
          <li>1.3</li>
        </ul>
        <p>
          Les Sites Internet visent uniquement les personnes qui r&eacute;sident au Canada. Si vous n&apos;acceptez pas
          d&apos;&ecirc;tre li&eacute; par ces Modalit&eacute;s d&apos;utilisation, ou si vous n&apos;&ecirc;tes pas
          autoris&eacute; &agrave; consentir &agrave; l&apos;une de ces Modalit&eacute;s, ou n&apos;avez pas la
          capacit&eacute; juridique d&apos;y consentir, vous ne pouvez pas utiliser les Sites Internet. Dans
          l&apos;&eacute;ventualit&eacute; o&ugrave; vous consid&eacute;riez que des amendements ult&eacute;rieurs aux
          pr&eacute;sentes Modalit&eacute;s d&apos;utilisation sont inacceptables, vous devez cesser d&apos;utiliser
          imm&eacute;diatement les Sites Internet. Votre utilisation continue des Sites Internet apr&egrave;s que de
          tels amendements aient &eacute;t&eacute; annonc&eacute;s constitue votre acceptation de ces m&ecirc;mes
          amendements.
        </p>
        <ul>
          <li>1.4</li>
        </ul>
        <p>
          En utilisant les Sites Internet, vous repr&eacute;sentez et garantissez avoir atteint l&apos;&acirc;ge de la
          majorit&eacute; dans votre province ou territoire de votre r&eacute;sidence ou avoir une autorisation
          parentale ou d&apos;un tuteur ayant l&apos;autorit&eacute; parentale et vous autorisez ainsi un mineur
          &agrave; naviguer sur les Sites Internet et &agrave; utiliser les fonctionnalit&eacute;s et vous reconnaissez
          express&eacute;ment &ecirc;tre responsable de ses faits et gestes et des cons&eacute;quences de tout
          manquement aux termes, conditions, affirmations, repr&eacute;sentations et garanties des Modalit&eacute;s
          d&apos;utilisation. Toute personne qui n&apos;a pas atteint l&apos;&acirc;ge de la majorit&eacute; dans la
          province ou le territoire o&ugrave; il/elle r&eacute;side doit obtenir l&apos;autorisation de ses parents ou
          de son tuteur avant d&apos;utiliser les Sites Internet pr&eacute;alablement &agrave; toute divulgation
          d&apos;un renseignement personnel ainsi que pour toute soumission par carte de cr&eacute;dit pour enregistrer
          une commande. Nous recommandons fortement que les parents ou les tuteurs surveillent et supervisent les
          activit&eacute;s et usages de leurs enfants sur les Sites Internet.
        </p>
        <ul>
          <li>1.5</li>
        </ul>
        <p>
          <b>
            TOUT D&Eacute;FAUT DE VOUS CONFORMER AUX PR&Eacute;SENTES MODALIT&Eacute;S D&apos;UTILISATION PEUT
            ENTRA&Icirc;NER L&apos;ANNULATION DE VOTRE COMPTE AINSI QUE TOUS LES PRIVIL&Egrave;GES QUI Y SONT
            ASSOCI&Eacute;S.
          </b>
        </p>
        <ul>
          <li>2</li>
        </ul>
        <p>
          <b>LIEU D&apos;EXPLOITATION</b>
        </p>
        <ul>
          <li>2.1</li>
        </ul>
        <p>
          Groupe Contex Inc.exploite ses Sites Internet &agrave; partir de ses bureaux au Canada. Groupe Contex Inc. ne
          fait aucune repr&eacute;sentation &agrave; l&apos;effet que tous les &eacute;l&eacute;ments de ses Sites
          Internet peuvent &ecirc;tre utilis&eacute;s en d&apos;autres lieux. Ceux qui choisissent d&apos;utiliser les
          Sites Internet le font de leur propre initiative et il leur appartient de se conformer &agrave;
          l&apos;ensemble des lois applicables dans leur juridiction.
        </p>
        <ul>
          <li>2.2</li>
        </ul>
        <p>
          Tout le contenu affich&eacute; sur les Sites Internet fait r&eacute;f&eacute;rence &agrave; l&apos;heure de
          notre serveur (TMG-5); en cas de divergence entre l&apos;heure de la publication de l&apos;offre d&apos;un
          Site Internet et l&apos;heure de l&apos;offre du tiers fournisseur, l&apos;heure du tiers fournisseur
          pr&eacute;vaudra toujours.
        </p>
        <ul>
          <li>3</li>
        </ul>
        <p>
          <b>CHANGEMENTS AUX SITES INTERNET</b>
        </p>
        <ul>
          <li>3.1</li>
        </ul>
        <p>
          &Agrave; son enti&egrave;re discr&eacute;tion, Groupe Contex Inc. se r&eacute;serve le droit de modifier,
          suspendre, imposer des limites aux Services ou de mettre fin &agrave; tout &eacute;l&eacute;ment de ses Sites
          Internet, incluant la disponibilit&eacute; de tout contenu, application ou base de donn&eacute;es. Groupe
          Contex Inc. peut &eacute;galement imposer des limites sur certains &eacute;l&eacute;ments ou restreindre
          l&apos;acc&egrave;s &agrave; certaines sections de ses Sites Internet sans avis pr&eacute;alable et sans
          engager sa responsabilit&eacute;. Groupe Contex Inc. se r&eacute;serve le droit, &agrave; son enti&egrave;re
          discr&eacute;tion sans y &ecirc;tre tenue, de corriger toute erreur ou omission comprise dans toute partie de
          l&apos;un de ses Sites Internet &agrave; tout moment et sans pr&eacute;avis.
        </p>
        <ul>
          <li>4</li>
        </ul>
        <p>
          <b>USAGE PERSONNEL</b>
        </p>
        <ul>
          <li>4.1</li>
        </ul>
        <p>
          Tous les &eacute;l&eacute;ments des Sites Internet vous sont offerts pour usage personnel &agrave; des fins
          non commerciales. Il est interdit d&apos;utiliser les Sites Internet &agrave; des fins commerciales ou
          publicitaires sans le consentement &eacute;crit de Groupe Contex Inc.
        </p>
        <ul>
          <li>5</li>
        </ul>
        <p>
          <b>INSCRIPTION ET S&Eacute;CURIT&Eacute;</b>
        </p>
        <ul>
          <li>5.1</li>
        </ul>
        <p>
          Lors de l&apos;inscription d&apos;un compte d&apos;utilisateur sur les Sites Internet, vous devez choisir un
          mot de passe et un nom d&apos;utilisateur. Si vous proc&eacute;dez &agrave; l&apos;enregistrement, vous
          acceptez de nous fournir une information v&eacute;ridique et compl&egrave;te et de nous informer
          imm&eacute;diatement de tout changement ou mise &agrave; jour de telle information. Il est interdit de : (i)
          choisir ou utiliser un nom d&apos;utilisateur d&apos;une personne avec l&apos;intention de vous faire passer
          pour telle personne ou de faire de la fausse repr&eacute;sentation sur votre affiliation &agrave; toute
          personne ou entit&eacute;, (ii) utiliser un nom d&apos;utilisateur sur lequel une autre personne
          d&eacute;tient des droits, sans l&apos;autorisation de cette derni&egrave;re, (iii) utiliser un mot de passe
          ou un nom d&apos;utilisateur que Groupe Contex Inc. juge offensant &agrave; sa seule discr&eacute;tion ou (iv)
          cr&eacute;er un compte d&apos;utilisateur pour autrui ou pour le compte d&apos;un groupe de personnes.
        </p>
        <ul>
          <li>5.2</li>
        </ul>
        <p>
          Vous &ecirc;tes responsable du maintien de la confidentialit&eacute; de votre mot de passe et de votre nom
          d&apos;utilisateur. Vous devez aviser Groupe Contex Inc. le plus rapidement possible par courriel (
          <a href="mailto:responsabledesdonnees@groupecontex.ca">responsabledesdonnees@groupecontex.ca</a>) de toute
          utilisation non autoris&eacute;e ou d&apos;utilisation pr&eacute;sum&eacute;e de votre compte, ou de tout bris
          ou bris pr&eacute;sum&eacute; de s&eacute;curit&eacute;.
        </p>
        <ul>
          <li>5.3</li>
        </ul>
        <p>
          Vous &ecirc;tes responsable de toute utilisation des comptes que vous d&eacute;tenez relativement sur les
          Sites Internet. Vous &ecirc;tes ainsi responsable de toute activit&eacute; qui a lieu sous votre nom
          d&apos;utilisateur et vous devez vous assurer de la fermeture de votre compte &agrave; la fin de chaque
          session aux Sites Internet. Groupe Contex Inc. ne peut &ecirc;tre tenue responsable d&apos;un usage non
          autoris&eacute; de votre compte ni de toute perte ou de tout dommage r&eacute;sultant notamment du non-respect
          des mesures de s&eacute;curit&eacute; &eacute;nonc&eacute;es au pr&eacute;sent paragraphe.
        </p>
        <ul>
          <li>5.4</li>
        </ul>
        <p>
          Vous &ecirc;tes responsable de mettre &agrave; jour votre compte d&apos;utilisateur sur les Sites Internet et
          vous devez retirer toute information invalide afin de pr&eacute;venir la transmission erron&eacute;e de
          communications &agrave; un tiers.
        </p>
        <ul>
          <li>5.5</li>
        </ul>
        <p>
          En raison de la difficult&eacute; d&apos;authentification v&eacute;ritable et exacte d&apos;un utilisateur sur
          l&apos;Internet, Groupe Contex Inc. ne garantit pas l&apos;identit&eacute; des utilisateurs. Advenant un
          conflit entre vous et un utilisateur, vous acceptez de lib&eacute;rer Groupe Contex Inc. (et ses dirigeants,
          administrateurs, actionnaires, employ&eacute;s et agents) de toutes pertes, r&eacute;clamations, dommages et
          demandes d&eacute;coulant directement ou indirectement d&apos;actions ou d&apos;omissions reli&eacute;es
          &agrave; de tels conflits.
        </p>
        <ul>
          <li>6</li>
        </ul>
        <p>
          <b>FRAIS D&apos;ABONNEMENTS ET PAIEMENTS</b>
        </p>
        <ul>
          <li>6.1</li>
        </ul>
        <p>
          Groupe Contex Inc. offre &agrave; ses utilisateurs sur certain de ces sites un espace d&apos;abonnement
          priv&eacute; permettant un acc&egrave;s privil&eacute;gi&eacute; &agrave; certains espaces du site internet
          et/ou &agrave; la publication papier et/ou &agrave; la version num&eacute;rique (tablette) de la publication
          en fonction du type d&apos;abonnement que l&apos;utilisateur s&eacute;lectionne (l&apos;&quotAbonnement&quot).
          L&apos;acc&egrave;s &agrave; certain contenu peut &ecirc;tre limit&eacute; d&eacute;pendamment du type
          d&apos;Abonnement choisi et de la publication de Groupe Contex Inc. Il est interdit d&apos;acc&eacute;der
          simultan&eacute;ment &agrave; l&apos;Abonnement &agrave; l&apos;aide de plus d&apos;un appareil &agrave; la
          fois.
        </p>
        <ul>
          <li>6.2</li>
        </ul>
        <p>
          Pour s&apos;abonner, l&apos;utilisateur devra choisir un nom d&apos;usager et mot de passe. L&apos;Abonnement
          et le code d&apos;utilisateur est incessibles et ne doit pas &ecirc;tre partag&eacute; avec des tiers. Tout
          utilisateur souhaitant profiter d&apos;un Abonnement reconna&icirc;t par les pr&eacute;sentes avoir lu et
          accept&eacute; les termes et conditions des pr&eacute;sentes.
        </p>
        <ul>
          <li>7</li>
        </ul>
        <p>
          <b>TRANSACTIONS AVEC UN TIERS</b>
        </p>
        <ul>
          <li>7.1</li>
        </ul>
        <p>
          &Agrave; titre d&apos;utilisateur de l&apos;un des Sites Internet, vous pourrez recevoir diverses
          communications sur les produits ou services de tiers qui affichent sur l&apos;un des Sites Internet. Lorsque
          vous utilisez les Sites Internet, vous pouvez faire des op&eacute;rations commerciales avec des tiers, acheter
          de tiers et participer &agrave; des transactions commerciales avec ces derniers.
        </p>
        <ul>
          <li>7.2</li>
        </ul>
        <p>
          Certains Sites Internet sont des plateformes permettant &agrave; des tiers de pr&eacute;senter des offres et
          de fournir des renseignements sur leurs produits ou services. Il incombe exclusivement aux tiers de remplir
          les conditions de leurs offres et Groupe Contex Inc. ne sera en aucun cas responsable des diff&eacute;rences
          entre l&apos;information affich&eacute;e sur l&apos;un de ses Sites Internet et les termes et conditions
          &eacute;tablis par une tierce partie, y compris, notamment, tout &eacute;cart avec le prix et la description
          d&apos;un produit tel qu&apos;affich&eacute; en magasin. Groupe Contex Inc. n&apos;est partie &agrave; aucune
          offre sur ces Sites Internet et n&apos;appuie pas, ne recommande pas et ne parraine pas les produits, services
          ou offres promotionnelles disponibles sur ces Sites Internet ou sur le site Internet d&apos;un tiers, sauf
          s&apos;il s&apos;agit d&apos;un concours o&ugrave; elle est pr&eacute;cis&eacute;ment d&eacute;sign&eacute;e
          comme commanditaire. Toute correspondance, la publicit&eacute;, les achats ou les promotions se rapportant
          &agrave; un tiers, et notamment la livraison de produits ou services de tiers, et le paiement de ces produits
          ou services, ainsi que toutes les autres termes, conditions, garanties ou repr&eacute;sentations li&eacute;es
          &agrave; cette correspondance, ces achats ou promotions rel&egrave;vent uniquement de vous et du tiers en
          question. Groupe Contex Inc. ne fait aucune repr&eacute;sentation, modalit&eacute;s, et ne donne aucune
          garantie ou recommandation quant &agrave; la convenance ou la valeur potentielle d&apos;un produit, d&apos;un
          service ou d&apos;une offre promotionnelle d&apos;un tiers en particulier. Les images et photographies
          affich&eacute;es sur les Sites Internet en lien avec un produit, service ou offre promotionnelle d&apos;un
          tiers sont fournies uniquement &agrave; titre indicatif. Vous reconnaissez que Groupe Contex Inc. ne
          v&eacute;rifie aucun contenu au pr&eacute;alable. Plus pr&eacute;cis&eacute;ment, Groupe Contex Inc. ne
          contr&ocirc;le pas le contenu cr&eacute;&eacute; par des tiers et ne fait aucune repr&eacute;sentation ni ne
          donne aucune garantie quelconque quant &agrave; l&apos;exactitude des renseignements accessibles sur le Site
          Internet ou par son entremise, y compris, notamment, les renseignements sur les rabais, coupons, escomptes,
          aubaines ou autres promotions pour des produits ou services offerts par des tiers. Groupe Contex Inc. ne peut
          garantir que les biens ou services annonc&eacute;s sur les Sites Internet seront disponibles
          conform&eacute;ment aux modalit&eacute;s et conditions (y compris le prix) d&eacute;crites &agrave; quelque
          moment que ce soit sur les Sites Internet ou par l&apos;entremise de celui-ci. Vous acceptez que toutes ces
          op&eacute;rations soient &agrave; vos risques. Groupe Contex Inc. n&apos;est pas partie &agrave; de telles
          op&eacute;rations et d&eacute;cline toute responsabilit&eacute; relativement &agrave; ces transactions avec
          des tiers.
        </p>
        <ul>
          <li>7.3</li>
        </ul>
        <p>
          Groupe Contex Inc. ne saurait &ecirc;tre tenue responsable directement ou indirectement des conditions
          g&eacute;n&eacute;rales de toute transaction entre vous et tout tiers, dommage ou de toute autre
          responsabilit&eacute; civile d&eacute;coulant de la prestation de tels services ou s&apos;y rapportant. En cas
          de diff&eacute;rend entre vous et d&apos;un tiers, vous d&eacute;gagez Groupe Contex Inc. (incluant, sans
          limitation, ses dirigeants, administrateurs, employ&eacute;s et agents) de toute r&eacute;clamation, demande
          et atteinte de toutes sortes et natures, d&eacute;coulant de tout acte ou omission s&apos;y rapportant de
          quelque mani&egrave;re que ce soit.
        </p>
        <ul>
          <li>8</li>
        </ul>
        <p>
          <b>COUPONS ET CONCOURS</b>
        </p>
        <ul>
          <li>8.1</li>
        </ul>
        <p>
          Groupe Contex Inc. peut, de temps &agrave; autre, organiser des concours sur ses Sites Internet, seule ou
          conjointement avec des tierces parties intervenant &agrave; titre de commanditaires. Tous ces concours seront
          r&eacute;gis par leurs r&egrave;glements respectifs. Avant de participer &agrave; un concours, il vous incombe
          de lire, comprendre et accepter les modalit&eacute;s et conditions d&eacute;crites dans le r&egrave;glement de
          chaque concours.
        </p>
        <ul>
          <li>8.2</li>
        </ul>
        <p>
          Tous les coupons affich&eacute;s sur les Sites Internet sont r&eacute;gis par les modalit&eacute;s et
          conditions applicables &agrave; ces coupons. Groupe Contex Inc. n&apos;exerce aucun contr&ocirc;le sur le
          contenu cr&eacute;&eacute; par de tierces parties et ne fait aucune repr&eacute;sentation ni ne donne aucune
          garantie quant &agrave; l&apos;exactitude de l&apos;information relative aux produits ou services offerts par
          des tiers d&eacute;crits sur les coupons.
        </p>
        <ul>
          <li>9</li>
        </ul>
        <p>
          CONTENUS G&Eacute;N&Eacute;R&Eacute;S PAR LES UTILISATEURS : Forums de discussion, babillards
          &eacute;lectroniques, blogues, clavardage et autres (collectivement les &laquo; Plateformes
          d&apos;&eacute;change &raquo;)
        </p>
        <ul>
          <li>9.1</li>
        </ul>
        <p>
          Toutes les communications faites sur l&apos;un des Sites Internet sont publiques et non priv&eacute;es. A
          titre d&apos;utilisateur, vous &ecirc;tes seul responsable des dommages r&eacute;sultant de votre utilisation
          du Site Internet, des communications ou transmissions dans le cadre de votre utilisation et du mat&eacute;riel
          que vous y affichez. Ainsi, vous &ecirc;tes tenu de respecter les r&egrave;gles &eacute;nonc&eacute;es
          suivantes :
        </p>
        <ul>
          <li>9.1.1</li>
        </ul>
        <p>
          Utilisez un langage respectueux. Un langage grossier ou offensant ne sera en aucun temps tol&eacute;r&eacute;.
          De plus, nul ne doit tenir ou soumettre un contenu diffamatoire, obsc&egrave;ne, injurieux, pornographique,
          sexiste, raciste, homophobe, abusif, inappropri&eacute;, disgracieux, d&eacute;raisonnable, violent,
          religieux, immoral, ill&eacute;gal ou un contenu qui viole les droits d&apos;un tiers.
        </p>
        <ul>
          <li>9.1.2</li>
        </ul>
        <p>
          Soyez courtois. Nul ne doit prof&eacute;rer des menaces ou inciter quiconque &agrave; la violence ou la haine.
          Il est &eacute;galement interdit de perturber d&eacute;lib&eacute;r&eacute;ment toute discussion par des
          messages r&eacute;p&eacute;titifs, des messages sans fondement ou du pourriel (&laquo; spamming &raquo;).
        </p>
        <ul>
          <li>9.1.3</li>
        </ul>
        <p>
          Discutez mais n&apos;attaquez pas. Lors d&apos;une discussion accessible &agrave; tous o&ugrave; il y a de
          multiples opinions, les gens ne sont souvent pas d&apos;accord. Groupe Contex Inc. encourage les discussions
          ainsi que les &eacute;changes d&apos;id&eacute;es et d&eacute;bats dans ses Plateformes d&apos;&eacute;change.
          Toute attaque personnelle est formellement prohib&eacute;e.
        </p>
        <ul>
          <li>9.1.4</li>
        </ul>
        <p>
          Nul ne doit utiliser les Plateformes d&apos;&eacute;change pour promouvoir ou offrir en vente tout bien ou
          service dans un but commercial, mener ou envoyer tout concours, &eacute;tude, syst&egrave;me pyramidal ou
          cha&icirc;ne de lettres, pourriel (&laquo; spamming &raquo;), ni pour faire du piratage (&laquo; hacking
          &raquo;), de la sollicitation ou tout autre acte &agrave; des fins ill&eacute;gales. Il est &eacute;galement
          interdit de porter atteinte aux droits d&apos;autrui de quelconque mani&egrave;re ou emp&ecirc;chant la
          jouissance de la Plateforme d&apos;&eacute;change. Il est &eacute;galement interdit d&apos;utiliser les Sites
          Internet pour tout usage autopromotionnel ou pour t&eacute;l&eacute;charger du contenu pour fins commerciales
          sans le consentement pr&eacute;alable de Groupe Contex Inc.
        </p>
        <ul>
          <li>9.2</li>
        </ul>
        <p>
          En utilisant les Sites Internet, vous reconnaissez et acceptez de ne pas envoyer ou t&eacute;l&eacute;charger
          de virus, bogue, logiciels malveillants, ou autre programme causant des probl&egrave;mes de fonctionnement aux
          Sites Internet.
        </p>
        <ul>
          <li>9.3</li>
        </ul>
        <p>
          Groupe Contex Inc. offre la possibilit&eacute; de soumettre et d&apos;afficher du contenu tel que des messages
          bruts, des hyperliens, des donn&eacute;es, textes, logiciels, musique, sons, photographies, images,
          vid&eacute;os, et tous autres mat&eacute;riels (collectivement votre &laquo; Mat&eacute;riel &raquo;). Vous
          demeurez responsable du mat&eacute;riel que vous t&eacute;l&eacute;chargez, partagez, copiez sur la Plateforme
          d&apos;&eacute;change ou en lien avec les Services. Vous repr&eacute;sentez que votre Mat&eacute;riel est
          original et reconnaissez que vous &ecirc;tes titulaire de tous les droits de propri&eacute;t&eacute;
          intellectuelle ou, le cas &eacute;ch&eacute;ant, que vous avez obtenu la permission &eacute;crite du titulaire
          d&apos;utiliser le Mat&eacute;riel pour tout usage reli&eacute; aux Services. Les images et photographies de
          photographes professionnels ou de Sites Internet de tiers sont prot&eacute;g&eacute;es par droits
          d&apos;auteur et ne doivent pas &ecirc;tre t&eacute;l&eacute;charg&eacute;es, partag&eacute;es ou autrement
          utilis&eacute;es en relation avec les Services sans les autorisations pr&eacute;alables du titulaire des
          droits.
        </p>
        <ul>
          <li>9.4</li>
        </ul>
        <p>
          En utilisant les Sites Internet, vous reconnaissez et acceptez que vous demeurez titulaire des droits
          d&apos;auteur sur le Mat&eacute;riel que vous soumettez en tant qu&apos;utilisateur &agrave; Groupe Contex
          Inc. Par ailleurs, vous reconnaissez et acceptez n&eacute;anmoins que par le seul acte de soumettre votre
          Mat&eacute;riel sur les Sites Internet, vous accordez &agrave; Groupe Contex Inc., ses filiales, agents,
          employ&eacute;s, partenaires d&apos;affaires, une licence mondiale, illimit&eacute;e, irr&eacute;vocable,
          perp&eacute;tuelle, non exclusive, sans compensation aucune &agrave; votre &eacute;gard, d&apos;utiliser,
          d&apos;&eacute;diter, de modifier, de supprimer, de reproduire, de publier, de diffuser, d&apos;adapter, de
          traduire, de d&eacute;velopper des produits d&eacute;riv&eacute;s, de transmettre, de le distribuer, de
          pr&eacute;senter devant le public, d&apos;archiver, d&apos;indexer, de communiquer le contenu ou
          Mat&eacute;riel soumis &agrave; quelque fin et d&apos;octroyer des sous licences ou tout autre permission
          d&eacute;coulant de la loi sur droits d&apos;auteur. &Agrave; cet effet, vous renoncez &agrave; tout droit
          moral que vous pourriez faire valoir relativement &agrave; l&apos;int&eacute;grit&eacute; de votre
          Mat&eacute;riel. Tous les droits accord&eacute;s aux pr&eacute;sentes s&apos;appliquent &agrave;
          l&apos;&eacute;gard de toute utilisation ou exploitation que ce soit &agrave; travers ou &agrave; l&apos;aide
          de quelque m&eacute;dium que ce soit, existant ou &agrave; venir pour la dur&eacute;e compl&egrave;te des
          droits d&apos;auteur ainsi que tout renouvellement. Vous consentez &eacute;galement &agrave; ce que votre nom,
          adresse et courriel soient affich&eacute;s, ainsi qu&apos;&agrave; la divulgation et /ou l&apos;affichage de
          ce Mat&eacute;riel qui est consid&eacute;r&eacute; et trait&eacute; comme non-confidentiel. Vous reconnaissez
          et acceptez d&apos;&ecirc;tre personnellement responsable du contenu des messages que vous communiquez sur les
          Plateformes d&apos;&eacute;change des Sites Internet, ainsi que des cons&eacute;quences de ces messages.
        </p>
        <ul>
          <li>9.5</li>
        </ul>
        <p>
          Vous acceptez de ne publier aucun Mat&eacute;riel offensant &agrave; la communaut&eacute; internaute, tel que
          (i) des propos racistes, diffamatoires, obsc&egrave;nes, mena&ccedil;ants, abusifs, haineux ou une agression
          physique de toute sorte visant un groupe ou un individu; (ii) harc&egrave;lement d&apos;une personne pour un
          tiers, ou tout propos violant le droit de tiers &agrave; la vie priv&eacute;e ou &agrave; l&apos;image; (iii)
          impliquant la transmission d&apos;un &laquo; spam &raquo;, cha&icirc;nes de courriers ou de courriels ou des
          annonces d&apos;occasions d&apos;affaires de type pyramidal; (iv) promouvoir des propos faux, diffamatoires,
          des activit&eacute;s ill&eacute;gales ou une conduite abusive; (v) de rendre disponible du Mat&eacute;riel qui
          contient ou pourrait contenir des virus, chevaux de Troie, vers informatiques, bombes logiques, robots
          d&apos;annulation de message ou tous autres programmes informatiques qui peuvent endommager, supprimer,
          s&apos;ing&eacute;rer dans un syst&egrave;me, intercepter, perturber l&apos;utilisation de tout Service ou
          Mat&eacute;riel d&apos;un Site Internet. Vous convenez de ne pas vous adonner &agrave; des activit&eacute;s
          visant &agrave; obtenir la liste d&apos;utilisateurs d&apos;un Site Internet.
        </p>
        <ul>
          <li>9.6</li>
        </ul>
        <p>
          Groupe Contex Inc. se r&eacute;serve le droit, mais ne saurait &ecirc;tre tenue de le faire,
          d&apos;enqu&ecirc;ter, de mod&eacute;rer, d&apos;&eacute;diter, de retirer, de divulguer tout Mat&eacute;riel
          transmis par un usager sur ses Sites Internet. Au surplus, Groupe Contex Inc. se r&eacute;serve le droit,
          &agrave; son enti&egrave;re discr&eacute;tion, de ne pas publier, de supprimer, de filtrer,
          d&apos;enqu&ecirc;ter sur all&eacute;gation, d&apos;&eacute;diter ou de retirer tout Mat&eacute;riel, en
          partie ou en totalit&eacute;, transmis par un utilisateur sur l&apos;un ou l&apos;autre de ses Sites Internet,
          pour toute raison, et ce, sans pr&eacute;avis. Par ailleurs, ceci demeure une facult&eacute; et non une
          obligation. Groupe Contex Inc. se r&eacute;serve le droit d&apos;enqu&ecirc;ter sur toute violation des
          pr&eacute;sentes Modalit&eacute;s d&apos;utilisation et peut demander l&apos;intervention des autorit&eacute;s
          charg&eacute;es de l&apos;application des lois et collaborer avec celles-ci, dans le but d&apos;engager des
          poursuites judiciaires contre les utilisateurs pouvant &ecirc;tre impliqu&eacute;s dans de tels cas de
          violation.
        </p>
        <ul>
          <li>9.7</li>
        </ul>
        <p>
          Groupe Contex Inc. ne fait aucune repr&eacute;sentation sur le contr&ocirc;le du mat&eacute;riel disponible
          sur ses Sites Internet. Groupe Contex Inc. n&apos;est pas, et ne peut &ecirc;tre tenue responsable de
          l&apos;exactitude, de la l&eacute;galit&eacute;, de la conformit&eacute; aux r&egrave;gles de
          propri&eacute;t&eacute; intellectuelle ou aux pr&eacute;sentes Modalit&eacute;s d&apos;utilisation de tout
          mat&eacute;riel fourni par ses utilisateurs.
        </p>
        <ul>
          <li>10</li>
        </ul>
        <p>
          <b>LIENS AUX SITES DE TIERS</b>
        </p>
        <ul>
          <li>10.1</li>
        </ul>
        <p>
          Pour faciliter votre navigation, les Sites de Groupe Contex Inc. contiennent des liens hypertextes ou
          d&apos;autres liens &agrave; des sites Internet contr&ocirc;l&eacute;s par des tiers. L&apos;activation de ces
          liens peut vous faire quitter les Sites Internet. Sachez que Groupe Contex Inc. n&apos;exerce aucun
          contr&ocirc;le sur les Sites Internet des tiers. Ces sites sont enti&egrave;rement g&eacute;r&eacute;s par des
          tiers et Groupe Contex Inc. ne peut faire quelconque repr&eacute;sentation que ce soit sur la qualit&eacute;
          ou le contenu global de ceux-ci ni quant &agrave; la qualit&eacute; des produits offerts ou sur la
          s&eacute;curit&eacute; transactionnelle des sites en question. Groupe Contex Inc. pr&eacute;sente ces liens
          uniquement &agrave; titre de commodit&eacute; et le fait que ces Sites de tierces parties soient
          r&eacute;pertori&eacute;s sur les Sites Internet ne doit en aucun cas &ecirc;tre interpr&eacute;t&eacute;
          comme un endossement de la part de Groupe Contex Inc. et n&apos;engage d&apos;aucune fa&ccedil;on la
          responsabilit&eacute; de cette derni&egrave;re. Groupe Contex Inc. ne peut en aucun cas &ecirc;tre tenue
          responsable, directement ou indirectement, pour tout dommage ou perte d&eacute;coulant ou occasionn&eacute;s
          par l&apos;utilisation des sites d&apos;un tiers. Toute plainte concernant les Sites de tierces parties doit
          &ecirc;tre dirig&eacute;e vers les administrateurs desdits Sites.
        </p>
        <ul>
          <li>10.2</li>
        </ul>
        <p>
          &Agrave; moins d&apos;avis contraire &eacute;crit de Groupe Contex Inc., toute reproduction d&apos;un lien
          hypertexte de l&apos;un des Sites Internet sur un site d&apos;un tiers devra respecter les conditions
          suivantes : (i) la reproduction de l&apos;apparence, la position et autre caract&eacute;ristique visuelle ne
          devra pas porter de pr&eacute;judice &agrave; l&apos;une des marques enregistr&eacute;e ou non ou nom
          d&apos;affaires du Groupe Contex Inc., (ii) le lien ne doit pas porter &agrave; confusion &agrave;
          l&apos;effet qu&apos;il existe une association avec un tiers ou un lien de commandite entre les parties; (iii)
          le lien devra s&apos;ouvrir dans une nouvelle fen&ecirc;tre du navigateur, permettant ainsi la reproduction de
          la totalit&eacute; de la page HTML associ&eacute;e au Site Internet de Groupe Contex Inc. Groupe Contex Inc.
          se r&eacute;serve le droit de terminer, unilat&eacute;ralement et sans pr&eacute;avis, la pr&eacute;sence du
          lien sur la page de tiers, et de le retirer sans avis suppl&eacute;mentaire.
        </p>
        <ul>
          <li>11</li>
        </ul>
        <p>
          <b>COMMUNICATIONS MOBILES ET APPLICATIONS</b>
        </p>
        <ul>
          <li>11.1</li>
        </ul>
        <p>
          Toute utilisation d&apos;un Site Internet &agrave; partir d&apos;un service de communication sans fil ou
          d&apos;une application demeure r&eacute;gie par les pr&eacute;sentes Modalit&eacute;s d&apos;utilisation.
        </p>
        <ul>
          <li>11.2</li>
        </ul>
        <p>
          Vous reconnaissez que votre utilisation d&apos;un Site Internet &agrave; partir d&apos;une communication sans
          fil ou d&apos;une application ou r&eacute;ception d&apos;un SMS est soumise aux services de
          t&eacute;l&eacute;communication offerts dans les zones de couverture de votre fournisseur de service mobile et
          de ses partenaires d&apos;itin&eacute;rance et qu&apos;ils sont sujets &agrave; vos modalit&eacute;s de
          services habituels tels que des frais de messageries textes, contenu, et d&apos;acc&egrave;s &agrave;
          l&apos;Internet et que des frais peuvent vous &ecirc;tre factur&eacute;s en fonction de ces modalit&eacute;s.
        </p>
        <ul>
          <li>11.3</li>
        </ul>
        <p>
          Advenant que vous modifiez ou d&eacute;sactiviez votre service de t&eacute;l&eacute;communication sans fil,
          veuillez mettre &agrave; jour votre profil d&apos;usager dans les 48 heures de la fin de votre modification au
          compte afin de vous assurer que Groupe Contex Inc. ne corresponde pas par inadvertance avec un tiers ayant
          acquis votre ancien num&eacute;ro.
        </p>
        <ul>
          <li>12</li>
        </ul>
        <p>
          <b>PROPRI&Eacute;T&Eacute; INTELLECTUELLE</b>
        </p>
        <ul>
          <li>12.1</li>
        </ul>
        <p>
          Tout contenu diffus&eacute; ou autrement accessible par les Sites Internet est prot&eacute;g&eacute; par
          droits d&apos;auteur, marques de commerce, brevet ou autre droit de propri&eacute;t&eacute; intellectuelle
          applicable. Les marques de commerce et logos utilis&eacute;s ou affich&eacute;s sur les Sites Internet sont
          des marques de commerce et logos enregistr&eacute;s ou non par Groupe Contex Inc. ou des tiers. Rien sur les
          Sites Internet ne peut &ecirc;tre interpr&eacute;t&eacute; de fa&ccedil;on &agrave; permettre, directement ou
          indirectement, l&apos;utilisation de telles marques de commerce ou logos sans la permission pr&eacute;alable
          &eacute;crite de leur propri&eacute;taire. Sans limiter la g&eacute;n&eacute;ralit&eacute; de ce qui
          pr&eacute;c&egrave;de, il est interdit de reproduire les marques de commerce et les logos affich&eacute;s sur
          les Sites Internet &agrave; des fins commerciales, publicitaires ou pour augmenter le volume de visiteurs sur
          votre Site.
        </p>
        <ul>
          <li>12.2</li>
        </ul>
        <p>
          Tout le contenu accessible des Sites Internet (incluant, sans limitation, les textes, articles, photographies,
          images, illustrations, extraits audio et vid&eacute;o, logiciels, fichiers, etc.) sont prot&eacute;g&eacute;s
          par droits d&apos;auteur et appartiennent &agrave; Groupe Contex Inc., ses filiales, partenaires
          d&apos;affaires ou au tiers agissant &agrave; titre de fournisseur de contenu. &Agrave; moins
          d&apos;exceptions clairement &eacute;nonc&eacute;es, aucun &eacute;l&eacute;ment des Sites Internet ne doit
          &ecirc;tre interpr&eacute;t&eacute; comme vous accordant un droit quelconque par rapport &agrave; la
          propri&eacute;t&eacute; intellectuelle de Groupe Contex Inc., ses filiales, partenaires d&apos;affaires ou
          d&apos;un tiers agissant &agrave; titre de fournisseur de contenu.
        </p>
        <ul>
          <li>12.3</li>
        </ul>
        <p>
          Sous r&eacute;serve de ce qui est pr&eacute;vu aux pr&eacute;sentes, vous ne pouvez copier, reproduire,
          modifier, publier, diffuser, distribuer, participer au transfert ou &agrave; la vente, cr&eacute;er des
          nouvelles oeuvres d&eacute;riv&eacute;es, traduire, t&eacute;l&eacute;charger, conc&eacute;der sous licence ou
          d&apos;aucune mani&egrave;re exploiter, tout &eacute;l&eacute;ment offert sur les Sites Internet (y compris,
          sans limitation, tout logiciel), en tout ou en partie.
        </p>
        <ul>
          <li>12.4</li>
        </ul>
        <p>
          Vous pouvez t&eacute;l&eacute;charger ou copier des &eacute;l&eacute;ments des Sites Internet pour utilisation
          personnelle &agrave; des fins non commerciales seulement, en autant que vous conserviez tous les avis de
          propri&eacute;t&eacute; intellectuelle qu&apos;ils contiennent. Le fait de copier ou de m&eacute;moriser tout
          &eacute;l&eacute;ment &agrave; des fins autres qu&apos;une utilisation personnelle est absolument interdit
          sans la permission &eacute;crite pr&eacute;alable de Groupe Contex Inc. ou du d&eacute;tenteur des droits
          d&apos;auteur identifi&eacute; dans l&apos;avis de propri&eacute;t&eacute; intellectuelle.
        </p>
        <ul>
          <li>13</li>
        </ul>
        <p>
          <b>INDEMNISATION</b>
        </p>
        <ul>
          <li>13.1</li>
        </ul>
        <p>
          En utilisant les Sites Internet, vous repr&eacute;sentez &ecirc;tre responsable de votre conduite sur ces
          Sites Internet. Par les pr&eacute;sentes, vous vous engagez &agrave; tenir indemne et &agrave; d&eacute;fendre
          Groupe Contex Inc., ses filiales et soci&eacute;t&eacute;s affili&eacute;es, ses administrateurs, officiers,
          actionnaires, employ&eacute;s, agents et fournisseurs de contenu de toute condamnation mon&eacute;taire,
          direct ou indirect, en capital et int&eacute;r&ecirc;t, frais judiciaires et extrajudiciaires et de tout autre
          dommage, incluant mais sans limitation, tous les frais l&eacute;gaux encourus par Groupe Contex Inc. se
          rapportant &agrave; toute r&eacute;clamation, poursuite, action ou mise en demeure (une &laquo;
          R&eacute;clamation &raquo;) d&eacute;coulant (i) d&apos;image ou tout autre contenu
          t&eacute;l&eacute;charg&eacute; ou que vous diffusez par l&apos;interm&eacute;diaire de Sites Internet; ou
          (ii) toute violation des pr&eacute;sentes Modalit&eacute;s d&apos;utilisation. Groupe Contex Inc. se
          r&eacute;serve le droit, &agrave; sa discr&eacute;tion, de participer &agrave; la d&eacute;fense de toute
          telle R&eacute;clamation et d&apos;&ecirc;tre repr&eacute;sent&eacute;e par un avocat de son choix.
        </p>
        <ul>
          <li>14</li>
        </ul>
        <p>
          <b>AVIS SUR INFORMATIONS PROFESSIONNELLES</b>
        </p>
        <ul>
          <li>14.1</li>
        </ul>
        <p>
          Groupe Contex Inc. et des tiers diffusent, par l&apos;interm&eacute;diaire des Sites Internet, certaines
          informations concernant diverses disciplines professionnelles telles que les arts et professions de la
          sant&eacute;, la m&eacute;decine, le conditionnement physique, le droit, la planification financi&egrave;re,
          les relations de travail, la comptabilit&eacute;, le voyage (les &laquo; Informations Professionnelles
          &raquo;). Ces Informations Professionnelles sont pr&eacute;sent&eacute;es uniquement &agrave; des fins
          &eacute;ducationnelles et culturelles et ne sauraient &ecirc;tre interpr&eacute;t&eacute;es comme traitement
          ou action sp&eacute;cifique individualis&eacute;e &agrave; une personne. Les Informations Professionnelles
          sont fr&eacute;quemment mises &agrave; jour, mais consid&eacute;rant l&apos;&eacute;volution constante de
          l&apos;information, certains contenus peuvent &ecirc;tre d&eacute;suets. Ces informations sur les Informations
          Professionnelles ne sont jamais consid&eacute;r&eacute;es comme des conseils professionnels et ne peuvent
          remplacer les conseils d&apos;un professionnel vers&eacute; ou accr&eacute;dit&eacute; dans cette profession.
          L&apos;usage des Sites Internet ne remplace pas les consultations aupr&egrave;s d&apos;un professionnel en la
          mati&egrave;re. Tout usage d&apos;une information professionnelle d&apos;une Information Professionnelle
          partag&eacute;e sur un Site Internet sera aux seuls risques et p&eacute;rils de l&apos;usager. Groupe Contex
          Inc., incluant ses commanditaires, ne saurait &ecirc;tre tenu responsable, directement ou indirectement, des
          cons&eacute;quences, pertes, dommage ou autres qui pourraient r&eacute;sulter, de l&apos;utilisation de
          quelconque avis professionnel sur une Information Professionnelle.
        </p>
        <ul>
          <li>15</li>
        </ul>
        <p>
          <b>EXON&Eacute;RATION DE GARANTIES ET LIMITATION DE RESPONSABILIT&Eacute;</b>
        </p>
        <ul>
          <li>15.1</li>
        </ul>
        <p>
          Les Sites Internet, le Mat&eacute;riel et les services vous sont offerts &laquo; tels quels &raquo; et &laquo;
          tels que disponibles &raquo;, &agrave; l&apos;exception des termes de ses politiques de retour. Groupe Contex
          Inc., ses filiales et ses soci&eacute;t&eacute;s apparent&eacute;es, ainsi que chacun de leurs
          administrateurs, dirigeants, employ&eacute;s, conseillers, fournisseurs de services et agents respectifs
          r&eacute;futent express&eacute;ment toute repr&eacute;sentation, garantie, implicite ou explicite, y compris,
          sans limitation, les garanties relatives &agrave; la propri&eacute;t&eacute;, la qualit&eacute; marchande, la
          convenance &agrave; des fins particuli&egrave;res, l&apos;utilisation, la disponibilit&eacute;, la
          validit&eacute;, l&apos;exactitude, l&apos;exhaustivit&eacute;, l&apos;actualit&eacute; d&apos;une
          information, la suffisance, la fiabilit&eacute; des &eacute;l&eacute;ments reproduits sur lesdits Sites,
          &agrave; la non-violation des droits de propri&eacute;t&eacute; intellectuelle ou quant au fonctionnement de
          l&apos;un des Sites Internet de mani&egrave;re s&eacute;curitaire et sans interruption, ni erreur ou &agrave;
          la correction de ses d&eacute;fauts ou erreurs, &agrave; l&apos;absence de virus ou
          d&apos;&eacute;l&eacute;ments dommageables dans les Sites Internet. Sans limiter la
          g&eacute;n&eacute;ralit&eacute; de ce qui pr&eacute;c&egrave;de, Groupe Contex Inc. ne fait aucune
          repr&eacute;sentation sur le caract&egrave;re exhaustif du contenu ou des r&eacute;sultats de vos recherches
          ex&eacute;cut&eacute;es sur ses Sites Internet. Vous reconnaissez et acceptez par les pr&eacute;sentes que
          votre utilisation des Sites Internet se fait &agrave; vos propres risques.
        </p>
        <ul>
          <li>15.2</li>
        </ul>
        <p>
          Groupe Contex Inc. d&eacute;cline toute responsabilit&eacute; relativement &agrave; toute erreur qui pourrait
          se retrouver sur ses Sites Internet. Groupe Contex Inc. ainsi que toute tierce partie fournissant du contenu
          sur les Sites Internet, leurs compagnies li&eacute;es, dirigeants, employ&eacute;s ou agents ne peuvent en
          aucun cas &ecirc;tre tenus responsables de la perte d&apos;usage, de donn&eacute;es ou de profit ou revenus,
          des actes, erreurs ou omissions des Sites Internet. En aucun cas Groupe Contex Inc. ne peut &ecirc;tre tenu
          responsable de tout pr&eacute;judice, perte, dommage (incluant notamment les dommages indirects
          compensatoires, sp&eacute;ciaux, punitifs ou accessoires) d&eacute;coulant de quelque mani&egrave;re de
          l&apos;usage, disponibilit&eacute; ou non des Sites Internet.
        </p>
        <ul>
          <li>15.3</li>
        </ul>
        <p>
          En utilisant les Services et le contenu, vous reconnaissez et acceptez que la soumission de mat&eacute;riel
          num&eacute;ris&eacute; &agrave; Groupe Contex Inc. et son t&eacute;l&eacute;chargement de tout Mat&eacute;riel
          aux Sites Internet est fait &agrave; vos propres risques et vous serez seul responsable de toute perte ou
          dommage &agrave; votre Mat&eacute;riel ou syst&egrave;me &eacute;lectronique ou toute perte. Vous &ecirc;tes
          responsable de faire les copies n&eacute;cessaires pour assurer la sauvegarde de votre contenu dont vous
          pourriez avoir ensuite besoin. Groupe Contex Inc. ne sera pas responsable de la suppression de tout contenu.
        </p>
        <ul>
          <li>16</li>
        </ul>
        <p>
          <b>R&Eacute;SILIATION</b>
        </p>
        <ul>
          <li>16.1</li>
        </ul>
        <p>
          Vous pouvez r&eacute;silier votre compte en tout temps en acc&eacute;dant &agrave; la section &laquo; Votre
          Profil &raquo; du Site Internet. Pour toute demande de r&eacute;siliation d&apos;un abonnement, vous devez
          contacter le service &agrave; la client&egrave;le de Groupe Contex Inc. aux coordonn&eacute;es suivantes:
        </p>
        <p>
          <strong>Adresse postale</strong>
          <br /> Groupe Contex Inc.
          <br /> 355 rue Sainte-Catherine Ouest, 5e &eacute;tage
          <br /> Montr&eacute;al (Qu&eacute;bec) H3B 1A5
          <br /> T&eacute;l&eacute;phone
          <br /> 514 392-2009 <br /> Courriel
          <br /> <a href="mailto:responsabledesdonnees@groupecontex.ca">responsabledesdonnees@groupecontex.ca</a>
          <br /> 16.2
        </p>
        <p>
          Groupe Contex Inc. se r&eacute;serve le droit, &agrave; son enti&egrave;re discr&eacute;tion et &agrave; tout
          moment, de r&eacute;silier ou suspendre votre compte en totalit&eacute; ou en partie de ses Sites Internet
          pour toute raison, incluant, sans limitation, le non respect de ses Modalit&eacute;s d&apos;utilisation.
        </p>
        <ul>
          <li>17</li>
        </ul>
        <p>
          <b>POLITIQUE DE CONFIDENTIALIT&Eacute;</b>
        </p>
        <ul>
          <li>17.1</li>
        </ul>
        <p>
          Pour toute information sur la politique de collecte et la protection d&apos;informations personnelles par
          Groupe Contex Inc., veuillez consulter notre politique de confidentialit&eacute; &agrave; l&apos;adresse
          principale suivante :
        </p>
        <ul>
          <li>17.2</li>
        </ul>
        <p>
          De plus, lorsque vous acceptez de participer &agrave; un concours ou une &agrave; offre
          pr&eacute;sent&eacute;e par un tiers ou que vous consentez &agrave; recevoir de l&apos;information directement
          de tiers, vous acceptez express&eacute;ment les politiques de confidentialit&eacute; de ce tiers. Nous vous
          recommandons de prendre connaissance des termes de la politique de confidentialit&eacute; dudit tiers avant
          toute divulgation d&apos;information de nature priv&eacute;e &agrave; votre sujet. Groupe Contex Inc. ne peut
          &ecirc;tre tenue responsable de r&eacute;clamation, perte, action, dommage, poursuite ou recours
          associ&eacute;s de quelque fa&ccedil;on que ce soit pour l&apos;utilisation de vos informations priv&eacute;es
          par ce tiers.
        </p>
        <ul>
          <li>17.3</li>
        </ul>
        <p>
          Les renseignements personnels recueillis par l&apos;interm&eacute;diaire de Sites Internet peuvent &ecirc;tre
          enregistr&eacute;s et trait&eacute;s au Canada ou &agrave; l&apos;&eacute;tranger et &ecirc;tre soumis aux
          lois applicables dans d&apos;autres juridictions.
        </p>
        <ul>
          <li>18</li>
        </ul>
        <p>
          <b>G&Eacute;N&Eacute;RALIT&Eacute;</b>
        </p>
        <ul>
          <li>18.1</li>
        </ul>
        <p>
          Les Modalit&eacute;s d&apos;utilisation sont pr&eacute;sum&eacute;es redevables. Si l&apos;une des
          dispositions des pr&eacute;sentes Modalit&eacute;s d&apos;utilisation est d&eacute;clar&eacute;e invalide ou
          inapplicable pour quelque motif que ce soit, le caract&egrave;re invalide de cette disposition n&apos;a aucune
          incidence sur la validit&eacute; et l&apos;applicabilit&eacute; des autres dispositions des pr&eacute;sentes
          Modalit&eacute;s d&apos;utilisation.
        </p>
        <ul>
          <li>19</li>
        </ul>
        <p>
          <b>LOIS APPLICABLES ET COMP&Eacute;TENCE JUDICIAIRE</b>
        </p>
        <ul>
          <li>19.1</li>
        </ul>
        <p>
          En utilisant les Sites Internet, incluant toute commande ou achat sur un Site Internet de Groupe Contex Inc.,
          vous reconnaissez et acceptez que les pr&eacute;sentes Modalit&eacute;s d&apos;utilisation soient
          r&eacute;gies par les lois applicables dans la province de Qu&eacute;bec, et par les lois
          f&eacute;d&eacute;rales applicables du Canada. Par les pr&eacute;sentes, vous soumettez irr&eacute;vocablement
          &agrave; la comp&eacute;tence exclusive des tribunaux de la province de Qu&eacute;bec, dans la ville de
          Montr&eacute;al en ce qui concerne tous les diff&eacute;rends d&eacute;coulant des pr&eacute;sentes
          Modalit&eacute;s d&apos;utilisation, de votre usage de l&apos;un des Sites Internet.
        </p>
        <ul>
          <li>20</li>
        </ul>
        <p>
          <b>POUR COMMUNIQUER AVEC NOUS SUR CETTE POLITIQUE</b>
        </p>
        <ul>
          <li>20.1</li>
        </ul>
        <p>
          Dans l&apos;&eacute;ventualit&eacute; o&ugrave; vous auriez une r&eacute;clamation pour violation des
          Modalit&eacute;s d&apos;utilisation ou toute violation des droits de propri&eacute;t&eacute; intellectuelle
          d&eacute;coulant ou relative &agrave; l&apos;usage des Sites Internet relativement &agrave; du mat&eacute;riel
          inclus dans les Sites Internet, ou pour toute autre communication, veuillez nous en aviser &agrave;
          l&apos;adresse suivante : <br /> <br /> <strong>Adresse postale</strong>
          <br /> Groupe Contex Inc.
          <br /> 355 rue Sainte-Catherine Ouest, 5e &eacute;tage
          <br /> Montr&eacute;al (Qu&eacute;bec) H3B 1A5
          <br /> T&eacute;l&eacute;phone
          <br /> 514 392-2009 <br /> <b>Courriel</b>
          <br /> <a href="mailto:responsabledesdonnees@groupecontex.ca">responsabledesdonnees@groupecontex.ca</a>
        </p>
        <p>&nbsp;</p>
      </div>
    );
  } else {
    return (
      <div>
        <p>
          TERMS OF USE OF THE WEBSITE OWNED BY CONTEX GROUP INC. AND ITS AFFILIATES. PLEASE READ THESE TERMS OF USE
          CAREFULLY BEFORE USING CONTEX GROUP INC. WHEREAS ANY USE OF ANY OF THE WEBSITES AND ANY SALE OF PRODUCTS AND
          SERVICES OFFERED ON ANY OF THE WEBSITES (THE &quot;SERVICES&quot;), INCLUDING ITS PAID SERVICES, ARE SUBJECT
          TO THE LEGAL TERMS AND CONDITIONS DETAILED HEREIN (COLLECTIVELY THE &quot;TERMS OF USE&quot;).
        </p>
        <ul>
          <li>1</li>
        </ul>
        <p>
          <b>
            <b>ACCEPTANCE OF THE TERMS OF USE</b>
          </b>
        </p>
        <ul>
          <li>1.1</li>
        </ul>
        <p>
          Welcome to one of the Contex Group Inc. websites. By browsing any of the Contex Group Inc. websites, or by
          using a site through wireless communication, social media or any of its features, including, but not limited
          to, RSS news feeds (collectively, the &quot;Websites&quot;), in any format or medium now known or hereafter
          discovered, you acknowledge that you have read and understand these Terms of Use and agree to abide by them
          and any subsequent amendments. These Terms of Use constitute an agreement between you and Contex Group Inc.
        </p>
        <ul>
          <li>1.2</li>
        </ul>
        <p>
          Contex Group Inc. reserves the right, at its discretion, to make changes at any time to the Terms of Use
          without notice. Any amendments to the Terms of Use are posted on this page. Please check these Terms of Use
          periodically for subsequent changes. Please refer to the update date at the top of these Terms of Use to check
          the date of the latest amendments.
        </p>
        <ul>
          <li>1.3</li>
        </ul>
        <p>
          The Websites are intended for use only by persons resident in Canada. If you do not agree to be bound by these
          Terms of Use, or if you are not authorized or do not have the legal capacity to consent to any of these Terms
          of Use, you may not use the Websites. In the event that you consider any subsequent amendments to these Terms
          of Use to be unacceptable, you must immediately cease using the Websites. Your continued use of the Websites
          after such amendments have been announced constitutes your acceptance of such amendments.
        </p>
        <ul>
          <li>1.4</li>
        </ul>
        <p>
          By using the Websites, you represent and warrant that you are of the age of majority in the province or
          territory in which you reside or have parental or guardian consent and thereby authorize a minor to browse the
          Websites and use the features and you expressly acknowledge that you are responsible for his/her actions and
          for the consequences of any breach of the terms, conditions, representations and warranties of the Terms of
          Use. Any person who has not reached the age of majority in the province or territory in which he/she resides
          must obtain his/her parent&apos;s or guardian&apos;s permission to use the Websites prior to any disclosure of
          personal information and for any credit card submission to register an order. We strongly recommend that
          parents or guardians monitor and supervise their children&apos;s activities and uses of the Websites.
        </p>
        <ul>
          <li>1.5</li>
        </ul>
        <p>
          <b>
            FAILURE TO COMPLY WITH THESE TERMS OF USE MAY RESULT IN TERMINATION OF YOUR ACCOUNT AND ALL ASSOCIATED
            PRIVILEGES.
          </b>
        </p>
        <ul>
          <li>2</li>
        </ul>
        <p>
          <b>PLACE OF OPERATION</b>
        </p>
        <ul>
          <li>2.1</li>
        </ul>
        <p>
          Groupe Contex Inc. operates its Websites from its offices in Canada. Contex Group Inc. makes no representation
          that any of the materials on its Websites may be used in other locations. Those who choose to use the Websites
          do so on their own initiative and are responsible for compliance with all applicable laws in their
          jurisdiction.
        </p>
        <ul>
          <li>2.2</li>
        </ul>
        <p>
          All content posted on the Websites refers to our server time (TMG-5); in the event of a discrepancy between
          the time of publication of a Website&apos;s offer and the time of the third-party provider&apos;s offer, the
          third-party provider&apos;s time will always prevail.
        </p>
        <ul>
          <li>3</li>
        </ul>
        <p>
          <b>CHANGES TO WEBSITES</b>
        </p>
        <ul>
          <li>3.1</li>
        </ul>
        <p>
          In its sole discretion, Groupe Contex Inc. reserves the right to modify, suspend, limit or discontinue any of
          the Services or any part of the Websites, including the availability of any content, application or database.
          Group Contex Inc. may also impose limits on certain features or restrict access to certain sections of its
          Websites without notice or liability. Groupe Contex Inc. reserves the right, in its sole discretion and
          without liability, to correct any errors or omissions in any portion of its Websites at any time without
          notice.
        </p>
        <ul>
          <li>4</li>
        </ul>
        <p>
          <b>PERSONAL USE</b>
        </p>
        <ul>
          <li>4.1</li>
        </ul>
        <p>
          All elements of the Websites are offered to you for your personal use for non-commercial purposes. You may not
          use the Websites for commercial or advertising purposes without the written consent of Groupe Contex Inc.
        </p>
        <ul>
          <li>5</li>
        </ul>
        <p>
          <b>REGISTRATION AND SECURITY</b>
        </p>
        <ul>
          <li>5.1</li>
        </ul>
        <p>
          When registering for a user account on the Websites, you must choose a password and a user name. If you
          register, you agree to provide us with true and complete information and to notify us immediately of any
          changes or updates to such information. You may not: (i) select or use a user name of any person with the
          intent to impersonate such person or misrepresent your affiliation with any person or entity, (ii) use a user
          name in which another person has rights, without such person&apos;s permission, (iii) use a password or user
          name that Contex Group Inc. deems offensive in its sole discretion, or (iv) create a user account for others
          or on behalf of a group of persons.
        </p>
        <ul>
          <li>5.2</li>
        </ul>
        <p>
          You are responsible for maintaining the confidentiality of your password and user name. You must notify Groupe
          Contex Inc. as soon as possible by e-mail (
          <a href="mailto:responsabledesdonnees@groupecontex.ca">responsabledesdonnees@groupecontex.ca</a>) of any
          unauthorized use or suspected use of your account, or any breach or suspected breach of security.
        </p>
        <ul>
          <li>5.3</li>
        </ul>
        <p>
          You are responsible for all use of the accounts you hold in connection with the Websites. You are responsible
          for all activity that occurs under your user name and you must ensure that your account is closed at the end
          of each session on the Websites. Groupe Contex Inc. cannot be held responsible for any unauthorized use of
          your account or for any loss or damage resulting from, among other things, a failure to comply with the
          security measures set out in this paragraph.
        </p>
        <ul>
          <li>5.4</li>
        </ul>
        <p>
          You are responsible for updating your user account on the Websites and for removing any invalid information to
          prevent the erroneous transmission of communications to a third party.
        </p>
        <ul>
          <li>5.5</li>
        </ul>
        <p>
          Due to the difficulty of truly and accurately authenticating a user on the Internet, Groupe Contex Inc. does
          not guarantee the identity of users. In the event of a dispute between you and a user, you agree to release
          Groupe Contex Inc. (and its officers, directors, shareholders, employees and agents) from all losses, claims,
          damages and demands arising directly or indirectly from actions or omissions related to such disputes.
        </p>
        <ul>
          <li>6</li>
        </ul>
        <p>
          <b>SUBSCRIPTION FEES AND PAYMENTS</b>
        </p>
        <ul>
          <li>6.1</li>
        </ul>
        <p>
          Groupe Contex Inc. offers its users on some of these sites a private subscription space allowing privileged
          access to certain areas of the website and/or to the paper publication and/or to the digital version (tablet)
          of the publication depending on the type of subscription the user selects (the &quot;Subscription&quot;).
          Access to certain content may be limited depending on the type of Subscription selected and the Contex Group
          Inc. publication. Access to the Subscription using more than one device at a time is prohibited.
        </p>
        <ul>
          <li>6.2</li>
        </ul>
        <p>
          To subscribe, the user will have to choose a username and password. The Subscription and User ID are
          non-transferable and may not be shared with third parties. Any user wishing to take advantage of a
          Subscription hereby acknowledges having read and accepted the terms and conditions herein.
        </p>
        <ul>
          <li>7</li>
        </ul>
        <p>
          <b>TRANSACTIONS WITH A THIRD PARTY</b>
        </p>
        <ul>
          <li>7.1</li>
        </ul>
        <p>
          As a user of one of the Websites, you may receive various communications about third party products or
          services that appear on one of the Websites. When you use the Websites, you may engage in commercial
          transactions with third parties, purchase from third parties and participate in commercial transactions with
          third parties.
        </p>
        <ul>
          <li>7.2</li>
        </ul>
        <p>
          Some Websites are platforms for third parties to make offers and provide information about their products or
          services. It is the sole responsibility of the third parties to fulfill the terms of their offers and Groupe
          Contex Inc. will not be responsible for any discrepancies between the information posted on any of its
          Websites and the terms and conditions established by a third party, including, without limitation, any
          discrepancies with the price and description of a product as posted in a store. Contex Group Inc. is not a
          party to any offer on these Websites and does not endorse, recommend or sponsor any products, services or
          promotional offers available on these Websites or on any third party website, except in the case of a contest
          where it is specifically named as a sponsor. Any correspondence, advertising, purchases or promotions relating
          to a third party, including without limitation the provision of, and payment for, third party products or
          services, and any other terms, conditions, warranties or representations associated with such correspondence,
          purchases or promotions, are solely between you and such third party. Groupe Contex Inc. makes no
          representations, terms, warranties or recommendations as to the suitability or potential value of any
          particular third party product, service or promotional offer. Images and photographs displayed on the Websites
          in connection with a third party product, service or promotional offer are provided for informational purposes
          only. You acknowledge that Groupe Contex Inc. does not pre-screen any content. Specifically, Groupe Contex
          Inc. does not control the content created by third parties and makes no representations or warranties
          whatsoever as to the accuracy of any information available on or through the Website, including, without
          limitation, information regarding rebates, coupons, discounts, bargains or other promotions for products or
          services offered by third parties. Groupe Contex Inc. cannot guarantee that the goods or services advertised
          on the Websites will be available according to the terms and conditions (including price) described at any
          time on or through the Websites. You agree that all such transactions are at your risk. Groupe Contex Inc. is
          not a party to such transactions and disclaims any liability in connection with such third party transactions.
        </p>
        <ul>
          <li>7.3</li>
        </ul>
        <p>
          Groupe Contex Inc. shall not be responsible or liable, directly or indirectly, for the terms and conditions of
          any transaction between you and any third party, or for any damages or other civil liability arising out of or
          relating to the provision of such services. In the event of a dispute between you and any third party, you
          release Groupe Contex Inc. (including, without limitation, its officers, directors, employees and agents) from
          all claims, demands and damages of every kind and nature, arising out of any act or omission relating thereto
          in any manner whatsoever.
        </p>
        <ul>
          <li>8</li>
        </ul>
        <p>
          <b>COUPONS AND CONTESTS</b>
        </p>
        <ul>
          <li>8.1</li>
        </ul>
        <p>
          Groupe Contex Inc. may, from time to time, conduct contests on its Internet Sites, either alone or in
          conjunction with third party sponsors. All such contests will be governed by their respective rules. Before
          entering a contest, it is your responsibility to read, understand and agree to the terms and conditions
          described in each contest&apos;s rules.
        </p>
        <ul>
          <li>8.2</li>
        </ul>
        <p>
          All coupons posted on the Websites are governed by the terms and conditions applicable to such coupons. Contex
          Group Inc. has no control over the content created by third parties and makes no representations or warranties
          as to the accuracy of the information relating to the products or services offered by third parties described
          on the coupons.
        </p>
        <ul>
          <li>9</li>
        </ul>
        <p>
          <b>USER-GENER CONTENT</b>: Discussion forums, bulletin boards, blogs, chat rooms and the like (collectively,
          the &quot;Exchange Platforms&quot;)
        </p>
        <ul>
          <li>9.1</li>
        </ul>
        <p>
          All communications made on any of the Web Sites are public and not private. As a user, you are solely
          responsible for any damages resulting from your use of the Website, any communications or transmissions in
          connection with your use of the Website, and any material you post to the Website. As such, you are required
          to comply with the following rules:
        </p>
        <ul>
          <li>9.1.1</li>
        </ul>
        <p>
          Use respectful language. Coarse or offensive language will not be tolerated at any time. In addition, no one
          may post or submit content that is defamatory, obscene, abusive, pornographic, sexist, racist, homophobic,
          abusive, inappropriate, disgraceful, unreasonable, violent, religious, immoral, illegal, or content that
          violates the rights of any third party.
        </p>
        <ul>
          <li>9.1.2</li>
        </ul>
        <p>
          Be courteous. No one should make threats or incite violence or hatred. It is also forbidden to deliberately
          disrupt any discussion by repetitive messages, unsubstantiated messages or spamming.
        </p>
        <ul>
          <li>9.1.3</li>
        </ul>
        <p>
          Discuss but do not attack. In an open discussion where there are multiple opinions, people often disagree.
          Groupe Contex Inc. encourages discussion and the exchange of ideas and debates in its Exchange Platforms. Any
          personal attack is strictly prohibited.
        </p>
        <ul>
          <li>9.1.4</li>
        </ul>
        <p>
          No person shall use the Trading Platforms to promote or offer for sale any goods or services for commercial
          purposes, conduct or send any contests, surveys, pyramid or chain letters, spamming, hacking, solicitation or
          any other act for illegal purposes. It is also forbidden to infringe the rights of others in any way or
          prevent the use of the Exchange Platform. It is also forbidden to use the Websites for any self-promotional
          purpose or to download content for commercial purposes without the prior consent of Groupe Contex Inc.
        </p>
        <ul>
          <li>9.2</li>
        </ul>
        <p>
          By using the Websites, you acknowledge and agree that you will not upload or download any viruses, bugs,
          malware, or other programs that cause the Websites to malfunction.
        </p>
        <ul>
          <li>9.3</li>
        </ul>
        <p>
          Contex Group Inc. offers the ability to submit and post content such as raw messages, hyperlinks, data, text,
          software, music, sound, photographs, images, videos, and any other materials (collectively your
          &quot;Materials&quot;). You remain responsible for the Materials you upload, share, copy on the Exchange
          Platform or in connection with the Services. You represent that your Materials are original and acknowledge
          that you own all intellectual property rights or, if applicable, have obtained written permission from the
          owner to use the Materials for any purpose related to the Services. Images and photographs from professional
          photographers or third party websites are copyrighted and may not be downloaded, shared or otherwise used in
          connection with the Services without the prior permission of the rights holder.
        </p>
        <ul>
          <li>9.4</li>
        </ul>
        <p>
          By using the Websites, you acknowledge and agree that you remain the copyright owner of the Material you
          submit as a user to Groupe Contex Inc. Furthermore, you acknowledge and agree that by the mere act of
          submitting your Material to the Websites, you grant Groupe Contex Inc, its affiliates, agents, employees,
          business partners, a worldwide, unlimited, irrevocable, perpetual, non-exclusive license, without compensation
          to you, to use, edit, modify, delete, reproduce, publish, broadcast, adapt, translate, develop derivative
          works from, transmit, distribute, publicly perform, archive, index, communicate the content or Material
          submitted for any purpose and to grant sub-licenses or other permissions under copyright law. To this end, you
          waive any moral rights you may have in the integrity of your Material. All rights granted herein apply with
          respect to any use or exploitation through or by means of any medium whatsoever, whether existing or future,
          for the full term of the copyright and any renewals. You also consent to the posting of your name, address and
          email address, and to the disclosure and/or posting of such Material, which shall be deemed and treated as
          non-confidential. You acknowledge and agree that you are personally responsible for the content of the
          messages you communicate on the Websites&apos; Exchange Platforms, as well as the consequences of such
          messages.
        </p>
        <ul>
          <li>9.5</li>
        </ul>
        <p>
          You agree not to post any Material that is offensive to the Internet community, such as (i) racist,
          defamatory, obscene, threatening, abusive, hateful, or physical attacks of any kind directed at any group or
          individual; (ii) harassment of any person for a third party, or any statement that violates a third
          party&apos;s right to privacy or publicity; (iii) involving the transmission of &quot;spam&quot;, chain
          letters or e-mails, or advertisements for pyramid schemes; (iv) promote false, defamatory, illegal activities
          or abusive conduct; (v) make available Materials that contain or may contain viruses, Trojan horses, worms,
          logic bombs, cancelbots or any other computer programs that may damage, delete, interfere with, intercept or
          disrupt the use of any Services or Materials on any Website. You agree not to engage in activities designed to
          obtain a list of users of a Web Site.
        </p>
        <ul>
          <li>9.6</li>
        </ul>
        <p>
          Groupe Contex Inc. reserves the right, but has no obligation, to investigate, moderate, edit, remove or
          disclose any Material transmitted by a user on its Websites. In addition, Groupe Contex Inc. reserves the
          right, in its sole discretion, to not publish, delete, screen, investigate allegations, edit or remove any
          Material, in whole or in part, transmitted by a user on any of its Websites, for any reason, without prior
          notice. However, this remains an option and not an obligation. Contex Group Inc. reserves the right to
          investigate any violation of these Terms of Use and may seek the intervention of, and cooperate with, law
          enforcement authorities in order to bring legal action against users who may be involved in such violations.
        </p>
        <ul>
          <li>9.7</li>
        </ul>
        <p>
          Groupe Contex Inc. makes no representations about the control of materials available on its Websites. Contex
          Group Inc. is not, and cannot be held responsible for the accuracy, legality, intellectual property compliance
          or these Terms of Use of any material provided by its users.
        </p>
        <ul>
          <li>10</li>
        </ul>
        <p>
          <b>LINKS TO THIRD PARTY SITES</b>
        </p>
        <ul>
          <li>10.1</li>
        </ul>
        <p>
          For your convenience, the Contex Group Inc. Sites contain hypertext or other links to websites controlled by
          third parties. Activating these links may cause you to leave the Websites. Please be aware that Groupe Contex
          Inc. has no control over third party websites. These sites are entirely managed by third parties and Groupe
          Contex Inc. cannot make any representation whatsoever as to the quality or overall content of these sites or
          as to the quality of the products offered or the transactional security of the sites in question. Groupe
          Contex Inc. is providing these links only as a convenience and the fact that these Third Party Sites are
          listed on the Websites should not be construed as an endorsement by Groupe Contex Inc. and does not in any way
          engage its responsibility. Groupe Contex Inc. shall in no event be liable, directly or indirectly, for any
          damage or loss arising out of or in connection with the use of any third party sites. Any complaints regarding
          third party sites should be directed to the administrators of such sites.
        </p>
        <ul>
          <li>10.2</li>
        </ul>
        <p>
          Unless otherwise specified in writing by Groupe Contex Inc. any reproduction of a hyperlink from one of the
          Websites to a third party site must comply with the following conditions: (i) the reproduction of the
          appearance, position and other visual characteristics must not be such as to be detrimental to any registered
          or unregistered trademark or business name of Groupe Contex Inc, (ii) the link must not create confusion as to
          the existence of an association with a third party or a sponsorship link between the parties; (iii) the link
          must open in a new browser window, thus allowing the reproduction of the entire HTML page associated with the
          Groupe Contex Inc. website. Groupe Contex Inc. reserves the right to unilaterally terminate the presence of
          the link on the third party page, without prior notice, and to remove it without further notice.
        </p>
        <ul>
          <li>11</li>
        </ul>
        <p>
          <b>MOBILE COMMUNICATIONS AND APPLICATIONS</b>
        </p>
        <ul>
          <li>11.1</li>
        </ul>
        <p>
          Any use of a Website from a wireless communication service or application is governed by these Terms of Use.
        </p>
        <ul>
          <li>11.2</li>
        </ul>
        <p>
          You acknowledge that your use of a Website from a wireless communication or application or receipt of a text
          message is subject to the telecommunication services offered in the coverage areas of your mobile service
          provider and its roaming partners and are subject to your usual service terms such as text messaging, content,
          and Internet access charges and that you may be charged fees based on these terms.
        </p>
        <ul>
          <li>11.3</li>
        </ul>
        <p>
          In the event that you change or deactivate your wireless telecommunication service, please update your user
          profile within 48 hours of completing your account modification to ensure that Contex Group Inc. does not
          inadvertently correspond with a third party who acquired your old number.
        </p>
        <ul>
          <li>12</li>
        </ul>
        <p>
          <b>INTELLECTUAL PROPERTY</b>
        </p>
        <ul>
          <li>12.1</li>
        </ul>
        <p>
          All content posted or otherwise accessible through the Websites is protected by copyright, trademark, patent
          or other applicable intellectual property right. The trademarks and logos used or displayed on the Websites
          are registered or unregistered trademarks and logos of Groupe Contex Inc. or third parties. Nothing on the
          Websites should be construed as permitting, directly or indirectly, the use of such trademarks or logos
          without the prior written permission of their owner. Without limiting the generality of the foregoing, it is
          prohibited to reproduce the trademarks and logos displayed on the Websites for commercial or advertising
          purposes or to increase the volume of visitors to your Site.
        </p>
        <ul>
          <li>12.2</li>
        </ul>
        <p>
          All accessible content on the Websites (including, without limitation, text, articles, photographs, images,
          illustrations, audio and video clips, software, files, etc.) are protected by copyright and belong to Groupe
          Contex Inc. or its subsidiaries, business partners or third parties acting as content providers. Unless
          clearly stated otherwise, nothing on the Websites shall be construed as granting you any right in or to the
          intellectual property of Groupe Contex Inc. or its subsidiaries, business partners or third party content
          providers.
        </p>
        <ul>
          <li>12.3</li>
        </ul>
        <p>
          Except as provided herein, you may not copy, reproduce, modify, publish, broadcast, distribute, participate in
          the transfer or sale of, create derivative works from, translate, upload, license or in any way exploit, any
          of the materials offered on the Websites (including, without limitation, any software), in whole or in part.
        </p>
        <ul>
          <li>12.4</li>
        </ul>
        <p>
          You may download or copy material from the Websites for your personal, non-commercial use only, provided that
          you retain all copyright notices contained therein. Copying or storing any material for purposes other than
          personal use is absolutely prohibited without the prior written permission of Contex Group Inc. or the
          copyright owner identified in the intellectual property notice.
        </p>
        <ul>
          <li>13</li>
        </ul>
        <p>
          <b>INDEMNIFICATION</b>
        </p>
        <ul>
          <li>13.1</li>
        </ul>
        <p>
          By using the Websites, you represent that you are responsible for your conduct on the Websites. You hereby
          agree to indemnify and hold Groupe Contex Inc, its subsidiaries and affiliates, its directors, officers,
          shareholders, employees, agents and content providers from any and all direct or indirect monetary awards,
          principal and interest, legal and extrajudicial costs and any other damages, including without limitation, all
          legal costs incurred by Groupe Contex Inc. relating to any claim, suit, action or demand (a &quot;Claim&quot;)
          arising out of (i) images or other content uploaded to or posted by you on the Websites; or (ii) any violation
          of these Terms of Use. Contex Group Inc. reserves the right, at its discretion, to participate in the defense
          of any such Claim and to be represented by counsel of its choice.
        </p>
        <ul>
          <li>14</li>
        </ul>
        <p>
          <b>OPINION ON PROFESSIONAL INFORMATION</b>
        </p>
        <ul>
          <li>14.1</li>
        </ul>
        <p>
          Contex Group Inc. and third parties disseminate, through the Websites, certain information regarding various
          professional disciplines such as health care arts and professions, medicine, fitness, law, financial planning,
          labor relations, accounting, travel (the &quot;Professional Information&quot;). This Professional Information
          is presented for educational and cultural purposes only and should not be construed as specific treatment or
          action individualized to any person. The Professional Information is frequently updated, but due to the
          constant evolution of information, some content may be outdated. This information on the Professional
          Information is never intended to be professional advice and is not a substitute for the advice of a trained or
          licensed professional in that profession. Use of the Websites is not a substitute for consultation with a
          professional in that field. Any use of Professional Information shared on a Website shall be at the
          user&apos;s sole risk. Groupe Contex Inc, including its sponsors, shall not be responsible or liable, directly
          or indirectly, for any consequences, losses, damages or other matters that may result from the use of any
          professional advice on any Professional Information.
        </p>
        <ul>
          <li>15</li>
        </ul>
        <p>
          <b>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</b>
        </p>
        <ul>
          <li>15.1</li>
        </ul>
        <p>
          The Websites, Materials and services are provided to you on an &quot;as is&quot; and &quot;as available&quot;
          basis, except for the terms of its return policies. Contex Group Inc, its subsidiaries and affiliates, and
          each of their respective directors, officers, employees, consultants, service providers and agents expressly
          disclaim all representations, warranties, express or implied, including, without limitation, warranties of
          title, merchantability, fitness for a particular purpose, use, availability, validity, accuracy, completeness,
          timeliness of information, sufficiency, reliability of materials reproduced on such Sites, non-infringement of
          intellectual property rights, or that any of the Websites will operate securely and without interruption or
          error or that defects or errors will be corrected, or that the Websites will be free of viruses or harmful
          components. Without limiting the generality of the foregoing, Groupe Contex Inc. makes no representations
          about the completeness of the content or results of your searches of its Websites. You hereby acknowledge and
          agree that your use of the Websites is at your own risk.
        </p>
        <ul>
          <li>15.2</li>
        </ul>
        <p>
          Groupe Contex Inc. assumes no responsibility for any errors that may be found on its Websites. Neither Groupe
          Contex Inc. nor any third party providing content on the Websites, their related companies, officers,
          employees or agents shall be liable for any loss of use, data or profits or revenues, acts, errors or
          omissions on the Websites. In no event shall Groupe Contex Inc. be liable for any loss or damage (including,
          without limitation, compensatory, special, indirect, punitive or consequential damages) arising out of or in
          connection with the use, availability or non-availability of the Websites.
        </p>
        <ul>
          <li>15.3</li>
        </ul>
        <p>
          By using the Services and the Content, you acknowledge and agree that the submission of digitized material to
          Groupe Contex Inc. and the uploading of any Material to the Websites is done at your own risk and you will be
          solely responsible for any loss or damage to your Material or electronic system. You are responsible for
          making any necessary copies to ensure the backup of your content that you may later require. Groupe Contex
          Inc. will not be responsible for the deletion of any content.
        </p>
        <ul>
          <li>16</li>
        </ul>
        <p>
          <b>TERMINATION</b>
        </p>
        <ul>
          <li>16.1</li>
        </ul>
        <p>
          You may cancel your account at any time by accessing the &quot;Your Profile&quot; section of the Website. To
          cancel your subscription, you must contact Groupe Contex Inc. customer service at the following address:
          <br />
          <br />
          <strong>Mailing address </strong>
          <br />
          Groupe Contex Inc.
          <br />
          355 Sainte-Catherine Street West, 5th floor Montreal,
          <br />
          Quebec H3B 1A5
          <br />
          Telephone
          <br />
          514 392-2009
          <br />
          <strong>E-mail</strong>
          <br />
          <u>
            <a href="mailto:chefdataofficer@groupecontex.ca">chefdataofficer@groupecontex.ca</a>
          </u>
          <br />
          16.2 Contex Group Inc. reserves the right, in its sole discretion and at any time, to terminate or suspend
          your account in whole or in part from its Websites for any reason, including, without limitation, failure to
          comply with these Terms of Use.
        </p>
        <ul>
          <li>17</li>
        </ul>
        <p>
          <b>PRIVACY POLICY</b>
        </p>
        <ul>
          <li>17.1</li>
        </ul>
        <p>
          For information on Contex Group Inc.&apos;s personal information collection and protection policy, please see
          our privacy policy at the following main address
        </p>
        <ul>
          <li>17.2</li>
        </ul>
        <p>
          In addition, when you agree to participate in a contest or offer presented by a third party or consent to
          receive information directly from a third party, you expressly agree to the privacy policies of that third
          party. We recommend that you review the terms of the third party&apos;s privacy policy before disclosing any
          personal information about you. Contex Group Inc. shall not be liable for any claim, loss, action, damage,
          suit or remedy related in any way to the use of your private information by such third party.
        </p>
        <ul>
          <li>17.3</li>
        </ul>
        <p>
          Personal information collected through the Websites may be stored and processed in Canada or abroad and may be
          subject to applicable laws in other jurisdictions.
        </p>
        <ul>
          <li>18</li>
        </ul>
        GENERAL
        <ul>
          <li>18.1</li>
        </ul>
        <p>
          The Terms of Use shall be deemed enforceable. If any provision of these Terms of Use is held to be invalid or
          unenforceable for any reason, the invalidity of such provision shall not affect the validity and
          enforceability of any other provision of these Terms of Use.
        </p>
        <ul>
          <li>19</li>
        </ul>
        <p>
          <b>APPLICABLE LAWS AND JURISDICTION</b>
        </p>
        <ul>
          <li>19.1</li>
        </ul>
        <p>
          By using the Websites, including any order or purchase on a Groupe Contex Inc. website, you acknowledge and
          agree that these Terms of Use are governed by the applicable laws of the Province of Quebec, and the
          applicable federal laws of Canada. You hereby irrevocably submit to the exclusive jurisdiction of the courts
          of the Province of Quebec, in the City of Montreal, with respect to all disputes arising out of these Terms of
          Use, your use of any of the Websites.
        </p>
        <ul>
          <li>20</li>
        </ul>
        <p>
          <b>TO COMMUNICATE WITH US ABOUT THIS POLICY</b>
        </p>
        <ul>
          <li>20.1</li>
        </ul>
        <p>
          In the event that you have a claim for breach of the Terms of Use or any infringement of intellectual property
          rights arising out of or relating to the use of the Websites in connection with material included in the
          Websites, or for any other communication, please notify us at the following address
          <br />
          <strong>Mailing Address</strong>
          <br />
          Groupe Contex Inc.
          <br />
          355 Sainte-Catherine Street West, 5th Floor Montreal,
          <br />
          Quebec H3B 1A5
          <br />
          Telephone
          <br />
          514 392-2009
          <br />
          <b>Email</b>
          <br />
          <a href="mailto:chefdataofficer@groupecontex.ca">chefdataofficer@groupecontex.ca</a>
        </p>
      </div>
    );
  }
};
