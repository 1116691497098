import { BoldP, NormalP } from './FilterPanel';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Form, Tree } from 'antd';
import React, { useContext, useState } from 'react';
import { regionEn, regionFr } from '../../../dtos/regions';

import { LocaleContext } from '../../../router/Router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const SearchRegions = (): JSX.Element => {
  const { t } = useTranslation(['businessesForSale', 'regions']);
  const { locale } = useContext(LocaleContext);
  const [hidden, setHidden] = useState(false);

  return (
    <>
      <StyledRow>
        <BoldP>{t('businessesForSale:search.location')}</BoldP>
        <ClickDiv
          onClick={() => {
            setHidden(!hidden);
          }}
        >
          {hidden ? (
            <NormalP>
              <UpOutlined /> {t('businessesForSale:search.showAll')}
            </NormalP>
          ) : (
            <NormalP>
              <DownOutlined /> {t('businessesForSale:search.showLess')}
            </NormalP>
          )}
        </ClickDiv>
      </StyledRow>

      <Form.Item hidden={hidden} name="regions" valuePropName="checkedKeys" trigger="onCheck">
        <Tree
          checkable
          defaultExpandedKeys={['1800']}
          treeData={[
            ...(locale === 'en' ? regionEn : regionFr).map((ind) => {
              return {
                key: Object.keys(ind)[0],
                title: t(`regions:regions.${Object.keys(ind)[0]}`),
                children: Object.values(ind)[0].map((f: string) => {
                  return { key: f, title: t(`regions:regions.${f}`) };
                }),
              };
            }),
          ]}
        />
      </Form.Item>
    </>
  );
};

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ClickDiv = styled.div`
  cursor: pointer;
`;
