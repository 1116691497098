export const enum OwnerSubscriptionEnum {
  owner_1 = 'owner_1',
  owner_2 = 'owner_2',
  owner_3 = 'owner_3',
}

export const enum BrokerDuration {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export const enum BrokerLevel {
  ELITE = 'elite',
  PRIVILEGE = 'privilege',
}

export const enum BrokerPlan {
  broker_privilege_2listings_monthly = 'broker_privilege_2listings_monthly',
  broker_privilege_6listings_monthly = 'broker_privilege_6listings_monthly',
  broker_privilege_12listings_monthly = 'broker_privilege_12listings_monthly',
  broker_elite_2listings_monthly = 'broker_elite_2listings_monthly',
  broker_elite_6listings_monthly = 'broker_elite_6listings_monthly',
  broker_elite_12listings_monthly = 'broker_elite_12listings_monthly',
  broker_privilege_2listings_yearly = 'broker_privilege_2listings_yearly',
  broker_privilege_6listings_yearly = 'broker_privilege_6listings_yearly',
  broker_privilege_12listings_yearly = 'broker_privilege_12listings_yearly',
  broker_privilege_24listings_yearly = 'broker_privilege_24listings_yearly',
  broker_privilege_48listings_yearly = 'broker_privilege_48listings_yearly',
  broker_elite_2listings_yearly = 'broker_elite_2listings_yearly',
  broker_elite_6listings_yearly = 'broker_elite_6listings_yearly',
  broker_elite_12listings_yearly = 'broker_elite_12listings_yearly',
  broker_elite_24listings_yearly = 'broker_elite_24listings_yearly',
  broker_elite_48listings_yearly = 'broker_elite_48listings_yearly',
}

export const monthlyPrivilege = [
  BrokerPlan.broker_privilege_2listings_monthly,
  BrokerPlan.broker_privilege_6listings_monthly,
  BrokerPlan.broker_privilege_12listings_monthly,
];

export const monthlyElite = [
  BrokerPlan.broker_elite_2listings_monthly,
  BrokerPlan.broker_elite_6listings_monthly,
  BrokerPlan.broker_elite_12listings_monthly,
];

export const yearlyPrivilege = [
  BrokerPlan.broker_privilege_2listings_yearly,
  BrokerPlan.broker_privilege_6listings_yearly,
  BrokerPlan.broker_privilege_12listings_yearly,
  BrokerPlan.broker_privilege_24listings_yearly,
  BrokerPlan.broker_privilege_48listings_yearly,
];

export const yearlyElite = [
  BrokerPlan.broker_elite_2listings_yearly,
  BrokerPlan.broker_elite_6listings_yearly,
  BrokerPlan.broker_elite_12listings_yearly,
  BrokerPlan.broker_elite_24listings_yearly,
  BrokerPlan.broker_elite_48listings_yearly,
];
