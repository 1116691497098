import React, { DidomiSDK } from '@didomi/react';

export const Didomi = () => {
  const didomiConfig = {
    app: {
      apiKey: 'd0913bd2-e061-464f-be6d-294a4397bca0',
      vendors: {
        iab: {
          version: 2,
        },
      },
    },
  };
  return (
    <DidomiSDK
      config={didomiConfig}
      iabVersion={2} // If you want to support the TCF v1, don't forget to change this value. This parameter will load the correct stub in the React Component
      noticeId="ZUbTYg8D" // If you want to target the notice by ID and not by domain
      gdprAppliesGlobally={true}
      sdkPath="https://sdk.privacy-center.org/"
      embedTCFStub={true}
      onReady={(didomi) => console.log('Didomi SDK is loaded and ready', didomi)}
      onConsentChanged={(cwtToken) => console.log('A consent has been given/withdrawn', cwtToken)}
      onNoticeShown={() => console.log('Didomi Notice Shown')}
      onNoticeHidden={() => console.log('Didomi Notice Hidden')}
      onNoticeBackdropclick={() => console.log('Didomi Notice Backdrop Click')}
      onNoticeClickAgree={() => console.log('Didomi Notice Click Agree')}
      onNoticeClickMoreInfo={() => console.log('Didomi Notice Click More Info')}
      onPreferencesClickAgreeToAll={() => console.log('Didomi Preferences Click Agree to All')}
      onPreferencesClickDisagreeToAll={() => console.log('Didomi Preferences Click Disagree to All')}
      onPreferencesClickPurposeAgree={(purposeId) => console.log('Didomi Preferences Click Purpose Agree', purposeId)}
      onPreferencesClickPurposeDisagree={(purposeId) =>
        console.log('Didomi Preferences Click Purpose Disagree', purposeId)
      }
      onPreferencesClickViewVendors={() => console.log('Didomi Preferences Click View Vendors')}
      onPreferencesClickSaveChoices={() => console.log('Didomi Preferences Click Save Choices')}
      onPreferencesClickVendorAgree={(vendorId) => console.log('Didomi Preferences Click Vendor Agree', vendorId)}
      onPreferencesClickVendorDisagree={(vendorId) => console.log('Didomi Preferences Click Vendor Disagree', vendorId)}
      onPreferencesClickVendorSaveChoices={() => console.log('Didomi Preferences Click Vendor Save Choices')}
    />
  );
};
