import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import React, { useContext } from 'react';
import { privateRoutes, publicRoutes } from '../../router/routes';

import { LocaleContext } from '../../router/Router';
import { NavLink } from 'react-router-dom';
import { Row } from 'antd';
import { UserContext } from '../../contexts/UserContext';
import broker from './../../img/broker.webp';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useTranslation } from 'react-i18next';

export const Broker = (): JSX.Element => {
  const { t } = useTranslation('home');
  const { locale } = useContext(LocaleContext);
  const { authTokens } = useContext(UserContext);

  return (
    <StyledSection>
      <Row justify="center">
        <Block>
          <Row justify="center" align="middle">
            <h3>{t('home:broker.title')}</h3>
          </Row>
          <Row justify="center" align="middle">
            <ButtonDiv>
              <FlexGrow>
                <NavLink to={publicRoutes.brokerSection(locale)}>
                  <ButtonSmall status={ButtonStatus.PRIMARY} text={t('home:broker.button1')} block={true} />
                </NavLink>
              </FlexGrow>
              <FlexGrow>
                <NavLink to={authTokens ? privateRoutes.myBrokerInfo(locale) : publicRoutes.signup(locale)}>
                  <ButtonSmall status={ButtonStatus.PRIMARY} text={t('home:broker.button2')} block={true} />
                </NavLink>
              </FlexGrow>
            </ButtonDiv>
          </Row>
        </Block>
      </Row>
    </StyledSection>
  );
};

const Block = styled.section`
  padding-top: 10vw;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-bottom: 10vw;
  max-width: 1800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledSection = styled.div`
  width: 100%;
  background-image: url(${broker});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  h3 {
    color: ${theme.color.white};
    white-space: pre-wrap;
  }
  text-align: center;
`;

const FlexGrow = styled.div`
  width: 248px;
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 24px;
  @media only screen and (max-width: 800px) {
    justify-content: center;
  }
`;
