import { Helmet } from 'react-helmet-async';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const HelmetSellABusiness = () => {
  const { t } = useTranslation('helmet');

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{t('sellABusiness.title')}</title>
      <meta name="description" content={t('sellABusiness.content')} />
      <link rel="alternate" hrefLang="fr" href="https://www.acquizition.biz/fr/sellABusiness" />
      <link rel="alternate" hrefLang="en" href="https://www.acquizition.biz/en/sellABusiness" />
    </Helmet>
  );
};
