import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { Col, Row } from 'antd';
import React, { useContext } from 'react';

import { LocaleContext } from '../../router/Router';
import { NavLink } from 'react-router-dom';
import { publicRoutes } from '../../router/routes';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useTranslation } from 'react-i18next';

export const Contact = (): JSX.Element => {
  const { t } = useTranslation('home');
  const { locale } = useContext(LocaleContext);

  return (
    <StyledSection>
      <Row justify="center">
        <Block>
          <Row justify="center" align="middle" gutter={[48, 48]}>
            <Col lg={10}>
              <Row>
                <h3>{t('home:contact.question')}</h3>
                <p>
                  <b>{t('home:contact.contactUs')}</b>
                  <u>
                    <a href="mailto:info@acquizition.biz">info@acquizition.biz</a>
                  </u>
                </p>
              </Row>
            </Col>
            <Col lg={10}>
              <Row justify={'center'}>
                <ButtonDiv>
                  <NavLink to={publicRoutes.contactUs(locale)}>
                    <ButtonSmall text={t('home:contact.contactButton')} status={ButtonStatus.SECONDARY} block={true} />
                  </NavLink>
                </ButtonDiv>
              </Row>
            </Col>
          </Row>
        </Block>
      </Row>
    </StyledSection>
  );
};

const Block = styled.section`
  padding: 4vw;
  max-width: 1800px;
  width: 100%;
`;

const ButtonDiv = styled.div`
  width: 100%;
  max-width: 280px;
`;

const StyledSection = styled.div`
  background-color: ${theme.color.green};
  width: 100%;
  p,
  a,
  h3 {
    color: ${theme.color.white};
  }
  p {
    margin: 0 !important;
  }
`;
