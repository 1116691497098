import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { Link, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';

import { LocaleContext } from '../../router/Router';
import { UserContext } from '../../contexts/UserContext';
import { ViewDashboard } from '../../common/views/ViewDashboard';
import logo from '../../img/LOGO-Acquizition-HR.webp';
import { privateRoutes } from '../../router/routes';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const TransactionSuccessful = () => {
  const { t } = useTranslation('transactionSuccessful'),
    { userType } = useContext(UserContext),
    navigate = useNavigate(),
    { locale } = useContext(LocaleContext);

  useEffect(() => {
    if (userType !== 'broker') {
      navigate(privateRoutes.myListings(locale));
    }
  }, []);

  return (
    <ViewDashboard>
      <FlexTitle>
        <h3>{t('welcome')}</h3>
        <img src={logo} alt="Acquizition logo" height={30} />
      </FlexTitle>
      <FlexOptions>
        <DivCol>
          <div>
            <h5>{t('createProfile')}</h5>
            <p>{t('createProfileDesc')}</p>
          </div>
          <ButtonDiv>
            <Link to={privateRoutes.myBrokerInfo(locale)}>
              <ButtonSmall status={ButtonStatus.PRIMARY} text={t('myBrokerInfo')} />
            </Link>
            <p>{t('myBrokerInfoMenu')}</p>
          </ButtonDiv>
        </DivCol>
        <DivCol>
          <div>
            <h5>{t('createListing')}</h5>
            <p>{t('createListingDesc')}</p>
          </div>
          <ButtonDiv>
            <Link to={privateRoutes.myListings(locale)}>
              <ButtonSmall status={ButtonStatus.SECONDARY} text={t('myListings')} />
            </Link>
            <p>{t('myListingsMenu')}</p>
          </ButtonDiv>
        </DivCol>
      </FlexOptions>
    </ViewDashboard>
  );
};

const FlexTitle = styled.div`
  display: flex;
  align-items: center;
  h3 {
    margin-bottom: 0;
  }
  gap: 4px;
  margin-bottom: 40px;
`;

const FlexOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1200px) {
    flex-flow: wrap;
  }
  gap: 40px;
`;

const DivCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  height: 264px;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 0.5px solid #7b7b7d;
  padding: 24px;
  p {
    margin-bottom: 0;
  }
`;
const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
