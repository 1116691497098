import {
  FileOutlined,
  IdcardOutlined,
  LogoutOutlined,
  MailOutlined,
  PhoneOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  StarOutlined,
  UserOutlined,
} from '@ant-design/icons';
import React, { useContext } from 'react';
import { privateRoutes, publicRoutes } from '../../router/routes';

import { Divider } from 'antd';
import { LocaleContext } from '../../router/Router';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useTranslation } from 'react-i18next';

export const SignedInMenu = () => {
  const { signOut, userType } = useContext(UserContext);
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation();

  return (
    <>
      <NavLink to={privateRoutes.profile(locale)}>
        <h6>
          <SyledSpan>
            <UserOutlined />
          </SyledSpan>
          {t('header:dashboardContent.myProfile')}
        </h6>
      </NavLink>
      {userType === 'broker' && (
        <NavLink to={privateRoutes.myBrokerInfo(locale)}>
          <h6>
            <SyledSpan>
              <IdcardOutlined />
            </SyledSpan>
            {t('header:dashboardContent.brokerInfo')}
          </h6>
        </NavLink>
      )}
      <NavLink to={privateRoutes.myListings(locale)}>
        <h6>
          <SyledSpan>
            <FileOutlined />
          </SyledSpan>
          {userType === 'broker'
            ? t('header:dashboardContent.myListingsBroker')
            : t('header:dashboardContent.myListingsOwner')}
        </h6>
      </NavLink>
      <NavLink to={privateRoutes.favorites(locale)}>
        <h6>
          <SyledSpan>
            <StarOutlined />
          </SyledSpan>
          {t('header:dashboardContent.myFavorites')}
        </h6>
      </NavLink>
      <NavLink to={privateRoutes.myMessages(locale)}>
        <h6>
          <SyledSpan>
            <MailOutlined />
          </SyledSpan>
          {t('header:dashboardContent.myMessages')}
        </h6>
      </NavLink>
      <NavLink to={privateRoutes.alertsAndMails(locale)}>
        <h6>
          <SyledSpan>
            <SettingOutlined />
          </SyledSpan>
          {t('header:dashboardContent.alertsAndMails')}
        </h6>
      </NavLink>
      <NavLink to={publicRoutes.signin(locale)} onClick={signOut}>
        <h6>
          <SyledSpan>
            <LogoutOutlined />
          </SyledSpan>
          {t('header:dashboardContent.signOut')}
        </h6>
      </NavLink>
      <Divider />
      <NavLink to={publicRoutes.faq(locale)}>
        <h6>
          <SyledSpan>
            <QuestionCircleOutlined />
          </SyledSpan>
          {t('header:dashboardContent.faq')}
        </h6>
      </NavLink>
      <NavLink to={publicRoutes.contactUs(locale)}>
        <h6>
          <SyledSpan>
            <PhoneOutlined />
          </SyledSpan>
          {t('header:dashboardContent.contactUs')}
        </h6>
      </NavLink>
    </>
  );
};

const SyledSpan = styled.span`
  font-size: ${theme.fontsize.h5};
  padding-right: 8px;
`;
