import { ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import React, { useState } from 'react';

import { UserAdmin } from '../../../dtos/admin';
import styled from 'styled-components';

export const DisplayAction = (props: UserAdmin): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        title="Profil utilisateur"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <p>
          <b>{'ID: '}</b>
          {props.id}
        </p>
        <p>
          <b>{'Courriel: '}</b>
          {props.email}
        </p>
        <p>
          <b>{'Prénom: '}</b>
          {props.first_name}
        </p>
        <p>
          <b>{'Nom: '}</b>
          {props.last_name}
        </p>
        <p>
          <b>{'Type de compte: '}</b>
          {props.user_type || 'N/A'}
        </p>
        <p>
          <b>
            <Tooltip title={"CONSULTEZ STRIPE POUR VOUS ASSURER QUE L'UTILISATEUR A UN FORFAIT ACTIF"}>
              {'Historique, action de paiement '}
              <ExclamationCircleOutlined style={{ color: 'red' }} />
            </Tooltip>
          </b>
          <PBreak>{props.last_invoice_paid_log || 'N/A'}</PBreak>
        </p>
      </Modal>
      <Tooltip title={"Voir les informations de l'utilisateur"}>
        <UserOutlined onClick={showModal} />
      </Tooltip>
    </div>
  );
};

const PBreak = styled.p`
  white-space: pre-wrap;
`;
