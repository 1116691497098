import { AdditionalInfo } from './AdditionalInfo';
import { MainInfo } from './MainInfo';
import { NotFound } from '../notFound/NotFound';
import React from 'react';
import { Spin } from 'antd';
import { getFranchise } from '../../services/FranchiseService';
import styled from 'styled-components';
import { useAxiosMiddleware } from '../../services/useAxiosMiddleware';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

export const FranchiseInfo = (): JSX.Element => {
  const axiosMiddleware = useAxiosMiddleware();

  const param = useParams<{ id: string }>();
  const { isLoading, isRefetching, data, isSuccess } = useQuery(
    ['getFullFranchise'],
    () => getFranchise({ franchiseId: param.id || '', axiosMid: axiosMiddleware }),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
  return (
    <ScrollDiv>
      {isLoading || isRefetching ? (
        <Spin size="large" />
      ) : isSuccess ? (
        <StyledDiv>
          <WrapperDiv>
            <MainInfo {...data} />
            <AdditionalInfo {...data} />
          </WrapperDiv>
        </StyledDiv>
      ) : (
        <NotFound />
      )}
    </ScrollDiv>
  );
};

const StyledDiv = styled.div`
  grid-area: main;
  padding: 32px 4vw;
  @media only screen and (max-width: 1400px) {
    padding: 32px 2vw;
  }
`;

const WrapperDiv = styled.div`
  max-width: 1520px;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 1260px) {
    flex-direction: column;
  }
  column-gap: 128px;
`;

const ScrollDiv = styled.div`
  overflow-y: scroll;
  display: flex;
  justify-content: center;
`;
