import { FieldError } from 'rc-field-form/lib/interface';
import { Modal } from 'antd';
import React from 'react';
import { TFunction } from 'react-i18next';

export const ModalError = (
  field: FieldError,
  title: string,
  couldNotBe: string,
  t: TFunction<'createListing'[]>,
): void => {
  Modal.error({
    title: title,
    content: (
      <>
        <p>{t(`createListing:errorsField.${field.name[0]}`) + couldNotBe}</p>
        <p>{field.errors[0]}</p>
      </>
    ),
  });
};
