import { Alert, Button, Form, Input, Spin } from 'antd';
import { ButtonSmall, ButtonStatus } from '../../../common/button/ButtonSmall';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { privateRoutes, publicRoutes } from '../../../router/routes';

import { FieldLength } from '../../../dtos/fields';
import { HelmtSignIn } from '../../../helmets/HelmetSignIn';
import { HttpError } from '../../../dtos/error';
import { LocaleContext } from '../../../router/Router';
import { SignInData } from '../../../dtos/user';
import { UserContext } from '../../../contexts/UserContext';
import { ViewCentered } from '../../../common/views/ViewCentered';
import styled from 'styled-components';
import { theme } from '../../../styles/theme';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const SignIn = (): JSX.Element => {
  const { authTokens, signInMutation } = useContext(UserContext);
  const { locale } = useContext(LocaleContext);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation(['profileForm', 'common']);

  useEffect(() => {
    signInMutation?.reset();
    if (authTokens) {
      navigate(privateRoutes.profile(locale));
    }
  }, []);

  const encodeJsonField = (key: string, value: string): string =>
    encodeURIComponent(key) + '=' + encodeURIComponent(value);

  const onFinish = (data: SignInData) => {
    const formBody = [encodeJsonField('username', data.username), encodeJsonField('password', data.password)];
    for (const credential in data) {
      if (!Object.keys(data).includes(credential)) {
        formBody.push(encodeJsonField(credential, ''));
      }
    }
    if (signInMutation) {
      signInMutation.mutate(formBody.join('&'));
    }
  };

  return (
    <ViewCentered maxWidth="464px">
      <HelmtSignIn />
      <H2Styled>{t('profileForm:signIn.title')}</H2Styled>
      <>
        {signInMutation?.isLoading ? (
          <Spin size="large" />
        ) : (
          <>
            <Form onFinish={onFinish} layout="vertical" requiredMark={'optional'}>
              <Form.Item
                name="username"
                label={<label>{t('profileForm:signIn.username.title')}</label>}
                rules={[
                  {
                    required: true,
                    message: t('profileForm:email'),
                  },
                  {
                    max: FieldLength.MEDIUM,
                    message: t('common:rules.tooLong', { length: FieldLength.MEDIUM }),
                  },
                  { type: 'email', message: t('profileForm:email') },
                ]}
              >
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t('profileForm:signIn.username.placeholder')}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label={<label>{t('profileForm:signIn.password.title')}</label>}
                rules={[
                  {
                    required: true,
                    message: t('profileForm:password'),
                  },
                ]}
              >
                <Input.Password
                  placeholder={t('profileForm:signIn.password.placeholder')}
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                ></Input.Password>
              </Form.Item>

              <ButtonSmall text={t(`profileForm:signIn.signIn`)} status={ButtonStatus.PRIMARY} block={true} />
            </Form>
            <Spacer>
              <Button
                type="link"
                color={theme.color.electricBlue}
                onClick={() => navigate(publicRoutes.emailPasswordForgot(locale))}
              >
                <p>
                  <u>{t('profileForm:signIn.password_forgot')}</u>
                </p>
              </Button>
            </Spacer>
          </>
        )}
      </>

      <Spacer />
      <StyledDiv>
        <div>
          <NoPaddingP>{t('profileForm:signIn.noAccount')} </NoPaddingP>
        </div>
        <div>
          <ButtonSmall
            text={t('profileForm:signIn.signUpNow')}
            status={ButtonStatus.SECONDARY}
            onClick={() => navigate(publicRoutes.signup(locale))}
          />
        </div>
      </StyledDiv>
      <>
        <Spacer />
        {(signInMutation?.error as HttpError)?.response?.status === 401 && (
          <Alert message={t('profileForm:signIn.error.401')} type="error" showIcon />
        )}
        {(signInMutation?.error as HttpError)?.response?.status === 412 && (
          <>
            <Alert message={t('profileForm:signIn.error.412')} type="error" showIcon />
            <Row>
              <Button
                type="link"
                color={theme.color.electricBlue}
                onClick={() =>
                  navigate(publicRoutes.confirmEmailForm(locale), {
                    state: { email: email },
                  })
                }
              >
                <p>
                  <u>{t('profileForm:signIn.error.validate')}</u>
                </p>
              </Button>
            </Row>
          </>
        )}
      </>
    </ViewCentered>
  );
};

const H2Styled = styled.h1`
  margin-top: 32px;
  font-size: ${theme.fontsize.h2} !important;
`;

const Spacer = styled.div`
  margin-top: 24px;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-flow: wrap;
  align-items: center;
`;

const NoPaddingP = styled.p`
  margin: 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 24px;
`;
