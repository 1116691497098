import { Modal, Spin, Tooltip } from 'antd';

import { AxiosError } from 'axios';
import { DollarOutlined } from '@ant-design/icons';
import React from 'react';
import { UserAdmin } from '../../../dtos/admin';
import { clearToBeDeletedForUser } from '../../../services/AdminService';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';

export const ClearToBeDeletedOnListingsAction = (props: UserAdmin) => {
  const axiosMiddleware = useAxiosMiddleware();

  const clearToBeDeletedOnListings = useMutation(clearToBeDeletedForUser, {
    onSuccess: () => {
      Modal.success({ content: "La mention 'A supprimer' a été retirée avec succès" });
    },
    onError: (err) => {
      const errorDetail = (err as AxiosError).response?.data as { detail: string };
      Modal.error({ content: "La mention a supprimer n'a pu être retiré car: " + errorDetail.detail });
    },
  });

  const clearToBeDeletedAction = () => {
    Modal.confirm({
      content: "Êtes-vous sur de vouloir retirer la mention 'À supprimer' des annonces de cet utilisteur?",
      onOk: () =>
        clearToBeDeletedOnListings.mutate({
          axiosMid: axiosMiddleware,
          userId: props.id || '',
        }),
    });
  };

  return (
    <div>
      <Tooltip title={"Retier la mention 'À supprimer' sur les annonces de l'utilisateur"}>
        {clearToBeDeletedOnListings.isLoading ? <Spin /> : <DollarOutlined onClick={() => clearToBeDeletedAction()} />}
      </Tooltip>
    </div>
  );
};
