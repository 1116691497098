import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { privateRoutes, publicRoutes } from '../../router/routes';

import { LocaleContext } from '../../router/Router';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import smallBusinessOwner from './../../img/smallBusinessOwner.webp';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useTranslation } from 'react-i18next';

export const Intro = (): JSX.Element => {
  const { t } = useTranslation('home');
  const { locale } = useContext(LocaleContext);
  const { authTokens } = useContext(UserContext);

  return (
    <StyledSection>
      <Row justify="center">
        <Block>
          <Row justify="center" align="middle">
            <Col lg={15}>
              <TextDiv>
                <h1>
                  {t('home:intro.title1')}
                  <span style={{ color: theme.color.green, fontWeight: 'bolder' }}>{t('home:intro.title2')}</span>
                </h1>
                <b>
                  <p style={{ fontSize: theme.fontsize.h5 }}>{t('home:intro.subtitle')}</p>
                </b>
              </TextDiv>
              <ButtonDiv>
                <FlexGrow>
                  <NavLink to={publicRoutes.businessesForSale(locale)}>
                    <ButtonSmall text={t('home:intro.buttonBuy')} status={ButtonStatus.PRIMARY} block={true} />
                  </NavLink>
                </FlexGrow>
                <FlexGrow>
                  <NavLink to={authTokens ? privateRoutes.myListings(locale) : publicRoutes.sellABusiness(locale)}>
                    <ButtonSmall text={t('home:intro.buttonSell')} status={ButtonStatus.PRIMARY} block={true} />
                  </NavLink>
                </FlexGrow>
                <FlexGrow>
                  <NavLink to={publicRoutes.franchiseSection(locale)}>
                    <ButtonSmall text={t('home:intro.buttonFranchise')} status={ButtonStatus.PRIMARY} block={true} />
                  </NavLink>
                </FlexGrow>
              </ButtonDiv>
            </Col>
            <Col lg={7} />
          </Row>
        </Block>
      </Row>
    </StyledSection>
  );
};

const Block = styled.section`
  padding: 10vw 4vw 6vw 4vw;
  max-width: 1800px;
`;

const StyledSection = styled.div`
  width: 100%;
  background-image: url(${smallBusinessOwner});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  p,
  h1 {
    color: ${theme.color.white};
  }

  h1 {
    font-size: 45px !important;
    @media only screen and (max-width: 1280px) {
      font-size: 36px !important;
    }
  }
  p {
    font-size: 30px !important;
    @media only screen and (max-width: 1280px) {
      font-size: 22px !important;
    }
  }
`;

const TextDiv = styled.div`
  width: 100%;
  max-width: 1200px;
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 20px;
`;

const FlexGrow = styled.div`
  width: 216px;
`;
