import React, { useContext } from 'react';

import { FaqEN } from './FaqEN';
import { FaqFR } from './FaqFR';
import { Footer } from '../../components/footer/Footer';
import { HelmetFAQ } from '../../helmets/HelmetFAQ';
import { LocaleContext } from '../../router/Router';
import styled from 'styled-components';

export const FaqPage = (): JSX.Element => {
  const { locale } = useContext(LocaleContext);
  return (
    <>
      <HelmetFAQ />
      <Scroller>
        <StyledGrid>
          <StyledDiv>
            <StyledH>FAQs</StyledH>
          </StyledDiv>
          {locale === 'fr' ? <FaqFR /> : <FaqEN />}
        </StyledGrid>
        <Footer />
      </Scroller>
    </>
  );
};

const StyledGrid = styled.div`
  grid-area: main;
  padding-left: 4vw;
  padding-right: 4vw;
  @media only screen and (max-width: 1400px) {
    padding-left: 2vw;
    padding-right: 2vw;
  }
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const StyledH = styled.h1`
  margin-top: 32px;
  margin-bottom: 0;
`;

const StyledDiv = styled.div`
  text-align: center;
`;

const Scroller = styled.div`
  overflow: auto;
`;
