import { Form, Modal } from 'antd';
import React, { useState } from 'react';
import Upload, { UploadFile } from 'antd/lib/upload';

import { PicturePath } from '../../../dtos/uploadImage';
import { PlusOutlined } from '@ant-design/icons';
import { UploadProps } from 'antd/lib/upload/interface';
import { getUploadUrl } from '../../../services/ListingService';
import styled from 'styled-components';
import { theme } from '../../../styles/theme';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

const getBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

export const FormImage = (props: {
  fileList: UploadFile<File>[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile<File>[]>>;
  dirtyImages: boolean;
  setDirtyImages: React.Dispatch<React.SetStateAction<boolean>>;
  content: number;
}): JSX.Element => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const axiosMid = useAxiosMiddleware();
  const { t } = useTranslation('createListing');

  const handleCancel = () => setPreviewOpen(false);

  // eslint-disable-next-line
  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const upLoadImageUrlMutaltion = useMutation(getUploadUrl, {
    onSuccess: (url) => {
      props.setFileList((prevList) => {
        prevList[prevList.length - 1].name = url;
        prevList[prevList.length - 1].status = 'success';
        return prevList;
      });
    },
  });

  const getUploadUrlMutation = () => {
    upLoadImageUrlMutaltion.mutate({ axiosMid: axiosMid, picturePath: PicturePath.LITING_PICTURES });
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    props.setDirtyImages(true);
    props.setFileList(newFileList);
  };

  const uploadButton = (
    <div>
      <p>
        <PlusOutlined />
      </p>
      {t('formAdditional.pictures.add')}
    </div>
  );

  return (
    <Form.Item label={t('formAdditional.pictures.title')}>
      <Form.Item name={'pictures'}>
        <Upload
          listType={'picture-card'}
          fileList={props.fileList}
          onPreview={handlePreview}
          customRequest={getUploadUrlMutation}
          onChange={handleChange}
          accept={'.png,.jpg'}
        >
          {props.fileList.length >= props.content ? null : uploadButton}
        </Upload>
      </Form.Item>
      {props.dirtyImages && <PDirtry>{t('formAdditional.pictures.dirty')}</PDirtry>}

      <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
        <LargeImg alt={t('formAdditional.pictures.dirty')} src={previewImage} />
      </Modal>
    </Form.Item>
  );
};

const PDirtry = styled.p`
  color: ${theme.color.errorRed};
`;

const LargeImg = styled.img`
  width: 100%;
  padding: 16px;
`;
