import { CreditCardFilled, DollarCircleOutlined, PushpinOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { StyledH6, TextDescription } from '../descriptions/TextDescription';

import { IconDescription } from '../descriptions/IconDescription';
import { Image } from 'antd';
import { Listing } from '../../../dtos/listingCreation';
import { LocaleContext } from '../../../router/Router';
import { StyledBox } from '../FullListing';
import { currencyFormatter } from '../fullListingHelper';
import styled from 'styled-components';
import { tCustom } from '../../../services/TranslationService';
import { theme } from '../../../styles/theme';
import { useTranslation } from 'react-i18next';

type BasicInfoProps = {
  id: string;
  listing: Listing;
};

export const BasicInfo = (props: BasicInfoProps): JSX.Element => {
  const { t } = useTranslation(['fullListing', 'industries', 'regions']);
  const { locale } = useContext(LocaleContext);
  const fromater = new Intl.NumberFormat('fr-CA');

  const formatPrice = (value: number | undefined): string => {
    if (value === undefined || value === null) {
      return '-';
    } else {
      return fromater.format(Math.floor(value));
    }
  };

  const [isSamllScreen, setIsSamllScreen] = useState(window.innerWidth < 1360);

  const handleResize = () => {
    if (window.innerWidth < 1360) {
      setIsSamllScreen(true);
    } else {
      setIsSamllScreen(false);
    }
  };

  const bodyEmail = () => {
    return `${tCustom(props.listing.basic_info.about_company, locale)!} ${window.location.href}`;
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.addEventListener('resize', handleResize);
    };
  });

  return (
    <>
      <h3>{tCustom(props.listing.basic_info?.listing_title, locale)}</h3>
      <Image.PreviewGroup>
        <ImgDiv>
          {props.listing.additional_info.video && (
            <iframe
              width={isSamllScreen ? 188 : 284}
              height={isSamllScreen ? 104 : 156}
              src={
                'https://www.youtube.com/embed/' +
                props.listing.additional_info.video.split('/')?.[
                  props.listing.additional_info.video.split('/')?.length - 1
                ]
              }
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
          {props.listing.additional_info.picture_primary && (
            <Image
              width={isSamllScreen ? 104 : 156}
              height={isSamllScreen ? 104 : 156}
              src={props.listing.additional_info.picture_primary}
            />
          )}
          {(props.listing.additional_info.pictures_secondary || []).map((image, key) => {
            return <Image key={key} width={isSamllScreen ? 104 : 156} height={isSamllScreen ? 104 : 156} src={image} />;
          })}
        </ImgDiv>
      </Image.PreviewGroup>
      <FlexDivJustified>
        <h4>{t('fullListing:basic_info.title')}</h4>
        <FlexDivCentered>
          <IconShare>
            <a
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&text=${tCustom(
                props.listing.basic_info.about_company,
                locale,
              )!}`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
              </svg>
            </a>
          </IconShare>
          <IconShare>
            <LinkFB
              href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
              target="_blank"
              rel="noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
              </svg>
            </LinkFB>
          </IconShare>
          <IconShare>
            <a
              href={`mailto:your@recipient?subject=${tCustom(
                props.listing.basic_info?.listing_title,
                locale,
              )}&body=${bodyEmail()}`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M64 112c-8.8 0-16 7.2-16 16l0 22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1l0-22.1c0-8.8-7.2-16-16-16L64 112zM48 212.2L48 384c0 8.8 7.2 16 16 16l384 0c8.8 0 16-7.2 16-16l0-171.8L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z" />
              </svg>
            </a>
          </IconShare>
        </FlexDivCentered>
      </FlexDivJustified>
      <StyledBox>
        <FlexDiv>
          {props.listing.basic_info.asking_price ? (
            <IconDescription
              description={t('fullListing:basic_info.asking_price')}
              content={formatPrice(props.listing.basic_info.asking_price)}
              icon={<CreditCardFilled />}
            />
          ) : (
            <IconDescription
              description={t('fullListing:basic_info.asking_price')}
              content={t('fullListing:basic_info.open_to_offers')}
              icon={<CreditCardFilled />}
            />
          )}
          {props.listing.financial_details.revenue && (
            <IconDescription
              description={t('fullListing:basic_info.revenue')}
              content={currencyFormatter(props.listing.financial_details.revenue, 1)}
              icon={<DollarCircleOutlined />}
            />
          )}
          {props.listing.financial_details.ebitda && (
            <IconDescription
              description={t('fullListing:basic_info.ebitda')}
              content={currencyFormatter(props.listing.financial_details.ebitda, 1)}
              icon={<DollarCircleOutlined />}
            />
          )}
          {props.listing.financial_details.net_income && (
            <IconDescription
              description={t('fullListing:basic_info.net_income')}
              content={currencyFormatter(props.listing.financial_details.net_income, 1)}
              icon={<DollarCircleOutlined />}
            />
          )}

          {props.listing.additional_info.number_employees && (
            <IconDescription
              description={t('fullListing:basic_info.number_employees')}
              content={props.listing.additional_info.number_employees.toString()}
            />
          )}
          {props.listing.additional_info.date_founded && (
            <IconDescription
              description={t('fullListing:basic_info.date_founded')}
              content={props.listing.additional_info.date_founded.toString()}
            />
          )}
        </FlexDiv>
        <FlexDiv>
          {props.listing.basic_info.region_1?.[0] && (
            <IconDescription
              description={t('fullListing:basic_info.region_1')}
              content={
                t(`regions:regions.${props.listing.basic_info.region_1?.[0]}`) +
                (props.listing.basic_info.region_1?.[1]
                  ? ',\n' + t(`regions:regions.${props.listing.basic_info.region_1?.[1]}`)
                  : '')
              }
              icon={<PushpinOutlined />}
            />
          )}
          {props.listing.basic_info.region_2?.[0] && (
            <IconDescription
              description={t('fullListing:basic_info.region_2')}
              content={
                t(`regions:regions.${props.listing.basic_info.region_2?.[0]}`) +
                (props.listing.basic_info.region_2?.[1]
                  ? ',\n' + t(`regions:regions.${props.listing.basic_info.region_2?.[1]}`)
                  : '')
              }
              icon={<PushpinOutlined />}
            />
          )}
          {props.listing.basic_info.region_3?.[0] && (
            <IconDescription
              description={t('fullListing:basic_info.region_3')}
              content={
                t(`regions:regions.${props.listing.basic_info.region_3?.[0]}`) +
                (props.listing.basic_info.region_3?.[1]
                  ? ',\n' + t(`regions:regions.${props.listing.basic_info.region_3?.[1]}`)
                  : '')
              }
              icon={<PushpinOutlined />}
            />
          )}
        </FlexDiv>
        <FlexDiv>
          {props.listing.basic_info.industry_1?.[0] && (
            <IconDescription
              description={t('fullListing:basic_info.industries_1')}
              content={
                t(`industries:industries.${props.listing.basic_info.industry_1?.[0]}`) +
                (props.listing.basic_info.industry_1?.[1]
                  ? ',\n' + t(`industries:industries.${props.listing.basic_info.industry_1?.[1]}`)
                  : '')
              }
            />
          )}
          {props.listing.basic_info.industry_2?.[0] && (
            <IconDescription
              description={t('fullListing:basic_info.industries_2')}
              content={
                t(`industries:industries.${props.listing.basic_info.industry_2?.[0]}`) +
                (props.listing.basic_info.industry_2?.[1]
                  ? ',\n' + t(`industries:industries.${props.listing.basic_info.industry_2?.[1]}`)
                  : '')
              }
            />
          )}
          {props.listing.basic_info.industry_3?.[0] && (
            <IconDescription
              description={t('fullListing:basic_info.industries_3')}
              content={
                t(`industries:industries.${props.listing.basic_info.industry_3?.[0]}`) +
                (props.listing.basic_info.industry_3?.[1]
                  ? ',\n' + t(`industries:industries.${props.listing.basic_info.industry_3?.[1]}`)
                  : '')
              }
            />
          )}
        </FlexDiv>
        {tCustom(props.listing.basic_info.about_company, locale) && (
          <TextDescription
            title={t('fullListing:basic_info.about_company')}
            // eslint-disable-next-line
            content={tCustom(props.listing.basic_info.about_company, locale)!}
          />
        )}
        {tCustom(props.listing.basic_info.competitive_advantages, locale) && (
          <TextDescription
            title={t('fullListing:basic_info.competitive_advantages')}
            // eslint-disable-next-line
            content={tCustom(props.listing.basic_info.competitive_advantages, locale)!}
          />
        )}
        {tCustom(props.listing.basic_info.customer_base, locale) && (
          <TextDescription
            title={t('fullListing:basic_info.customer_base')}
            // eslint-disable-next-line
            content={tCustom(props.listing.basic_info.customer_base, locale)!}
          />
        )}
        {tCustom(props.listing.basic_info.competitors, locale) && (
          <TextDescription
            title={t('fullListing:basic_info.competitors')}
            // eslint-disable-next-line
            content={tCustom(props.listing.basic_info.competitors, locale)!}
          />
        )}
        {props.listing.basic_info.patent && (
          <TextDescription title={t('fullListing:basic_info.patent')} content={props.listing.basic_info.patent} />
        )}
        {props.listing.basic_info.license && (
          <TextDescription title={t('fullListing:basic_info.license')} content={props.listing.basic_info.license} />
        )}
        {props.listing.additional_info.website && (
          <>
            <StyledH6>
              <u>{t('fullListing:basic_info.website')}</u>
            </StyledH6>
            <PBlue>{props.listing.additional_info.website}</PBlue>
          </>
        )}
      </StyledBox>
    </>
  );
};

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FlexDivCentered = styled.div`
  display: flex;
  align-items: center;

  a {
    width: 15px;
    display: block;
  }
`;

const FlexDivJustified = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const PBlue = styled.p`
  color: ${theme.color.electricBlue};
`;

const IconShare = styled.div`
  margin-left: 15px;
`;

const LinkFB = styled.a`
  width: 10px !important;
`;

const ImgDiv = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 4px;
  margin-bottom: 16px;

  img {
    object-fit: cover !important;
  }
`;
