import { BankOutlined, FileAddOutlined } from '@ant-design/icons';
import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { Col, Modal, Radio, RadioChangeEvent, Row } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import React, { useContext, useState } from 'react';

import { LocaleContext } from '../../router/Router';
import { StripeProduct } from '../../dtos/listingCreation';
import { getInitialListing } from '../createListing/helpers';
import { privateRoutes } from '../../router/routes';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const BrokerCard = (props: { product: StripeProduct }) => {
  const { t } = useTranslation('myListings'),
    { locale } = useContext(LocaleContext),
    navigate = useNavigate(),
    [modalOpen, setModalOpen] = useState(false),
    [listingType, setListingType] = useState();

  const handleOk = () => {
    if (listingType === undefined) {
      return;
    }

    const initialListing = getInitialListing({
      content: props.product[listingType].content,
      priority_exposure: props.product[listingType].priority_exposure,
      featured: props.product[listingType].featured,
      email_freq: props.product[listingType].email_freq,
    });

    if (!initialListing) {
      return;
    }

    navigate(privateRoutes.createListing(locale), {
      state: initialListing,
    });
    setModalOpen(false);
  };

  const onChange = (e: RadioChangeEvent) => {
    setListingType(e.target.value);
  };

  return (
    <>
      <StyledModal width={400} open={modalOpen} onOk={() => handleOk()} onCancel={() => setModalOpen(false)}>
        <StyledColumn>
          <h2>
            <b>{t('subscriptionCard.newListing')}</b>
          </h2>
          <div>
            {props.product &&
              props.product.map((listing, key) => (
                <p key={key}>
                  {!listing.priority_exposure ? t('subscriptionCard.standard') : t('subscriptionCard.privilege')}
                  {listing.allowed_listing_count}
                </p>
              ))}
          </div>
          <Radio.Group onChange={onChange} value={listingType}>
            {props.product.map((listing, key) => {
              return (
                <Radio key={key} value={key} disabled={listing.allowed_listing_count <= 0}>
                  {props.product[key].priority_exposure ? t('subscriptionCard.priv') : t('subscriptionCard.stand')}
                </Radio>
              );
            })}
          </Radio.Group>
        </StyledColumn>
      </StyledModal>
      <Row align={'top'} gutter={[32, 32]}>
        <Col>
          <StyledBox>
            {props.product.map((listing, key) => (
              <p key={key}>
                {!listing.priority_exposure ? t('subscriptionCard.standard') : t('subscriptionCard.privilege')}
                {listing.allowed_listing_count}
              </p>
            ))}
          </StyledBox>
        </Col>
        <Col>
          {props.product !== undefined && props.product !== null && (
            <FlexGrow>
              <ButtonSmall
                onClick={() => setModalOpen(true)}
                status={ButtonStatus.PRIMARY}
                icon={<FileAddOutlined />}
                text={t('myListings:createListing')}
                block={true}
              />
            </FlexGrow>
          )}
          <FlexGrow>
            <NavLink to={privateRoutes.legalKit(locale)}>
              <ButtonSmall
                status={ButtonStatus.SECONDARY}
                text={t('myListings:legalKit')}
                icon={<BankOutlined />}
                block={true}
              />
            </NavLink>
          </FlexGrow>
        </Col>
      </Row>
    </>
  );
};

const StyledModal = styled(Modal)`
  text-align: center;
  h2,
  p {
    margin: 0;
  }
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledBox = styled.div`
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 8px;
`;

const FlexGrow = styled.div`
  width: 248px;
  margin-bottom: 8px;
`;
