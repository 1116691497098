import { FinancialData } from '../../../dtos/listingCreation';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const IncomeStatementTable = (props: { listing: FinancialData }): JSX.Element => {
  const { t } = useTranslation('fullListing');
  const fromater = new Intl.NumberFormat('fr-CA');

  const toMargin = (value: number | undefined, revenue: number | undefined): string => {
    if (revenue === undefined || value === undefined || revenue === null || value === null || revenue === 0) {
      return '-';
    } else {
      return String(((value / revenue) * 100).toFixed() + ' %');
    }
  };
  const formatNumber = (value: number | undefined): string => {
    if (value === undefined || value === null) {
      return '-';
    } else {
      return fromater.format(Math.floor(value));
    }
  };

  return (
    <StyleTable>
      <tbody>
        <tr>
          <td>
            <b>
              <u>{t('fullListing:financial_details.incomeStatement')}</u>
            </b>
          </td>
          <td>{t('fullListing:financial_details.mostRecentFiscal')}</td>
        </tr>
        <tr>
          <td>{t('fullListing:financial_details.revenue')}</td>
          <td>{formatNumber(props.listing.revenue)}</td>
        </tr>
        <tr>
          <td>{t('fullListing:financial_details.ebitda')}</td>
          <td>{formatNumber(props.listing.ebitda)}</td>
        </tr>
        <tr>
          <td>
            <DivMargin>{t('fullListing:financial_details.ebitdaMargin')}</DivMargin>
          </td>
          <td>{toMargin(props.listing.ebitda, props.listing.revenue)}</td>
        </tr>
        <tr>
          <td>{t('fullListing:financial_details.netIncome')}</td>
          <td>{formatNumber(props.listing.net_income)}</td>
        </tr>
        <tr>
          <td>
            <DivMargin>{t('fullListing:financial_details.netIncomeMargin')}</DivMargin>
          </td>
          <td>{toMargin(props.listing.net_income, props.listing.revenue)}</td>
        </tr>
      </tbody>
    </StyleTable>
  );
};

const StyleTable = styled.table`
  border-collapse: collapse;
  color: black;
  margin-bottom: 32px;
  width: 100%;

  td {
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #ececee;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
  }

  td:nth-child(2),
  td:nth-child(3) {
    text-align: right;
  }
`;

const DivMargin = styled.div`
  margin-left: 16px;
`;
