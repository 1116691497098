import * as messageService from '../../services/MessageService';

import React, { useEffect, useState } from 'react';
import Table, { ColumnsType } from 'antd/lib/table';

import { Message } from './Message';
import { MessageDTO } from '../../dtos/myMessages';
import { useAxiosMiddleware } from '../../services/useAxiosMiddleware';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

const columns: ColumnsType<MessageDTO> = [
  {
    render: (_, message) => <Message {...message} />,
  },
];

export const ReceivedMessages = () => {
  const axiosMiddleware = useAxiosMiddleware(),
    [tableData, setTableData] = useState<MessageDTO[]>([]),
    [totalMessages, setTotalMessages] = useState<number>(0),
    [currPage, setCurrPage] = useState<number>(1),
    { t } = useTranslation('myMessages');

  const query = useQuery(
    ['messageReceivedSearchQuery', currPage],
    () =>
      messageService.getMessages({
        axiosMid: axiosMiddleware,
        pageNumber: currPage - 1,
        isSender: false,
      }),
    {
      refetchOnWindowFocus: false,
      onError: () => {
        setTableData([]);
        setTotalMessages(0);
      },
    },
  );

  useEffect(() => {
    if (query.data) {
      setTableData(query.data.messages);
      setTotalMessages(query.data.total);
    } else {
      setTableData([]);
      setTotalMessages(0);
    }
  }, [query.data]);

  const getNextPage = (page: number) => {
    setCurrPage(page);
  };

  if (!query.isFetching && tableData.length === 0) {
    return <p>{t('receivedMessages.noneReceived')}</p>;
  }

  return (
    <Table
      loading={query.isFetching}
      showHeader={false}
      dataSource={tableData}
      columns={columns}
      pagination={{
        pageSize: 20,
        showSizeChanger: false,
        total: totalMessages,
        current: currPage,
        onChange: (page) => {
          getNextPage(page);
        },
      }}
      rowKey={(message) => message._id}
    />
  );
};
