import React, { useContext, useEffect } from 'react';

import { LocaleContext } from '../../router/Router';
import { SignedInMenu } from '../../components/header/SignedInMenu';
import { UserContext } from '../../contexts/UserContext';
import { privateRoutes } from '../../router/routes';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useNavigate } from 'react-router-dom';

type ViewDashboardProps = {
  children: React.ReactNode;
};

export const ViewDashboard = ({ children }: ViewDashboardProps): JSX.Element => {
  const { userType } = useContext(UserContext);
  const { locale } = useContext(LocaleContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (userType === '' || userType === undefined) {
      navigate(privateRoutes.onbarding(locale));
    }
  }, []);

  return (
    <StyledContainer>
      <StyledMenu>
        <SignedInMenu />
      </StyledMenu>
      <Content>{children}</Content>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  grid-area: main;
  padding-left: 4vw;
  padding-right: 4vw;
  @media only screen and (max-width: 1400px) {
    padding-left: 2vw;
    padding-right: 2vw;
  }
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'dashboard content content content';
  @media only screen and (max-width: 1200px) {
    grid-template-columns: auto;
    grid-template-areas: 'content';
  }
  column-gap: 16px;
  overflow: overlay;
`;

const StyledMenu = styled.div`
  grid-area: dashboard;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
  max-width: 345px;
  padding-top: 2px;
  border-width: 0px 1px;
  border-style: solid;
  border-color: ${theme.color.lightGrey};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;

  h6 {
    margin-bottom: 0;
  }

  h6:hover {
    color: ${theme.color.green} !important;
  }

  .active {
    h6 {
      color: ${theme.color.green} !important;
    }
  }
`;

const Content = styled.div`
  grid-area: content;
  margin-top: 32px;
  margin-bottom: 32px;
`;
