import React, { useContext } from 'react';

import { FranchiseDTO } from '../../dtos/franchise';
import { Image } from 'antd';
import { LocaleContext } from '../../router/Router';
import styled from 'styled-components';
import { tCustom } from '../../services/TranslationService';

export const MainInfo = (props: FranchiseDTO) => {
  const { locale } = useContext(LocaleContext);

  const returnImage = (picture_fr?: string, picture_en?: string) => {
    const res = [];
    if (picture_fr) {
      res.push(picture_fr);
    }
    if (picture_en) {
      res.push(picture_en);
    }
    return locale === 'fr' ? res?.[0] : res?.[res?.length - 1];
  };

  return (
    <StyledDiv>
      <h3>{props.title}</h3>
      <Image width={'100%'} src={returnImage(props.picture1, props.picture1_en)} preview={false} />
      {props.text1 && <PBreak>{tCustom(props.text1, locale)}</PBreak>}
      <Image width={'100%'} src={returnImage(props.picture2, props.picture2_en)} preview={false} />
      {props.text2 && <PBreak>{tCustom(props.text2, locale)}</PBreak>}
      <Image width={'100%'} src={returnImage(props.picture3, props.picture3_en)} preview={false} />
      {props.text3 && <PBreak>{tCustom(props.text3, locale)}</PBreak>}
      <Image width={'100%'} src={returnImage(props.picture4, props.picture4_en)} preview={false} />
      {props.text4 && <PBreak>{tCustom(props.text4, locale)}</PBreak>}
      <Image width={'100%'} src={returnImage(props.picture5, props.picture5_en)} preview={false} />
      {props.text5 && <PBreak>{tCustom(props.text5, locale)}</PBreak>}
      <Image width={'100%'} src={returnImage(props.picture6, props.picture6_en)} preview={false} />
      {props.text6 && <PBreak>{tCustom(props.text6, locale)}</PBreak>}
      <Image width={'100%'} src={returnImage(props.picture7, props.picture7_en)} preview={false} />
      {props.text7 && <PBreak>{tCustom(props.text7, locale)}</PBreak>}
      <Image width={'100%'} src={returnImage(props.picture8, props.picture8_en)} preview={false} />
      {props.text8 && <PBreak>{tCustom(props.text8, locale)}</PBreak>}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  h3 {
    margin-bottom: 0;
  }
`;

const PBreak = styled.p`
  white-space: pre-line;
`;
