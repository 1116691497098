import * as BrokerService from '../../services/BrokerService';

import { BrokerDTO, BrokerForm } from '../../dtos/broker';
import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { Checkbox, Form, Input, InputNumber, Modal, Spin, Switch } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { calculateBilingual, toBrokerForm, toBrokerProfile } from './helper';
import { useLocation, useNavigate } from 'react-router-dom';

import { FieldLength } from '../../dtos/fields';
import { LocaleContext } from '../../router/Router';
import { PicturePath } from '../../dtos/uploadImage';
import TextArea from 'antd/lib/input/TextArea';
import { UploadImage } from '../../common/uploadImage/UploadImage';
import { UserContext } from '../../contexts/UserContext';
import { ViewForm } from '../../common/views/ViewForm';
import { getUserGroupFromToken } from '../../router/RequireAuth';
import { privateRoutes } from '../../router/routes';
import styled from 'styled-components';
import { useAxiosMiddleware } from '../../services/useAxiosMiddleware';
import { useIamgeCompressor } from '../../services/useImageCompressor';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const BrokerFileForm = () => {
  const { userType, authTokens } = useContext(UserContext),
    navigate = useNavigate(),
    { locale } = useContext(LocaleContext),
    [compressImage, imageCompressorLoading] = useIamgeCompressor();

  useEffect(() => {
    if (authTokens && getUserGroupFromToken(authTokens) === 'admin') {
      return;
    } else if (userType !== 'broker') {
      navigate(privateRoutes.myListings(locale));
    }
  }, []);

  const { t } = useTranslation('brokerFileForm'),
    { tokenErrorHandler } = useContext(UserContext),
    { state } = useLocation(),
    [isBilingual, setIsBilingual] = useState(calculateBilingual(state as BrokerDTO)),
    [brokerForm] = Form.useForm<BrokerForm>(),
    [dirtyProfile, setDirtyProfile] = useState(false),
    [dirtyLogo, setDirtyLogo] = useState(false),
    [saveIsLoading, setSaveIsLoading] = useState(false),
    axiosMid = useAxiosMiddleware();

  const onChange = (checked: boolean) => {
    setIsBilingual(checked);
  };

  const fr = isBilingual && <span> (fr)</span>;
  const en = isBilingual && <span> (en)</span>;

  const postPhotoMutation = useMutation(BrokerService.postImage);

  const postPhotoRoutine = async (fileField: string) => {
    const fileObject = brokerForm.getFieldValue(fileField);
    if (fileObject === undefined) {
      return;
    }
    const fileList = fileObject.fileList;
    if (fileList.length === 1 && fileList[0].status == 'success') {
      await postPhotoMutation.mutateAsync(
        {
          file: ((await compressImage(fileList[0].originFileObj as File)) as File) || '',
          secureUrl: fileList[0].name || '',
        },
        {
          onSuccess: () => {
            fileList[0].status = 'done';
            if (fileList[0].name && fileList[0].name?.includes('?')) {
              // eslint-disable-next-line
              fileList[0].url = fileList[0].name!.split('?')[0];
              fileList[0].name = '';
            }
            brokerForm.setFieldValue(fileField, fileObject);
          },
        },
      );
    }
  };

  const brokerUpdateMutation = useMutation(BrokerService.updateBrokerInfo, {
    onSuccess: () => {
      Modal.confirm({
        content: t('updateConfirmed'),
        onOk: () => navigate(privateRoutes.myBrokerInfo(locale)),
      });
    },
    onError: tokenErrorHandler,
  });

  const onFinish = async () => {
    setSaveIsLoading(true);
    await postPhotoRoutine('profile_picture');
    setDirtyProfile(false);

    await postPhotoRoutine('company_logo');
    setDirtyLogo(false);

    brokerUpdateMutation.mutate({
      axiosMid: axiosMid,
      data: { _id: (state as BrokerDTO)?._id, ...toBrokerProfile(brokerForm.getFieldsValue()) },
    });
    setSaveIsLoading(false);
  };

  return (
    <ViewForm maxWidth={'560px'}>
      <StyledH3>{t('brokerFile')}</StyledH3>
      <StyledDiv>
        <Spin size={'large'} spinning={brokerUpdateMutation.isLoading || imageCompressorLoading || saveIsLoading}>
          <Form
            layout="vertical"
            form={brokerForm}
            initialValues={{ isBilingual: isBilingual, ...toBrokerForm(state as BrokerDTO) }}
            onFinish={onFinish}
          >
            <Form.Item valuePropName="checked" name="isBilingual" label={<label>{t('fillFrEn')}</label>}>
              <Switch onChange={onChange} />
            </Form.Item>
            <UploadImage
              service={BrokerService.getUploadUrl}
              picturePath={PicturePath.BROKER_PROFILE_PICTURES}
              state={[dirtyProfile, setDirtyProfile]}
              currForm={brokerForm}
              fileField={'profile_picture'}
            />
            <UploadImage
              service={BrokerService.getUploadUrl}
              picturePath={PicturePath.BROKER_LOGO_PICTURES}
              state={[dirtyLogo, setDirtyLogo]}
              currForm={brokerForm}
              fileField={'company_logo'}
            />
            <Form.Item
              name="broker_title_fr"
              label={
                <label>
                  {t('title')}
                  {fr}
                </label>
              }
              rules={[
                {
                  required: true,
                  message: t('requiredFiled'),
                },
                {
                  max: FieldLength.SHORT,
                  message: t('errors.ruleTooLong', { length: FieldLength.SHORT }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            {isBilingual && (
              <Form.Item
                name="broker_title_en"
                label={
                  <label>
                    {t('title')}
                    {en}
                  </label>
                }
                rules={[
                  {
                    max: FieldLength.SHORT,
                    message: t('errors.ruleTooLong', { length: FieldLength.SHORT }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
            <Form.Item
              name="broker_expertise_fr"
              label={
                <label>
                  {t('expertise')}
                  {fr}
                </label>
              }
              rules={[
                {
                  max: FieldLength.SHORT,
                  message: t('errors.ruleTooLong', { length: FieldLength.SHORT }),
                },
              ]}
              tooltip={<LabelBreak>{t('expertiseHelp')}</LabelBreak>}
            >
              <Input />
            </Form.Item>
            {isBilingual && (
              <Form.Item
                name="broker_expertise_en"
                label={
                  <label>
                    {t('expertise')}
                    {en}
                  </label>
                }
                rules={[
                  {
                    max: FieldLength.SHORT,
                    message: t('errors.ruleTooLong', { length: FieldLength.SHORT }),
                  },
                ]}
                tooltip={<LabelBreak>{t('expertiseHelp')}</LabelBreak>}
              >
                <Input />
              </Form.Item>
            )}
            <Form.Item name="years_of_experience" label={<label>{t('years')}</label>}>
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="desctiption_fr"
              label={
                <label>
                  {t('description')}
                  {fr}
                </label>
              }
              rules={[
                {
                  required: true,
                  message: t('requiredFiled'),
                },
                {
                  max: FieldLength.VERY_LONG,
                  message: t('errors.ruleTooLong', { length: FieldLength.VERY_LONG }),
                },
              ]}
            >
              <TextArea rows={18} />
            </Form.Item>
            {isBilingual && (
              <Form.Item
                name="desctiption_en"
                label={
                  <label>
                    {t('description')}
                    {en}
                  </label>
                }
                rules={[
                  {
                    max: FieldLength.VERY_LONG,
                    message: t('errors.ruleTooLong', { length: FieldLength.VERY_LONG }),
                  },
                ]}
              >
                <TextArea rows={18} />
              </Form.Item>
            )}
            <Form.Item
              name="broker_email"
              label={<label>{t('email')}</label>}
              rules={[
                {
                  required: true,
                  message: t('requiredFiled'),
                },
                {
                  max: FieldLength.SHORT,
                  message: t('errors.ruleTooLong', { length: FieldLength.SHORT }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="broker_phone_number"
              label={<label>{t('phone')}</label>}
              rules={[
                {
                  message: t('phoneError'),
                  required: false,
                  validator: (_, value) => {
                    if (value === null || value === undefined || (1111111111 <= value && value <= 9999999999)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject();
                    }
                  },
                },
              ]}
              validateFirst
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="linkedin"
              label="Linkedin"
              rules={[
                {
                  pattern: new RegExp('^(https://www.linkedin.com).*$'),
                  message: t('linkedIn'),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="region"
              label={<label>{t('region')}</label>}
              rules={[
                {
                  max: FieldLength.SHORT,
                  message: t('errors.ruleTooLong', { length: FieldLength.SHORT }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="company_name"
              rules={[
                {
                  required: true,
                  message: t('requiredFiled'),
                },
                {
                  max: FieldLength.SHORT,
                  message: t('errors.ruleTooLong', { length: FieldLength.SHORT }),
                },
              ]}
              label={<label>{t('brokerGroup')}</label>}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="company_website"
              rules={[
                {
                  pattern: new RegExp('^(https://).*$'),
                  message: t('website'),
                },
              ]}
              label={<label>{t('brokerWebsite')}</label>}
            >
              <Input />
            </Form.Item>
            <Form.Item name="show_broker_profile" valuePropName="checked">
              <StyledCheckbox>{<label>{t('displayMyProfile')}</label>}</StyledCheckbox>
            </Form.Item>
            <ButtonDiv>
              <ButtonSmall text={t('save')} status={ButtonStatus.PRIMARY} />
            </ButtonDiv>
          </Form>
        </Spin>
      </StyledDiv>
    </ViewForm>
  );
};

const StyledH3 = styled.h3`
  margin-top: 32px;
`;

const StyledCheckbox = styled(Checkbox)`
  width: 100%;
  color: red;
`;

const StyledDiv = styled.div`
  .ant-picker,
  .ant-input-number,
  .ant-space,
  textarea {
    width: 100%;
  }
  .ant-input-number-handler-wrap {
    visibility: hidden;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LabelBreak = styled.label`
  white-space: pre-line;
`;
