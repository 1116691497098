import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { FranchiseDTO, FranchiseInCache } from '../../dtos/franchise';
import React, { useState } from 'react';

import { AddToMyList } from '../franchiseSection/AddToMyList';
import { FranchiseContactForm } from './FranchiseContactForm';
import { Image } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useTranslation } from 'react-i18next';

export const AdditionalInfo = (props: FranchiseDTO) => {
  const { t } = useTranslation('franchiseInfo');
  const [visibleForm, setVisibleForm] = useState(false);

  const contactFranchisor = () => {
    const prevList = JSON.parse(localStorage.getItem('franchiseList') || '[]');
    if (!prevList.map((franchise: FranchiseInCache) => franchise._id).includes(props._id)) {
      prevList.push({
        _id: props._id,
        title: props.title,
        franchisor_email: props.franchisor_email,
      });
      localStorage.setItem('franchiseList', JSON.stringify(prevList));
    }
    setVisibleForm(true);
  };

  const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };
  return (
    <StyledDiv>
      <StyledImg src={props.logo} height={'240px'} preview={false} />
      <StyledBox>
        <h3>{t('franchiseInfo.contact')}</h3>
        {props.franchise_type && (
          <Element>
            <B50>{t('franchiseInfo.type')}</B50>
            <p>{t(`franchiseInfo.typeCode.${props.franchise_type}`)}</p>
          </Element>
        )}
        {props.cash_down && (
          <Element>
            <B50>{t('franchiseInfo.cashDown')}</B50>
            <p>
              {numberWithCommas(props.cash_down)}
              {' $'}
            </p>
          </Element>
        )}
        {props.total_investment_min && (
          <Element>
            <B50>{t('franchiseInfo.totalInvestment')}</B50>
            <p>
              {numberWithCommas(props.total_investment_min) + ' $'}
              {props.total_investment_max ? (
                <>
                  {t('franchiseInfo.to')}
                  {numberWithCommas(props.total_investment_max) + ' $'}
                </>
              ) : (
                t('franchiseInfo.orMore')
              )}
            </p>
          </Element>
        )}
        <ButtonSmall
          status={ButtonStatus.PRIMARY}
          text={t('franchiseInfo.contactButton')}
          icon={<MailOutlined />}
          block={true}
          onClick={contactFranchisor}
        />
        <AddToMyList franchise={props} isFull={true} />
      </StyledBox>
      {(props.nb_franchise_quebec || props.nb_franchise_canada || props.nb_franchise_ww || props.year_founded) && (
        <StyledBox>
          <h3>{t('franchiseInfo.additionalInfo')}</h3>
          {(props.nb_franchise_quebec || props.nb_franchise_canada || props.nb_franchise_ww) && (
            <h6>
              <u>{t('franchiseInfo.locations')}</u>
            </h6>
          )}
          {props.nb_franchise_ww && (
            <Element>
              <B50>{t('franchiseInfo.world')}</B50>
              <p>{props.nb_franchise_ww}</p>
            </Element>
          )}
          {props.nb_franchise_canada && (
            <Element>
              <B50>{t('franchiseInfo.canada')}</B50>
              <p>{props.nb_franchise_canada}</p>
            </Element>
          )}
          {props.nb_franchise_quebec && (
            <Element>
              <B50>{t('franchiseInfo.quebec')}</B50>
              <p>{props.nb_franchise_quebec}</p>
            </Element>
          )}
          {props.year_founded && (
            <Element>
              <B50>{t('franchiseInfo.fondation')}</B50>
              <p>{props.year_founded}</p>
            </Element>
          )}
        </StyledBox>
      )}
      {props.video && (
        <VideoBox>
          <iframe
            width={'100%'}
            height={'100%'}
            src={'https://www.youtube.com/embed/' + props.video?.split('/')?.[props.video?.split('/')?.length - 1]}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </VideoBox>
      )}
      <FranchiseContactForm useVisible={[visibleForm, setVisibleForm]} />
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
`;

const StyledBox = styled.div`
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${theme.color.lightGrey};
  padding: 24px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  p,
  h3,
  h6 {
    margin-bottom: 0;
  }
`;

const B50 = styled.p`
  font-weight: bold;
  width: 50%;
`;

const Element = styled.div`
  display: flex;
`;

const VideoBox = styled.div`
  width: 100%;
  height: 270px !important;
`;

const StyledImg = styled(Image)`
  object-fit: scale-down !important;
`;
