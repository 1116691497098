import { ButtonSmall, ButtonStatus } from '../../../common/button/ButtonSmall';
import { Checkbox, Form, Input, InputNumber, Modal, Select, Spin } from 'antd';
import { FranchiseDTO, FranchiseForm, franchiseTypeOptions } from '../../../dtos/franchise';
import React, { useContext, useState } from 'react';
import { getUploadUrl, postImage } from '../../../services/ListingService';
import { toFranchise, toFranchiseForm } from './helper';
import { useLocation, useNavigate } from 'react-router-dom';

import { PicturePath } from '../../../dtos/uploadImage';
import TextArea from 'antd/lib/input/TextArea';
import { UploadImage } from '../../../common/uploadImage/UploadImage';
import { UserContext } from '../../../contexts/UserContext';
import { ViewAdmin } from '../../../common/views/ViewAdmin';
import { adminRoutes } from '../../../router/routes';
import styled from 'styled-components';
import { updateFranchise } from '../../../services/AdminService';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useIamgeCompressor } from '../../../services/useImageCompressor';
import { useMutation } from '@tanstack/react-query';

export const FranchiseFormAdmin = () => {
  const [franchiseForm] = Form.useForm<FranchiseForm>();
  const { state } = useLocation();
  const { tokenErrorHandler } = useContext(UserContext);
  const axiosMid = useAxiosMiddleware();
  const navigate = useNavigate();
  const [dirtyImage, setDirtyImage] = useState(false);
  const [franchiseId, setFranchiseId] = useState(state !== null ? (state as FranchiseDTO)._id : undefined);
  const [compressImage, imageCompressorLoading] = useIamgeCompressor();

  const postPhotoMutation = useMutation(postImage);

  const postPhotoRoutine = async (fileField: string) => {
    const fileObject = franchiseForm.getFieldValue(fileField);
    if (fileObject === undefined) {
      return;
    }
    const fileList = fileObject.fileList;
    if (fileList.length === 1 && fileList[0].status == 'success') {
      await postPhotoMutation.mutateAsync(
        {
          file: ((await compressImage(fileList[0].originFileObj as File)) as File) || '',
          secureUrl: fileList[0].name || '',
        },
        {
          onSuccess: () => {
            fileList[0].status = 'done';
            if (fileList[0].name && fileList[0].name?.includes('?')) {
              // eslint-disable-next-line
              fileList[0].url = fileList[0].name!.split('?')[0];
              fileList[0].name = '';
            }
            franchiseForm.setFieldValue(fileField, fileObject);
          },
        },
      );
    }
  };

  const franchiseUpdateMutation = useMutation(updateFranchise, {
    onSuccess: (res) => {
      setFranchiseId(res);
      Modal.confirm({
        content: 'Vos changement ont été enregistré avec succès',
        onOk: () => navigate(adminRoutes.franchisesAdmin),
      });
    },
    onError: tokenErrorHandler,
  });

  const onFinish = async () => {
    await postPhotoRoutine('picture1');
    await postPhotoRoutine('picture1_en');
    await postPhotoRoutine('picture2');
    await postPhotoRoutine('picture2_en');
    await postPhotoRoutine('picture3');
    await postPhotoRoutine('picture3_en');
    await postPhotoRoutine('picture4');
    await postPhotoRoutine('picture4_en');
    await postPhotoRoutine('picture5');
    await postPhotoRoutine('picture5_en');
    await postPhotoRoutine('picture6');
    await postPhotoRoutine('picture6_en');
    await postPhotoRoutine('picture7');
    await postPhotoRoutine('picture7_en');
    await postPhotoRoutine('picture8');
    await postPhotoRoutine('picture8_en');
    await postPhotoRoutine('logo');
    setDirtyImage(false);

    franchiseUpdateMutation.mutate({
      axiosMid: axiosMid,
      franchise: { ...toFranchise(franchiseForm.getFieldsValue()), _id: franchiseId },
    });
  };

  return (
    <ViewAdmin>
      <FlexDiv>
        <CentedDiv>
          <Spin size={'large'} spinning={franchiseUpdateMutation.isLoading || imageCompressorLoading}>
            <h3>Formulaire de création de franchise</h3>
            <Form
              layout={'vertical'}
              form={franchiseForm}
              onFinish={onFinish}
              initialValues={{ ...toFranchiseForm(state as FranchiseDTO) }}
            >
              <Form.Item name={'title'} label={'Nom de la franchise'} rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name={'franchisor_email'} label={'Email du franchiseur'} rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name={'plus_subscription'} rules={[{ required: true }]} valuePropName="checked">
                <Checkbox>{'Ce franchiseur a le forfait Plus'}</Checkbox>
              </Form.Item>
              <UploadImage
                service={getUploadUrl}
                picturePath={PicturePath.FRANCHISE_PICTURES}
                state={[dirtyImage, setDirtyImage]}
                currForm={franchiseForm}
                fileField={'picture1'}
              />
              <UploadImage
                service={getUploadUrl}
                picturePath={PicturePath.FRANCHISE_PICTURES}
                state={[dirtyImage, setDirtyImage]}
                currForm={franchiseForm}
                fileField={'picture1_en'}
              />
              <Form.Item name={'text1_fr'} label={'Description 1 (fr)'}>
                <TextArea rows={8} />
              </Form.Item>
              <Form.Item name={'text1_en'} label={'Description 1 (en)'}>
                <TextArea rows={8} />
              </Form.Item>

              <UploadImage
                service={getUploadUrl}
                picturePath={PicturePath.FRANCHISE_PICTURES}
                state={[dirtyImage, setDirtyImage]}
                currForm={franchiseForm}
                fileField={'picture2'}
              />
              <UploadImage
                service={getUploadUrl}
                picturePath={PicturePath.FRANCHISE_PICTURES}
                state={[dirtyImage, setDirtyImage]}
                currForm={franchiseForm}
                fileField={'picture2_en'}
              />
              <Form.Item name={'text2_fr'} label={'Description 2 (fr)'}>
                <TextArea rows={8} />
              </Form.Item>
              <Form.Item name={'text2_en'} label={'Description 2 (en)'}>
                <TextArea rows={8} />
              </Form.Item>

              <UploadImage
                service={getUploadUrl}
                picturePath={PicturePath.FRANCHISE_PICTURES}
                state={[dirtyImage, setDirtyImage]}
                currForm={franchiseForm}
                fileField={'picture3'}
              />
              <UploadImage
                service={getUploadUrl}
                picturePath={PicturePath.FRANCHISE_PICTURES}
                state={[dirtyImage, setDirtyImage]}
                currForm={franchiseForm}
                fileField={'picture3_en'}
              />
              <Form.Item name={'text3_fr'} label={'Description 3 (fr)'}>
                <TextArea rows={8} />
              </Form.Item>
              <Form.Item name={'text3_en'} label={'Description 3 (en)'}>
                <TextArea rows={8} />
              </Form.Item>

              <UploadImage
                service={getUploadUrl}
                picturePath={PicturePath.FRANCHISE_PICTURES}
                state={[dirtyImage, setDirtyImage]}
                currForm={franchiseForm}
                fileField={'picture4'}
              />
              <UploadImage
                service={getUploadUrl}
                picturePath={PicturePath.FRANCHISE_PICTURES}
                state={[dirtyImage, setDirtyImage]}
                currForm={franchiseForm}
                fileField={'picture4_en'}
              />
              <Form.Item name={'text4_fr'} label={'Description 4 (fr)'}>
                <TextArea rows={8} />
              </Form.Item>
              <Form.Item name={'text4_en'} label={'Description 4 (en)'}>
                <TextArea rows={8} />
              </Form.Item>

              <UploadImage
                service={getUploadUrl}
                picturePath={PicturePath.FRANCHISE_PICTURES}
                state={[dirtyImage, setDirtyImage]}
                currForm={franchiseForm}
                fileField={'picture5'}
              />
              <UploadImage
                service={getUploadUrl}
                picturePath={PicturePath.FRANCHISE_PICTURES}
                state={[dirtyImage, setDirtyImage]}
                currForm={franchiseForm}
                fileField={'picture5_en'}
              />
              <Form.Item name={'text5_fr'} label={'Description 5 (fr)'}>
                <TextArea rows={8} />
              </Form.Item>
              <Form.Item name={'text5_en'} label={'Description 5 (en)'}>
                <TextArea rows={8} />
              </Form.Item>

              <UploadImage
                service={getUploadUrl}
                picturePath={PicturePath.FRANCHISE_PICTURES}
                state={[dirtyImage, setDirtyImage]}
                currForm={franchiseForm}
                fileField={'picture6'}
              />
              <UploadImage
                service={getUploadUrl}
                picturePath={PicturePath.FRANCHISE_PICTURES}
                state={[dirtyImage, setDirtyImage]}
                currForm={franchiseForm}
                fileField={'picture6_en'}
              />
              <Form.Item name={'text6_fr'} label={'Description 6 (fr)'}>
                <TextArea rows={8} />
              </Form.Item>
              <Form.Item name={'text6_en'} label={'Description 6 (en)'}>
                <TextArea rows={8} />
              </Form.Item>

              <UploadImage
                service={getUploadUrl}
                picturePath={PicturePath.FRANCHISE_PICTURES}
                state={[dirtyImage, setDirtyImage]}
                currForm={franchiseForm}
                fileField={'picture7'}
              />
              <UploadImage
                service={getUploadUrl}
                picturePath={PicturePath.FRANCHISE_PICTURES}
                state={[dirtyImage, setDirtyImage]}
                currForm={franchiseForm}
                fileField={'picture7_en'}
              />
              <Form.Item name={'text7_fr'} label={'Description 7 (fr)'}>
                <TextArea rows={8} />
              </Form.Item>
              <Form.Item name={'text7_en'} label={'Description 7 (en)'}>
                <TextArea rows={8} />
              </Form.Item>

              <UploadImage
                service={getUploadUrl}
                picturePath={PicturePath.FRANCHISE_PICTURES}
                state={[dirtyImage, setDirtyImage]}
                currForm={franchiseForm}
                fileField={'picture8'}
              />
              <UploadImage
                service={getUploadUrl}
                picturePath={PicturePath.FRANCHISE_PICTURES}
                state={[dirtyImage, setDirtyImage]}
                currForm={franchiseForm}
                fileField={'picture8_en'}
              />
              <Form.Item name={'text8_fr'} label={'Description 8 (fr)'}>
                <TextArea rows={8} />
              </Form.Item>
              <Form.Item name={'text8_en'} label={'Description 8 (en)'}>
                <TextArea rows={8} />
              </Form.Item>

              <UploadImage
                service={getUploadUrl}
                picturePath={PicturePath.FRANCHISE_PICTURES}
                state={[dirtyImage, setDirtyImage]}
                currForm={franchiseForm}
                fileField={'logo'}
              />
              <Form.Item name={'franchise_type'} label={'Type de franchise'}>
                <Select options={franchiseTypeOptions} />
              </Form.Item>
              <Form.Item
                name={'video'}
                label={'Vidéo promotionnelle de la fanchise'}
                rules={[
                  {
                    pattern: new RegExp('^(https://youtu.be/).*$'),
                    message: 'Utlisez le lien de type https://youtu.be/... fourni par la fontion de paratage YouTube',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name={'cash_down'} label={'Apport personnel exigé'}>
                <InputNumber />
              </Form.Item>
              <Form.Item name={'total_investment_min'} label={'Investissement total (min)'}>
                <InputNumber />
              </Form.Item>
              <Form.Item name={'total_investment_max'} label={'Investissement total (max)'}>
                <InputNumber />
              </Form.Item>
              <Form.Item name={'nb_franchise_ww'} label={'Unité monde'}>
                <InputNumber />
              </Form.Item>
              <Form.Item name={'nb_franchise_canada'} label={'Unité Canada'}>
                <InputNumber />
              </Form.Item>
              <Form.Item name={'nb_franchise_quebec'} label={'Unité Québec'}>
                <InputNumber />
              </Form.Item>
              <Form.Item name={'year_founded'} label={'Année de fondation'}>
                <InputNumber />
              </Form.Item>
              <Form.Item name={'is_published'} valuePropName="checked">
                <StyledCheckbox>{'Afficher la franchise dans la section franchise'}</StyledCheckbox>
              </Form.Item>
              <LeftDiv>
                <ButtonSmall
                  status={ButtonStatus.PRIMARY}
                  text={'Enregistrer'}
                  disabled={franchiseUpdateMutation.isLoading}
                />
              </LeftDiv>
            </Form>
          </Spin>
        </CentedDiv>
      </FlexDiv>
    </ViewAdmin>
  );
};

const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
  .ant-picker,
  .ant-input-number,
  .ant-space,
  textarea {
    width: 100%;
  }
  .ant-input-number-handler-wrap {
    visibility: hidden;
  }
`;

const CentedDiv = styled.div`
  width: 100%;
  max-width: 720px;
  padding-bottom: 32px;
`;

const StyledCheckbox = styled(Checkbox)`
  width: 100%;
  color: red;
`;

const LeftDiv = styled.div`
  display: flex;
  justify-content: right;
`;
