import { BrokerDTO, BrokerForm } from '../../dtos/broker';

export const toBrokerProfile = (form: BrokerForm): BrokerDTO => {
  const res = {
    profile_picture: form.profile_picture?.fileList.length === 1 ? form.profile_picture?.fileList[0].url : undefined,
    broker_title: fieldsToArr(form.broker_title_fr, form.broker_title_en),
    broker_expertise: fieldsToArr(form.broker_expertise_fr, form.broker_expertise_en),
    years_of_experience: form.years_of_experience ? form.years_of_experience : undefined,
    desctiption: fieldsToArr(form.desctiption_fr, form.desctiption_en),
    broker_email: form.broker_email,
    broker_phone_number: form.broker_phone_number ? form.broker_phone_number : undefined,
    linkedin: form.linkedin ? form.linkedin : undefined,
    region: form.region ? form.region : undefined,
    company_name: form.company_name,
    company_website: form.company_website ? form.company_website : undefined,
    company_logo: form.company_logo?.fileList.length === 1 ? form.company_logo?.fileList[0].url : undefined,
    show_broker_profile: form.show_broker_profile ? true : false,
  };
  return res;
};

const fieldsToArr = (def: string | undefined, add: string | undefined): string[] => {
  const ret = [];
  if (def) {
    ret.push(def);
  }
  if (add) {
    ret.push(add);
  }
  return ret;
};

export const toBrokerForm = (brokerProfile: BrokerDTO | null): BrokerForm | undefined => {
  if (brokerProfile === null) {
    return undefined;
  }
  const ret: BrokerForm = {
    _id: brokerProfile._id,
    profile_picture: brokerProfile.profile_picture
      ? {
          fileList: [
            {
              uid: brokerProfile.profile_picture,
              name: brokerProfile.profile_picture,
              url: brokerProfile.profile_picture,
              status: 'done',
            },
          ],
        }
      : { fileList: [] },
    broker_title_fr: brokerProfile.broker_title?.[0],
    broker_title_en: brokerProfile.broker_title?.[1],
    broker_expertise_fr: brokerProfile.broker_expertise?.[0],
    broker_expertise_en: brokerProfile.broker_expertise?.[1],
    years_of_experience: brokerProfile.years_of_experience,
    desctiption_fr: brokerProfile.desctiption?.[0],
    desctiption_en: brokerProfile.desctiption?.[1],
    broker_email: brokerProfile.broker_email,
    broker_phone_number: brokerProfile.broker_phone_number,
    linkedin: brokerProfile.linkedin,
    region: brokerProfile.region,
    company_name: brokerProfile.company_name,
    company_website: brokerProfile.company_website,
    company_logo: brokerProfile.company_logo
      ? {
          fileList: [
            {
              uid: brokerProfile.company_logo,
              name: brokerProfile.company_logo,
              url: brokerProfile.company_logo,
              status: 'done',
            },
          ],
        }
      : { fileList: [] },
    show_broker_profile: brokerProfile.show_broker_profile ? true : false,
  };
  return ret;
};

export const calculateBilingual = (broker: BrokerDTO | null) => {
  if (broker === null) {
    return false;
  }

  if (
    (broker.broker_title && broker.broker_title.length > 1) ||
    (broker.broker_expertise && broker.broker_expertise.length > 1) ||
    (broker.desctiption && broker.desctiption.length > 1)
  ) {
    return true;
  }
  return false;
};
