import React, { useContext } from 'react';

import { BrokerCard } from './BrokerCard';
import { OwnerCard } from './OwnerCard';
import { StripeProduct } from '../../dtos/listingCreation';
import { UserContext } from '../../contexts/UserContext';
import styled from 'styled-components';

export const SubscriptionCard = (props: { product: StripeProduct | undefined | null }): JSX.Element => {
  const { userType } = useContext(UserContext);

  if (props.product === undefined || props.product === null) {
    return <></>;
  }

  return (
    <RightFlex>
      {userType === 'broker' ? <BrokerCard product={props.product} /> : <OwnerCard product={props.product} />}
    </RightFlex>
  );
};

const RightFlex = styled.div`
  display: flex;
  column-gap: 32px;
  p {
    margin: 0;
  }
`;
