import React, { useContext } from 'react';

import { LocaleContext } from '../../../router/Router';
import { OfferFinancingEnum } from '../../../dtos/listingCreationFields';
import { SellingDetailsData } from '../../../dtos/listingCreation';
import { StyledBox } from '../FullListing';
import { TextDescription } from '../descriptions/TextDescription';
import { tCustom } from '../../../services/TranslationService';
import { useTranslation } from 'react-i18next';

export const SellingDetails = (props: SellingDetailsData): JSX.Element => {
  const { t } = useTranslation(['fullListing', 'createListing']);
  const { locale } = useContext(LocaleContext);

  return (
    <>
      <h4>{t('fullListing:selling_details.title')}</h4>
      <StyledBox>
        {props.selling_reason && (
          <TextDescription
            title={t('fullListing:selling_details.reason')}
            content={t(`createListing:formDetails.selling_reason.${props.selling_reason}`)}
          />
        )}
        {props.financing_option !== undefined && props.financing_option !== null && (
          <TextDescription
            title={t('fullListing:selling_details.financing')}
            content={t(
              `createListing:formDetails.financing_option.${
                props.financing_option ? OfferFinancingEnum.Willing : OfferFinancingEnum.NotWilling
              }`,
            )}
          />
        )}
        {tCustom(props.offered_support, locale) && (
          <TextDescription
            title={t('fullListing:selling_details.support')}
            // eslint-disable-next-line
            content={tCustom(props.offered_support, locale)!}
          />
        )}
      </StyledBox>
    </>
  );
};
