import React, { useContext } from 'react';
import { Spin, Tooltip } from 'antd';

import { EditOutlined } from '@ant-design/icons';
import { FranchiseDTO } from '../../../dtos/franchise';
import { UserContext } from '../../../contexts/UserContext';
import { adminRoutes } from '../../../router/routes';
import { getFranchise } from '../../../services/FranchiseService';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export const EditAction = (props: FranchiseDTO): JSX.Element => {
  const axiosMiddleware = useAxiosMiddleware(),
    { tokenErrorHandler } = useContext(UserContext),
    navigate = useNavigate();

  const getFranchiseMutation = useMutation(getFranchise, {
    onSuccess: (franchise) => {
      navigate(adminRoutes.franchiseFormAdmin, { state: franchise });
    },
    onError: tokenErrorHandler,
  });
  const editFranchiseAction = (id: string) => {
    getFranchiseMutation.mutate({ franchiseId: id, axiosMid: axiosMiddleware });
  };
  return getFranchiseMutation.isLoading ? (
    <Spin />
  ) : (
    <div>
      <Tooltip title={'Modifier la franchise'}>
        <EditOutlined onClick={() => editFranchiseAction(props._id || '')} />
      </Tooltip>
    </div>
  );
};
