import { ButtonSmall, ButtonStatus } from '../../../common/button/ButtonSmall';
import { Image, Tag } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import { ListingTileData, SearchParameters } from '../../../dtos/businessesForSale';
import React, { useContext, useEffect, useState } from 'react';

import { Description } from './Description';
import { FavoriteButton } from './FavoriteButton';
import { Financials } from './Financials';
import { LocaleContext } from '../../../router/Router';
import fallback from './../../../img/fallbackimg.webp';
import styled from 'styled-components';
import { tCustom } from '../../../services/TranslationService';
import { useTranslation } from 'react-i18next';

export const ListingTile = (props: {
  tile: ListingTileData;
  searchState?: {
    page: number;
    searchParams: SearchParameters;
  };
}): JSX.Element => {
  const { t } = useTranslation(['businessesForSale', 'industries', 'regions']);
  const { locale } = useContext(LocaleContext);
  const setSearchParams = useSearchParams()[1];

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1500);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  const handleResize = () => {
    if (window.innerWidth < 1500) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
    if (window.innerWidth < 1000) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.addEventListener('resize', handleResize);
    };
  });

  const onClick = () => {
    setSearchParams({
      prev_id: props.tile._id || '',
      sort_option: props.searchState?.searchParams.sort?.toString() || '',
      page: props.searchState?.page.toString() || '',
      price_min: props.searchState?.searchParams.price?.min?.toString() || '',
      price_max: props.searchState?.searchParams.price?.max?.toString() || '',
      regions: JSON.stringify(props.searchState?.searchParams.regions) || '',
      industries: JSON.stringify(props.searchState?.searchParams.industries) || '',
      targeted_regions: props.searchState?.searchParams.targetedRegions?.toString() || '',
      timestamp: props.searchState?.searchParams.timestamp?.toString() || '',
    });
  };

  return (
    <StyledCard>
      <MainColumn>
        <SmallImgDiv>
          <StyledImg
            width={'100%'}
            height={'40vw'}
            src={
              props.tile.additional_info?.picture_primary !== null ? props.tile.additional_info?.picture_primary : ''
            }
            fallback={fallback}
            preview={false}
          />
        </SmallImgDiv>
        <LeftDiv>
          <MiddleDiv>
            <BigImgDiv>
              <StyledImg
                width={276}
                height={203}
                src={
                  props.tile.additional_info?.picture_primary !== null
                    ? props.tile.additional_info?.picture_primary
                    : ''
                }
                fallback={fallback}
                preview={false}
              />
            </BigImgDiv>

            <div>
              <Link onClick={onClick} to={`../fl/${props.tile._id}`}>
                <StyledH5>{tCustom(props.tile.basic_info?.listing_title, locale)}</StyledH5>
              </Link>
              <StyledTags>
                {props.tile.basic_info.region_1 && (
                  <Description
                    title={t('businessesForSale:tile.location')}
                    content={
                      t(`regions:regions.${props.tile.basic_info.region_1?.[0]}`) +
                      (props.tile.basic_info.region_1?.[1]
                        ? ', ' + t(`regions:regions.${props.tile.basic_info.region_1?.[1]}`)
                        : '')
                    }
                  />
                )}
                {props.tile.basic_info.industry_1 && (
                  <Description
                    title={t('businessesForSale:tile.industries')}
                    content={
                      t(`industries:industries.${props.tile.basic_info.industry_1?.[0]}`) +
                      (props.tile.basic_info.industry_1?.[1]
                        ? ', ' + t(`industries:industries.${props.tile.basic_info.industry_1?.[1]}`)
                        : '')
                    }
                    isSmallScreen={isSmallScreen}
                  />
                )}
                {!isSmallScreen && props.tile.additional_info.number_employees && (
                  <Description
                    title={t('businessesForSale:tile.number_employees')}
                    content={props.tile.additional_info.number_employees.toString()}
                  />
                )}
                {!isSmallScreen && props.tile.additional_info.date_founded && (
                  <Description
                    title={t('businessesForSale:tile.date_founded')}
                    content={props.tile.additional_info.date_founded.toString()}
                  />
                )}
              </StyledTags>
              {!isMobile && <ElP>{tCustom(props.tile.basic_info?.about_company, locale)}</ElP>}
              {isMobile && <Financials {...props.tile} />}
            </div>
          </MiddleDiv>
          {!isMobile && <Financials {...props.tile} />}
        </LeftDiv>
        <ButtonDiv>
          {props.tile.priority_exposure && !isMobile && (
            <Tag color={'#f5a937'}>
              <PNopad>{'PREMIUM'}</PNopad>
            </Tag>
          )}
          <div>
            <FavoriteButton listingId={props.tile._id} />
          </div>
          <Link onClick={onClick} to={`../fl/${props.tile._id}`}>
            <ButtonSmall status={ButtonStatus.PRIMARY} text={t('businessesForSale:tile.seeListing')} />
          </Link>
        </ButtonDiv>
      </MainColumn>
    </StyledCard>
  );
};

const StyledCard = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  margin-bottom: 16px;
  height: 286px;
  @media only screen and (max-width: 650px) {
    height: 486px;
  }
  width: 100%;
  border-radius: 5px;
  border: 1px solid #a2a0a8;
  padding: 1rem;
  :hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
`;

const StyledImg = styled(Image)`
  object-fit: cover !important;
  border-radius: 5px;
`;

const PNopad = styled.p`
  margin: 0;
`;

const ElP = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0;
`;

const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  column-gap: 32px;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    justify-content: space-between;
  }
`;

const StyledTags = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 32px;
`;

const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const LeftDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 24px;
`;

const MiddleDiv = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 24px;
`;

const BigImgDiv = styled.div`
  @media only screen and (max-width: 650px) {
    display: none;
  }
`;

const SmallImgDiv = styled.div`
  display: none;
  @media only screen and (max-width: 650px) {
    display: block;
  }
`;

const StyledH5 = styled.h5`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
