import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { Link, useSearchParams } from 'react-router-dom';
import React, { useContext } from 'react';

import { AddToMyList } from './AddToMyList';
import { FranchiseDTO } from '../../dtos/franchise';
import { Image } from 'antd';
import { LocaleContext } from '../../router/Router';
import styled from 'styled-components';
import { tCustom } from '../../services/TranslationService';
import { theme } from '../../styles/theme';
import { useTranslation } from 'react-i18next';

export const FranchiseTile = (props: {
  franchise: FranchiseDTO;
  searchParams: { sortOption: string; page: number };
  setFavCnt: React.Dispatch<number>;
}): JSX.Element => {
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation('franchiseSection');
  const fromater = new Intl.NumberFormat('fr-CA');
  const setSearchParams = useSearchParams()[1];

  const toThousand = (value: number | undefined): string => {
    if (value === undefined || value === null) {
      return '-';
    } else {
      return fromater.format(Math.floor(value));
    }
  };
  const onClick = () => {
    setSearchParams({
      prev_id: props.franchise._id || '',
      sort_option: props.searchParams.sortOption,
      page: props.searchParams.page.toString(),
    });
  };

  return (
    <Card>
      <Div1>
        <Link onClick={onClick} to={`../franchise/${props.franchise._id}`}>
          <StyledImg height={120} src={props.franchise.logo} preview={false} />
        </Link>
      </Div1>
      <Div2>
        <Link onClick={onClick} to={`../franchise/${props.franchise._id}`}>
          <h5>{props.franchise.title}</h5>
        </Link>
        {props.franchise.cash_down && (
          <Element>
            <PGrey>{t('franchiseTile.cashDown')}</PGrey>
            <p>
              <b>
                {toThousand(props.franchise.cash_down)}
                {' $'}
              </b>
            </p>
          </Element>
        )}
        {props.franchise.total_investment_min && (
          <Element>
            <PGrey>{t('franchiseTile.totalInvestment')}</PGrey>
            <p>
              <b>
                {toThousand(props.franchise.total_investment_min) + ' $'}
                {props.franchise.total_investment_max && (
                  <>
                    {t('franchiseTile.to')}
                    {toThousand(props.franchise.total_investment_max) + ' $'}
                  </>
                )}
              </b>
            </p>
          </Element>
        )}
      </Div2>
      <Div3>
        <PD1>{tCustom(props.franchise.text1, locale)}</PD1>
        <ButtonDiv>
          <AddToMyList franchise={props.franchise} setFavCnt={props.setFavCnt} />
          <Link onClick={onClick} to={`../franchise/${props.franchise._id}`}>
            <ButtonSmall status={ButtonStatus.PRIMARY} text={t('franchiseTile.seeDetails')} />
          </Link>
        </ButtonDiv>
      </Div3>
    </Card>
  );
};

const Card = styled.div`
  margin-bottom: 16px;
  border-radius: 5px;
  border: 1px solid #a2a0a8;
  padding: 1rem;
  :hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  display: flex;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
  gap: 16px;
  h5 {
    margin: 0;
  }
`;

const StyledImg = styled(Image)`
  object-fit: scale-down !important;
`;

const Div1 = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 0.75px solid ${theme.color.lightGrey};
`;

const Div2 = styled.div`
  flex: 1;
`;

const Div3 = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 16px;
`;

const PGrey = styled.p`
  color: ${theme.color.darkGrey};
  font-size: ${theme.fontsize.smallp};
`;

const Element = styled.div`
  p {
    margin: 0;
  }
`;

const PD1 = styled.p`
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
