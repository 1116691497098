import { Cascader, Form, Input, InputNumber, Switch } from 'antd';
import React, { useContext } from 'react';
import { industriesEn, industriesFr } from '../../../dtos/industries';
import { regionEn, regionFr } from '../../../dtos/regions';

import { FieldLength } from '../../../dtos/fields';
import { LocaleContext } from '../../../router/Router';
import TextArea from 'antd/lib/input/TextArea';
import { formatNumber } from '../helpers';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const FormBasic = (props: {
  bilingual: boolean;
  setBilingual: React.Dispatch<React.SetStateAction<boolean>>;
  fr: false | JSX.Element;
  en: false | JSX.Element;
}): JSX.Element => {
  const { t } = useTranslation(['createListing', 'industries', 'regions']);
  const { locale } = useContext(LocaleContext);

  const onChange = (checked: boolean) => {
    props.setBilingual(checked);
  };

  return (
    <>
      <h4>{t('createListing:formBasic.sectionTitle')}</h4>
      <Form.Item label={t('createListing:formBasic.bilingual.title')}>
        <Switch onChange={onChange} />
      </Form.Item>
      <Form.Item
        name={'listing_title_def'}
        label={
          <label>
            {t('createListing:formBasic.listing_title.title')}
            {props.fr}
          </label>
        }
        rules={[
          { required: true, message: t('createListing:errors.ruleRequired') },
          {
            max: FieldLength.SHORT,
            message: t('createListing:errors.ruleTooLong', { length: FieldLength.SHORT }),
          },
          { whitespace: true, message: t('createListing:errors.whitespace') },
        ]}
        tooltip={t('createListing:formBasic.listing_title.tooltip')}
      >
        <Input placeholder={t('createListing:formBasic.listing_title.placeholder')} type="text" />
      </Form.Item>

      {props.bilingual && (
        <Form.Item
          name={'listing_title_add'}
          label={
            <label>
              {t('createListing:formBasic.listing_title.title')}
              {props.en}
            </label>
          }
          rules={[
            {
              max: FieldLength.SHORT,
              message: t('createListing:errors.ruleTooLong', { length: FieldLength.SHORT }),
            },
            { whitespace: true, message: t('createListing:errors.whitespace') },
          ]}
          tooltip={t('createListing:formBasic.listing_title.tooltip')}
        >
          <Input placeholder={t('createListing:formBasic.listing_title.placeholder')} type="text" />
        </Form.Item>
      )}

      <Form.Item
        name={'about_company_def'}
        label={
          <label>
            {t('createListing:formBasic.about_company.title')}
            {props.fr}
          </label>
        }
        rules={[
          { required: true, message: t('createListing:errors.ruleRequired') },
          {
            max: FieldLength.VERY_LONG,
            message: t('createListing:errors.ruleTooLong', { length: FieldLength.VERY_LONG }),
          },
          { whitespace: true, message: t('createListing:errors.whitespace') },
        ]}
        tooltip={<SpanBreak>{t('createListing:formBasic.about_company.tooltip')}</SpanBreak>}
      >
        <TextArea rows={8} placeholder={t('createListing:formBasic.about_company.placeholder')} />
      </Form.Item>
      {props.bilingual && (
        <Form.Item
          name={'about_company_add'}
          label={
            <label>
              {t('createListing:formBasic.about_company.title')}
              {props.en}
            </label>
          }
          rules={[
            {
              max: FieldLength.VERY_LONG,
              message: t('createListing:errors.ruleTooLong', { length: FieldLength.VERY_LONG }),
            },
            { whitespace: true, message: t('createListing:errors.whitespace') },
          ]}
          tooltip={<SpanBreak>{t('createListing:formBasic.about_company.tooltip')}</SpanBreak>}
        >
          <TextArea rows={8} placeholder={t('createListing:formBasic.about_company.placeholder')} />
        </Form.Item>
      )}

      <Form.Item
        name={'region_1'}
        label={<label>{t('createListing:formBasic.region_1.title')}</label>}
        rules={[{ required: true, message: t('createListing:errors.ruleRequired') }]}
        tooltip={<SpanBreak>{t('createListing:formBasic.region_1.tooltip')}</SpanBreak>}
      >
        <Cascader
          changeOnSelect
          options={[
            ...(locale === 'en' ? regionEn : regionFr).map((ind) => {
              return {
                value: Object.keys(ind)[0],
                label: t(`regions:regions.${Object.keys(ind)[0]}`),
                children: Object.values(ind)[0].map((f: string) => {
                  return { value: f, label: t(`regions:regions.${f}`) };
                }),
              };
            }),
          ]}
        />
      </Form.Item>

      <Form.Item name={'region_2'} label={<label>{t('createListing:formBasic.region_2.title')}</label>}>
        <Cascader
          changeOnSelect
          options={[
            ...(locale === 'en' ? regionEn : regionFr).map((ind) => {
              return {
                value: Object.keys(ind)[0],
                label: t(`regions:regions.${Object.keys(ind)[0]}`),
                children: Object.values(ind)[0].map((f: string) => {
                  return { value: f, label: t(`regions:regions.${f}`) };
                }),
              };
            }),
          ]}
        />
      </Form.Item>

      <Form.Item name={'region_3'} label={<label>{t('createListing:formBasic.region_3.title')}</label>}>
        <Cascader
          changeOnSelect
          options={[
            ...(locale === 'en' ? regionEn : regionFr).map((ind) => {
              return {
                value: Object.keys(ind)[0],
                label: t(`regions:regions.${Object.keys(ind)[0]}`),
                children: Object.values(ind)[0].map((f: string) => {
                  return { value: f, label: t(`regions:regions.${f}`) };
                }),
              };
            }),
          ]}
        />
      </Form.Item>

      <Form.Item
        name={'industry_1'}
        label={<label>{t('createListing:formBasic.industry_1.title')}</label>}
        rules={[{ required: true, message: t('createListing:errors.ruleRequired') }]}
        tooltip={<SpanBreak>{t('createListing:formBasic.industry_1.tooltip')}</SpanBreak>}
      >
        <Cascader
          changeOnSelect
          options={[
            ...(locale === 'en' ? industriesEn : industriesFr).map((ind) => {
              return {
                value: Object.keys(ind)[0],
                label: t(`industries:industries.${Object.keys(ind)[0]}`),
                children: Object.values(ind)[0].map((f: string) => {
                  return { value: f, label: t(`industries:industries.${f}`) };
                }),
              };
            }),
          ]}
        />
      </Form.Item>

      <Form.Item name={'industry_2'} label={<label>{t('createListing:formBasic.industry_2.title')}</label>}>
        <Cascader
          changeOnSelect
          options={[
            ...(locale === 'en' ? industriesEn : industriesFr).map((ind) => {
              return {
                value: Object.keys(ind)[0],
                label: t(`industries:industries.${Object.keys(ind)[0]}`),
                children: Object.values(ind)[0].map((f: string) => {
                  return { value: f, label: t(`industries:industries.${f}`) };
                }),
              };
            }),
          ]}
        />
      </Form.Item>

      <Form.Item name={'industry_3'} label={<label>{t('createListing:formBasic.industry_3.title')}</label>}>
        <Cascader
          changeOnSelect
          options={[
            ...(locale === 'en' ? industriesEn : industriesFr).map((ind) => {
              return {
                value: Object.keys(ind)[0],
                label: t(`industries:industries.${Object.keys(ind)[0]}`),
                children: Object.values(ind)[0].map((f: string) => {
                  return { value: f, label: t(`industries:industries.${f}`) };
                }),
              };
            }),
          ]}
        />
      </Form.Item>

      <Form.Item
        name={'asking_price'}
        label={<label>{t('createListing:formBasic.asking_price.title')}</label>}
        rules={[
          {
            type: 'number',
            min: 0,
            max: 100000000,
            message: t('createListing:errors.between0and100'),
          },
        ]}
      >
        <InputNumber style={{ width: '100%' }} formatter={formatNumber} />
      </Form.Item>

      <Form.Item
        name={'competitive_advantages_def'}
        label={
          <label>
            {t('createListing:formBasic.competitive_advantages.title')}
            {props.fr}
          </label>
        }
        rules={[
          {
            max: FieldLength.LONG,
            message: t('createListing:errors.ruleTooLong', { length: FieldLength.LONG }),
          },
          { whitespace: true, message: t('createListing:errors.whitespace') },
        ]}
        tooltip={t('createListing:formBasic.competitive_advantages.tooltip')}
      >
        <TextArea rows={2} />
      </Form.Item>
      {props.bilingual && (
        <Form.Item
          name={'competitive_advantages_add'}
          label={
            <label>
              {t('createListing:formBasic.competitive_advantages.title')}
              {props.en}
            </label>
          }
          rules={[
            {
              max: FieldLength.LONG,
              message: t('createListing:errors.ruleTooLong', { length: FieldLength.LONG }),
            },
            { whitespace: true, message: t('createListing:errors.whitespace') },
          ]}
          tooltip={t('createListing:formBasic.competitive_advantages.tooltip')}
        >
          <TextArea rows={2} />
        </Form.Item>
      )}

      <Form.Item
        name={'customer_base_def'}
        label={
          <label>
            {t('createListing:formBasic.customer_base.title')}
            {props.fr}
          </label>
        }
        rules={[
          {
            max: FieldLength.LONG,
            message: t('createListing:errors.ruleTooLong', { length: FieldLength.LONG }),
          },
          { whitespace: true, message: t('createListing:errors.whitespace') },
        ]}
        tooltip={t('createListing:formBasic.customer_base.tooltip')}
      >
        <TextArea rows={2} />
      </Form.Item>
      {props.bilingual && (
        <Form.Item
          name={'customer_base_add'}
          label={
            <label>
              {t('createListing:formBasic.customer_base.title')}
              {props.en}
            </label>
          }
          rules={[
            {
              max: FieldLength.LONG,
              message: t('createListing:errors.ruleTooLong', { length: FieldLength.LONG }),
            },
            { whitespace: true, message: t('createListing:errors.whitespace') },
          ]}
          tooltip={t('createListing:formBasic.customer_base.tooltip')}
        >
          <TextArea rows={2} />
        </Form.Item>
      )}

      <Form.Item
        name={'competitors_def'}
        label={
          <label>
            {t('createListing:formBasic.competitors.title')}
            {props.fr}
          </label>
        }
        rules={[
          {
            max: FieldLength.MEDIUM,
            message: t('createListing:errors.ruleTooLong', { length: FieldLength.MEDIUM }),
          },
          { whitespace: true, message: t('createListing:errors.whitespace') },
        ]}
        tooltip={t('createListing:formBasic.competitors.tooltip')}
      >
        <TextArea rows={2} />
      </Form.Item>
      {props.bilingual && (
        <Form.Item
          name={'competitors_add'}
          label={
            <label>
              {t('createListing:formBasic.competitors.title')}
              {props.en}
            </label>
          }
          rules={[
            {
              max: FieldLength.MEDIUM,
              message: t('createListing:errors.ruleTooLong', { length: FieldLength.MEDIUM }),
            },
            { whitespace: true, message: t('createListing:errors.whitespace') },
          ]}
          tooltip={t('createListing:formBasic.competitors.tooltip')}
        >
          <TextArea rows={2} />
        </Form.Item>
      )}

      <Form.Item
        name={'patent'}
        label={<label>{t('createListing:formBasic.patent.title')}</label>}
        rules={[
          {
            max: FieldLength.MEDIUM,
            message: t('createListing:errors.ruleTooLong', { length: FieldLength.MEDIUM }),
          },
          { whitespace: true, message: t('createListing:errors.whitespace') },
        ]}
        tooltip={t('createListing:formBasic.patent.tooltip')}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={'license'}
        label={<label>{t('createListing:formBasic.license.title')}</label>}
        rules={[
          {
            max: FieldLength.SHORT,
            message: t('createListing:errors.ruleTooLong', { length: FieldLength.SHORT }),
          },
          { whitespace: true, message: t('createListing:errors.whitespace') },
        ]}
        tooltip={t('createListing:formBasic.license.tooltip')}
      >
        <Input />
      </Form.Item>
    </>
  );
};

const SpanBreak = styled.span`
  white-space: pre-line;
`;
