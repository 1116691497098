import * as listingService from '../../services/ListingService';

import { Form, Modal, Row, Spin, UploadFile } from 'antd';
import { Listing, ListingForm } from '../../dtos/listingCreation';
import React, { useContext, useEffect, useState } from 'react';
import { calculatePublishable, isBilingual, toForm, toListing } from './helpers';
import { useLocation, useNavigate } from 'react-router-dom';

import { ActionPanel } from './listingPanels/ActionPanel';
import { ErrorMessage } from '../../common/ErrorMessage';
import { FormAdditional } from './listingForms/FormAdditional';
import { FormBasic } from './listingForms/FormBasic';
import { FormFinancials } from './listingForms/FormFinancials';
import { FormPanel } from './listingPanels/FormPanel';
import { FormSale } from './listingForms/FormSale';
import { HttpError } from '../../dtos/error';
import { LocaleContext } from '../../router/Router';
import { ModalError } from './ModalError';
import { ProgressPanel } from './listingPanels/ProgressPanel';
import { StepPanel } from './listingPanels/StepPanel';
import { UserContext } from '../../contexts/UserContext';
import { privateRoutes } from '../../router/routes';
import styled from 'styled-components';
import { useAxiosMiddleware } from '../../services/useAxiosMiddleware';
import { useIamgeCompressor } from '../../services/useImageCompressor';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const CreateListing = (): JSX.Element => {
  const { tokenErrorHandler } = useContext(UserContext),
    { locale } = useContext(LocaleContext),
    axiosMiddleware = useAxiosMiddleware(),
    { t } = useTranslation(['createListing']),
    navigate = useNavigate(),
    location = useLocation(),
    [activeStep, setActiveStep] = useState(0),
    [listingContent, setListingContent] = useState(location.state as Listing),
    [stepForm] = Form.useForm<ListingForm>(),
    [fileList, setFileList] = useState<UploadFile<File>[]>(toForm(listingContent).pictures || []),
    [dirtyImages, setDirtyImages] = useState(false),
    [publishable, setPublishable] = useState(false),
    [bilingual, setBilingual] = useState(isBilingual(listingContent)),
    [compressImage, imageCompressorLoading] = useIamgeCompressor(),
    [saveIsLoading, setSaveIsLoading] = useState(false);

  useEffect(() => {
    setPublishable(calculatePublishable(stepForm));
    return () => {
      setPublishable(calculatePublishable(stepForm));
    };
  }, []);

  const next = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const prev = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const createMutation = useMutation(listingService.createListing, {
    onError: tokenErrorHandler,
  });

  const updateMutation = useMutation(listingService.updateListing, {
    onError: tokenErrorHandler,
  });

  const postPhotosMutation = useMutation(listingService.postImage);

  const publishMutation = useMutation(listingService.publishListing, {
    onSuccess: () => {
      Modal.success({
        content: t('createListing:createListing.publishedSuccessfully'),
        onOk: () => navigate(privateRoutes.myListings(locale)),
        okText: t('createListing:createListing.seeInMyBusiness'),
        closable: true,
      });
    },
    onError: tokenErrorHandler,
  });

  const onSave = async (isPublish: boolean) => {
    setSaveIsLoading(true);
    //the listing has a title
    if (!stepForm.getFieldValue('listing_title_def')) {
      ModalError(
        { name: ['listing_title_def'], errors: [t(`createListing:errors.ruleRequired`)], warnings: [] },
        t('createListing:errors.yourChanges'),
        t('createListing:errorsField.couldNotBeSaved'),
        t,
      );
      setSaveIsLoading(false);
      return;
    }
    // region has 2 level if Quebec
    if (
      stepForm.getFieldValue('region_1')?.length &&
      stepForm.getFieldValue('region_1').length === 1 &&
      stepForm.getFieldValue('region_1')[0] === '1800'
    ) {
      ModalError(
        { name: ['region_1'], errors: [t(`createListing:errors.2levelReg`)], warnings: [] },
        t('createListing:errors.yourChanges'),
        t('createListing:errorsField.couldNotBeSaved'),
        t,
      );
      setSaveIsLoading(false);
      return;
    }
    if (
      stepForm.getFieldValue('region_2')?.length &&
      stepForm.getFieldValue('region_2').length === 1 &&
      stepForm.getFieldValue('region_2')[0] === '1800'
    ) {
      ModalError(
        { name: ['region_2'], errors: [t(`createListing:errors.2levelReg`)], warnings: [] },
        t('createListing:errors.yourChanges'),
        t('createListing:errorsField.couldNotBeSaved'),
        t,
      );
      setSaveIsLoading(false);
      return;
    }
    if (
      stepForm.getFieldValue('region_3')?.length &&
      stepForm.getFieldValue('region_3').length === 1 &&
      stepForm.getFieldValue('region_3')[0] === '1800'
    ) {
      ModalError(
        { name: ['region_3'], errors: [t(`createListing:errors.2levelReg`)], warnings: [] },
        t('createListing:errors.yourChanges'),
        t('createListing:errorsField.couldNotBeSaved'),
        t,
      );
      setSaveIsLoading(false);
      return;
    }
    if (stepForm.getFieldValue('industry_1')?.length && stepForm.getFieldValue('industry_1').length === 1) {
      ModalError(
        { name: ['industry_1'], errors: [t(`createListing:errors.2levelInd`)], warnings: [] },
        t('createListing:errors.yourChanges'),
        t('createListing:errorsField.couldNotBeSaved'),
        t,
      );
      setSaveIsLoading(false);
      return;
    }

    // the set fields all respect the rules
    const errors = stepForm.getFieldsError();
    for (let i = 0; i < errors.length; i++) {
      if (errors[i].errors.length > 0) {
        ModalError(errors[i], t('createListing:errors.yourChanges'), t('createListing:errorsField.couldNotBeSaved'), t);
        setSaveIsLoading(false);
        return;
      }
    }

    // upload the new images
    const myFiles = fileList;
    for (let i = 0; i < myFiles.length; i++) {
      if (myFiles[i].status == 'success') {
        await postPhotosMutation.mutateAsync(
          {
            file: ((await compressImage(myFiles[i].originFileObj as File)) as File) || '',
            secureUrl: myFiles[i].name || '',
          },
          {
            onSuccess: () => {
              myFiles[i].status = 'done';
              if (myFiles[i].name && myFiles[i].name?.includes('?')) {
                // eslint-disable-next-line
                myFiles[i].url = myFiles[i].name!.split('?')[0];
                myFiles[i].name = '';
              }
            },
          },
        );
      }
    }
    setFileList(myFiles.filter((file) => file.status === 'done'));
    stepForm.setFieldValue('pictures', myFiles);
    setDirtyImages(false);

    // create or update a listing
    if (listingContent._id) {
      setListingContent((prevListingConent) => {
        const nextlistingContent = toListing(prevListingConent, stepForm.getFieldsValue());
        updateMutation.mutate(
          {
            listingid: listingContent._id || '',
            listing: nextlistingContent,
            axiosMid: axiosMiddleware,
          },
          {
            onSuccess: () => {
              if (!isPublish) {
                Modal.success({ content: t('createListing:createListing.saveConfirmation'), closable: true });
              }
            },
          },
        );
        return nextlistingContent;
      });
    } else {
      createMutation.mutate(
        {
          listing: toListing(listingContent, stepForm.getFieldsValue()),
          axiosMid: axiosMiddleware,
        },
        {
          onSuccess: (id: string) => {
            setListingContent((prevListingContent) => ({ ...prevListingContent, _id: id }));
            if (!isPublish) {
              Modal.success({
                content: t('createListing:createListing.saveConfirmation'),
              });
            }
          },
        },
      );
    }
    setSaveIsLoading(false);
  };

  const onPublish = async () => {
    await onSave(true);
    publishMutation.mutate({ listingid: listingContent._id || '', axiosMid: axiosMiddleware });
  };

  const fr = bilingual && <span> (fr)</span>;
  const en = bilingual && <span> (en)</span>;

  const steps = [
    {
      step: 0,
      title: t('createListing:createListing.basic'),
      content: <FormBasic bilingual={bilingual} setBilingual={setBilingual} fr={fr} en={en} />,
    },
    {
      step: 1,
      title: t('createListing:createListing.additional'),
      content: (
        <FormAdditional
          fileList={fileList}
          setFileList={setFileList}
          dirtyImages={dirtyImages}
          setDirtyImages={setDirtyImages}
          content={listingContent.content}
        />
      ),
    },
    {
      step: 2,
      title: t('createListing:createListing.financials'),
      content: <FormFinancials bilingual={bilingual} setBilingual={setBilingual} fr={fr} en={en} />,
    },
    {
      step: 3,
      title: t('createListing:createListing.details'),
      content: <FormSale bilingual={bilingual} setBilingual={setBilingual} fr={fr} en={en} />,
    },
  ];

  return (
    <Container>
      <ProgressPanel activeStep={activeStep} />
      <Row>
        <StepPanel steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
        <DownVector>
          <RightDiv>
            <Spin
              size="large"
              spinning={
                updateMutation.isLoading ||
                createMutation.isLoading ||
                publishMutation.isLoading ||
                imageCompressorLoading ||
                saveIsLoading
              }
              tip={imageCompressorLoading && t('createListing:createListing.compressImage')}
            >
              <Form
                layout="vertical"
                form={stepForm}
                initialValues={toForm(listingContent)}
                onFieldsChange={() => {
                  setPublishable(calculatePublishable(stepForm));
                }}
              >
                <FormPanel steps={steps} activeStep={activeStep} />
              </Form>
            </Spin>
            <ActionPanel
              steps={steps}
              activeStep={activeStep}
              listing={listingContent}
              next={next}
              prev={prev}
              onSave={() => onSave(false)}
              onPublish={onPublish}
              publishable={publishable}
              isPublished={listingContent.is_published}
            />
          </RightDiv>
          <>
            {[createMutation, updateMutation, publishMutation].map(
              (mutation, key) =>
                !mutation.isIdle && mutation.error && <ErrorMessage key={key} error={mutation.error as HttpError} />,
            )}
          </>
        </DownVector>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  grid-area: main;
  overflow: auto;
  padding: 32px 4vw;
  @media only screen and (max-width: 1400px) {
    padding: 32px 2vw;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 48px;
  width: 100%;
`;

const DownVector = styled.div`
  display: flex;
  flex-direction: column;
`;

const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  @media only screen and (max-width: 850px) {
    width: 100%;
  }
  align-items: stretch;
`;
