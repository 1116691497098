import { Form, InputNumber } from 'antd';

import { FieldLength } from '../../../dtos/fields';
import React from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { formatNumber } from '../helpers';
import { useTranslation } from 'react-i18next';

export const FormFinancials = (props: {
  bilingual: boolean;
  setBilingual: React.Dispatch<React.SetStateAction<boolean>>;
  fr: false | JSX.Element;
  en: false | JSX.Element;
}): JSX.Element => {
  const { t } = useTranslation(['createListing']);

  return (
    <>
      <h4>{t('createListing:formFinancials.sectionTitle')}</h4>
      <Form.Item
        name={'revenue'}
        label={<label>{t('createListing:formFinancials.revenue.title')}</label>}
        rules={[
          {
            type: 'number',
            min: 0,
            max: 100000000,
            message: t('createListing:errors.between0and100'),
          },
        ]}
      >
        <InputNumber style={{ width: '100%' }} formatter={formatNumber} />
      </Form.Item>

      <Form.Item
        name={'ebitda'}
        label={<label>{t('createListing:formFinancials.ebitda.title')}</label>}
        rules={[
          {
            type: 'number',
            min: -100000000,
            max: 100000000,
            message: t('createListing:errors.between100and100'),
          },
        ]}
      >
        <InputNumber style={{ width: '100%' }} formatter={formatNumber} />
      </Form.Item>

      <Form.Item
        name={'net_income'}
        label={<label>{t('createListing:formFinancials.net_income.title')}</label>}
        rules={[
          {
            type: 'number',
            min: -100000000,
            max: 100000000,
            message: t('createListing:errors.between100and100'),
          },
        ]}
      >
        <InputNumber style={{ width: '100%' }} formatter={formatNumber} />
      </Form.Item>

      <Form.Item
        name={'assets_def'}
        label={
          <label>
            {t('createListing:formFinancials.assets.title')}
            {props.fr}
          </label>
        }
        rules={[
          {
            max: FieldLength.MEDIUM,
            message: t('createListing:errors.ruleTooLong', { length: FieldLength.MEDIUM }),
          },
          { whitespace: true, message: t('createListing:errors.whitespace') },
        ]}
        tooltip={t('createListing:formFinancials.assets.tooltip')}
      >
        <TextArea rows={4} placeholder={t('createListing:formFinancials.assets.placeholder')} />
      </Form.Item>

      {props.bilingual && (
        <Form.Item
          name={'assets_add'}
          label={
            <label>
              {t('createListing:formFinancials.assets.title')}
              {props.en}
            </label>
          }
          rules={[
            {
              max: FieldLength.MEDIUM,
              message: t('createListing:errors.ruleTooLong', { length: FieldLength.MEDIUM }),
            },
            { whitespace: true, message: t('createListing:errors.whitespace') },
          ]}
          tooltip={t('createListing:formFinancials.assets.tooltip')}
        >
          <TextArea rows={4} placeholder={t('createListing:formFinancials.assets.placeholder')} />
        </Form.Item>
      )}

      <Form.Item
        name={'leases_def'}
        label={
          <label>
            {t('createListing:formFinancials.leases.title')}
            {props.fr}
          </label>
        }
        rules={[
          {
            max: FieldLength.MEDIUM,
            message: t('createListing:errors.ruleTooLong', { length: FieldLength.MEDIUM }),
          },
          { whitespace: true, message: t('createListing:errors.whitespace') },
        ]}
      >
        <TextArea rows={4} placeholder={t('createListing:formFinancials.leases.placeholder')} />
      </Form.Item>
      {props.bilingual && (
        <Form.Item
          name={'leases_add'}
          label={
            <label>
              {t('createListing:formFinancials.leases.title')}
              {props.en}
            </label>
          }
          rules={[
            {
              max: FieldLength.MEDIUM,
              message: t('createListing:errors.ruleTooLong', { length: FieldLength.MEDIUM }),
            },
            { whitespace: true, message: t('createListing:errors.whitespace') },
          ]}
        >
          <TextArea rows={4} placeholder={t('createListing:formFinancials.leases.placeholder')} />
        </Form.Item>
      )}
    </>
  );
};
