import React, { ReactElement } from 'react';

import { Button } from 'antd';
import { red } from '@ant-design/colors';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

export enum ButtonStatus {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

type ButtonSmallProps = {
  status: string;
  text: string | ReactElement;
  icon?: React.ReactNode;
  onClick?: () => void;
  block?: boolean;
  disabled?: boolean;
};

export const ButtonSmall = (props: ButtonSmallProps): JSX.Element => (
  <StyledButton status={props.status} disabled={props.disabled} onClick={props.onClick}>
    {props.block ? (
      <Button type="primary" size="large" htmlType="submit" disabled={props.disabled} block>
        {props.text}
        {props.icon}
      </Button>
    ) : (
      <Button type="primary" size="large" htmlType="submit" disabled={props.disabled}>
        {props.text}
        {props.icon}
      </Button>
    )}
  </StyledButton>
);

const StyledButton = styled.div<{ status?: string; disabled?: boolean }>`
  .ant-btn-primary {
    border-radius: 5px;
    border-width: 2px;
    background-color: ${(props) => {
      if (props.disabled) {
        return;
      }
      switch (props.status) {
        case ButtonStatus.SECONDARY:
          return theme.color.darkBlue;
        case ButtonStatus.PRIMARY:
          return theme.color.green;
        case ButtonStatus.TERTIARY:
          return theme.color.white;
        default:
          return red[4];
      }
    }};
    border-color: ${(props) => {
      if (props.disabled) {
        return;
      }
      switch (props.status) {
        case ButtonStatus.SECONDARY:
          return theme.color.darkBlue;
        case ButtonStatus.PRIMARY:
          return theme.color.green;
        case ButtonStatus.TERTIARY:
          return '#d9d9d9';
        default:
          return red[4];
      }
    }};
    color: ${(props) => {
      if (props.disabled) {
        return;
      }
      switch (props.status) {
        case ButtonStatus.SECONDARY:
          return theme.color.white;
        case ButtonStatus.PRIMARY:
          return theme.color.white;
        case ButtonStatus.TERTIARY:
          return theme.color.darkBlue;
        default:
          return red[4];
      }
    }};
  }
  &:hover {
    .ant-btn-primary {
      background-color: ${(props) => {
        if (props.disabled) {
          return;
        }
        switch (props.status) {
          case ButtonStatus.SECONDARY:
            return '#4d5677';
          case ButtonStatus.PRIMARY:
            return '#79b797';
          case ButtonStatus.TERTIARY:
            return theme.color.white;
          default:
            return red[4];
        }
      }};
      border-color: ${(props) => {
        if (props.disabled) {
          return;
        }
        switch (props.status) {
          case ButtonStatus.SECONDARY:
            return theme.color.darkBlue;
          case ButtonStatus.PRIMARY:
            return theme.color.green;
          case ButtonStatus.TERTIARY:
            return theme.color.darkBlue;
          default:
            return red[4];
        }
      }};
    }
  }
`;
