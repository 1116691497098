import React, { useContext } from 'react';
import { privateRoutes, publicRoutes } from '../../router/routes';

import { LocaleContext } from '../../router/Router';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useTranslation } from 'react-i18next';

export const HeaderSiteSections = (): JSX.Element => {
  const { t } = useTranslation('header');
  const { authTokens } = useContext(UserContext);
  const { locale } = useContext(LocaleContext);

  return (
    <StyledNav>
      <NavLink to={publicRoutes.businessesForSale(locale)}>
        <h6> {t('headerContent.buyABusiness')}</h6>
      </NavLink>
      {authTokens && (
        <NavLink to={privateRoutes.myListings(locale)}>
          <h6>{t('headerContent.sellABusiness')}</h6>
        </NavLink>
      )}
      {!authTokens && (
        <NavLink to={publicRoutes.sellABusiness(locale)}>
          <h6>{t('headerContent.sellABusiness')}</h6>
        </NavLink>
      )}
      <NavLink to={publicRoutes.franchiseSection(locale)}>
        <h6>{t('headerContent.startAFranchise')}</h6>
      </NavLink>
      <NavLink to={publicRoutes.brokerSection(locale)}>
        <h6>{t('headerContent.findABroker')}</h6>
      </NavLink>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
  text-align: center;
  padding-left: 32px;

  h6 {
    margin-bottom: 0 !important;
  }

  h6:hover {
    color: ${theme.color.green} !important;
  }

  .active {
    h6 {
      color: ${theme.color.green} !important;
    }
  }
`;
