import React, { useState } from 'react';

import { ReceivedMessages } from './ReceivedMessages';
import { SentMessages } from './SentMessages';
import { ViewDashboard } from '../../common/views/ViewDashboard';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const MyMessages = (): JSX.Element => {
  const { state } = useLocation();
  const [isReceived, setIsReceived] = useState(state !== null ? state.isReceived : true);
  const { t } = useTranslation('myMessages');
  return (
    <ViewDashboard>
      <h3>{t('myMessages.myMessages')}</h3>
      <Pgrey>{t('myMessages.deleted')}</Pgrey>

      <StyledFlex>
        <H6Received isReceived={isReceived} onClick={() => setIsReceived(true)}>
          {t('myMessages.reveivedMessages')}
        </H6Received>
        <H6Sent isReceived={isReceived} onClick={() => setIsReceived(false)}>
          {t('myMessages.sentMessages')}
        </H6Sent>
      </StyledFlex>
      {isReceived ? <ReceivedMessages /> : <SentMessages />}
    </ViewDashboard>
  );
};

const StyledFlex = styled.div`
  display: flex;
  margin-top: 24px;
  column-gap: 32px;
`;

const H6Received = styled.h6<{ isReceived: boolean }>`
  color: ${(props) => (props.isReceived ? theme.color.green : '')};
  cursor: pointer;
`;

const H6Sent = styled.h6<{ isReceived: boolean }>`
  color: ${(props) => (!props.isReceived ? theme.color.green : '')};
  cursor: pointer;
`;

const Pgrey = styled.p`
  color: ${theme.color.darkGrey};
`;
