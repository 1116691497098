export const redirectedRoutesMigration: { from: string; to: string }[] = [
  {
    from: '/prod/home-fr.html',
    to: '/fr/home',
  },
  {
    from: '/prod/secteur/insolvabilite',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/home-franchise-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/secteur/finance.assurances',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/internet',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/sante.services.sociaux',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/automobile',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/hebergement.restauration',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/entretien.nettoyage',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/transport.entreposage',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/home-en.html',
    to: '/en/home',
  },
  {
    from: '/prod/secteur/construction',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/commerce.detail',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/services.personnels.residentiels',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/commerce.gros',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/fabrication.transformation',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/tourisme',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/spectacles.loisirs',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/communication',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/immobilier.commercial',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/location',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/agriculture',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/enseignement',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/haute.technologie',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/secteur/services.professionnels.techniques',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/maintenance.cleaning',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/sector/shows.recreation',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/static-franchise-adele-fr-2021-22.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-wendys-FR.2022-2023.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-chaussurespop-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-dagwoods-fr-2019-20.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-menu-animal-fr-2021-22.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/sector/manufacturing.transformation',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/static-franchise-presse-cafe-fr-2021.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-yuzu-sushi-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/sector/automobile',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/static-franchise-les-moulins-la-fayette-fr-2021-22.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/sector/agriculture',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/static-franchise-harveys-fr-2020-21.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/sector/commercial.real.estate',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/sector/health.social.services',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/static-franchise-cotard-fr-2021-22.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-massage-addict-fr-2022-23.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-my-breezzz-fr-2022-23.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/sector/retail.business',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/sector/professional.technical.services',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/sector/insolvability',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/static-franchise-padgett-fr-2022.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-columbus-cafe-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-benjamin-fr-2022.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-van-houtte-fr-2018-19.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/sector/construction',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/sector/tourism',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/sector/transportation.storage',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/sector/education',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/static-franchise-prohibition-fr-2022-2.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/sector/personal.residential.services',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/sector/wholesale.business',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/home-franchise-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/home-testimonials-fr.html',
    to: '/fr/home',
  },
  {
    from: '/prod/sector/high.technology',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/sector/finance.insurance',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/static-franchise-st-hubert-fr-2022-06.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-la-fabrique-de-bagel-montreal-fr-2021.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-titefrette-2022-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/sector/lodging.restaurants',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/static-franchise-pizza-pizza-fr-2021.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/sector/renting',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/sector/communications',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/static-franchise-ultramar-fr-2020-21.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/sector/internet',
    to: '/en/businessesForSale',
  },
  {
    from: '/prod/static-franchise-lodico-fr-2019-20.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-columbus-cafe-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-cotard-en-2021-22.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/formulaires/les-moulins-la-fayette-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/formulaires/menu-animal-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-presse-cafe-en-2021.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-chaussurespop-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-padgett-en-2022.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/formulaires/st-hubert-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-my-breezzz-en-2022-23.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-st-hubert-en-2022-06.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/formulaires/yuzu-sushi-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/home-contact-us-fr.html',
    to: '/fr/contactUs',
  },
  {
    from: '/prod/formulaires/massage-addict-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/formulaires/columbus-cafe-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/formulaires/cotard.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-van-houtte-en-2018-19.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-pizza-pizza-en-2021.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-les-moulins-la-fayette-en-2021-22.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-massage-addict-en-2022-23.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/home-testimonials-en.html',
    to: '/en/home',
  },
  {
    from: '/prod/static-franchise-benjamin-en-2022.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-harveys-en-2020-21.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-la-fabrique-de-bagel-montreal-en-2021.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-titefrette-2022-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/formulaires/pizza-pizza-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-menu-animal-en-2021-22.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-dagwoods-en-2019-20.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/formulaires/adele-plus.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-prohibition-en-2022-2.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-wendys-EN.2022-2023.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-adele-en-2021-22.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-ultramar-en-2020-21.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/formulaires/harveys-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-lodico-en-2019-20.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-yuzu-sushi-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/formulaires/massage-addict-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/formulaires/harveys-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/formulaires/st-hubert-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/home-contact-us-en.html',
    to: '/en/contactUs',
  },
  {
    from: '/prod/formulaires/pizza-pizza-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/acq15.js',
    to: '/fr/home',
  },
  {
    from: '/prod/acq20.js',
    to: '/fr/home',
  },
  {
    from: '/prod/acqDataEn.js',
    to: '/fr/home',
  },
  {
    from: '/prod/acqDataFr.js',
    to: '/fr/home',
  },
  {
    from: '/prod/acqEdit.js',
    to: '/fr/home',
  },
  {
    from: '/prod/acqFeatured-BUS.js',
    to: '/fr/home',
  },
  {
    from: '/prod/acqFranchise.js',
    to: '/fr/home',
  },
  {
    from: '/prod/acqLangEn.js',
    to: '/fr/home',
  },
  {
    from: '/prod/acqLangFr.js',
    to: '/fr/home',
  },
  {
    from: '/prod/acqSearch.js',
    to: '/fr/home',
  },
  {
    from: '/prod/acqStats.js',
    to: '/fr/home',
  },
  {
    from: '/prod/acqTip.js',
    to: '/fr/home',
  },
  {
    from: '/prod/benjaminmoore.ca',
    to: '/fr/notFound',
  },
  {
    from: '/prod/benjaminmoore.ca/newdealers',
    to: '/fr/notFound',
  },
  {
    from: '/prod/benjaminmoore.ca/partnerwithus',
    to: '/fr/notFound',
  },
  {
    from: '/prod/photos/A140770-1.jpg',
    to: '/fr/notFound',
  },
  {
    from: '/',
    to: '/fr/home',
  },
  {
    from: '/prod/formulaires/chocolat-favoris-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/formulaires/chocolats-Favoris-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/formulaires/fbm-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/formulaires/fbm-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/formulaires/parfait-menage-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/formulaires/sofatech-form.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/home-contact-us-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/home-contact-us-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/home-en.html',
    to: '/fr/home',
  },
  {
    from: '/prod/sector/agriculture',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/automobile',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/commercial.real.estate',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/communications',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/construction',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/education',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/finance.insurance',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/health.social.services',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/high.technology',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/insolvability',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/internet',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/lodging.restaurants',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/maintenance.cleaning',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/manufacturing.transformation',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/personal.residential.services',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/professional.technical.services',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/renting',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/retail.business',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/shows.recreation',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/tourism',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/transportation.storage',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/sector/wholesale.business',
    to: '/fr/businessesForSale',
  },
  {
    from: '/prod/static-franchise-chocolat-favoris-en_2022-09-30.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-chocolat-favoris-fr_2022-09-30.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-fbm-en-01-2023.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-fbm-fr-01-2023.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-massage-addict-en-2022-10-05.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-massage-addict-fr-2022-10-05.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-parfait-menage-2023-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-sofatech-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-polygon-fr-2017.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/secteur/',
    to: '/fr/home',
  },
  {
    from: '/prod/static-franchise-globe-note-distribution-en-2020-21.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-basco-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-barbies-fr-2017-18.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-au-coq-fr-2019-20.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-valentine-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-aki-sushi-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-group-kleincity-en-2020-21.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-valentine-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/chronique-SC-libre-de-toute-charge.html',
    to: 'fr/brokerSection',
  },
  {
    from: '/prod/experts-RichardsonGMP-set-expectations-for-moderate-growth.html',
    to: 'fr/brokerSection',
  },
  {
    from: '/prod/static-franchise-combo-st-hubert-harveys-en-2020-21.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-au-coq-en-2019-20.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-amaro-en-2018-19.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-chez-cora-en-2018-19.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-planete-poutine-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-fritou-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-ben-florentine-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-ben-florentine-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-fitclub24-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-steforno-en-2017-18.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-les-moulins-la-fayette-en-2020-21.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-harveys-fr-2017.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-cellzone-fr-2020-21.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-pizza-salvatore-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/sector/commercial.real',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/formulaires/maitre-glacier-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-scores-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-inspection-residentielle-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-signarama-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-cafe-depot-en-2019.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-thai-express-fr-2019.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-la-cremiere-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-sushi-shop-en-2019.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-ben-et-florentine-en-2019-20.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-la-casa-grecque-fr-2019-20.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-aliments-mm-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/chronique-SC-consentements-requis-pour-la-vente-d-entreprise.html',
    to: 'fr/brokerSection',
  },
  {
    from: '/prod/static-franchise-modern-fr-2020-21.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-les-moulins-la-fayette-fr-2019-20.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-groupe-investors-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-cacao70-fr-2017.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/chronique-Sunbelt-valeur-entreprise.html',
    to: 'fr/brokerSection',
  },
  {
    from: '/prod/static-franchise-la-casa-grecque-en-2019-20.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-mucho-burrito-fr-2019.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-fritou-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/secteur/immo',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-mom-fr-2017.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-carbon-cleaning-fr-2018-19.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-pita-pit-en-2020-21.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/experts-RichardsonGMP-europe-land-of-opportunity.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-renobox-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-rouge-boeuf-fr-2018-19.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/chronique-SC-vente-entreprise-1.html',
    to: 'fr/brokerSection',
  },
  {
    from: '/prod/static-franchise-beton-surface-en-2019-20.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-mucho-burrito-en-2019.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/experts-RichardsonGMP-attendez-vous-a-une-croissance-moderee.html',
    to: 'fr/brokerSection',
  },
  {
    from: '/prod/static-franchise-mom-en-2017.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-modern-en-2020-21.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-maitre-glacier-fr-2020-21.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-giorgio-en-2019-20.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/formulaires/dermaconcept-fr.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-thermo-trap-en-2018-19.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-presse-cafe-en-2016.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-presse-cafe-fr-2016.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/chronique-Sunbelt-importance-confidentialite.html',
    to: 'fr/brokerSection',
  },
  {
    from: '/prod/static-franchise-sesame-en-2017.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-cellzone-en-2020-21.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-sofatech-en-2019-20.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/experts-RichardsonGMP-investment-mistakes.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/static-franchise-maitre-glacier-en-2020-21.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-chocolato-en-2019-20.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/formulaires/aliments-mm-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-gestion-succes-en-2019-20.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/chronique-SC-vente-entreprise-1.html',
    to: '/fr/home',
  },
  {
    from: '/prod/secteur/immo',
    to: '/fr/home',
  },
  {
    from: '/prod/static-franchise-fitclub24-fr.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/chronique-Sunbelt-importance-confidentialite.html',
    to: '/fr/home',
  },
  {
    from: '/prod/chronique-SC-consentements-requis-pour-la-vente-d-entreprise.html',
    to: '/fr/home',
  },
  {
    from: '/prod/static-franchise-polygon-en-2017.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-carlos-et-pepes-fr-2019-20.html',
    to: '/fr/franchiseSection',
  },
  {
    from: '/prod/experts-RichardsonGMP-investment-mistakes.html',
    to: '/fr/home',
  },
  {
    from: '/prod/static-franchise-parfait-menage-en-2018-19.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-thaizone-en.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/static-franchise-bacaro-en-2019-20.html',
    to: '/en/franchiseSection',
  },
  {
    from: '/prod/experts-RichardsonGMP-attendez-vous-a-une-croissance-moderee.html',
    to: '/fr/home',
  },
  {
    from: '/prod/static-franchise-bacaro-fr-2019-20.html',
    to: '/fr/franchiseSection',
  },
];
