import React from 'react';
import styled from 'styled-components';

export const FormPanel = (props: {
  steps: { step: number; content: JSX.Element }[];
  activeStep: number;
}): JSX.Element => {
  return (
    <>
      {props.steps.map((item, key) => (
        <StyledDiv key={key} style={{ display: item.step === props.activeStep ? '' : 'none' }}>
          {item.content}
        </StyledDiv>
      ))}
    </>
  );
};

const StyledDiv = styled.div`
  .ant-input-number-handler-wrap {
    visibility: hidden;
  }
`;
