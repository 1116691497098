import * as userService from '../../../services/UserService';

import { ButtonSmall, ButtonStatus } from '../../../common/button/ButtonSmall';
import { Form, Modal, Radio, Spin } from 'antd';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Store } from 'rc-field-form/lib/interface';
import { UserContext } from '../../../contexts/UserContext';
import { UserData } from '../../../dtos/user';
import { ViewCentered } from '../../../common/views/ViewCentered';
import styled from 'styled-components';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const UpdateRole = (): JSX.Element => {
  const { tokenErrorHandler, setUserTypeOnUpdate } = useContext(UserContext),
    axiosMiddleware = useAxiosMiddleware(),
    navigate = useNavigate(),
    location = useLocation(),
    { t } = useTranslation(['profileForm']),
    [updateUserForm] = Form.useForm();

  const onSuccess = () => {
    setUserTypeOnUpdate(updateUserForm.getFieldValue('user_type'));
    Modal.success({
      content: t(`profileForm:update_user.success`),
      onOk: () => navigate(-1),
      okText: t('profileForm:back'),
    });
  };

  const onFinish = (data: UserData) => {
    updateUserMutation.mutate({ data: data, axiosMid: axiosMiddleware });
  };

  const updateUserMutation = useMutation(userService.updateUser, {
    onSuccess: onSuccess,
    onError: tokenErrorHandler,
  });

  return (
    <ViewCentered maxWidth={'400px'}>
      <Spin size="large" spinning={updateUserMutation.isLoading}>
        <Form
          form={updateUserForm}
          onFinish={onFinish}
          initialValues={location.state as Store}
          layout="vertical"
          requiredMark={false}
        >
          <StyledH4>{t('updateRole.title')}</StyledH4>
          <Form.Item
            name="user_type"
            label={<label>{t('updateRole.sub')}</label>}
            rules={[
              {
                required: true,
                message: t('updateRole.error'),
              },
            ]}
          >
            <Radio.Group>
              <Radio value="buyer">{t('updateRole.buyer')}</Radio>
              <Radio value="seller">{t('updateRole.seller')}</Radio>
              <Radio value="broker">{t('updateRole.broker')}</Radio>
            </Radio.Group>
          </Form.Item>
          <ButtonSmall status={ButtonStatus.PRIMARY} text={t('updateRole.submit')} />
        </Form>
      </Spin>
    </ViewCentered>
  );
};

const StyledH4 = styled.h4`
  margin-top: 32px;
`;
