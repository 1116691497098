import { CheckOutlined, SwapOutlined } from '@ant-design/icons';
import { Modal, Radio, RadioChangeEvent, Spin, Tooltip } from 'antd';
import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { LinstingAdmin } from '../../../dtos/admin';
import { StripeProduct } from '../../../dtos/listingCreation';
import { editListingMetaData } from '../../../services/AdminService';
import { getSubscription } from '../../../services/PaymentService';
import styled from 'styled-components';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';

export const UpgradeDowngradeAction = (props: LinstingAdmin) => {
  const axiosMiddleware = useAxiosMiddleware(),
    [products, setProducts] = useState<StripeProduct>([]),
    [modalOpen, setModalOpen] = useState(false),
    [listingType, setListingType] = useState(),
    [isChanged, setIsChanged] = useState(false);

  const getStripeSubscriptionMutation = useMutation({
    mutationFn: getSubscription,
    onError: (err) => {
      const errorDetail = (err as AxiosError).response?.data as { detail: string };
      Modal.error({ content: "Les metas données de l'annonce ne peuvent être modifiées car: " + errorDetail.detail });
    },
    onSuccess: (stripeProduct) => {
      if (stripeProduct) {
        setProducts(stripeProduct);
        setModalOpen(true);
      } else {
        Modal.error({
          content: "Les metas données des annonces ne peuvent être modifiées car l'utilisateur n'a pas de forfait",
        });
      }
    },
  });

  const onClick = () => {
    getStripeSubscriptionMutation.mutate({ axiosMid: axiosMiddleware, userId: props.user_id });
  };

  const onChange = (e: RadioChangeEvent) => {
    setListingType(e.target.value);
  };

  const editListingMetaDataMutation = useMutation({
    mutationFn: editListingMetaData,
    onError: (err) => {
      const errorDetail = (err as AxiosError).response?.data as { detail: string };
      Modal.error({ content: "Les metas données de l'annonce ne peuvent être modifiées car: " + errorDetail.detail });
    },
    onSuccess: () => {
      setModalOpen(false);
      setIsChanged(true);
      Modal.success({ content: "Les metas données de l'annonce ont été modifiées avec succès" });
    },
  });

  const handleOk = () => {
    if (listingType !== undefined) {
      editListingMetaDataMutation.mutate({
        axiosMid: axiosMiddleware,
        listing_id: props._id,
        priority_exposure: products[listingType].priority_exposure,
        featured: products[listingType].featured,
        content: products[listingType].content,
        email_freq: products[listingType].email_freq,
      });
    } else {
      Modal.error({
        content:
          "Les metas données des annonces ne peuvent être modifiées car le forfait ou le id de l'utilisateur sont invalide",
      });
    }
  };

  return (
    <div>
      <StyledModal width={400} open={modalOpen} onOk={() => handleOk()} onCancel={() => setModalOpen(false)}>
        <StyledColumn>
          <h2>
            <b>{"Modifier le statut de l'annonce"}</b>
          </h2>
          <p>
            <span>
              <u>{props.title}</u>
            </span>
            <br />
            <span>{'Affichage privilège: ' + props.priority_exposure.toString()}</span>
            <br />
            <span>{'Affichage en vedette: ' + props.featured.toString()}</span>
          </p>
          <Radio.Group onChange={onChange} value={listingType}>
            {products.map((listing, key) => {
              return (
                <Radio key={key} value={key} disabled={listing.allowed_listing_count <= 0}>
                  <div>
                    <p>
                      <b>{`Statut ${key + 1}`}</b>
                    </p>
                    <p>{'Annonces restantes: ' + products[key].allowed_listing_count.toString()}</p>
                    <p>{'Affichage privilège: ' + products[key].priority_exposure.toString()}</p>
                    <p>{'Affichage en vedette: ' + products[key].featured.toString()}</p>
                    <p>{'Nombre de photos: ' + products[key].content.toString()}</p>
                    <p>{'Nombre de email (3 premier mois): ' + products[key].email_freq.toString()}</p>
                  </div>
                </Radio>
              );
            })}
          </Radio.Group>
        </StyledColumn>
      </StyledModal>
      {isChanged ? (
        <Tooltip title={'Les meta données ont été modifiées'} placement={'left'}>
          <CheckOutlined style={{ color: 'green' }} />
        </Tooltip>
      ) : (
        <Tooltip title={"Modifier les métas données de l'annonce (standard vs privilège)"}>
          {getStripeSubscriptionMutation.isLoading ? <Spin /> : <SwapOutlined onClick={onClick} />}
        </Tooltip>
      )}
    </div>
  );
};

const StyledModal = styled(Modal)`
  h2,
  p {
    margin: 0;
  }
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
