import React, { useRef } from 'react';
import { Sections, StyledSubtitle, StyledTitle } from './FaqFR';

export const FaqEN = () => {
  const t1 = useRef(null);
  const t2 = useRef(null);
  const t3 = useRef(null);
  const t4 = useRef(null);

  // eslint-disable-next-line
  const executeScroll = (ref: any) => {
    ref.current.scrollIntoView();
  };

  return (
    <div>
      <StyledTitle>Sections</StyledTitle>
      <Sections onClick={() => executeScroll(t1)}>General informations</Sections>
      <Sections onClick={() => executeScroll(t2)}>Services for members</Sections>
      <Sections onClick={() => executeScroll(t3)}>Services for owners</Sections>
      <Sections onClick={() => executeScroll(t4)}>Services for brokers</Sections>
      <br />
      <br />

      <StyledTitle ref={t1}>General informations</StyledTitle>
      <p>
        <strong>Who are your members?</strong>
      </p>
      <p>
        Our members are qualified potential buyers and sellers of businesses and franchises and professionals offering
        support services.
      </p>
      <p>
        <strong>Is Acquisition.biz acting as an intermediary in the transaction?</strong>
      </p>
      <p>No. We only provide the platform that connects you with potential buyers and sellers.</p>
      <StyledTitle ref={t2}>Services for members</StyledTitle>
      <p>
        <strong>How much does it cost to become a member of Acquizition.biz?</strong>
      </p>
      <p>
        Nothing, indeed, consultation of registrations, subscription to personalized alerts and contact with sellers are
        free.
      </p>
      <p>
        <strong>How can I find business opportunities?</strong>
      </p>
      <p>
        You can create your search profile on the site using the filters in the &ldquo;Businesses for sale&rdquo;
        section. All you have to do is indicate the location you are interested in, the price, and the sector of
        activity. You can directly view the opportunities that match your criteria or request to receive them by email
        as they arise, or once a week.
      </p>
      <p>
        <strong>How can I be notified of new listings that match what I&apos;m looking for?</strong>
      </p>
      <p>
        It is very simple. Just head to the &ldquo;Alerts and Emails&rdquo; section in your account and define what you
        are looking for there. New registrations matching these criteria will be automatically sent to you by email.
      </p>
      <StyledTitle ref={t3}>Services for owners</StyledTitle>
      <StyledSubtitle>Payment and registration for owners</StyledSubtitle>
      <p>
        <strong>How much does it cost to advertise a business opportunity on Acquizition.biz?</strong>
      </p>
      <p>
        A basic listing costs $160/month, a premium listing costs $215/month, and an elite listing costs $245/month. The
        higher the level of registration, the greater the exposure and features, which attract buyers&apos; interest.
        Please note that the minimum registration period is three months.
      </p>
      <p>
        <strong>Can I buy a listing for just one month?</strong>
      </p>
      <p>
        No, our registration conditions are 3 months. This duration ensures that you have the time to market your
        business appropriately, as finding the right buyer usually takes several months.
      </p>
      <p>
        <strong>
          If I find a potential buyer before the minimum 3 month period ends, can I get a refund for the difference?
        </strong>
      </p>
      <p>No, we do not offer a refund if the contract is filled before the expiry of the minimum period of 3 months.</p>
      <p>
        <strong>What happens at the end of my 3 month registration period?</strong>
      </p>
      <p>
        At the end of your 3 month term, your listing will continue at your monthly rate so you can continue to reach
        the most potential buyers with monthly flexibility. You can cancel at any time, but you will need to purchase a
        new 3 month listing if you decide to renew after your listing expires. Please note that it is not uncommon for
        some companies to take a year or more to find the right buyer.
      </p>
      <p>
        <strong>Can I turn off monthly renewal?</strong>
      </p>
      <p>
        Yes. Your ad will expire at the end of your term and will be removed from the platform. Once cancelled, you will
        not be able to renew this listing with monthly flexibility and will need to purchase another 3 month term and
        create a new listing if you decide to advertise later.
      </p>
      <p>
        <strong>How can I pay to sign up for a plan?</strong>
      </p>
      <p>
        All payment is made by credit card. We accept Visa, MasterCard, and American Express for payment. We do not
        accept checks or bank transfers.
      </p>
      <StyledSubtitle>Listing creation</StyledSubtitle>
      <p>
        <strong>What information do I need to provide when registering my business?</strong>
      </p>
      <p>
        Once you&apos;ve signed up for a package of your choice, all you have to do is go to the &quot;Sell a
        business&quot; section in your account, create a new ad and indicate the information you want to reveal. The
        information you wish to disclose is entirely at your discretion. You may include your industry, a description of
        your business, if applicable, the type of transaction sought, and your contact information. From our experience,
        an advertisement with a complete and detailed description, which clearly presents the background, the values and
        the financial statements of the company, is more likely to attract potential buyers. Please note: you alone
        decide what you want to disclose.
      </p>
      <p>
        <strong>Is it possible to present my listing in French as well as in English?</strong>
      </p>
      <p>
        Yes, to do this, you just have to activate the functionality &quot;Complete this ad in French and English&quot;
        during the creation of the listing.
      </p>
      <p>
        <strong>Can I send you my listing by email?</strong>
      </p>
      <p>
        No. All listings are created via the online form on our website. However, if you need help completing the
        listing, do not hesitate to contact us at 514 499-0334 or 1-866-499-0334.
      </p>
      <StyledSubtitle>Privacy</StyledSubtitle>
      <p>
        <strong>How do you protect my identity when communicating with a potential client?</strong>
      </p>
      <p>
        Our system is expressly designed to protect your confidentiality from the first contact with potential buyers or
        sellers. We provide you with the anonymous contact form to receive the first request from members. Your
        correspondent does not see your name, email address or any other information that could be used to identify you.
        Following this first contact, you can continue the communication according to your discretion.
      </p>
      <StyledSubtitle>Sales support</StyledSubtitle>
      <p>
        <strong>Do you offer professional support services for the sale and purchase of businesses?</strong>
      </p>
      <p>
        Signing up for one of our packages gives you access to a transactional kit that includes basic documents such as
        a confidentiality agreement, a letter of intent, an exclusive negotiation agreement. You will also find a
        description of the sales process. In creating the toolkit, our goal was to provide you with sample documents
        that you may find useful during the first stage of selling your business. We do not offer professional support
        beyond the transactional kit.
      </p>
      <p>We also offer you access to a directory of brokers who can facilitate the successful sale of your business.</p>
      <StyledTitle ref={t4}>Services for brokers</StyledTitle>
      <StyledSubtitle>Payment and registration</StyledSubtitle>
      <p>
        <strong>
          How much does it cost to advertise business opportunities on Acquizition.biz as a representative or broker?
        </strong>
      </p>
      <p>
        We offer packages designed according to the number of listings and the duration. You can consult our rates by
        creating an account as a broker or by sending us a request for information at info@acquizition.biz.
      </p>
      <p>
        <strong>How can I pay to sign up for a plan?</strong>
      </p>
      <p>
        All payment is made by credit card. We accept Visa, MasterCard, and American Express for payment. We do not
        accept checks or bank transfers.
      </p>
      <StyledSubtitle>Monthly plans</StyledSubtitle>
      <p>*Please note that all our monthly plans are offered with a minimum subscription period of 3 months</p>
      <p>
        <strong>
          If I complete my mandates before the minimum period of 3 months ends, can I have a refund for the difference?
        </strong>
      </p>
      <p>No, we do not offer refunds if the mandates are filled before the expiry of the minimum period of 3 months.</p>
      <p>
        <strong>What happens at the end of the 3 month registration period?</strong>
      </p>
      <p>
        At the end of your 3 month term, your listings will continue at your monthly rate so you can continue to reach
        the most potential buyers with monthly flexibility. You can cancel at any time, but you will need to purchase a
        new 3 month contract if you decide to renew after your listings have expired.
      </p>
      <p>
        <strong>Can I turn off monthly renewal?</strong>
      </p>
      <p>
        Yes. Your listings will expire at the end of your term and will be removed from the platform. Once cancelled,
        you will not be able to renew these listings with monthly flexibility and will need to purchase another 3 month
        term and create new listings if you decide to advertise later.
      </p>
      <StyledSubtitle>Annual packages</StyledSubtitle>
      <p>
        <strong>
          If I complete my mandates before the registration period ends, can I get a refund for the difference?
        </strong>
      </p>
      <p>
        Since the annual plans are designed to offer you the best prices, we do not offer refunds if the mandates are
        filled before the end of the registration period.
      </p>
      <StyledSubtitle>Professional services offer</StyledSubtitle>
      <p>
        <strong>
          I offer professional services related to business transactions, but I am not a broker. Can I promote my
          services on your platform?
        </strong>
      </p>
      <p>
        Our members who buy or sell businesses often need the services of professionals. We are delighted to offer you
        different advertising formats in order to offer maximum visibility to a very targeted clientele.
      </p>
    </div>
  );
};
