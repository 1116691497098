import { Form, Input, Modal, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { Checkbox } from 'antd';
import { FranchiseInCache } from '../../dtos/franchise';
import { UserContext } from '../../contexts/UserContext';
import { contactFranchisor } from '../../services/FranchiseService';
import { getUserProfile } from '../../services/UserService';
import styled from 'styled-components';
import { useAxiosMiddleware } from '../../services/useAxiosMiddleware';
import { useTranslation } from 'react-i18next';

export const FranchiseContactForm = (props: {
  useVisible: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}) => {
  const { t } = useTranslation('franchiseInfo');

  const [visibleForm, setVisibleForm] = props.useVisible;
  const [franchiseContactForm] = Form.useForm();

  const authToken = useContext(UserContext);
  if (authToken) {
    const axiosMiddleware = useAxiosMiddleware();
    const { data } = useQuery(['getUserData'], () => getUserProfile(axiosMiddleware));
    useEffect(() => {
      franchiseContactForm.setFieldsValue({
        first_name: data?.first_name,
        last_name: data?.last_name,
        email: data?.email,
      });
    }, [data]);
  }

  const onSuccess = () => {
    setVisibleForm(false);
    Modal.success({
      content: t('franchiseContactForm.success'),
    });
  };

  const contactFranchisorMutation = useMutation(contactFranchisor, {
    onSuccess: onSuccess,
  });

  const [franchiseList, setFranchiseList] = useState<FranchiseInCache[]>(
    JSON.parse(localStorage.getItem('franchiseList') || '[]'),
  );

  useEffect(() => {
    setFranchiseList(JSON.parse(localStorage.getItem('franchiseList') || '[]'));
    franchiseContactForm.setFieldValue(
      'franchises',
      JSON.parse(localStorage.getItem('franchiseList') || '[]').map((franchise: FranchiseInCache) => franchise._id),
    );
  }, [visibleForm]);

  const availabilities = ['9:00 - 12:00', '12:00 - 17:00', '17:00 - 21:00'];

  const handleOk = async () => {
    try {
      const res = await franchiseContactForm.validateFields();
      if (contactFranchisorMutation.isLoading) {
        return;
      }
      contactFranchisorMutation.mutate({
        franchises: franchiseList.filter((franchise) => res.franchises.includes(franchise._id)),
        first_name: res.first_name,
        last_name: res.last_name,
        email: res.email,
        phone: res.phone,
        location: res.location,
        cash_down: res.cash_down,
        availabilities: res.availabilities,
      });
    } catch (e) {
      return;
    }
  };

  const handleCancel = () => {
    setVisibleForm(false);
  };

  return (
    <>
      <Modal
        title={t('franchiseContactForm.title')}
        open={visibleForm}
        onCancel={handleCancel}
        cancelText={t('franchiseContactForm.cancel')}
        onOk={handleOk}
        okText={t('franchiseContactForm.send')}
      >
        <Spin spinning={contactFranchisorMutation.isLoading} size={'large'}>
          <StyledDiv>
            <Form
              form={franchiseContactForm}
              layout={'vertical'}
              requiredMark={'optional'}
              initialValues={{
                availabilities: availabilities,
                franchises: franchiseList.map((franchise) => franchise._id),
              }}
            >
              <Form.Item
                name={'franchises'}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('franchises').length === 0) {
                        return Promise.reject(new Error(t('franchiseContactForm.minOneFranchise')));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Checkbox.Group
                  options={franchiseList.map((franchise) => {
                    return { label: franchise.title, value: franchise._id || '' };
                  })}
                />
              </Form.Item>
              <Form.Item
                name={'first_name'}
                label={t('franchiseContactForm.firstName')}
                rules={[
                  {
                    required: true,
                    message: t('franchiseContactForm.required'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={'last_name'}
                label={t('franchiseContactForm.lastName')}
                rules={[
                  {
                    required: true,
                    message: t('franchiseContactForm.required'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={'email'}
                label={t('franchiseContactForm.email')}
                rules={[
                  {
                    required: true,
                    message: t('franchiseContactForm.required'),
                  },
                  { type: 'email', message: t('franchiseContactForm.emailFormat') },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={'phone'}
                label={t('franchiseContactForm.phone')}
                rules={[
                  {
                    required: true,
                    message: t('franchiseContactForm.required'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={'location'}
                label={t('franchiseContactForm.location')}
                rules={[
                  {
                    required: true,
                    message: t('franchiseContactForm.required'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={'cash_down'}
                label={t('franchiseContactForm.cashDown')}
                rules={[
                  {
                    required: true,
                    message: t('franchiseContactForm.required'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={'availabilities'}
                label={t('franchiseContactForm.availabilities')}
                rules={[
                  {
                    required: true,
                    message: t('franchiseContactForm.required'),
                  },
                ]}
              >
                <Checkbox.Group
                  options={availabilities.map((av) => {
                    return { label: av, value: av };
                  })}
                />
              </Form.Item>
            </Form>
          </StyledDiv>
        </Spin>
      </Modal>
    </>
  );
};

const StyledDiv = styled.div`
  .ant-form-item {
    margin-bottom: 8px !important;
  }
`;
