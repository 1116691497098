import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { Col, Row } from 'antd';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { privateRoutes, publicRoutes } from '../../router/routes';
import { useNavigationType, useSearchParams } from 'react-router-dom';

import { Image } from 'antd';
import { ListingTile } from '../businessesForSale/listingTile/ListingTile';
import { LocaleContext } from '../../router/Router';
import { NavLink } from 'react-router-dom';
import { ViewCentered } from '../../common/views/ViewCentered';
import { ViewNarrowColumn } from '../../common/views/ViewNarrowColumn';
import { groupedRegions } from '../../dtos/alertAndEmails';
import iamgeReport from './../../img/reportImage.webp';
import logo from '../../img/LOGO-Acquizition-HR.webp';
import { searchListingsAlerts } from '../../services/ListingService';
import styled from 'styled-components';
import { useInfiniteQuery } from '@tanstack/react-query';

const fetchListings = async (pageNumber: number, targetedRegions: string, timestamp: number) => {
  return searchListingsAlerts({
    pageNumber: pageNumber,
    targetedRegions: groupedRegions[targetedRegions],
    timestamp: timestamp,
  });
};

const toDate = (unixDate: string, locale: string) => {
  const lang = locale === 'fr' ? 'fr-FR' : 'en-US';
  return new Date(Number(unixDate) * 1000).toLocaleDateString(lang, {
    month: 'long',
    day: 'numeric',
  });
};

export const Report = () => {
  const { t } = useTranslation(['report', 'common']);
  const { locale } = useContext(LocaleContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigationType = useNavigationType();

  const [page, setPage] = useState(searchParams.get('page') ? Number(searchParams.get('page')) : 0);
  const [targetedRegions] = useState(
    searchParams.get('targeted_regions') ? searchParams.get('targeted_regions') : undefined,
  );
  const [timestamp] = useState(searchParams.get('timestamp') ? searchParams.get('timestamp') : undefined);
  const [prevId, setPrevId] = useState(searchParams.get('prev_id') ? searchParams.get('prev_id') : undefined);

  useEffect(() => {
    setSearchParams({
      targeted_regions: targetedRegions || '',
      timestamp: timestamp?.toString() || '',
    });
    return;
  }, []);

  const { data, fetchNextPage, isFetching, remove, refetch, error } = useInfiniteQuery({
    queryKey: ['getListingsAlert'],
    queryFn: ({ pageParam = { page: page, targetedRegions: targetedRegions, timestamp: timestamp } }) => {
      return fetchListings(pageParam.page, pageParam.targetedRegions, pageParam.timestamp);
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.listing_list?.length === 0) {
        return undefined;
      }
      return { page: page + 1, targetedRegions: targetedRegions, timestamp: timestamp };
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const observer = useRef<IntersectionObserver | undefined>();

  const lastListingElementRef = useCallback(
    (node: Element | null) => {
      if (isFetching) return;
      if (observer && observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchNextPage();
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetching, data],
  );

  useEffect(() => {
    if (!prevId) {
      remove();
      setPage(0);
      refetch();
    }
    return;
  }, []);

  useEffect(() => {
    if (navigationType === 'POP' && prevId) {
      document.getElementById(prevId.toString())?.scrollIntoView({ behavior: 'auto', block: 'end' });
      setPrevId(undefined);
    }
    return;
  }, [navigationType]);

  return error ? (
    <ViewCentered maxWidth="500px">
      <h4>{t('common:serverError')}</h4>
    </ViewCentered>
  ) : (
    <ViewNarrowColumn>
      <StyledRow justify="center">
        <Block>
          <Row justify="center" align="middle" gutter={[32, 0]}>
            <Col xl={14} xxl={12}>
              <Row gutter={[0, 16]}>
                <Col>
                  <Row>
                    <h1>
                      <Trans
                        i18nKey={'report:title'}
                        values={{
                          date: toDate(timestamp || '', locale),
                          region: t(`report:${targetedRegions}`),
                        }}
                        components={{ bold: <strong />, br: <br /> }}
                      />
                    </h1>
                  </Row>
                  <Row>
                    <p>
                      <Trans
                        i18nKey={'report:subtitle'}
                        values={{
                          cnt: data?.pages?.[0]?.total,
                          region: t(`report:${targetedRegions}`),
                        }}
                        components={{ bold: <strong /> }}
                      />
                    </p>
                  </Row>
                  <Row align={'middle'}>
                    <div>
                      <h3>{t('presented')}</h3>
                    </div>
                    <div>
                      <img src={logo} alt="Acquizition logo" height={32} />
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xl={10} xxl={8}>
              <Hidden>
                <StyledImg src={iamgeReport} preview={false} alt="Rapport" />
              </Hidden>
            </Col>
          </Row>
        </Block>
      </StyledRow>
      <Row justify="center">
        <Col xl={24} xxl={20}>
          <Block>
            <div>
              {targetedRegions &&
                timestamp &&
                data?.pages.map((result) => {
                  return result.listing_list?.map((tile, arrayIndex) => {
                    return (
                      <div
                        id={tile._id}
                        ref={
                          data.pages?.[data.pages.length - 1]?.listing_list?.[
                            data.pages[data.pages.length - 1].listing_list.length - 1
                          ]
                            ? lastListingElementRef
                            : null
                        }
                        key={arrayIndex}
                      >
                        <ListingTile
                          tile={tile}
                          searchState={{
                            page: page,
                            searchParams: {
                              targetedRegions: targetedRegions,
                              timestamp: timestamp,
                            },
                          }}
                        />
                      </div>
                    );
                  });
                })}
            </div>
            {!isFetching && data?.pages && data.pages.length > 1 && (
              <ReportEnd>
                <h5>{t('noResult')}</h5>
                <FlexDiv>
                  <NavLink to={privateRoutes.alertsAndMails(locale)}>
                    <ButtonSmall status={ButtonStatus.PRIMARY} text={t('editAlerts')} />
                  </NavLink>
                  <NavLink to={publicRoutes.businessesForSale(locale)}>
                    <ButtonSmall status={ButtonStatus.SECONDARY} text={t('seeListings')} />
                  </NavLink>
                </FlexDiv>
              </ReportEnd>
            )}
          </Block>
        </Col>
      </Row>
    </ViewNarrowColumn>
  );
};

const Block = styled.section`
  padding: 4vw 4vw 4vw 4vw;
  max-width: 1800px;
  h3 {
    margin-bottom: 0;
  }
`;

const StyledRow = styled(Row)`
  background-color: #eff6f5;
  width: 100%;
`;

const StyledImg = styled(Image)`
  border-radius: 32px 0px 32px 32px;
`;

const ReportEnd = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  gap: 16px;
`;

const Hidden = styled.div`
  display: block;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
