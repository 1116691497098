import * as userService from '../../../services/UserService';

import { ButtonSmall, ButtonStatus } from '../../../common/button/ButtonSmall';
import { Checkbox, Form, Input, Modal, Spin } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { NavLink, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { privateRoutes, publicRoutes } from '../../../router/routes';

import { ErrorMessage } from '../../../common/ErrorMessage';
import { FieldLength } from '../../../dtos/fields';
import { HelmetSignup } from '../../../helmets/HelmetSignup';
import { HttpError } from '../../../dtos/error';
import { LocaleContext } from '../../../router/Router';
import { ModaliteDUtilisation } from '../../termsOfUse/ModaliteDUtilisation';
import { SignUpData } from '../../../dtos/user';
import { UserContext } from '../../../contexts/UserContext';
import { ViewCentered } from '../../../common/views/ViewCentered';
import { confirmPasswordRule } from '../confirmPasswordRule';
import styled from 'styled-components';
import { theme } from '../../../styles/theme';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const SignUp = (): JSX.Element => {
  const navigate = useNavigate();
  const { authTokens } = useContext(UserContext);
  const { locale } = useContext(LocaleContext);

  const { t } = useTranslation(['profileForm', 'common']);

  useEffect(() => {
    if (authTokens) {
      navigate(privateRoutes.alertsAndMails(locale));
    }
  }, []);

  const signUpMutation = useMutation(userService.signUp, {
    onSuccess: () =>
      navigate(publicRoutes.confirmEmailForm(locale), { state: { email: formSignUp.getFieldValue('email') } }),
  });

  const onFinish = (data: SignUpData) => signUpMutation.mutate(data);

  const info = () => {
    Modal.info({
      width: '80%',
      title: t('profileForm:signUp.conditions.modal'),
      content: <ModaliteDUtilisation />,
      onOk() {},
    });
  };

  const [formSignUp] = Form.useForm();

  return (
    <ViewCentered maxWidth="464px">
      <HelmetSignup />
      <StyledH4>{t('profileForm:signUp.title')}</StyledH4>
      <p>
        {t('profileForm:signUp.alreadyAMember')}
        <NavLink to={publicRoutes.signin(locale)}>
          <StyledLink>{t('profileForm:signUp.signIn')}</StyledLink>
        </NavLink>
      </p>
      <>
        {signUpMutation.isLoading ? (
          <Spin size="large" />
        ) : (
          <div>
            <Form form={formSignUp} onFinish={onFinish} layout="vertical" requiredMark={'optional'}>
              <Form.Item
                name="first_name"
                label={<label>{t('profileForm:signUp.first_name.title')}</label>}
                rules={[
                  {
                    required: true,
                    message: t('profileForm:first_name'),
                  },
                  {
                    max: FieldLength.MEDIUM,
                    message: t('common:rules.tooLong', { length: FieldLength.MEDIUM }),
                  },
                ]}
              >
                <Input placeholder={t('profileForm:signUp.first_name.placeholder')} />
              </Form.Item>
              <Form.Item
                name="last_name"
                label={<label>{t('profileForm:signUp.last_name.title')}</label>}
                rules={[
                  {
                    required: true,
                    message: t('profileForm:last_name'),
                  },
                  {
                    max: FieldLength.MEDIUM,
                    message: t('common:rules.tooLong', { length: FieldLength.MEDIUM }),
                  },
                ]}
              >
                <Input placeholder={t('profileForm:signUp.last_name.placeholder')} />
              </Form.Item>
              <Form.Item
                name="email"
                label={<label>{t('profileForm:signUp.email.title')}</label>}
                rules={[
                  {
                    required: true,
                    message: t('profileForm:email'),
                  },
                  {
                    max: FieldLength.MEDIUM,
                    message: t('common:rules.tooLong', { length: FieldLength.MEDIUM }),
                  },
                  { type: 'email', message: t('profileForm:email') },
                ]}
              >
                <Input placeholder={t('profileForm:signUp.email.placeholder')} />
              </Form.Item>
              <Form.Item
                name="confirm_email"
                label={<label>{t('profileForm:signUp.confirm_email.title')}</label>}
                rules={[
                  {
                    required: true,
                    message: t('profileForm:email'),
                  },
                  {
                    max: FieldLength.MEDIUM,
                    message: t('common:rules.tooLong', { length: FieldLength.MEDIUM }),
                  },
                  { type: 'email', message: t('profileForm:email') },
                  confirmPasswordRule(t('profileForm:emailsMustMatch'), 'email'),
                ]}
              >
                <Input placeholder={t('profileForm:signUp.confirm_email.placeholder')} />
              </Form.Item>
              <Form.Item
                name="password"
                label={<label>{t('profileForm:signUp.password.title')}</label>}
                rules={[
                  {
                    required: true,
                    message: t('profileForm:password'),
                  },
                  {
                    required: true,
                    pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'),
                    message: t('profileForm:passwordRule'),
                  },
                ]}
              >
                <Input.Password
                  placeholder={t('profileForm:signUp.password.placeholder')}
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                ></Input.Password>
              </Form.Item>
              <Form.Item
                name="confirm_password"
                label={<label>{t('profileForm:signUp.confirm_password.title')}</label>}
                rules={[
                  {
                    required: true,
                    message: t('profileForm:confirm_password'),
                  },
                  confirmPasswordRule(t('profileForm:mustMatch'), 'password'),
                ]}
              >
                <Input.Password
                  placeholder={t('profileForm:signUp.confirm_password.placeholder')}
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                ></Input.Password>
              </Form.Item>
              <Form.Item
                name="consent_user_condition"
                rules={[
                  {
                    validator(_, value) {
                      return value ? Promise.resolve() : Promise.reject(new Error(t('profileForm:consent')));
                    },
                  },
                ]}
                valuePropName="checked"
              >
                <StyledCheckbox>
                  {t('profileForm:signUp.conditions.content')}
                  <StyledLink onClick={info}>{t('profileForm:signUp.conditions.link')}</StyledLink>
                </StyledCheckbox>
              </Form.Item>

              <LeftDiv>
                <ButtonSmall text={t('profileForm:signUp.signUp')} status={ButtonStatus.PRIMARY} />
              </LeftDiv>
            </Form>
          </div>
        )}
      </>
      <>
        {!signUpMutation.isIdle && signUpMutation.error && <ErrorMessage error={signUpMutation.error as HttpError} />}
      </>
    </ViewCentered>
  );
};

const StyledLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: ${theme.color.electricBlue};
`;

const StyledH4 = styled.h1`
  margin-top: 32px;
  font-size: ${theme.fontsize.h4} !important;
`;

const LeftDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledCheckbox = styled(Checkbox)`
  width: 100%;
`;
