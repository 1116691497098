import { DatePicker, Form, Input, InputNumber, UploadFile } from 'antd';

import { CONTENT } from '../../../dtos/listingCreation';
import { FieldLength } from '../../../dtos/fields';
import { FormImage } from './FormImage';
import React from 'react';
import { formatNumber } from '../helpers';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const FormAdditional = (props: {
  fileList: UploadFile<File>[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile<File>[]>>;
  dirtyImages: boolean;
  setDirtyImages: React.Dispatch<React.SetStateAction<boolean>>;
  content: number;
}): JSX.Element => {
  const { t } = useTranslation(['createListing']);

  return (
    <>
      <h4>{t('createListing:formAdditional.sectionTitle')}</h4>
      <FormImage
        fileList={props.fileList}
        setFileList={props.setFileList}
        dirtyImages={props.dirtyImages}
        setDirtyImages={props.setDirtyImages}
        content={props.content}
      />
      {props.content === CONTENT.Ten && (
        <Form.Item
          name={'video'}
          label={<label>{t('createListing:formAdditional.video.title')}</label>}
          tooltip={<SpanBreak>{t('createListing:formAdditional.video.tooltip')}</SpanBreak>}
          rules={[
            {
              pattern: new RegExp('^(https://youtu.be/).*$'),
              message: <SpanBreak>{t('createListing:formAdditional.video.tooltip')}</SpanBreak>,
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
      )}

      <Form.Item name={'date_founded'} label={<label>{t('createListing:formAdditional.date_founded.title')}</label>}>
        <DatePicker
          disabledDate={(current: moment.Moment) => current && current.valueOf() > Date.now()}
          style={{ width: '100%' }}
          picker="year"
        />
      </Form.Item>
      <Form.Item
        name={'number_employees'}
        label={<label>{t('createListing:formAdditional.number_employees.title')}</label>}
        rules={[
          {
            type: 'number',
            min: 0,
            max: 100000,
            message: t('createListing:errors.between0and10'),
          },
        ]}
      >
        <InputNumber style={{ width: '100%' }} formatter={formatNumber} />
      </Form.Item>
      <Form.Item
        name={'website'}
        label={<label>{t('createListing:formAdditional.website.title')}</label>}
        rules={[
          {
            max: FieldLength.SHORT,
            message: t('createListing:errors.ruleTooLong', { length: FieldLength.SHORT }),
          },
          { whitespace: true, message: t('createListing:errors.whitespace') },
        ]}
      >
        <Input type="text" />
      </Form.Item>
    </>
  );
};

const SpanBreak = styled.span`
  white-space: pre-line;
`;
