import { AdminMenu } from '../../components/admin/AdminMenu';
import { Header } from '../../components/header/Header';
import React from 'react';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

type AdminDashboard = {
  children: React.ReactNode;
};

export const ViewAdmin = ({ children }: AdminDashboard): JSX.Element => {
  return (
    <>
      <Header />
      <StyledContainer>
        <StyledMenu>
          <AdminMenu />
        </StyledMenu>
        <Content>{children}</Content>
      </StyledContainer>
    </>
  );
};

export const StyledContainer = styled.div`
  grid-area: main;
  padding-left: 4vw;
  padding-right: 4vw;
  @media only screen and (max-width: 1400px) {
    padding-left: 2vw;
    padding-right: 2vw;
  }
  overflow-y: scroll;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: max-content;
  grid-template-areas:
    'adminmenu'
    'content';
  column-gap: 16px;
  overflow: overlay;
`;

const Content = styled.div`
  grid-area: content;
  margin-top: 32px;
`;

export const StyledMenu = styled.div`
  grid-area: adminmenu;
  padding-top: 32px;
  border-color: ${theme.color.lightGrey};
  display: flex;
  column-gap: 3rem;

  h6:hover {
    color: ${theme.color.green} !important;
  }

  .active {
    h6 {
      color: ${theme.color.green} !important;
    }
  }
`;
