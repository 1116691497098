import { Alertes } from './Alertes';
import { Broker } from './Broker';
import { Carrousels } from './Carrousels';
import { Contact } from './Contact';
import { Footer } from '../../components/footer/Footer';
import { HelmetHomepage } from '../../helmets/HelmetHomepage';
import { Intro } from './Intro';
import { Metrics } from './Metrics';
import { Quotes } from './Quotes';
import React from 'react';
import { SellHook } from './SellHook';
import { ViewNarrowColumn } from '../../common/views/ViewNarrowColumn';

export const Home = (): JSX.Element => {
  return (
    <ViewNarrowColumn>
      <HelmetHomepage />
      <Intro />
      <Metrics />
      <Carrousels />
      <Alertes />
      <SellHook />
      <Broker />
      <Quotes />
      <Contact />
      <Footer />
    </ViewNarrowColumn>
  );
};
