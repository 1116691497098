import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { privateRoutes, publicRoutes } from '../../router/routes';

import { Image } from 'antd';
import { LocaleContext } from '../../router/Router';
import { NavLink } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { UserContext } from '../../contexts/UserContext';
import entrepreneur from './../../img/Entrepreneurs.webp';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const SellHook = (): JSX.Element => {
  const { t } = useTranslation('home');
  const { locale } = useContext(LocaleContext);
  const { authTokens } = useContext(UserContext);

  return (
    <StyledSection>
      <Row justify="center">
        <Block>
          <Row justify="center" align="middle">
            <Col lg={12}>
              <StyledH3>{t('home:sellHook.title1')}</StyledH3>
              <h3 style={{ fontWeight: 'bolder', color: theme.color.green }}>{t('home:sellHook.title2')}</h3>
              <FlexDiv>
                <StyledRightOutlined />
                <StyledP>
                  <Trans i18nKey={'home:sellHook.hook1'} components={{ bold: <strong /> }} />
                </StyledP>
              </FlexDiv>
              <FlexDiv>
                <StyledRightOutlined />
                <StyledP>
                  <Trans i18nKey={'home:sellHook.hook2'} components={{ bold: <strong /> }} />
                </StyledP>
              </FlexDiv>
              <FlexDiv>
                <StyledRightOutlined />
                <StyledP>
                  <Trans i18nKey={'home:sellHook.hook3'} components={{ bold: <strong /> }} />
                </StyledP>
              </FlexDiv>
              <FlexDiv>
                <StyledRightOutlined />
                <StyledP>
                  <Trans i18nKey={'home:sellHook.hook4'} components={{ bold: <strong /> }} />
                </StyledP>
              </FlexDiv>
              <FlexDiv>
                <ButtonDiv>
                  <FlexGrow>
                    <NavLink to={authTokens ? privateRoutes.myListings(locale) : publicRoutes.sellABusiness(locale)}>
                      <ButtonSmall status={ButtonStatus.PRIMARY} text={t('home:sellHook.button1')} block={true} />
                    </NavLink>
                  </FlexGrow>
                  <FlexGrow>
                    <NavLink to={publicRoutes.franchiseFranchisor(locale)}>
                      <ButtonSmall status={ButtonStatus.PRIMARY} text={t('home:sellHook.button2')} block={true} />
                    </NavLink>
                  </FlexGrow>
                </ButtonDiv>
              </FlexDiv>
            </Col>
            <Col lg={6}>
              <Row justify={'end'}>
                <Hidden>
                  <Image src={entrepreneur} preview={false} alt="Business owner" />
                </Hidden>
              </Row>
            </Col>
          </Row>
        </Block>
      </Row>
    </StyledSection>
  );
};

const Block = styled.section`
  padding: 4vw;
  max-width: 1800px;
  width: 100%;
`;

const StyledSection = styled.div`
  width: 100%;
`;

const Hidden = styled.div`
  display: block;
  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

const FlexDiv = styled.div`
  display: flex;
`;

const StyledP = styled.p`
  font-size: 18px !important;
`;

const StyledRightOutlined = styled(RightOutlined)`
  color: ${theme.color.green};
  font-size: 18px;
  margin-top: 6px;
  margin-right: 10px;
`;

const StyledH3 = styled.h3`
  font-weight: bolder;
  margin-bottom: 0;
`;
const ButtonDiv = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 24px;
`;

const FlexGrow = styled.div`
  width: 208px;
`;
