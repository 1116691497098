import { ContactFranchise, FranchiseDTO } from '../dtos/franchise';
import axios, { AxiosInstance } from 'axios';

const LISTING_URL = 'listing';
const MESSAGE_NOTIFICATION = 'message_notification';

export const searchFranchise = async (params: {
  show_only_publish: boolean;
  page: number;
  sort_parameters: string;
}): Promise<{ franchises: FranchiseDTO[]; total: number }> => {
  const res = await axios({
    method: 'POST',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${LISTING_URL}/franchise/search`,
    params: {
      page: params.page,
      number_of_franchise: 10,
      show_only_publish: params.show_only_publish,
      sort_parameters: params.sort_parameters,
    },
  });
  return res.data;
};

export const getFranchise = async (params: { axiosMid: AxiosInstance; franchiseId: string }): Promise<FranchiseDTO> => {
  const res = await params.axiosMid({
    method: 'GET',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${LISTING_URL}/franchise/${params.franchiseId}`,
  });
  return res.data;
};

export const contactFranchisor = async (params: ContactFranchise): Promise<string> => {
  const res = await axios({
    method: 'POST',
    baseURL: process.env.REACT_APP_NOTIFICATION_BASE_URL || 'http://127.0.0.1:/',
    url: `${MESSAGE_NOTIFICATION}/contact_franchisors/`,
    data: params,
  });
  return res.data;
};

export const getFranchiseCarousel = async (params: { nbOfFranchises: number }): Promise<FranchiseDTO[]> => {
  const res = await axios({
    method: 'GET',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `${LISTING_URL}/franchise/carousel`,
    params: { number_of_franchises: params.nbOfFranchises },
  });
  return res.data;
};
