import React, { useContext, useEffect, useState } from 'react';

import { LocaleContext } from '../../router/Router';
import styled from 'styled-components';

export const Language = (): JSX.Element => {
  const { locale, setLocale } = useContext(LocaleContext),
    [lang, setLang] = useState(locale);

  useEffect(() => {
    setLang(locale);
  }, [locale]);

  const changeLanguageHandler = () => {
    setLocale(locale === 'en' ? 'fr' : 'en');
    setLang((prevLang) => (prevLang === 'en' ? 'fr' : 'en'));
  };

  const isAdminView = () => {
    const adminUrls = ['/admin', '/adminUser', '/franchisesAdmin', '/franchiseFormAdmin'];
    return adminUrls.indexOf(location.pathname) != -1;
  };

  if (!isAdminView()) {
    if (lang === 'en') {
      return (
        <div onClick={changeLanguageHandler}>
          <StyledP>FR</StyledP>
        </div>
      );
    } else {
      return (
        <div onClick={changeLanguageHandler}>
          <StyledP>EN</StyledP>
        </div>
      );
    }
  } else {
    return <></>;
  }
};

const StyledP = styled.p`
  margin: 0px 0px 0px 0px;
  cursor: pointer;
`;
