import * as adminService from '../../../services/AdminService';

import { ButtonSmall, ButtonStatus } from '../../../common/button/ButtonSmall';
import { CheckCircleOutlined, EditOutlined, RocketOutlined, SaveOutlined, StarOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input, Space, Table, Tag, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';

import { ColumnsType } from 'antd/lib/table/interface';
import { DeleteAction } from './DeleteAction';
import { EditAction } from './EditAction';
import { Link } from 'react-router-dom';
import { LinstingAdmin } from '../../../dtos/admin';
import { PublishAction } from './PublishAction';
import { RePublishAction } from './RepublishAction';
import { UnpublishAction } from './UnpublishAction';
import { UpgradeDowngradeAction } from './UpgradeDowngradeAction';
import { ViewAdmin } from '../../../common/views/ViewAdmin';
import styled from 'styled-components';
import { theme } from '../../../styles/theme';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useInfiniteQuery } from '@tanstack/react-query';

const toDate = (unixDate: number | undefined) => {
  if (!unixDate) {
    return undefined;
  }
  return new Date(unixDate * 1000).toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

const columns: ColumnsType<LinstingAdmin> = [
  {
    title: 'Annonces',
    key: 'title',
    render: (_, listing) => (
      <>
        <Link to={`../fr/fl/${listing._id}`}>
          <StyledLink>{listing.title}</StyledLink>
          {listing.to_be_deleted && (
            <Tooltip title={"Cette annonce n'a plus de forfait associé"}>
              <Tag color="red">À SUPPRIMER</Tag>
            </Tooltip>
          )}
        </Link>
        <p>
          <b>{'ID: '}</b>
          {listing._id}
        </p>
        {!listing.is_published && (
          <Tag icon={<SaveOutlined />} color="blue">
            {"Brouillon (visible par l'utilisateur seulement)"}
          </Tag>
        )}
        {listing.is_published && !listing.is_verified && (
          <Tag icon={<EditOutlined />} color="purple">
            {'En révision par Acquizition'}
          </Tag>
        )}
        {listing.is_published && listing.is_verified && (
          <Tag icon={<CheckCircleOutlined />} color="green">
            {'Publiée'}
          </Tag>
        )}
        <br />

        {listing.priority_exposure ? (
          <Tag icon={<StarOutlined />} color={'gold'}>
            {'Premium'}
          </Tag>
        ) : (
          <Tag color="default">{'Standard'}</Tag>
        )}
        {listing.featured && (
          <Tag icon={<RocketOutlined />} color={'volcano'}>
            {'En vedette'}
          </Tag>
        )}
      </>
    ),
  },
  {
    title: 'Dates',
    key: 'dates',
    render: (_, listing) => (
      <>
        <p>
          <b>{'Date de création: '}</b>
          {toDate(listing.creation_date)}
        </p>
        <p>
          <b>{'Date de publication: '}</b>
          {toDate(listing.published_date) || ''}
        </p>
        <p>
          <b>{'Dernier envoi journalier: '}</b>
          {listing.daily_sending_history?.length && listing.daily_sending_history.length >= 1
            ? // eslint-disable-next-line
              toDate(listing.daily_sending_history?.[listing.daily_sending_history.length - 1]!)
            : ''}
        </p>
        <p>
          <b>{'Dernier envoi hebdomadaire: '}</b>
          {listing.weekly_sending_history?.length && listing.weekly_sending_history.length >= 1
            ? // eslint-disable-next-line
              toDate(listing.weekly_sending_history?.[listing.weekly_sending_history.length - 1])
            : ''}
        </p>
      </>
    ),
  },
  {
    title: 'Utilisateurs',
    key: 'name',
    render: (_, listing) => (
      <>
        <p>
          <b>{'Nom: '}</b>
          {listing.first_name}
          {', '}
          {listing.last_name}
        </p>
        <p>
          <b>{'Courriel: '}</b>
          {listing.email}
        </p>
        <p>
          <b>{'ID: '}</b>
          {listing.user_id}
        </p>
      </>
    ),
  },
  {
    title: 'Actions',
    key: 'action',
    render: (_, listing) => (
      <Space size="middle">
        <RePublishAction {...listing} />
        <UnpublishAction {...listing} />
        <DeleteAction {...listing} />
        <UpgradeDowngradeAction {...listing} />
        <EditAction {...listing} />
        <PublishAction {...listing} />
      </Space>
    ),
  },
];

const initialQueryParams = {
  email: undefined,
  listing_id: undefined,
  user_id: undefined,
  title: undefined,
  status: undefined,
  to_be_deleted: undefined,
};

export const ListingsAdmin = (): JSX.Element => {
  const [adminListingFrom] = Form.useForm();
  const axiosMid = useAxiosMiddleware();
  const [tableData, setTableData] = useState<LinstingAdmin[] | undefined>([]);
  const [totalPages, setTotalPages] = useState<number | undefined>(1);
  const [currPage, setCurrPage] = useState<number>(1);
  const [queryOptions, setQueryOption] = useState(initialQueryParams);

  const resetFields = () => {
    location.reload();
  };

  const { data, fetchNextPage, isFetching, remove, refetch } = useInfiniteQuery(
    ['adminListings'],
    ({ pageParam = 0 }) =>
      adminService.searchListingsAdmin(
        {
          axiosMid: axiosMid,
          ...queryOptions,
        },
        pageParam,
      ),
    { refetchOnWindowFocus: false },
  );

  const searchQuery = () => {
    setCurrPage(1);
    remove();
    setQueryOption({
      email: adminListingFrom.getFieldValue('email') || undefined,
      listing_id: adminListingFrom.getFieldValue('listing_id') || undefined,
      user_id: adminListingFrom.getFieldValue('user_id') || undefined,
      title: adminListingFrom.getFieldValue('title') || undefined,
      status: adminListingFrom.getFieldValue('status') || undefined,
      to_be_deleted: adminListingFrom.getFieldValue('to_be_deleted') || undefined,
    });
  };

  useEffect(() => {
    setTableData(data?.pages[data?.pages.length - 1].listing_list);
    setTotalPages(data?.pages[0].total);
    return;
  }, [data]);

  useEffect(() => {
    refetch();
    return;
  }, [queryOptions]);

  const getNextPage = (page: number) => {
    setCurrPage(page);
    fetchNextPage({ pageParam: page - 1 });
  };

  return (
    <ViewAdmin>
      <h3>Annonces </h3>
      <StyledDiv>
        <FormDiv>
          <Form form={adminListingFrom} layout="vertical">
            <Form.Item name="listing_id" label="ID Annonce">
              <Input></Input>
            </Form.Item>
            <Form.Item name="title" label="Titre annonce">
              <Input></Input>
            </Form.Item>
            <Form.Item name="user_id" label="ID Utilisateur">
              <Input></Input>
            </Form.Item>
            <Form.Item name="email" label="Courriel utilisateur">
              <Input></Input>
            </Form.Item>
            <Form.Item name="status" valuePropName="checked">
              <Checkbox>{'Annonces à valider'}</Checkbox>
            </Form.Item>
            <Form.Item name="to_be_deleted" valuePropName="checked">
              <Checkbox>{'Annonces à supprimer'}</Checkbox>
            </Form.Item>
          </Form>
        </FormDiv>

        <ButtonDiv>
          <ButtonSmall onClick={() => resetFields()} status={ButtonStatus.TERTIARY} text={'Réinitialiser'} />
          <ButtonSmall onClick={() => searchQuery()} status={ButtonStatus.PRIMARY} text={'Rechercher'} />
        </ButtonDiv>
        <p>{'Résultats: ' + data?.pages?.[0]?.total}</p>
      </StyledDiv>
      <StyledP>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={tableData}
          pagination={{
            pageSize: 25,
            showSizeChanger: false,
            total: totalPages,
            current: currPage,
            onChange: (page) => {
              getNextPage(page);
            },
          }}
          rowKey={(listing) => listing._id}
        />
      </StyledP>
    </ViewAdmin>
  );
};

const StyledLink = styled.p`
  text-decoration: underline;
  cursor: pointer;
  color: ${theme.color.electricBlue};
`;

const FormDiv = styled.div`
  width: 560px;
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 24px;
`;

const StyledDiv = styled.div`
  display: flex;
  column-gap: 32px;
  align-items: flex-end;
`;

const StyledP = styled.div`
  p {
    margin-bottom: 0;
  }
`;
