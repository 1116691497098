import { Navigate, useLocation } from 'react-router-dom';
import React, { useContext } from 'react';

import { GROUPS } from './routes';
import { UserContext } from '../contexts/UserContext';
import jwt_decode from 'jwt-decode';

type RequireAuthType = {
  redirectTo: string;
  allowedRoles?: string[];
  children: JSX.Element;
};

export const getUserGroupFromToken = (authTokens: { access_token: string }) => {
  const token: { 'cognito:groups'?: string[] } = jwt_decode(authTokens.access_token);
  const userGroupFromToken =
    token['cognito:groups'] && token['cognito:groups']?.length > 0 && token['cognito:groups'].includes(GROUPS.admin)
      ? GROUPS.admin
      : '';
  return userGroupFromToken;
};

export const RequireAuth = (props: RequireAuthType): JSX.Element => {
  const { authTokens } = useContext(UserContext);
  const location = useLocation();

  if (authTokens === undefined) {
    return <Navigate to={props.redirectTo} replace state={{ from: location }} />;
  }

  if (props.allowedRoles === undefined && authTokens) {
    return props.children;
  }

  if (props.allowedRoles && props.allowedRoles.includes(getUserGroupFromToken(authTokens))) {
    return props.children;
  }
  return <Navigate to={props.redirectTo} replace state={{ from: location }} />;
};
