import * as paymentService from '../../services/PaymentService';

import React, { useContext, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { LocaleContext } from '../../router/Router';
import { SubscriptionBroker } from './SubscriptionBroker';
import { SubscriptionOwner } from './SubscriptionOwner';
import { UserContext } from '../../contexts/UserContext';
import { ViewDashboard } from '../../common/views/ViewDashboard';
import { privateRoutes } from '../../router/routes';
import { useAxiosMiddleware } from '../../services/useAxiosMiddleware';
import { useNavigate } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';

export const Subscription = () => {
  const axiosMid = useAxiosMiddleware(),
    stripe = useStripe(),
    { locale } = useContext(LocaleContext),
    navigate = useNavigate(),
    { userType } = useContext(UserContext);

  const checkoutMutation = useMutation(paymentService.stripeCheckoutSession, {
    onSuccess: (res) => {
      stripe?.redirectToCheckout({ sessionId: res.sessionId });
    },
    retry: false,
  });

  const redirectToCheckout = async (priceId: string) => {
    checkoutMutation.mutate({
      axiosMid: axiosMid,
      priceId: priceId,
      baseUrl: window.location.origin,
      locale: locale,
    });
  };

  const subscriptionActiveQuery = useQuery(['userHasActiveSubscription'], {
    queryFn: () => paymentService.userHasActiveSubscription({ axiosMid: axiosMid }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!subscriptionActiveQuery.isLoading && subscriptionActiveQuery.data === true) {
      navigate(privateRoutes.myListings(locale));
    }
  }, [subscriptionActiveQuery.data]);

  const isBroker = userType === 'broker';

  return (
    <ViewDashboard>
      {isBroker ? (
        <SubscriptionBroker
          redirectToCheckout={redirectToCheckout}
          checkoutMutation={checkoutMutation}
          subscriptionQuery={subscriptionActiveQuery}
        />
      ) : (
        <SubscriptionOwner
          redirectToCheckout={redirectToCheckout}
          checkoutMutation={checkoutMutation}
          subscriptionQuery={subscriptionActiveQuery}
        />
      )}
    </ViewDashboard>
  );
};
