import { Form, FormInstance, Modal, Upload, UploadProps } from 'antd';
import React, { useState } from 'react';

import { AxiosInstance } from 'axios';
import { PicturePath } from '../../dtos/uploadImage';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useAxiosMiddleware } from '../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

const getBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const UploadImage = (props: {
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  currForm: FormInstance;
  fileField: string;
  service: (params: { axiosMid: AxiosInstance; picturePath: PicturePath }) => Promise<string>;
  picturePath: PicturePath;
}): JSX.Element => {
  const [previewOpen, setPreviewOpen] = useState(false),
    [previewImage, setPreviewImage] = useState(''),
    axiosMid = useAxiosMiddleware(),
    { t } = useTranslation('brokerFileForm'),
    [dirtyImage, setDirtyImage] = props.state;

  const [fileList, setFileList] = useState(props.currForm.getFieldValue(props.fileField)?.fileList || []);

  const handleCancel = () => setPreviewOpen(false);

  // eslint-disable-next-line
  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const upLoadImageUrlMutaltion = useMutation(props.service, {
    onSuccess: (url) => {
      const currFileList = props.currForm.getFieldValue(props.fileField);
      currFileList.fileList[0].name = url;
      currFileList.fileList[0].status = 'success';
      props.currForm.setFieldValue(props.fileField, currFileList);
    },
  });

  const getUploadUrl = () => {
    upLoadImageUrlMutaltion.mutate({ axiosMid: axiosMid, picturePath: props.picturePath });
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setDirtyImage(true);
    setFileList(newFileList);
  };

  const uploadButton = (
    <div>
      <p>
        <PlusOutlined />
      </p>
      {t('add')}
    </div>
  );

  return (
    <>
      <Form.Item name={props.fileField} label={t(`${props.fileField}`)}>
        <Upload
          multiple={false}
          listType={'picture-card'}
          fileList={fileList}
          maxCount={1}
          onPreview={handlePreview}
          customRequest={getUploadUrl}
          onChange={handleChange}
          accept={'.png,.jpg'}
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload>
      </Form.Item>
      {dirtyImage && <PDirtry>{t('dirtyImage')}</PDirtry>}

      <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
        <LargeImg alt={t('dirtyImage')} src={previewImage} />
      </Modal>
    </>
  );
};

const PDirtry = styled.p`
  color: ${theme.color.errorRed};
`;

const LargeImg = styled.img`
  width: 100%;
  padding: 16px;
`;
