import React, { useContext } from 'react';
import { LocaleContext } from '../../router/Router';

export const PrivacyPolicy = () => {
  const { locale } = useContext(LocaleContext);
  if (locale === 'fr') {
    return (
      <div>
        <p>
          <b>GÉNÉRALITÉS&nbsp;</b>
        </p>
        <p>
          Groupe Contex Inc. et ses marques affiliées s&apos;engagent à maintenir la confidentialité, la sécurité et
          l&apos;exactitude des Renseignements personnels (définis ci-après) qui sont sous notre garde ou contrôle. La
          présente Politique concernant la vie privée (la Politique sur la vie privée) a pour objectif de vous informer
          de nos pratiques en matière de vie privée eu égard à notre collecte, à notre utilisation et à notre
          communication de Renseignements personnels.
        </p>
        <p>
          La présente Politique sur la vie privée ne s&apos;applique pas aux Renseignements personnels de nos employés,
          des consultants ou autres individus assignés auprès du Groupe Contex Inc., ou à toute information qui ne
          constitue pas un Renseignement personnel.
        </p>
        <p>
          <b>RESPONSABILITÉ</b>
        </p>
        <p>
          Groupe Contex Inc. est responsable des Renseignements personnels sous sa garde ou son contrôle et elle a
          désigné un responsable de la protection de la vie privée qui doit assurer le respect de cette Politique sur la
          vie privée et des lois applicables en matière de protection sur la vie privée. Si vous avez des
          préoccupations, questions, requêtes ou plaintes à propos de la Politique sur la vie privée ou concernant la
          gestion de vos Renseignements personnels, veuillez contacter notre responsable de la protection de vie privée
          à l&apos;adresse suivante :
        </p>
        <p>
          <b>Responsable de la protection de la vie privée</b>
        </p>
        <p>
          <b>Adresse postale</b>
        </p>
        <p>
          Groupe Contex Inc.
          <br />
          355 rue Sainte-Catherine Ouest, 5e étage
          <br />
          Montréal (Québec) H3B 1A5
          <br />
          Téléphone
          <br />
          514 392-2009
          <br />
          Courriel
          <br />
          <a href="mailto:responsabledesdonnees@groupecontex.ca">responsabledesdonnees@groupecontex.ca</a>
        </p>
        <p>
          <b>COLLECTE, UTILISATION ET COMMUNICATION DE RENSEIGNEMENTS PERSONNELS</b>
        </p>
        <p>
          <b>Renseignements personnels et professionnels</b>
        </p>
        <p>
          Les types de Renseignements personnels et professionnels que nous pouvons recueillir de temps à autre peuvent
          comprendre votre prénom, nom, courriel, adresse professionnelle, ville, province, pays, code postal, titre
          professionnel, organisation, service, secteur d&apos;activité, type d&apos;organisation, numéro de téléphone,
          taille de l&apos;organisation, membre d&apos;un ordre professionnel, des renseignements financiers ou en
          matière de crédit ou de facturation ainsi que tout autre renseignement personnel identifiable (les
          Renseignements personnels).
        </p>
        <p>
          <b>Collecte, utilisation et communication</b>
        </p>
        <p>
          Les Renseignements personnels peuvent être recueillis par l&apos;entremise d&apos;une communication volontaire
          de votre part ou dans le cadre de l&apos;utilisation de nos services ou de nos sites Web, d&apos;achats de nos
          produits, d&apos;abonnements à nos publications, de formulaires d&apos;enregistrement ou d&apos;inscription,
          de sondages, d&apos;une participation à un concours ou à une autre promotion ou par des tiers agents,
          cocontractants, vendeurs ou fournisseurs qui nous fournissent des produits et services (les Fournisseurs de
          services) ou des tiers partenaires d&apos;affaires à qui nous fournissons nos produits et services.
        </p>
        <p>
          Nous recueillons, utilisons et/ou communiquons vos Renseignements personnels et personnels aux fins suivantes
          :
        </p>
        <p>• pour créer et maintenir les comptes de nos clients;</p>
        <p>• afin de fournir le produit, service ou renseignement que vous avez demandé;</p>
        <p>• pour vous envoyer des renseignements concernant les produits et services que</p>
        <p>vous avez demandés ou pour répondre à vos requêtes et questions;</p>
        <p>• pour traiter les paiements et recouvrir des montants impayés;</p>
        <p>• pour repérer les erreurs, fraudes, vols et autres activités illégales et se protéger contre eux;</p>
        <p>• pour mieux comprendre vos besoins et préférences;</p>
        <p>• pour réaliser des opérations commerciales;</p>
        <p>• pour offrir le service à la clientèle;</p>
        <p>• pour organiser et administrer des concours et/ou des sondages;</p>
        <p>• pour vous informer de nouvelles offres et promotions;</p>
        <p>• afin de respecter les exigences de la loi; et/ou</p>
        <p>• pour toute autre fin pour laquelle vous donnez par ailleurs votre consentement;</p>
        <p>
          <b>Communications à Groupe Contex Inc.</b>
        </p>
        <p>
          Nous pouvons communiquer vos Renseignements personnels et professionnels au sein de notre organisation afin de
          mieux vous servir et de vous informer des produits et services offerts par Groupe Contex Inc.
        </p>
        <p>
          Lorsque vous nous communiquez vos Renseignements personnels dans le cadre d&apos;opportunités d&apos;emploi, y
          compris, sans s&apos;y limiter, votre curriculum vitæ, nous traiterons ces Renseignements personnels sur une
          base confidentielle et utiliserons ces renseignements uniquement (i) afin d&apos;évaluer votre candidature
          pour des opportunités d&apos;emploi actuelles ou futures au sein du Groupe Contex Inc. et (ii) afin de vous
          contacter pour de telles opportunités d&apos;emploi. Nous préserverons vos Renseignements personnels pour de
          futures opportunités d&apos;emploi au sein du Groupe Contex Inc. qui pourront se présenter, à moins que vous
          nous informiez de ne pas conserver vos informations.
        </p>
        <p>
          <b>Communications à nos Fournisseurs de services</b>
        </p>
        <p>
          Groupe Contex Inc. exerce ses activités au Canada. Nous pouvons transférer vos Renseignements personnels et
          professionnels à des Fournisseurs de services agissant pour notre compte pour les fins mentionnées à la
          présente Politique sur la vie privée. Veuillez prendre note que certains de nos Fournisseurs de services qui
          traitent ou gèrent des Renseignements personnels et professionnels pour notre compte peuvent se trouver à
          l&apos;étranger.
        </p>
        <p>
          <b>Liste des fournisseurs :</b>
        </p>
        <p>
          AWS : nous utilisons AWS pour l&apos;hébergement infonuagique (cloud) des renseignements personnels. Voir leur
          politique de confidentialité.
          <a href="https://aws.amazon.com/fr/privacy/?nc1=f_pr">https://aws.amazon.com/fr/privacy/?nc1=f_pr</a>
        </p>
        <p>
          Dialog Insight : nous utilisons Dialog Insight pour la gestion des consentements lors de l&apos;inscription
          aux infolettres, conférences, formations, webinaires. Voir leur politique de confidentialité.
          <a href="https://www.dialoginsight.com/politique-de-confidentialite/">
            https://www.dialoginsight.com/politique-de-confidentialite/
          </a>
        </p>
        <p>
          EventMaker; nous utilisons EventMaker pour la gestion de nos expos et salons en présentiels et virtuelle. Voir
          leur politique de confidentialité
          <a href="https://www.eventmaker.com/conditions-generales-utilisation">
            https://www.eventmaker.com/conditions-generales-utilisation
          </a>
        </p>
        <p>
          Illuxi : nous utilisons Illuxi pour la gestion de nos formations en ligne.
          <a href="https://illuxi.com/securite/">https://illuxi.com/securite/</a>
        </p>
        <p>
          PQM.net : nous utilisons PQM.net pour la gestion de nos conférences en ligne.
          <a href="https://www.pqm.net/politique/">https://www.pqm.net/politique/</a>
        </p>
        <p>
          Pelcro : nous utilisons Pelcro pour la gestion de nos abonnements papier et numérique.
          <a href="https://www.pelcro.com/en/legal/privacy">https://www.pelcro.com/en/legal/privacy</a>
        </p>
        <p>
          Shopify : nous utilisons Shopify pour le paiement en ligne de nos conférences,formations Voir leur politique
          de confidentialité;
          <a href="https://fr.shopify.ca/legal/confidentialite">https://fr.shopify.ca/legal/confidentialite</a>
        </p>
        <p>
          CVent; nous utilisons Cvent pour la gestion de nos événements;
          <a href="https://www.cvent.com/en/privacy-policy">https://www.cvent.com/en/privacy-policy</a>
        </p>
        <p>
          Ereg : nous utilisons Ereg pour la gestion des formulaires en lignes;
          <a href="http://e-registernow.com/why-choose-us/#Privacy-Policy">
            http://e-registernow.com/why-choose-us/#Privacy-Policy
          </a>
        </p>
        <p>
          Formtack; nous utilisons Formstack pour la gestion de nos formulaires;
          <a href="https://www.formstack.com/legal/website-privacy-policy">
            https://www.formstack.com/legal/website-privacy-policy
          </a>
        </p>
        <p>
          Stripe : nous utilisons Stripe pour le paiement en ligne.
          <a href="https://stripe.com/fr-ca/privacy">https://stripe.com/fr-ca/privacy</a>
        </p>
        <p>
          Nous prenons des mesures raisonnables pour veiller à ce que vos données soient gérées de manière appropriée et
          sécuritaire, en conformité avec la présente Politique sur la vie privée, sous réserve, toutefois, des
          exigences juridiques applicables dans des pays étrangers.
        </p>
        <p>
          <p>Communication dans le cadre de transactions commerciales</p>
        </p>
        <p>
          Groupe Contex Inc. peut prendre part à des activités visant la vente, la fusion ou la restructuration
          d&apos;une partie ou de la totalité de son entreprise ou de ses éléments d&apos;actif. Dans le cadre de ces
          transactions, Groupe Contex Inc. peut divulguer vos Renseignements personnels à l&apos;acquéreur ou à la
          nouvelle entité d&apos;affaires. Le cas échéant, Groupe Contex Inc. exigera de ces tierces parties qu&apos;ils
          s&apos;engagent à protéger la confidentialité de vos Renseignements personnels et professionnels en conformité
          avec la présente Politique sur la vie privée et les lois applicables en matière de protection de
          Renseignements personnels.
        </p>
        <p>
          Nous déployons des efforts raisonnables afin d&apos;assurer que vos Renseignements personnels et
          professionnels soient utilisés et communiqués conformément à la présente Politique sur la vie privée, à moins
          d&apos;avoir obtenu votre consentement pour d&apos;autres fins, ou tel qu&apos;autrement permis ou exigé par
          les lois applicables.
        </p>
        <p>
          <b>CONSENTEMENT</b>
        </p>
        <p>
          Nous recueillons, utilisons et communiquons vos Renseignements personnels et professionnels avec votre
          consentement ou selon ce que la loi permet ou exige. Votre consentement peut être exprès ou implicite, selon
          les circonstances et les lois applicables. Lorsque vous nous communiquez des Renseignements personnels et
          professionnels concernant une autre personne, nous présumons que vous avez obtenu le consentement valide de
          cette personne nous permettant ainsi de recueillir, utiliser et communiquer ces renseignements conformément à
          la présente Politique sur la vie privée. Toute communication électronique que nous vous envoyons, que ce soit
          en notre nom ou pour le compte d&apos;un tiers partenaire d&apos;affaires, inclura des instructions vous
          informant comment vous désabonner de telle communication future. Si vous ne consentez pas, veuillez ne pas
          nous divulguer vos Renseignements personnels ou, lorsqu&apos;applicable, veuillez-vous désabonner.
        </p>
        <p>
          Veuillez prendre note qu&apos;il se peut que nous soyons dans l&apos;impossibilité de vous fournir des
          services si vous retirez votre consentement à l&apos;égard de certaines utilisations de vos Renseignements
          personnels.
        </p>
        <p>
          <b>PROTECTION DE LA VIE PRIVÉE ET NOS SITES WEB</b>
        </p>
        <p>
          Groupe Contex Inc. recueille parfois des Renseignements personnels et professionnels par l&apos;intermédiaire
          de ses divers sites Web. La présente rubrique renferme des renseignements supplémentaires concernant cette
          collecte.
        </p>
        <p>
          <b>Témoins</b>
        </p>
        <p>
          En utilisant une fonction du navigateur appelée « un témoin » et d&apos;autres méthodes, Groupe Contex Inc. et
          ses Fournisseurs de services peuvent recueillir des renseignements non personnels lorsque vous consultez nos
          sites Web, tels que des données concernant la fréquence de vos visites sur nos sites Web, la durée de vos
          visites, le nombre de pages que vous consultez et le type de navigateur Internet et de système
          d&apos;exploitation que vous utilisez. Un témoin est un petit fichier texte contenant un numéro
          d&apos;identification unique qui identifie le navigateur du visiteur, sans nécessairement identifier le
          visiteur, à nos ordinateurs à chaque fois que nos sites Web sont consultés. Des renseignements au sujet des
          individus qui consultent nos sites Web sont recueillis de manière anonyme, ce qui signifie qu&apos;aucun
          Renseignement personnel n&apos;est associé à ces données, à moins que le visiteur ne nous fasse part
          spécifiquement de tels renseignements (c.-à-d. en s&apos;inscrivant à nos services ou en nous envoyant un
          message à partir de nos sites Web).
        </p>
        <p>
          Outre les fins identifiées dans la présente Politique sur la vie privée, nous pouvons utiliser les
          renseignements obtenus à partir de nos sites Web et les partager avec d&apos;autres organisations avec
          lesquelles nous avons des relations commerciales afin de mesurer l&apos;utilisation de nos sites Web et
          d&apos;en améliorer leurs fonctionnalités ou leur contenu. Groupe Contex Inc. et ses tiers Fournisseurs de
          services peuvent également utiliser des témoins et des pixels invisibles pour diffuser des publicités qui sont
          pertinentes et ciblées selon les intérêts des visiteurs de nos sites Web.
        </p>
        <p>
          Vous pouvez gérer vos témoins en installant un programme d&apos;une tierce partie ou en réinitialisant votre
          navigateur. La plupart des navigateurs sont réglés initialement pour accepter des témoins; toutefois, vous
          pouvez supprimer ou désactiver vos témoins en modifiant vos paramètres. Si vous décidez de désactiver les
          témoins sur nos sites Web, vous pourriez ne pas pouvoir profiter pleinement de toutes les fonctionnalités de
          nos sites Web.
        </p>
        <p>
          <b>Communications en ligne</b>
        </p>
        <p>
          Si Groupe Contex Inc. vous a identifié en tant qu&apos;utilisateur inscrit d&apos;un service en ligne que nous
          offrons, nous pouvons combiner et mettre en mémoire des informations concernant votre utilisation de nos sites
          Web et l&apos;information en ligne que vous nous avez fournie avec certaines autres informations en ligne et
          hors ligne que nous avons pu recueillir.
        </p>
        <p>
          <b>Forums Publics</b>
        </p>
        <p>
          Certains des sites Web du Groupe Contex Inc. mettent à la disposition des internautes des salons de clavardage
          et autres forums publics. La communication volontaire de vos Renseignements personnels de votre part dans ces
          forums n&apos;est pas régie par la présente Politique sur la vie privée. Groupe Contex Inc. n&apos;est pas
          responsable de toute information communiquée dans ses salons de clavardage et/ou forums et toutes telles
          communications volontaires sont de nature publique et sont faites à vos propres risques. Bien qu&apos;il soit
          interdit d&apos;utiliser nos forums pour obtenir des adresses de courrier électronique aux fins d&apos;envoi
          de messages non fondés ou non sollicités ou de pollupostage, des tiers peuvent tout de même saisir votre
          adresse de courrier électronique et vous envoyer des messages par courriel si vous affichez votre adresse de
          courrier électronique; nous ne pouvons contrôler de tels gestes de la part de tiers. Nous vous conseillons
          d&apos;être prudent et attentif en tout temps lorsque vous naviguez sur Internet.
        </p>
        <p>
          <b>Recherches</b>
        </p>
        <p>
          Pour votre commodité, certaines fonctions de recherche sont parfois disponibles sur nos sites Web. Nous
          conservons des archives où sont consignés les termes recherchés afin d&apos;en apprendre davantage sur le type
          d&apos;information recherchée par nos visiteurs et, ainsi, améliorer nos produits et services.
        </p>
        <p>
          <b>Hyperliens</b>
        </p>
        <p>
          Pour votre commodité, les sites Web du Groupe Contex Inc. peuvent inclure des hyperliens vers des sites Web
          opérés par des tiers. En cliquant sur ces hyperliens, vous quitterez les sites Web du Groupe Contex Inc. pour
          ceux de tiers, lesquels peuvent faire l&apos;objet de politiques et de pratiques de protection sur la vie
          privée différente. La présente Politique sur la vie privée n&apos;est plus applicable lorsque vous quittez nos
          sites Web. Veuillez consulter les politiques en matière de vie privée de ces autres parties avant de fournir
          des Renseignements personnels sur leurs sites Web. Groupe Contex Inc. n&apos;est pas responsable des pratiques
          en matière de collecte, d&apos;utilisation et de communication de Renseignements personnels d&apos;autres
          parties auxquelles vous pouvez accéder à partir de nos sites Web et n&apos;accepte aucune responsabilité à cet
          égard.
        </p>
        <p>
          <b>Individus âgés de moins de 18 ans</b>
        </p>
        <p>
          Les individus âgés de moins de 18 ans ne devraient pas fournir ou afficher des Renseignements personnels sur
          nos sites Web sans la permission de leurs parents ou de leur tuteur légal. Nous pouvons demander des
          Renseignements personnels d&apos;un individu âgé de moins de 18 ans dans le cadre de l&apos;administration
          d&apos;un concours. Le cas échéant, nous demanderons le consentement du parent ou du tuteur légal de
          l&apos;individu avant de recueillir les renseignements.
        </p>
        <p>
          <b>SÉCURITÉ</b>
        </p>
        <p>
          Groupe Contex Inc. a mis en œuvre des mesures de sécurité raisonnables afin de préserver les Renseignements
          personnels dont nous avons la garde ou le contrôle. Tout Renseignement personnel et professionnel détenu par
          Groupe Contex Inc. est archivé dans un environnement raisonnablement sécurisé selon la nature des
          renseignements. Les renseignements liés aux cartes de crédit sont traités à l&apos;aide d&apos;une technologie
          de chiffrement courante selon les normes de l&apos;industrie. Groupe Contex Inc. limite la collecte de
          Renseignements personnels et professionnels à ce qui est nécessaire pour les fins identifiées par Groupe
          Contex Inc., sauf si autrement permis ou exigé par la loi. Les renseignements liés aux cartes de crédit qui
          sont soumis à Groupe Contex Inc. ne servent qu&apos;à compléter l&apos;achat et prendre les dispositions
          relatives à l&apos;expédition (lorsqu&apos;applicable) et/ou pour effectuer une vérification du crédit
          (lorsqu&apos;applicable). Nos politiques et procédures de sécurité sont revues périodiquement et améliorées au
          besoin.
        </p>
        <p>
          Seuls nos employés, agents, partenaires ou Fournisseurs de services autorisés peuvent avoir accès aux
          Renseignements personnels et professionnels sous notre garde ou contrôle. Nous exigeons de tous nos employés,
          agents, partenaires ou Fournisseurs de services autorisés qui traitent des Renseignements personnels et
          professionnels d&apos;utiliser les mesures de précaution adéquates pour assurer la sécurité et la protection
          de la vie privée lorsqu&apos;ils traitent cette information.
        </p>
        <p>
          <b>EXACTITUDE ET ACCÈS</b>
        </p>
        <p>
          Nous déployons des efforts et des mesures raisonnables pour nous assurer que vos Renseignements personnels et
          professionnels demeurent exacts, complets et aussi à jour que possible. Nous ne mettrons pas à jour de façon
          régulière vos Renseignements personnels et professionnels, à moins qu&apos;il ne soit nécessaire de le faire.
          Afin de nous aider à faire en sorte que vos Renseignements personnels et professionnels soient exacts et à
          jour, veuillez nous informer dès que possible de toute modification pour nous permettre de mettre nos dossiers
          à jour.
        </p>
        <p>
          Si vous désirez demander l&apos;accès aux Renseignements personnels et professionnels qui vous concernent dont
          nous avons la garde ou le contrôle, ou y faire apporter des modifications, veuillez en faire la demande par
          écrit à notre responsable de la protection de la vie privée à l&apos;adresse ou aux numéros indiqués
          ci-dessus. Dans tous les cas, nous traiterons les demandes d&apos;accès ou de modifications conformément aux
          lois applicables.
        </p>
        <p>
          <b>MODIFICATIONS À LA PRÉSENTE POLITIQUE SUR LA VIE PRIVÉE</b>
        </p>
        <p>
          Groupe Contex Inc. se réserve le droit d&apos;apporter à tout moment des modifications à la présente Politique
          sur la vie privée. Toutes telles modifications seront affichées sur les sites Web du Groupe Contex Inc. et
          entreront en vigueur immédiatement après leur affichage. Nous prendrons des mesures raisonnables, au besoin,
          pour informer les particuliers concernés de toute modification importante. Vous pouvez communiquer avec notre
          responsable de la protection de la vie privée à l&apos;adresse ou aux numéros indiqués ci-dessus pour obtenir
          un exemplaire à jour de cette Politique sur la vie privée.
        </p>
      </div>
    );
  } else {
    return (
      <div>
        <p>
          <b>GENERAL</b>
        </p>
        <p>
          Contex Group Inc. and its affiliated brands are committed to maintaining the confidentiality, security and
          accuracy of Personal Information (as defined below) in our custody or control. The purpose of this Privacy
          Policy (the &apos;Privacy Policy&apos;) is to inform you of our privacy practices with respect to our
          collection, use and disclosure of Personal Information.
        </p>
        <p>
          This Privacy Policy does not apply to Personal Information of our employees, consultants or other individuals
          assigned to Contex Group Inc. or to any information that is not Personal Information.
        </p>
        <p>
          <b>RESPONSIBILITY</b>
        </p>
        <p>
          Contex Group Inc. is responsible for Personal Information in its custody or control and has designated a
          Privacy Officer who is accountable for compliance with this Privacy Policy and applicable privacy laws. If you
          have any concerns, questions, requests or complaints about this Privacy Policy or the management of your
          Personal Information, please contact our Privacy Officer at
        </p>
        <p>
          <b>Privacy Officer</b>
        </p>
        <p>
          <b>Mailing address</b>
        </p>
        <p>
          Groupe Contex Inc.
          <br />
          355 rue Sainte-Catherine Ouest, 5th floor
          <br />
          Montréal (Québec) H3B 1A5
          <br />
          Telephone
          <br />
          514 392-2009
          <br />
          Email
          <br />
          <a href="mailto:chefdataofficer@groupecontex.ca">chefdataofficer@groupecontex.ca</a>
        </p>
        <p>
          <b>COLLECTION, USE AND DISCLOSURE OF PERSONAL INFORMATION</b>
        </p>
        <p>
          <b>Personal and Professional Information</b>
        </p>
        <p>
          The types of personal and professional information we may collect from time to time may include your first
          name, last name, email address, business address, city, province, country, postal code, professional title,
          organization, department, industry, type of organization, telephone number, size of organization, professional
          membership, financial or credit information, billing information and any other personally identifiable
          information (Personal Information).
        </p>
        <p>
          <b>Collection, use and communication</b>
        </p>
        <p>
          Personal Information may be collected through voluntary communication from you or in connection with your use
          of our services or websites, purchases of our products, subscriptions to our publications, registration or
          enrollment forms, surveys, participation in a contest or other promotion, or by third party agents,
          contractors, vendors or suppliers who provide products and services to us (Service Providers) or third party
          business partners to whom we provide our products and services.
        </p>
        <p>We collect, use and/or disclose your Personal and Business Information for the following purposes:</p>
        <p>-to create and maintain our clients&apos; accounts;</p>
        <p>-to provide the product, service or information you have requested;</p>
        <p>-to send you information about products and services that to answer your requests and questions;</p>
        <p>-to process payments and collect outstanding amounts;</p>
        <p>-to detect and protect against errors, fraud, theft and other illegal activities;</p>
        <p>-to better understand your needs and preferences;</p>
        <p>-to carry out commercial operations;</p>
        <p>-to provide customer service;</p>
        <p>-to organize and administer contests and/or surveys;</p>
        <p>-to inform you of new offers and promotions;</p>
        <p>-to meet the requirements of the law; and/or</p>
        <p>-for any other purpose for which you otherwise give your consent;</p>
        <p>
          <b>Communications to Groupe Contex Inc.</b>
        </p>
        <p>
          We may share your Personal and Professional Information within our organization to better serve you and to
          inform you of products and services offered by Groupe Contex Inc.
        </p>
        <p>
          When you provide us with your Personal Information in connection with employment opportunities, including but
          not limited to your resume, we will treat such Personal Information on a confidential basis and will use such
          information only (i) to evaluate your candidacy for current or future employment opportunities with Contex
          Group Inc. and (ii) to contact you regarding such employment opportunities. We will preserve your Personal
          Information for future employment opportunities with Contex Group Inc. that may arise, unless you inform us
          not to retain your information.
        </p>
        <p>
          <b>Communications to our Service Providers</b>
        </p>
        <p>
          Contex Group Inc. operates in Canada. We may transfer your Personal and Business Information to Service
          Providers acting on our behalf for the purposes identified in this Privacy Policy. Please note that some of
          our Service Providers who process or manage Personal and Business Information on our behalf may be located
          outside of Canada.
        </p>
        <p>
          <b>List of suppliers :</b>
        </p>
        <p>
          AWS: We use AWS for cloud hosting of personal information. See their privacy policy.
          <a href="https://aws.amazon.com/fr/privacy/?nc1=f_pr">https://aws.amazon.com/fr/privacy/?nc1=f_pr</a>
        </p>
        <p>
          Dialog Insight: we use Dialog Insight to manage consent for registration to newsletters, conferences,
          training, webinars. See their privacy policy.
          <a href="https://www.dialoginsight.com/politique-de-confidentialite/">
            https://www.dialoginsight.com/politique-de-confidentialite/
          </a>
        </p>
        <p>
          EventMaker; we use EventMaker to manage our in-person and virtual exhibitions. See their privacy policy
          <a href="https://www.eventmaker.com/conditions-generales-utilisation">
            https://www.eventmaker.com/conditions-generales-utilisation
          </a>
        </p>
        <p>
          https://illuxi.com/securite/ Illuxi: we use Illuxi to manage our online training courses.
          <a href="https://illuxi.com/securite/">https://illuxi.com/securite/</a>
        </p>
        <p>
          PQM.net: we use PQM.net to manage our online conferences.
          <a href="https://www.pqm.net/politique/">https://www.pqm.net/politique/</a>
        </p>
        <p>
          Pelcro: we use Pelcro to manage our print and digital subscriptions.
          <a href="https://www.pelcro.com/en/legal/privacy">https://www.pelcro.com/en/legal/privacy</a>
        </p>
        <p>
          Shopify : we use Shopify for online payment of our conferences, trainings See their privacy policy;
          <a href="https://fr.shopify.ca/legal/confidentialite">https://fr.shopify.ca/legal/confidentialite</a>
        </p>
        <p>
          CVent; we use Cvent to manage our events;
          <a href="https://www.cvent.com/en/privacy-policy">https://www.cvent.com/en/privacy-policy</a>
        </p>
        <p>
          Ereg : we use Ereg to manage online forms;
          <a href="http://e-registernow.com/why-choose-us/#Privacy-Policy">
            http://e-registernow.com/why-choose-us/#Privacy-Policy
          </a>
        </p>
        <p>
          Formtack; we use Formstack to manage our forms;
          <a href="https://www.formstack.com/legal/website-privacy-policy">
            https://www.formstack.com/legal/website-privacy-policy
          </a>
        </p>
        <p>
          Stripe: we use Stripe for online payments.
          <a href="https://stripe.com/fr-ca/privacy">https://stripe.com/fr-ca/privacy</a>
        </p>
        <p>
          Google Analytics 4, we use GA4 in order to know our audience visiting our sites.
          <a href="https://policies.google.com/privacy?hl=fr">https://policies.google.com/privacy?hl=fr</a>
        </p>

        <p>
          We take reasonable steps to ensure that your data is managed appropriately and securely in accordance with
          this Privacy Policy, subject, however, to applicable legal requirements in foreign countries.
        </p>
        <p>
          <p>Communication in commercial transactions</p>
        </p>
        <p>
          Contex Group Inc. may become involved in the sale, merger or restructuring of some or all of its business or
          assets. As part of these transactions, Contex Group Inc. may disclose your Personal Information to the
          acquirer or new business entity. If so, Contex Group Inc. will require such third parties to agree to protect
          the confidentiality of your Personal and Business Information in accordance with this Privacy Policy and
          applicable privacy laws.
        </p>
        <p>
          We make reasonable efforts to ensure that your Personal and Business Information is used and disclosed in
          accordance with this Privacy Policy, unless we have obtained your consent for other purposes, or as otherwise
          permitted or required by applicable law.
        </p>
        <p>
          <b>CONSENT</b>
        </p>
        <p>
          We collect, use and disclose your Personal and Business Information with your consent or as permitted or
          required by law. Your consent may be express or implied, depending on the circumstances and applicable law.
          When you provide us with Personal and Business Information about another individual, we will assume that you
          have obtained that individual&apos;s valid consent for us to collect, use and disclose that information in
          accordance with this Privacy Policy. Any electronic communication we send to you, whether on our behalf or on
          behalf of a third party business partner, will include instructions on how to unsubscribe from such future
          communication. If you do not consent, please do not disclose your Personal Information to us or, where
          applicable, unsubscribe.
        </p>
        <p>
          Please note that we may not be able to provide services to you if you withdraw your consent to certain uses of
          your Personal Information.
        </p>
        <p>
          <b>PRIVACY AND OUR WEBSITES</b>
        </p>
        <p>
          Contex Group Inc. sometimes collects Personal and Business Information through its various websites. This
          section contains additional information regarding such collection.
        </p>
        <p>
          <b>Witnesses</b>
        </p>
        <p>
          Through the use of a browser feature called a &apos;cookie&apos; and other methods, Contex Group Inc. and its
          Service Providers may collect non-personal information when you visit our websites, such as data about how
          often you visit our websites, the length of your visits, the number of pages you view, and the type of
          Internet browser and operating system you use. A cookie is a small text file containing a unique
          identification number that identifies the visitor&apos;s browser, but not necessarily the visitor, to our
          computers each time our websites are visited. Information about individuals who visit our websites is
          collected anonymously, which means that no Personal Information is associated with that data, unless the
          visitor specifically provides such information to us (i.e., by registering for our services or sending us a
          message from our websites).
        </p>
        <p>
          In addition to the purposes identified in this Privacy Policy, we may use information obtained from our
          websites and share it with other organizations with whom we have a business relationship in order to measure
          the use of our websites and improve their functionality or content. Contex Group Inc. and its third party
          Service Providers may also use cookies and web beacons to serve ads that are relevant and targeted to the
          interests of visitors to our websites.
        </p>
        <p>
          You can manage your cookies by installing a third-party program or by resetting your browser. Most browsers
          are initially set to accept cookies; however, you can remove or disable your cookies by changing your
          settings. If you choose to disable cookies on our websites, you may not be able to take full advantage of all
          the features of our websites.
        </p>
        <p>
          <b>Online communications</b>
        </p>
        <p>
          If Contex Group Inc. has identified you as a registered user of an online service we offer, we may combine and
          store information about your use of our websites and the online information you have provided to us with
          certain other online and offline information we may have collected.
        </p>
        <p>
          <b>Public Forums</b>
        </p>
        <p>
          Some of Contex Group Inc.&apos;s websites offer chat rooms and other public forums. Your voluntary disclosure
          of Personal Information in these forums is not governed by this Privacy Policy. Contex Group Inc. is not
          responsible for any information communicated in its chat rooms and/or forums and all such voluntary
          communications are public in nature and are made at your own risk. Although it is prohibited to use our forums
          to obtain e-mail addresses for the purpose of sending unsolicited or unsubstantiated messages or spam, third
          parties may still capture your e-mail address and send you e-mail messages if you post your e-mail address; we
          cannot control such actions by third parties. We advise you to be careful and attentive at all times when
          surfing the Internet.
        </p>
        <p>
          <b>Research</b>
        </p>
        <p>
          For your convenience, certain search functions are sometimes available on our websites. We keep records of
          search terms in order to learn more about the type of information our visitors are looking for and to improve
          our products and services.
        </p>
        <p>
          <b>Hyperlinks</b>
        </p>
        <p>
          For your convenience, Contex Group Inc. websites may include hyperlinks to websites operated by third parties.
          By clicking on these hyperlinks, you will leave the Contex Group Inc. websites for those of third parties,
          which may have different privacy policies and practices. This Privacy Policy no longer applies once you leave
          our websites. Please review the privacy policies of these other parties before providing any Personal
          Information on their websites. Contex Group Inc. is not responsible for, and accepts no liability for, the
          personal information collection, use and disclosure practices of other parties that you may access through our
          websites.
        </p>
        <p>
          <b>Individuals under the age of 18</b>
        </p>
        <p>
          Individuals under the age of 18 should not provide or post Personal Information on our websites without the
          permission of their parent or legal guardian. We may request Personal Information from an individual under the
          age of 18 in connection with the administration of a contest. In such cases, we will seek the consent of the
          individual&apos;s parent or legal guardian before collecting the information.
        </p>
        <p>
          <b>SAFETY</b>
        </p>
        <p>
          Contex Group Inc. has implemented reasonable security measures to safeguard the Personal Information in our
          custody or control. All Personal and Business Information held by Contex Group Inc. is stored in a reasonably
          secure environment depending on the nature of the information. Credit card information is processed using
          industry standard encryption technology. Contex Group Inc. limits the collection of Personal and Business
          Information to that which is necessary for the purposes identified by Contex Group Inc. unless otherwise
          permitted or required by law. Credit card information submitted to Groupe Contex Inc. is used only to complete
          the purchase and make shipping arrangements (where applicable) and/or to perform a credit check (where
          applicable). Our security policies and procedures are reviewed periodically and enhanced as necessary.
        </p>
        <p>
          Only our employees, agents, partners or Authorized Service Providers may have access to Personal and Business
          Information in our custody or control. We require all of our employees, agents, partners or Authorized Service
          Providers who handle Personal and Business Information to use appropriate security and privacy precautions
          when handling such information.
        </p>
        <p>
          <b>ACCURACY AND ACCESS</b>
        </p>
        <p>
          We use reasonable efforts and measures to ensure that your Personal and Business Information remains accurate,
          complete and as up-to-date as possible. We will not routinely update your Personal and Business Information
          unless it is necessary to do so. To help us ensure that your Personal and Business Information is accurate and
          up-to-date, please notify us as soon as possible of any changes to enable us to update our records.
        </p>
        <p>
          If you wish to request access to, or correction of, Personal and Business Information about you in our custody
          or control, please make your request in writing to our Privacy Officer at the address or numbers listed above.
          In all cases, we will process requests for access or amendment in accordance with applicable law.
        </p>
        <p>
          <b>CHANGES TO THIS PRIVACY POLICY</b>
        </p>
        <p>
          Contex Group Inc. reserves the right to make changes to this Privacy Policy at any time. Any such changes will
          be posted on the Contex Group Inc. websites and will be effective immediately upon posting. We will take
          reasonable steps, if necessary, to notify affected individuals of any material changes. You may contact our
          Privacy Officer at the address or numbers listed above to obtain a current copy of this Privacy Policy.
        </p>
      </div>
    );
  }
};
