import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigationType, useSearchParams } from 'react-router-dom';

import { ListingTile } from '../businessesForSale/listingTile/ListingTile';
import React from 'react';
import { SearchParameters } from '../../dtos/businessesForSale';
import { Spin } from 'antd';
import { UserContext } from '../../contexts/UserContext';
import { ViewCentered } from '../../common/views/ViewCentered';
import { ViewDashboard } from '../../common/views/ViewDashboard';
import { searchListings } from '../../services/ListingService';
import styled from 'styled-components';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const fetchListings = async (pageNumber: number, searchParameters: SearchParameters) => {
  return searchListings({ searchParameters: searchParameters, pageNumber: pageNumber });
};

export const Favorites = (): JSX.Element => {
  const { t } = useTranslation(['common', 'favorites']);
  const { userFavorites } = useContext(UserContext);
  const navigationType = useNavigationType();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(searchParams.get('page') ? Number(searchParams.get('page')) : 0);
  const [prevId, setPrevId] = useState(searchParams.get('prev_id') ? searchParams.get('prev_id') : undefined);

  useEffect(() => {
    setSearchParams({});
    return;
  }, []);

  const { data, fetchNextPage, isFetching, remove, refetch, error } = useInfiniteQuery({
    queryKey: ['getFavListings'],
    queryFn: ({ pageParam = { page: 0, searchState: { listings: userFavorites || [] } } }) => {
      return fetchListings(pageParam.page, { listings: userFavorites || [] });
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.tile_list?.length === 0) {
        return undefined;
      }
      return { page: page + 1, searchState: { listings: userFavorites || [] } };
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const observer: React.MutableRefObject<IntersectionObserver | undefined> = useRef();
  const lastListingElementRef = useCallback(
    (node: Element | null) => {
      if (isFetching) return;
      if (typeof observer != 'undefined' && observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchNextPage();
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetching, data],
  );

  useEffect(() => {
    if (!prevId) {
      remove();
      setPage(0);
      refetch();
    }
    return;
  }, []);

  useEffect(() => {
    if (navigationType === 'POP' && prevId) {
      document.getElementById(prevId.toString())?.scrollIntoView({ behavior: 'auto', block: 'end' });
      setPrevId(undefined);
    }
    return;
  }, [navigationType]);

  const favorites = userFavorites || [];
  const hasFavorites = favorites && favorites.length > 0 && data?.pages.length && data?.pages.length > 1;

  return error ? (
    <ViewCentered maxWidth="500px">{<h6>{t('common:serverError')}</h6>}</ViewCentered>
  ) : (
    <ViewDashboard>
      <h3>{t('favorites:favorites')}</h3>
      {data?.pages.map((result) => {
        return result.tile_list?.map((tile, arrayIndex) => (
          <div
            id={tile._id}
            ref={
              data.pages?.[data.pages.length - 1]?.tile_list?.[data.pages[data.pages.length - 1].tile_list.length - 1]
                ? lastListingElementRef
                : null
            }
            key={arrayIndex}
          >
            <ListingTile
              tile={tile}
              searchState={{
                page: page,
                searchParams: {},
              }}
            />
          </div>
        ));
      })}
      {isFetching && (
        <Center>
          <Spin size="large" />
        </Center>
      )}
      {!hasFavorites && !isFetching && (
        <Center>
          <p>{t('favorites:noFavorites')}</p>
        </Center>
      )}
    </ViewDashboard>
  );
};

const Center = styled.div`
  display: flex;
  justify-content: center;
`;
