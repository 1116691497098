import * as BrokerService from './../../services/BrokerService';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigationType, useSearchParams } from 'react-router-dom';
import { BrokerDTO } from '../../dtos/broker';
import { BrokerTile } from './BrokerTile';
import { HelmetBrokerSection } from '../../helmets/HelmetBrokerSection';
import { Spin } from 'antd';
import { ViewCentered } from '../../common/views/ViewCentered';
import { theme } from '../../styles/theme';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const BrokerSection = (): JSX.Element => {
  const { t } = useTranslation(['brokerSection', 'common']);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigationType = useNavigationType();
  const [page, setPage] = useState(searchParams.get('page') ? Number(searchParams.get('page')) : 0);
  const [prevId, setPrevId] = useState(searchParams.get('prev_id') ? searchParams.get('prev_id') : undefined);

  useEffect(() => {
    setSearchParams({});
    return;
  }, []);

  const { data, fetchNextPage, isFetching, error } = useInfiniteQuery({
    queryKey: ['getBrokers'],
    queryFn: ({ pageParam = 0 }) => BrokerService.searchBrokers(pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage.broker_list.length === 0) {
        return undefined;
      }
      return page + 1;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const observer = useRef<IntersectionObserver | undefined>();

  const lastListingElementRef = useCallback(
    (node: Element | null) => {
      if (isFetching) return;
      if (observer && observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchNextPage();
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetching, data],
  );

  useEffect(() => {
    if (navigationType === 'POP' && prevId) {
      document.getElementById(prevId.toString())?.scrollIntoView({ behavior: 'auto', block: 'end' });
      setPrevId(undefined);
    }
    return;
  }, [navigationType]);

  const renderBroker = (broker: BrokerDTO, arrayIndex: number) => {
    return (
      <TileWrapper
        id={broker._id}
        ref={
          data?.pages?.[data.pages.length - 1]?.broker_list?.[
            data.pages?.[data.pages.length - 1].broker_list.length - 1
          ]
            ? lastListingElementRef
            : null
        }
        key={arrayIndex}
      >
        <BrokerTile {...broker} />
      </TileWrapper>
    );
  };
  return error ? (
    <ViewCentered maxWidth="500px">
      <h4>{t('common:serverError')}</h4>
    </ViewCentered>
  ) : (
    <StyledLayout>
      <HelmetBrokerSection />
      <DummyCard>
        <StyledH3>{t('findABroker')}</StyledH3>
        <p>
          {data?.pages?.[0]?.total}
          {t('results')}
        </p>
      </DummyCard>
      {/* ELITE RANDOMIZED */}
      {data?.pages.map((result) =>
        result.broker_list
          .filter((broker) => broker.is_elite)
          .sort(() => 0.5 - Math.random())
          .map((broker, arrayIndex) => renderBroker(broker, arrayIndex)),
      )}
      {/* NON-ELITE RANDOMIZED */}
      {data?.pages.map((result) =>
        result.broker_list
          .filter((broker) => !broker.is_elite)
          .sort(() => 0.5 - Math.random())
          .map((broker, arrayIndex) => renderBroker(broker, arrayIndex)),
      )}
      <Spin size={'large'} spinning={isFetching} />
      {!isFetching && data?.pages && data.pages.length > 1 && (
        <NoListing>
          <h6>{t('noMoreResults')}</h6>
        </NoListing>
      )}
    </StyledLayout>
  );
};

const StyledLayout = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-left: 4vw;
  padding-right: 4vw;
  @media only screen and (max-width: 1400px) {
    padding-left: 2vw;
    padding-right: 2vw;
  }
  overflow: auto;
`;

const DummyCard = styled.div`
  max-width: 1088px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  p {
    margin-bottom: 0.5rem;
  }
`;

const TileWrapper = styled.div`
  width: 100%;
  max-width: 1088px;
`;

const NoListing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;

const StyledH3 = styled.h1`
  font-size: ${theme.fontsize.h3} !important;
`;
