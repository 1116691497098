import { ButtonSmall, ButtonStatus } from '../../../common/button/ButtonSmall';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Form, Input, Modal, Spin } from 'antd';
import React, { useContext, useState } from 'react';
import { passwordForgot, resetPassword } from '../../../services/UserService';

import { FieldLength } from '../../../dtos/fields';
import { LocaleContext } from '../../../router/Router';
import { ViewCentered } from '../../../common/views/ViewCentered';
import { confirmPasswordRule } from '../confirmPasswordRule';
import { publicRoutes } from '../../../router/routes';
import { theme } from '../../../styles/theme';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type EmailInput = {
  email: string;
};

type PasswordInput = {
  password: string;
  validation_code: string;
};

export const EmailPasswordForgot = (): JSX.Element => {
  const { t } = useTranslation('profileForm');
  const { locale } = useContext(LocaleContext);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const passwordForgotMutation = useMutation(passwordForgot);
  const onFinish = (input: EmailInput) => {
    setEmail(input.email);
    passwordForgotMutation.mutate(input.email);
  };

  const passwordResetMutation = useMutation(resetPassword);
  const onSubmit = (input: PasswordInput) => {
    passwordResetMutation.mutate(
      { email: email, validation_code: input.validation_code, password: input.password },
      {
        onSuccess: () => {
          Modal.success({
            content: t(`profileForm:forgot_password.modal`),
            onOk: () => navigate(publicRoutes.signin(locale)),
            okText: t('profileForm:continue'),
          });
        },
      },
    );
  };

  return (
    <>
      {passwordForgotMutation.isLoading ? (
        <Spin size="large" />
      ) : (
        <ViewCentered maxWidth="464px">
          <h4 style={{ marginTop: '32px' }}>{t('profileForm:forgot_password.forgotPassword')}</h4>
          {!passwordForgotMutation.isSuccess && (
            <>
              <Form onFinish={onFinish} layout="vertical">
                <Form.Item
                  style={{ marginBottom: 0 }}
                  name="email"
                  label={<label>{t('profileForm:forgot_password.email')}</label>}
                  rules={[
                    { type: 'email' },
                    { max: FieldLength.MEDIUM },
                    {
                      required: true,
                      message: t('profileForm:email'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <div style={{ marginTop: '24px' }}>
                  <ButtonSmall status={ButtonStatus.PRIMARY} text={t('profileForm:forgot_password.continue')} />
                </div>
              </Form>
            </>
          )}
          {passwordForgotMutation.isSuccess &&
            (passwordResetMutation.isLoading ? (
              <Spin size="large" />
            ) : (
              <>
                <p style={{ marginTop: '25px', color: theme.color.darkGrey }}>
                  {t('profileForm:forgot_password.success')}
                </p>
                <Form onFinish={onSubmit} layout="vertical">
                  <Form.Item
                    name="validation_code"
                    label={<label>{t('profileForm:forgot_password.validation_code.title')}</label>}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t('profileForm:forgot_password.validation_code.placeholder')}
                    ></Input>
                  </Form.Item>

                  <Form.Item
                    name="new_password"
                    label={<label>{t('profileForm:forgot_password.new_password.title')}</label>}
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'),
                        message: t('profileForm:passwordRule'),
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      placeholder={t('profileForm:forgot_password.new_password.placeholder')}
                      iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    ></Input.Password>
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label={<label>{t('profileForm:forgot_password.confirm_new_password.title')}</label>}
                    rules={[
                      {
                        required: true,
                        message: t('profileForm:confirm_password'),
                      },
                      confirmPasswordRule(t('profileForm:mustMatch'), 'new_password'),
                    ]}
                  >
                    <Input.Password
                      size="large"
                      placeholder={t('profileForm:forgot_password.confirm_new_password.placeholder')}
                      iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    ></Input.Password>
                  </Form.Item>
                  <div style={{ marginTop: '24px', display: 'flex', justifyContent: 'flex-end' }}>
                    <ButtonSmall status={ButtonStatus.PRIMARY} text={t('profileForm:forgot_password.continue')} />
                  </div>
                </Form>
              </>
            ))}
        </ViewCentered>
      )}
    </>
  );
};
