import { Divider } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import fallback from './../../img/fallbackimg.webp';
import { getFranchiseCarousel } from '../../services/FranchiseService';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const FranchiseCarousel = () => {
  const { t } = useTranslation('franchiseSection');
  const NUMBER_OF_FRANCHISES = 6;

  const getCarouselQuery = useQuery({
    queryKey: ['getCarouselFranchises'],
    queryFn: () =>
      getFranchiseCarousel({
        nbOfFranchises: NUMBER_OF_FRANCHISES,
      }),
    refetchOnWindowFocus: false,
    retry: false,
  });

  return getCarouselQuery.isError ? (
    <></>
  ) : (
    <div>
      <Divider orientation="left" plain>
        <PNoPad>{t('carousel.featured')}</PNoPad>
      </Divider>
      <FlexDiv>
        {getCarouselQuery.data?.map((franchise, index) => (
          <Link key={`${index}`} to={`../franchise/${franchise._id}`}>
            <Container>
              <StyledImg src={franchise.logo || fallback} height={120} width={180} />
            </Container>
          </Link>
        ))}
      </FlexDiv>
      <Divider />
    </div>
  );
};

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 16px;
  justify-content: space-between;
  max-width: 1088px;
  @media only screen and (max-width: 500px) {
    justify-content: center;
  }
  height: 120px;
  overflow: hidden;
  gap: 8px;
`;

const Container = styled.div`
  width: 180px;
  display: block;
  position: relative;
`;

const StyledImg = styled.img`
  object-fit: contain;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
`;

const PNoPad = styled.p`
  margin-bottom: 4px;
  font-weight: 500;
`;
