import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import React, { useContext, useState } from 'react';

import { FranchiseDTO } from '../../../dtos/franchise';
import Tooltip from 'antd/es/tooltip';
import { UserContext } from '../../../contexts/UserContext';
import { deleteFranchise } from '../../../services/AdminService';
import styled from 'styled-components';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';

export const DeleteAction = (props: FranchiseDTO): JSX.Element => {
  const { tokenErrorHandler } = useContext(UserContext),
    axiosMiddleware = useAxiosMiddleware(),
    [deleted, setDeleted] = useState(false);

  const deleteMutation = useMutation(deleteFranchise, {
    onSuccess: () => {
      setDeleted(true);
    },
    onError: tokenErrorHandler,
  });

  const deleteFranchiseAction = (id: string) => {
    Modal.error({
      content: 'Êtes-vous sur de vouloir supprimer cette franchise?',
      onOk: () => deleteMutation.mutate({ franchiseId: id, axiosMid: axiosMiddleware }),
      closable: true,
    });
  };

  return deleted ? (
    <NotAllowedDiv>
      <Tooltip title={'Cette franchise a été supprimée'} placement={'left'}>
        <CloseCircleOutlined style={{ color: 'red' }} />
      </Tooltip>
    </NotAllowedDiv>
  ) : deleteMutation.isLoading ? (
    <Spin />
  ) : (
    <div>
      <Tooltip title={'Supprimer la franchise'} placement={'left'}>
        <DeleteOutlined onClick={() => deleteFranchiseAction(props._id || '')} />
      </Tooltip>
    </div>
  );
};

export const NotAllowedDiv = styled.div`
  cursor: not-allowed;
`;
