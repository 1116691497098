import {
  AdminRouteInterface,
  GROUPS,
  PrivateRouteInterface,
  PublicRoutesInterface,
  adminRoutes,
  adminRoutesComponents,
  privateRoutes,
  privateRoutesComponents,
  publicRoutes,
  publicRoutesComponents,
} from './routes';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { Row, Spin } from 'antd';

import { Header } from '../components/header/Header';
import { NotFound } from '../pages/notFound/NotFound';
import { RequireAuth } from './RequireAuth';
import { redirectedRoutesMigration } from './routesRedirected';
import { useTranslation } from 'react-i18next';

export const LocaleContext = createContext({
  locale: '',
  // eslint-disable-next-line
  setLocale: (newLocale: string) => {},
});

export const AppRouter = (): JSX.Element => {
  const { i18n } = useTranslation(),
    { pathname, search, hash } = useLocation(),
    navigate = useNavigate(),
    availableLocales = ['en', 'fr'],
    defaultLocale = 'fr',
    pathnameLocale = pathname.substring(1, 3).toLowerCase(),
    [locale, setLocale] = useState(defaultLocale),
    // eslint-disable-next-line
    loaderTimerRef = useRef<any>(),
    [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loaderTimerRef.current = setTimeout(() => {
      setIsLoading(false);
      clearTimeout(loaderTimerRef.current);
    }, 300);
  }, []);

  useEffect(() => {
    if (availableLocales.includes(pathnameLocale)) {
      updateLocale(pathnameLocale);
    } else if (pathname === '/') {
      updateLocale(defaultLocale);
    }
  }, [pathname]);

  useEffect(() => {
    let lang = defaultLocale;

    if (availableLocales.includes(pathnameLocale)) {
      lang = pathnameLocale;
      setLanguageHandler(lang);
    } else if (pathname === '/') {
      setLanguageHandler(lang);
    }
  }, [locale]);

  const setLanguageHandler = (lang: string) => {
    if (lang === 'en') {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('fr');
    }
  };

  const updateLocale = (newLocale: string) => {
    const newPath = `/${newLocale}` + pathname.substring(3);

    if (locale !== newLocale) {
      if (newPath === `/${newLocale}/` || newPath === `/${newLocale}` || pathname === '/') {
        navigate(publicRoutes.home(newLocale));
      } else {
        navigate(`${newPath}${hash}${search}`);
      }
      setLocale(newLocale);
    } else if (newPath === `/${newLocale}/` || newPath === `/${newLocale}` || pathname === '/') {
      navigate(publicRoutes.home(newLocale));
    }
  };

  useEffect(() => {
    if (availableLocales.includes(pathnameLocale)) {
      updateLocale(pathnameLocale);
    } else if (pathname === '/') {
      updateLocale(defaultLocale);
    }
  }, [pathname]);

  if (isLoading) {
    return (
      <Row justify="center">
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <LocaleContext.Provider value={{ locale, setLocale: updateLocale }}>
      <Routes>
        <Route path={locale} element={<Wrapper />}>
          {Object.entries(publicRoutes).map(([key, value]) => (
            <Route
              key={key}
              path={value(locale)}
              element={publicRoutesComponents[key as keyof PublicRoutesInterface]}
            />
          ))}
          {Object.entries(privateRoutes).map(([key, value]) => (
            <Route
              key={key}
              path={value(locale)}
              element={
                <RequireAuth redirectTo={publicRoutes.signin(locale)}>
                  {privateRoutesComponents[key as keyof PrivateRouteInterface]}
                </RequireAuth>
              }
            />
          ))}
        </Route>
        {Object.entries(adminRoutes).map(([key, value]) => (
          <Route
            key={key}
            path={value}
            element={
              <RequireAuth redirectTo={publicRoutes.businessesForSale(locale)} allowedRoles={[GROUPS.admin]}>
                {adminRoutesComponents[key as keyof AdminRouteInterface]}
              </RequireAuth>
            }
          />
        ))}
        {redirectedRoutesMigration.map(({ from, to }, key) => (
          <Route key={key} path={from} element={<Navigate replace to={to} />} />
        ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </LocaleContext.Provider>
  );
};

const Wrapper = () => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};
