import { ButtonSmall, ButtonStatus } from '../../common/button/ButtonSmall';
import React, { useContext } from 'react';

import { LocaleContext } from '../../router/Router';
import { ViewCentered } from '../../common/views/ViewCentered';
import logo from '../../img/LOGO-Acquizition-HR.webp';
import { publicRoutes } from '../../router/routes';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const NotFound = (): JSX.Element => {
  const { t } = useTranslation('notFound');
  const { locale } = useContext(LocaleContext);
  const navigate = useNavigate();

  return (
    <ViewCentered maxWidth={'464px'}>
      <Sapcer />
      <img src={logo} alt="logo" height={32} />
      <Sapcer />
      <h2>{t('title')}</h2>
      <Sapcer />
      <ButtonSmall
        status={ButtonStatus.PRIMARY}
        text={t('goHome')}
        onClick={() => navigate(publicRoutes.home(locale))}
      />
    </ViewCentered>
  );
};

const Sapcer = styled.div`
  height: 32px;
`;
