import React from 'react';
import styled from 'styled-components';

type ViewNarroColumnProps = {
  children: React.ReactNode;
};

export const ViewNarrowColumn = ({ children }: ViewNarroColumnProps): JSX.Element => (
  <StyledContainer>{children}</StyledContainer>
);

const StyledContainer = styled.section`
  grid-area: main;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  height: 100%;
`;
