import { Col, Row, Spin } from 'antd';
import React, { useContext, useEffect } from 'react';

import { Footer } from '../../components/footer/Footer';
import { Image } from 'antd';
import { LocaleContext } from '../../router/Router';
import { UserContext } from '../../contexts/UserContext';
import { ViewNarrowColumn } from '../../common/views/ViewNarrowColumn';
import legalKit from './../../img/legalkit.webp';
import { privateRoutes } from '../../router/routes';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useAxiosMiddleware } from '../../services/useAxiosMiddleware';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { userHasActiveSubscription } from '../../services/PaymentService';

export const LegalKit = () => {
  const { tokenErrorHandler } = useContext(UserContext),
    { locale } = useContext(LocaleContext),
    axiosMiddleware = useAxiosMiddleware(),
    navigate = useNavigate(),
    { t } = useTranslation('legalKit');

  const subscriptionActiveQuery = useQuery(['userHasActiveSubscription'], {
    queryFn: () => userHasActiveSubscription({ axiosMid: axiosMiddleware }),
    refetchOnWindowFocus: false,
    onError: tokenErrorHandler,
  });

  useEffect(() => {
    if (!subscriptionActiveQuery.isLoading && subscriptionActiveQuery.data === false) {
      navigate(privateRoutes.subscription(locale));
    }
  }, [subscriptionActiveQuery.data]);

  return (
    <ViewNarrowColumn>
      <Row justify="center">
        <Spin spinning={subscriptionActiveQuery.isLoading} size={'large'} />
        {!subscriptionActiveQuery.isLoading && subscriptionActiveQuery.data === true && (
          <Block>
            <Row justify="space-around" align={'top'} gutter={[0, 32]}>
              <Col md={12} lg={10}>
                <StyledH2>{t('legalKit')}</StyledH2>
                <PBreak>{t('intro')}</PBreak>
                <h6>Introduction</h6>
                <a href="https://acquizition-prod.s3.ca-central-1.amazonaws.com/legal_kit/Introduction+%E2%80%A6+la+trousse+transactionnelle.docx">
                  <PBreakU>{'Introduction à la trousse transactionnelle'}</PBreakU>
                </a>
                <h6>{t('advice')}</h6>
                <a href="https://acquizition-prod.s3.ca-central-1.amazonaws.com/legal_kit/ACQUIZITION.BIZ+-+4+-+Comment+bien+vendre+votre+entreprise.DOC">
                  <PBreakU>{'Comment bien vendre votre entreprise'}</PBreakU>
                </a>
                <a href="https://acquizition-prod.s3.ca-central-1.amazonaws.com/legal_kit/ACQUIZITION.BIZ+-+5+-+L'art+de+n%E2%80%9Agocier+la+vente+de+votre+entreprise.docx">
                  <PBreakU>{"L'art de négocier la vente de votre entreprise"}</PBreakU>
                </a>
                <a href="https://acquizition-prod.s3.ca-central-1.amazonaws.com/legal_kit/ACQUIZITION.BIZ+-+6+-+la+normalisation+de+votre+BAIIA.DOC">
                  <PBreakU>{'La normalisation de votre BAIIA'}</PBreakU>
                </a>
                <a href="https://acquizition-prod.s3.ca-central-1.amazonaws.com/legal_kit/ACQUIZITION.BIZ+-+7+-+Les+grandes+%E2%80%9Atapes+de+la+transaction.DOC">
                  <PBreakU>{'Les grandes étapes de la transaction'}</PBreakU>
                </a>
                <a href="https://acquizition-prod.s3.ca-central-1.amazonaws.com/legal_kit/ACQUIZITION.BIZ+-+8+-+Le+Business+Staging.DOCX">
                  <PBreakU>{'Le Business Staging'}</PBreakU>
                </a>
                <a href="https://acquizition-prod.s3.ca-central-1.amazonaws.com/legal_kit/ACQUIZITION.BIZ+-+9+-+Le+Transfert+et+la+Releve+de+votre+entreprise.DOCX">
                  <PBreakU>{'Le transfert et la relève de votre entreprise'}</PBreakU>
                </a>
                <a href="https://acquizition-prod.s3.ca-central-1.amazonaws.com/legal_kit/ACQUIZITION.BIZ+-+10+-+Trop+tard%2C+t'es+mort!.DOC">
                  <PBreakU>{"Trop tard, t'es mort"}</PBreakU>
                </a>
                <br />
                <h6>{t('models')}</h6>
                <a href="https://acquizition-prod.s3.ca-central-1.amazonaws.com/legal_kit/ACQUIZITION.BIZ+-+11+-+Lettre+d'inter%CB%86t.doc">
                  <PBreakU>{"Lettre d'intention"}</PBreakU>
                </a>
                <a href="https://acquizition-prod.s3.ca-central-1.amazonaws.com/legal_kit/ACQUIZITION.BIZ+-+12+-+Engagement+de+confidentialit%E2%80%9A+(NDA).DOC">
                  <PBreakU>{'Engagement de confidentialité'}</PBreakU>
                </a>
                <a href="https://acquizition-prod.s3.ca-central-1.amazonaws.com/legal_kit/ACQUIZITION.BIZ+-+13+-+Entente+de+n%E2%80%9Agociation+exclusive.doc">
                  <PBreakU>{'Entente de négociation exclusive'}</PBreakU>
                </a>
                <a href="https://acquizition-prod.s3.ca-central-1.amazonaws.com/legal_kit/ACQUIZITION.BIZ+-+14+-+Lettre+d'intention+(Achat+d'actifs).DOC">
                  <PBreakU>{"Lettre d'intention (achat d'actifs)"}</PBreakU>
                </a>
                <a href="https://acquizition-prod.s3.ca-central-1.amazonaws.com/legal_kit/ACQUIZITION.BIZ+-+15+-+Lettre+d'intention+(Achat+d'actions).doc">
                  <PBreakU>{"Lettre d'intention (achat d'actions)"}</PBreakU>
                </a>
                <a href="https://acquizition-prod.s3.ca-central-1.amazonaws.com/legal_kit/ACQUIZITION.BIZ+-+16+-+La+v%E2%80%9Arification+diligente+(%E2%80%A6+quoi+vous+attendre).DOC">
                  <PBreakU>{'La vérification diligente (... à quoi vous attendre)'}</PBreakU>
                </a>
                <br />
                <a href="https://acquizition-prod.s3.ca-central-1.amazonaws.com/legal_kit/ACQUIZITION.BIZ+-+3+-+Conditions+d'utilisation.docx">
                  <PBreakU>{"Conditions d'utilisation"}</PBreakU>
                </a>
              </Col>
              <Col md={12} lg={10}>
                <Image src={legalKit} preview={false} alt="Trousse légale" />
              </Col>
            </Row>
          </Block>
        )}
      </Row>
      <Footer />
    </ViewNarrowColumn>
  );
};

const Block = styled.section`
  padding: 4vw;
  max-width: 1800px;
  width: 100%;
`;

const PBreakU = styled.p`
  white-space: pre-line;
  margin-bottom: 4px;
  text-decoration: underline;
  color: ${theme.color.electricBlue};
`;
const PBreak = styled.p`
  white-space: pre-line;
`;

const StyledH2 = styled.h2`
  font-weight: bolder;
`;
