import { SortOptions, SortPanel } from './panels/SortPanel';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { useNavigationType, useSearchParams } from 'react-router-dom';

import { BusinessCarousel } from './panels/BusinessCarousel';
import { FilterPanel } from './panels/FilterPanel';
import { HelmetBusinessesForSale } from '../../helmets/HelmetBusinessesForSale';
import { ListingTile } from './listingTile/ListingTile';
import React from 'react';
import { SearchParameters } from '../../dtos/businessesForSale';
import { Spin } from 'antd';
import { ViewCentered } from '../../common/views/ViewCentered';
import { searchListings } from '../../services/ListingService';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export enum ActionSearchBusinessEnum {
  FILTER = 'FILTER',
  SORT = 'SORT',
}

const getInitialSearchStateFromSearchParams = (searchParams: URLSearchParams): SearchParameters => {
  const res = {
    sort: searchParams.get('sort_option') ? (searchParams.get('sort_option') as SortOptions) : SortOptions.NEWEST_FIRST,
    price: {
      min: searchParams.get('price_min') ? Number(searchParams.get('price_min')) : undefined,
      max: searchParams.get('price_max') ? Number(searchParams.get('price_max')) : undefined,
    },
    regions: JSON.parse(searchParams.get('regions') || '[]'),
    industries: JSON.parse(searchParams.get('industries') || '[]'),
  };
  return res;
};

const fetchListings = async (pageNumber: number, searchParameters: SearchParameters) => {
  return searchListings({ searchParameters: searchParameters, pageNumber: pageNumber });
};

export type ActionSearchBusinesses =
  | { type: ActionSearchBusinessEnum.FILTER; searchParameters: SearchParameters }
  | { type: ActionSearchBusinessEnum.SORT; searchParameters: SearchParameters };

const reducer = (state: SearchParameters, action: ActionSearchBusinesses) => {
  switch (action.type) {
    case ActionSearchBusinessEnum.SORT:
      return {
        ...state,
        ...action.searchParameters,
      };
    case ActionSearchBusinessEnum.FILTER:
      return {
        ...state,
        ...action.searchParameters,
      };
  }
};

export const BusinessesForSale = (): JSX.Element => {
  const { t } = useTranslation(['common', 'businessesForSale']);
  const navigationType = useNavigationType();
  const [searchParams, setSearchParams] = useSearchParams();

  const [dispState, dispatch] = useReducer(reducer, getInitialSearchStateFromSearchParams(searchParams));

  const [page, setPage] = useState(searchParams.get('page') ? Number(searchParams.get('page')) : 0);
  const [prevId, setPrevId] = useState(searchParams.get('prev_id') ? searchParams.get('prev_id') : undefined);

  useEffect(() => {
    setSearchParams({});
    return;
  }, []);

  const { data, fetchNextPage, isFetching, remove, refetch, error } = useInfiniteQuery({
    queryKey: ['getAllListings'],
    queryFn: ({ pageParam = { page: 0, searchState: dispState } }) => {
      return fetchListings(pageParam.page, pageParam.searchState);
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.tile_list?.length === 0) {
        return undefined;
      }
      return { page: page + 1, searchState: dispState };
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const observer = useRef<IntersectionObserver | undefined>();

  const lastListingElementRef = useCallback(
    (node: Element | null) => {
      if (isFetching) return;
      if (typeof observer != 'undefined' && observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchNextPage();
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetching, data],
  );

  useEffect(() => {
    if (!prevId) {
      remove();
      setPage(0);
      refetch();
    }
    return;
  }, [dispState]);

  useEffect(() => {
    if (navigationType === 'POP' && prevId) {
      document.getElementById(prevId.toString())?.scrollIntoView({ behavior: 'auto', block: 'end' });
      setPrevId(undefined);
    }
    return;
  }, [navigationType]);

  return error ? (
    <ViewCentered maxWidth="500px">
      <HelmetBusinessesForSale />
      <h4>{t('common:serverError')}</h4>
    </ViewCentered>
  ) : (
    <StyledDiv>
      <HelmetBusinessesForSale />
      <Header>
        <StyledH1>{t('businessesForSale:businessesForSale')}</StyledH1>
      </Header>
      <FilterPanel dispatcher={[dispState, dispatch]} />
      <SortPanel dispatcher={[dispState, dispatch]} total={data?.pages?.[0].total} />

      <StyledContent>
        <BusinessCarousel industries={dispState.industries} regions={dispState.regions} />
        {data?.pages.map((result) => {
          return result.tile_list?.map((tile, arrayIndex) => (
            <div
              id={tile._id}
              ref={
                data?.pages?.[data?.pages.length - 1]?.tile_list?.[
                  data?.pages?.[data?.pages.length - 1].tile_list.length - 1
                ]
                  ? lastListingElementRef
                  : null
              }
              key={arrayIndex}
            >
              <ListingTile
                tile={tile}
                searchState={{
                  page: page,
                  searchParams: dispState,
                }}
              />
            </div>
          ));
        })}
        {!isFetching && data?.pages && data.pages.length > 1 && (
          <NoListing>
            <h6>{t('businessesForSale:noMoreResults')}</h6>
          </NoListing>
        )}
        {isFetching && (
          <Center>
            <Spin size="large" />
          </Center>
        )}
        {!isFetching && data?.pages && data.pages.length === 1 && (
          <NoListing>
            <h5>{t('businessesForSale:noResults')}</h5>
          </NoListing>
        )}
      </StyledContent>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  grid-area: main;
  overflow: auto;
  overflow: overlay;
  display: grid;
  grid-template-rows: min-content 40px min-content;
  grid-template-columns: 1fr 3fr;
  grid-template-areas:
    'title title'
    'sidebar sort'
    'sidebar content';

  @media only screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
  column-gap: 30px;
  padding-top: 32px;
  padding-left: 4vw;
  padding-right: 4vw;
  @media only screen and (max-width: 1400px) {
    padding-left: 2vw;
    padding-right: 2vw;
  }
`;

const StyledContent = styled.div`
  grid-area: content;
`;

const NoListing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;

const Header = styled.div`
  grid-area: title;
`;

const StyledH1 = styled.h1`
  font-size: ${theme.fontsize.h3} !important;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;
