import imageCompression from 'browser-image-compression';
import { useState } from 'react';

export const useIamgeCompressor = (): [(imageFile: File) => Promise<File | undefined>, boolean] => {
  const [imageCompressorLoading, setLoading] = useState(false);

  const compressImage = async (imageFile: File) => {
    setLoading(true);
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      setLoading(false);
      return compressedFile;
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return [compressImage, imageCompressorLoading];
};
