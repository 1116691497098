import React, { useContext } from 'react';

import { LocaleContext } from '../../router/Router';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import contex from './../../img/Logo-Contex-Renverse.webp';
import logo from './../../img/LOGO-Acquizition-LR-R.webp';
import { publicRoutes } from '../../router/routes';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useTranslation } from 'react-i18next';
import { Didomi } from '../header/Didomi';

export const Footer = (): JSX.Element => {
  const { t } = useTranslation('footer');
  const { locale } = useContext(LocaleContext);
  const { authTokens } = useContext(UserContext);
  const globalWindow: any = window;
  const didomiObject = globalWindow.Didomi;
  return (
    <TopDiv>
      <Block>
        <StyledIntro>
          <NavLink to={publicRoutes.home(locale)}>
            <img src={logo} alt="logo" height={32} />
          </NavLink>
          <h6>{t('about')}</h6>
          <p>{t('intro')}</p>
        </StyledIntro>

        <StyledDiv>
          <div>
            <h6>{t('information')}</h6>
            <NavLink to={publicRoutes.faq(locale)}>
              <p>{t('faq')}</p>
            </NavLink>
            <NavLink to={publicRoutes.contactUs(locale)}>
              <p>{t('contact')}</p>
            </NavLink>
            <a
              href={
                locale == 'fr'
                  ? 'https://acquizition-prod.s3.ca-central-1.amazonaws.com/media_kit/Acquizition_biz_trousse_media_experts.pdf'
                  : 'https://acquizition-prod.s3.ca-central-1.amazonaws.com/media_kit/Acquizition_biz_media_kit_experts.pdf'
              }
            >
              <p>{t('expertSub')}</p>
            </a>
            <a
              href={
                locale == 'fr'
                  ? 'https://acquizition-prod.s3.ca-central-1.amazonaws.com/media_kit/Acquizition_biz_trousse_medias_proprietaires_vendeurs.pdf'
                  : 'https://acquizition-prod.s3.ca-central-1.amazonaws.com/media_kit/Acquizition_biz_media_kit_owners_sellers.pdf'
              }
            >
              <p>{t('ownerSub')}</p>
            </a>
            <NavLink to={publicRoutes.termsOfUse(locale)}>
              <p>{t('modalities')}</p>
            </NavLink>
            <NavLink to={publicRoutes.privacy(locale)}>
              <p>{t('privacy')}</p>
            </NavLink>

            {Didomi() ? (
              <a href="javascript:Didomi.preferences.show()">
                <p>{t('consent_choices')}</p>
              </a>
            ) : (
              ''
            )}
          </div>
        </StyledDiv>
        <div>
          <h6>Groupe Contex</h6>
          <a href={'https://www.avantages.ca/'}>
            <p>Avantages</p>
          </a>
          <a href={'https://www.benefitscanada.com/'}>
            <p>Benefits Canada</p>
          </a>
          <a href={'https://contech.qc.ca/'}>
            <p>Contech bâtiment</p>
          </a>
          <a href={'https://www.lesaffaires.com/'}>
            <p>Les Affaires</p>
          </a>
          <a href={'https://evenements.lesaffaires.com/'}>
            <p>Événements Les Affaires</p>
          </a>
          <a href={'https://ecarrieres.com/fr/'}>
            <p>L’Événement Carrières</p>
          </a>
        </div>
        <div>
          <img src={contex} alt="logo" height={32} />
          <StyledP>
            {t('contex')}
            <br />
            {t('addres')} <br />
            Montréal, QC H3B 1A5
          </StyledP>
        </div>
      </Block>
    </TopDiv>
  );
};

const Block = styled.section`
  padding: 64px 4vw 4vw 4vw;
  @media only screen and (max-width: 1400px) {
    padding: 64px 2vw 2vw 2vw;
  }
  max-width: 1800px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  row-gap: 16px;
  h6 {
    color: white;
    font-weight: 500 !important;
  }
  p {
    margin-bottom: 0;
    font-weight: 300 !important;
    color: white;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-flow: wrap;
  column-gap: 128px;
`;

const StyledIntro = styled.div`
  max-width: 400px;
  h6 {
    margin-top: 16px;
  }
`;

const TopDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${theme.color.darkBlue};
`;

const StyledP = styled.p`
  margin-top: 16px;
`;
