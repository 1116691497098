import { Row, Spin } from 'antd';

import { Count } from './Count';
import React from 'react';
import { SortOptions } from '../franchiseSection/FranchiseSort';
import { searchFranchise } from '../../services/FranchiseService';
import { searchListings } from '../../services/ListingService';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const Metrics = () => {
  const { t } = useTranslation('home');
  const listingCntQuery = useQuery({
    queryKey: ['listingCnt'],
    queryFn: () =>
      searchListings({
        searchParameters: {},
        pageNumber: 0,
      }),
  });

  const franchiseCntQuery = useQuery({
    queryKey: ['franchiseCnt'],
    queryFn: () =>
      searchFranchise({
        show_only_publish: true,
        page: 0,
        sort_parameters: SortOptions.A_FIRST,
      }),
  });

  return (
    <StyledSection>
      <Row justify="center">
        <Block>
          <KPIDiv>
            <FlexGrow>
              <FlexEl>
                {listingCntQuery.isLoading ? (
                  <Spin size={'large'} />
                ) : (
                  <Count value={listingCntQuery.data?.total} duration={2} jump={1} />
                )}
                <h6>{t('home:metrics.businessesForSale')}</h6>
              </FlexEl>
            </FlexGrow>
            <FlexGrow>
              <FlexEl>
                {franchiseCntQuery.isLoading ? (
                  <Spin size={'large'} />
                ) : (
                  <Count value={franchiseCntQuery.data?.total} duration={1} jump={1} />
                )}
                <h6>{t('home:metrics.franchisor')}</h6>
              </FlexEl>
            </FlexGrow>
            <FlexGrow>
              <FlexEl>
                <Count value={46000} duration={4} jump={100} />
                <h6>{t('home:metrics.buyers')}</h6>
              </FlexEl>
            </FlexGrow>
            <FlexGrow>
              <FlexEl>
                <Count value={90000} duration={4} jump={200} />
                <h6>{t('home:metrics.pageViews')}</h6>
              </FlexEl>
            </FlexGrow>
            <FlexGrow>
              <FlexEl>
                <h2>{t('home:metrics.active')}</h2>
                <h6>{t('home:metrics.since')}</h6>
              </FlexEl>
            </FlexGrow>
          </KPIDiv>
        </Block>
      </Row>
    </StyledSection>
  );
};

const Block = styled.section`
  padding: 4vw;
  max-width: 1800px;
`;

const StyledSection = styled.div`
  width: 100%;
  background-color: ${theme.color.smoke};
  h2 {
    color: ${theme.color.green};
    font-size: 40px !important;
  }
`;

const KPIDiv = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  @media only screen and (min-width: 1000px) {
    div:not(:first-child) {
      border-left: 2px solid ${theme.color.green};
      align-self: stretch;
    }
  }
`;

const FlexGrow = styled.div`
  width: 220px;
  text-align: center;
  display: flex;
  justify-content: center;
`;

const FlexEl = styled.div`
  width: 160px;
  display: flex;
  flex-direction: column;
`;
