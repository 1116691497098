import { Helmet } from 'react-helmet-async';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const HelmetFAQ = () => {
  const { t } = useTranslation('helmet');

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{t('faq.title')}</title>
      <meta name="description" content={t('faq.content')} />
      <link rel="alternate" hrefLang="fr" href="https://www.acquizition.biz/fr/faq" />
      <link rel="alternate" hrefLang="en" href="https://www.acquizition.biz/en/faq" />
    </Helmet>
  );
};
