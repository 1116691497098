export type OwnerSubscription = { id: string; lookup_key: string; unit_amount: number };
export type OwnerSubscriptions = {
  owner_1: OwnerSubscription;
  owner_2: OwnerSubscription;
  owner_3: OwnerSubscription;
};

export const parseOwnerPrices = (data: OwnerSubscription[]): OwnerSubscriptions => {
  const res = {
    owner_1: data.filter((obj) => obj.lookup_key === 'owner_1')[0],
    owner_2: data.filter((obj) => obj.lookup_key === 'owner_2')[0],
    owner_3: data.filter((obj) => obj.lookup_key === 'owner_3')[0],
  };

  return res;
};
