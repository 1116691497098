import * as adminService from '../../../services/AdminService';

import { CheckOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import React, { useContext, useState } from 'react';

import { LinstingAdmin } from '../../../dtos/admin';
import { NotAllowedDiv } from './DeleteAction';
import { UserContext } from '../../../contexts/UserContext';
import { theme } from '../../../styles/theme';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';

export const UnpublishAction = (props: LinstingAdmin): JSX.Element => {
  const [isDisplayed, setIsDisplayed] = useState(true);
  const { tokenErrorHandler } = useContext(UserContext);
  const axiosMid = useAxiosMiddleware();
  const unPublishMutation = useMutation(adminService.publishUnpublishListing, {
    onSuccess: () => {
      setIsDisplayed(false);
    },
    onError: tokenErrorHandler,
  });

  const undisplayAction = (id: string) => {
    Modal.confirm({
      content: "Êtes-vous sur de vouloir retirer l'annonce de la page 'Acheter une entreprise'",
      onOk: () =>
        unPublishMutation.mutate({
          axiosMid: axiosMid,
          listingid: id,
          state: false,
        }),
    });
  };
  return props.is_verified ? (
    <div>
      {isDisplayed ? (
        <Tooltip title={"Retirer l'annonce de la page 'Acheter une entreprise'"}>
          <EyeInvisibleOutlined onClick={() => undisplayAction(props._id)} />
        </Tooltip>
      ) : (
        <Tooltip title={"L'annonce a été retiré de la page 'Acheter une entreprise'"}>
          <CheckOutlined style={{ color: 'green' }} />
        </Tooltip>
      )}
    </div>
  ) : (
    <NotAllowedDiv>
      <Tooltip title={"L'annonce n'est pas affichée sur la page 'Acheter une entreprise'"}>
        <EyeInvisibleOutlined style={{ color: theme.color.lightGrey }} />
      </Tooltip>
    </NotAllowedDiv>
  );
};
