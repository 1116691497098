import React, { useEffect, useState } from 'react';

import { Image } from 'antd';
import { Row } from 'antd';
import left from '../../img/left.webp';
import remax from '../../img/remax.webp';
import right from '../../img/right.webp';
import royallepage from '../../img/royallepage.webp';
import styled from 'styled-components';
import sunbelt from '../../img/sunbelt.webp';
import { useTranslation } from 'react-i18next';

export const Quotes = () => {
  const { t } = useTranslation('home');
  const quotes = [
    {
      quote: 'quotes.quote1',
      org: royallepage,
      height: 48,
      author: 'quotes.author1',
    },
    {
      quote: 'quotes.quote2',
      org: remax,
      height: 32,
      author: 'quotes.author2',
    },
    {
      quote: 'quotes.quote3',
      org: sunbelt,
      height: 40,
      author: 'quotes.author3',
    },
  ];
  const [quoteData] = useState(quotes);
  const [current, setCurrent] = useState(0);
  const [quote, getQuote] = useState(quoteData[current]);

  useEffect(() => getQuote(quoteData[current]), [current, quote]);

  const nextQuote = () => {
    return current === quoteData.length - 1 ? setCurrent(0) : setCurrent(current + 1);
  };

  const prevQuote = () => {
    return current === 0 ? setCurrent(quoteData.length - 1) : setCurrent(current - 1);
  };

  return (
    <StyledSection>
      <Row justify="center">
        <Block>
          <StyledDiv>
            <StyledArrow onClick={prevQuote}>
              <img src={left} alt="left" height={32} />
            </StyledArrow>
            <StyledQuote>
              <HeavyP>{t(quote.author)}</HeavyP>
              <LogoWrapper>
                <StyledLogo height={quote.height} src={quote.org} preview={false} />
              </LogoWrapper>
              <LightQ>{t(quote.quote)}</LightQ>
            </StyledQuote>
            <StyledArrow onClick={nextQuote}>
              <img src={right} alt="right" height={32} />
            </StyledArrow>
          </StyledDiv>
        </Block>
      </Row>
    </StyledSection>
  );
};

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  margin-bottom: 24px;
`;

const StyledArrow = styled.a`
  cursor: pointer;
  font-size: 45px;
`;

const StyledQuote = styled.div`
  width: 100%;
  @media only screen and (max-width: 1000px) {
    width: 80%;
  }
  max-width: 900px;
  margin-right: 32px;
  margin-left: 32px;
  text-align: center;
  q,
  p {
    font-size: 1.2rem !important;
  }
`;

const HeavyP = styled.p`
  font-weight: 500 !important;
`;

const LightQ = styled.q`
  font-weight: 400 !important;
`;

const Block = styled.section`
  padding-left: 4vw;
  padding-right: 4vw;

  padding-top: 12vw;
  padding-bottom: 12vw;

  max-width: 1800px;
  width: 100%;
  height: 320px;
  @media only screen and (max-width: 1000px) {
    height: 100%;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSection = styled.div`
  width: 100%;
`;

const StyledLogo = styled(Image)`
  object-fit: scale-down !important;
`;
