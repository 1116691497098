import React from 'react';
import styled from 'styled-components';

type TextDescriptionProps = {
  title: string;
  content: string;
};

export const TextDescription = (props: TextDescriptionProps): JSX.Element => (
  <div>
    <StyledH6>
      <u>{props.title}</u>
    </StyledH6>
    <StyledPBreak>{props.content}</StyledPBreak>
  </div>
);

const StyledPBreak = styled.p`
  white-space: pre-line;
`;

export const StyledH6 = styled.h6`
  margin-bottom: 0 !important;
`;
