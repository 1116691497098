import React, { useContext } from 'react';
import { Spin, Tooltip } from 'antd';

import { EditOutlined } from '@ant-design/icons';
import { LocaleContext } from '../../../router/Router';
import { UserAdmin } from '../../../dtos/admin';
import { UserContext } from '../../../contexts/UserContext';
import { getBrokerProfile } from '../../../services/BrokerService';
import { privateRoutes } from '../../../router/routes';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export const EditBrokerAction = (props: UserAdmin) => {
  const navigate = useNavigate(),
    { locale } = useContext(LocaleContext),
    { tokenErrorHandler } = useContext(UserContext),
    axiosMid = useAxiosMiddleware();

  const getBrokerMutation = useMutation(() => getBrokerProfile({ brokerId: props.id || '', axiosMid: axiosMid }), {
    retry: false,
    onSuccess: (res) => {
      navigate(privateRoutes.brokerFileForm(locale), { state: { ...res, _id: props.id } });
    },
    onError: tokenErrorHandler,
  });

  return props.user_type === 'broker' ? (
    <div>
      <Tooltip title={'Modifier les infos du courtier'} placement={'left'}>
        {getBrokerMutation.isLoading ? <Spin /> : <EditOutlined onClick={() => getBrokerMutation.mutate()} />}
      </Tooltip>
    </div>
  ) : (
    <></>
  );
};
