import { Helmet } from 'react-helmet-async';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const HelmetFranchiseSection = (): JSX.Element => {
  const { t } = useTranslation('helmet');
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{t('franchiseSection.title')}</title>
      <meta name="description" content={t('franchiseSection.content')} />
      <link rel="alternate" hrefLang="fr" href="https://www.acquizition.biz/fr/franchiseSection" />
      <link rel="alternate" hrefLang="en" href="https://www.acquizition.biz/en/franchiseSection" />
    </Helmet>
  );
};
