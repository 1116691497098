export const groupedRegions: { [key: string]: number[] } = {
  GM: [1801, 1802, 1889, 1890, 1892],
  CN: [1881, 1884, 1891, 1885],
  OQ: [1894, 1887, 1882, 1700, 1000],
  NQ: [1895, 1886, 1893],
  EQ: [1883, 1500, 1888],
};

export const optionsNotificationWeekly = ['GM', 'CN', 'OQ', 'NQ', 'EQ'];

export const optionsNotificationDaily = [
  1801, 1802, 1882, 1883, 1881, 1884, 1885, 1886, 1887, 1888, 1889, 1890, 1891, 1892, 1893, 1894, 1895, 1700, 1500,
  1000,
];

export interface NotificationSettings {
  optin_consent_listing_for_sale?: boolean;
  optin_consent_contex?: boolean;
  optin_consent_acquizition?: boolean;
  f_email_frequency?: string;
  email_regions?: number[];
  email_industries?: number[];
}

export interface NotificationSettingsForm {
  consent: string[];
  f_email_frequency: string;
  regions_daily?: number[];
  industries_daily?: string[];
  regions_weekly?: string[];
}

export const TOT_INDUSTRIES = 22;
export const TOT_REGIONS = 20;
