import { FranchiseSort, SortOptions } from './FranchiseSort';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigationType, useSearchParams } from 'react-router-dom';

import { BottomBar } from './BottomBar';
import { FranchiseCarousel } from './FranchiseCarousel';
import { FranchiseContactForm } from '../franchiseInfo/FranchiseContactForm';
import { FranchiseTile } from './FranchiseTile';
import { HelmetFranchiseSection } from '../../helmets/HelmetFranchiseSection';
import { LocaleContext } from '../../router/Router';
import { NavLink } from 'react-router-dom';
import { Spin } from 'antd';
import { ViewCentered } from '../../common/views/ViewCentered';
import { publicRoutes } from '../../router/routes';
import { searchFranchise } from '../../services/FranchiseService';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

const fetchFranchises = async (pageParam: number, sortOption: SortOptions) => {
  return searchFranchise({
    show_only_publish: true,
    page: pageParam,
    sort_parameters: sortOption,
  });
};

export const FranchiseSection = (): JSX.Element => {
  const { t } = useTranslation(['franchiseSection', 'common']);
  const { locale } = useContext(LocaleContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigationType = useNavigationType();
  const [visibleForm, setVisibleForm] = useState(false);

  const [sortOption, setSortOption] = useState(
    searchParams.get('sort_option') ? (searchParams.get('sort_option') as SortOptions) : SortOptions.A_FIRST,
  );
  const [page, setPage] = useState(searchParams.get('page') ? Number(searchParams.get('page')) : 0);
  const [prevId, setPrevId] = useState(searchParams.get('prev_id') ? searchParams.get('prev_id') : undefined);

  const [favCnt, setFavCnt] = useState(JSON.parse(localStorage.getItem('franchiseList') || '[]').length);

  useEffect(() => {
    setSearchParams({});
    return;
  }, []);

  const { data, fetchNextPage, isFetching, remove, refetch, error } = useInfiniteQuery({
    queryKey: ['getFranchises'],
    queryFn: ({ pageParam = { page: 0, sort: sortOption } }) => {
      return fetchFranchises(pageParam.page, pageParam.sort);
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.franchises.length === 0) {
        return undefined;
      }
      return { page: page + 1, sort: sortOption };
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const observer = useRef<IntersectionObserver | undefined>();

  const lastListingElementRef = useCallback(
    (node: Element | null) => {
      if (isFetching) return;
      if (observer && observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchNextPage();
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetching, data],
  );

  useEffect(() => {
    if (!prevId) {
      remove();
      setPage(0);
      refetch();
    }
    return;
  }, [sortOption]);

  useEffect(() => {
    if (navigationType === 'POP' && prevId) {
      document.getElementById(prevId.toString())?.scrollIntoView({ behavior: 'auto', block: 'end' });
      setPrevId(undefined);
    }
    return;
  }, [navigationType]);

  return error ? (
    <ViewCentered maxWidth="500px">
      <h4>{t('common:serverError')}</h4>
    </ViewCentered>
  ) : (
    <StyledLayout>
      <HelmetFranchiseSection />

      <DummyCard>
        <div>
          <StyledH3>{t('startMyFranchise')}</StyledH3>
          <NavLink to={publicRoutes.franchiseFranchisor(locale)}>
            <StyledLink>{t('display')}</StyledLink>
          </NavLink>
          <p>
            {data?.pages?.[0]?.total}
            {t('results')}
          </p>
        </div>
        <FranchiseSort setSortOption={setSortOption} />
      </DummyCard>
      <FranchiseCarousel />
      <div>
        {data?.pages.map((result) =>
          result.franchises.map((franchise, arrayIndex) => (
            <TileWrapper
              id={franchise._id}
              ref={
                data.pages?.[data.pages.length - 1]?.franchises?.[
                  data.pages?.[data.pages.length - 1].franchises.length - 1
                ]
                  ? lastListingElementRef
                  : null
              }
              key={arrayIndex}
            >
              <FranchiseTile
                franchise={franchise}
                searchParams={{
                  sortOption: sortOption,
                  page: page,
                }}
                setFavCnt={setFavCnt}
              />
            </TileWrapper>
          )),
        )}
      </div>
      <Spin size={'large'} spinning={isFetching} />
      {!isFetching && data?.pages && data.pages.length > 1 && (
        <NoListing>
          <h6>{t('noMoreResults')}</h6>
        </NoListing>
      )}
      <div>
        <br />
        <br />
        <br />
        <br />
      </div>
      <BottomBar favCnt={favCnt} setVisibleForm={setVisibleForm} />
      <FranchiseContactForm useVisible={[visibleForm, setVisibleForm]} />
    </StyledLayout>
  );
};

const StyledLayout = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-left: 4vw;
  padding-right: 4vw;
  @media only screen and (max-width: 1400px) {
    padding-left: 2vw;
    padding-right: 2vw;
  }
  overflow: auto;
`;

const DummyCard = styled.div`
  max-width: 1088px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  p {
    margin-bottom: 0;
  }
`;

const TileWrapper = styled.div`
  width: 100%;
  max-width: 1088px;
`;
const NoListing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;

const StyledLink = styled.p`
  text-decoration: underline;
  cursor: pointer;
  color: ${theme.color.electricBlue};
`;

const StyledH3 = styled.h1`
  margin-bottom: 0;
  font-size: ${theme.fontsize.h3} !important;
`;
