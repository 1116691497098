import { Image, Tag } from 'antd';
import { LinkOutlined, LinkedinFilled, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';

import { BrokerDTO } from '../../dtos/broker';
import { ListingTile } from '../businessesForSale/listingTile/ListingTile';
import { LocaleContext } from '../../router/Router';
import { UserContext } from '../../contexts/UserContext';
import { getBrokerListings } from '../../services/BrokerService';
import styled from 'styled-components';
import { tCustom } from '../../services/TranslationService';
import { theme } from '../../styles/theme';
import { useAxiosMiddleware } from '../../services/useAxiosMiddleware';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

export const BrokerFullProfile = (props: BrokerDTO): JSX.Element => {
  const { locale } = useContext(LocaleContext),
    { t } = useTranslation('brokerProfile'),
    axiosMiddleware = useAxiosMiddleware(),
    { tokenErrorHandler } = useContext(UserContext);

  const brokerListingQuery = useQuery(
    ['getFullBrokerListings'],
    () => getBrokerListings({ brokerId: props._id || '', axiosMid: axiosMiddleware }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: tokenErrorHandler,
    },
  );

  const eliteTag = props.is_elite && <StyledTag color={theme.color.gold}>{t('brokerFullProfile.elite')}</StyledTag>;
  return (
    <>
      <MainDiv>
        <div>
          <TitleMobile>
            <h3>
              {props.first_name} {props.last_name}
            </h3>
            {eliteTag}
          </TitleMobile>

          <div>
            {props.profile_picture ? (
              <StyledProfilePicture width={232} src={props.profile_picture} preview={false} />
            ) : (
              <FallbackUser>
                <UserOutlined />
              </FallbackUser>
            )}
          </div>
          <p>{props.company_name}</p>
          <div>{props.company_logo && <StyledLogo width={232} src={props.company_logo} preview={false} />}</div>
          <h5>{t('brokerFullProfile.contact')}</h5>
          <ContactDiv>
            <IconSpan>
              <MailOutlined />
            </IconSpan>
            <PContact>{props.broker_email}</PContact>
          </ContactDiv>
          {props.broker_phone_number && (
            <ContactDiv>
              <IconSpan>
                <PhoneOutlined />
              </IconSpan>
              <PContact>{formatPhoneNumber(props.broker_phone_number.toString())}</PContact>
            </ContactDiv>
          )}
          {props.company_website && (
            <a href={props.company_website}>
              <ContactDiv>
                <IconSpan>
                  <LinkOutlined />
                </IconSpan>
                <PContact>
                  <u>{props.company_website}</u>
                </PContact>
              </ContactDiv>
            </a>
          )}
          {props.linkedin && (
            <a href={props.linkedin}>
              <ContactDiv>
                <IconSpan>
                  <LinkedinFilled />
                </IconSpan>
                <PContact>
                  <u>{t('brokerFullProfile.linkedIn')}</u>
                </PContact>
              </ContactDiv>
            </a>
          )}
        </div>
        <div>
          <TitleNotMobile>
            <h3>
              {props.first_name} {props.last_name}
            </h3>
            {eliteTag}
          </TitleNotMobile>
          <ShadowDiv>
            <h6>{tCustom(props.broker_title, locale)}</h6>
            <LabelDiv>
              {props.region && (
                // eslint-disable-next-line
                <Label title={t('brokerFullProfile.region')} content={props.region} />
              )}
              {props.years_of_experience && (
                <Label title={t('brokerFullProfile.yearsExp')} content={props.years_of_experience.toString()} />
              )}
              {tCustom(props.broker_expertise, locale) && (
                // eslint-disable-next-line
                <Label title={t('brokerFullProfile.expertise')} content={tCustom(props.broker_expertise, locale)!} />
              )}
            </LabelDiv>
            <PBreak>{tCustom(props.desctiption, locale)}</PBreak>
          </ShadowDiv>
        </div>
      </MainDiv>
      <div>
        {brokerListingQuery.isSuccess && brokerListingQuery.data.tile_list && (
          <h3>{t('brokerFullProfile.listingsForSale')}</h3>
        )}
        {brokerListingQuery.isSuccess &&
          brokerListingQuery.data.tile_list &&
          brokerListingQuery.data.tile_list?.map((tile, index) => <ListingTile key={index} tile={tile} />)}
      </div>
    </>
  );
};

const MainDiv = styled.div`
  display: flex;
  column-gap: 120px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin-left: 16px;
    margin-right: 16px;
  }
  margin-bottom: 64px;
`;

const TitleNotMobile = styled.div`
  h3 {
    margin: 0 !important;
  }
  display: flex;
  align-items: center;
  column-gap: 8px;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const TitleMobile = styled.div`
  h3 {
    margin: 0 !important;
  }
  display: none;
  @media only screen and (max-width: 600px) {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
`;

const StyledProfilePicture = styled(Image)`
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 5px;
  margin-bottom: 24px;
  max-width: 264px;
`;

const StyledLogo = styled(Image)`
  margin-bottom: 24px;
  max-width: 264px;
`;

const IconSpan = styled.span`
  font-size: 20px;
  margin-right: 8px;
  span,
  p {
    white-space: nowrap !important;
  }
`;

const PContact = styled.p`
  margin-bottom: 0px;
`;

const Label = (props: { title: string; content: string }): JSX.Element => (
  <div>
    <StyledPSmall>{props.title}</StyledPSmall>
    <StyledP>{props.content}</StyledP>
  </div>
);

const StyledPSmall = styled.p`
  font-size: 0.88rem;
  font-weight: 400;
  color: ${theme.color.darkGrey};
  margin: 0;
`;

const StyledP = styled.p`
  font-weight: bold;
`;

const LabelDiv = styled.div`
  display: flex;
  column-gap: 48px;
  flex-flow: wrap;
`;

const ShadowDiv = styled.div`
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 8px;
  border-radius: 5px;
`;

const ContactDiv = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

const FallbackUser = styled.div`
  width: 232px;
  height: 264px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eae9e9;
  border-radius: 5px;
  color: #fff;
  font-size: 152px;
  margin-bottom: 24px;
`;

const PBreak = styled.p`
  white-space: pre-line;
`;

const StyledTag = styled(Tag)`
  font-size: ${theme.fontsize.p};
  border-radius: 8px;
  font-weight: bold;
`;
