import { EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import React from 'react';
import { Tooltip } from 'antd';
import { UserAdmin } from '../../../dtos/admin';

export const ViewBrokerAction = (props: UserAdmin) => {
  return props.user_type === 'broker' ? (
    <div>
      <Tooltip title={'Voir le profil du courtier'}>
        <Link to={`../fr/brokerProfile/${props.id}`}>
          <EyeOutlined />
        </Link>
      </Tooltip>
    </div>
  ) : (
    <></>
  );
};
