import * as adminService from '../../../services/AdminService';

import { CheckOutlined, RocketOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import React, { useContext, useState } from 'react';

import { LinstingAdmin } from '../../../dtos/admin';
import { NotAllowedDiv } from './DeleteAction';
import { UserContext } from '../../../contexts/UserContext';
import { theme } from '../../../styles/theme';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';

export const RePublishAction = (props: LinstingAdmin): JSX.Element => {
  const [isPublished, setIsPublished] = useState(false);
  const { tokenErrorHandler } = useContext(UserContext);
  const axiosMid = useAxiosMiddleware();
  const verifyMutation = useMutation(adminService.publishUnpublishListing, {
    onSuccess: () => {
      setIsPublished(true);
    },
    onError: tokenErrorHandler,
  });
  const verifyListingAction = (id: string) => {
    Modal.confirm({
      content: 'Êtes-vous sur de vouloir republier cette annonce en tête de liste',
      onOk: () =>
        verifyMutation.mutate({
          axiosMid: axiosMid,
          listingid: id,
          state: true,
          repost: true,
        }),
    });
  };
  return props.is_published && props.is_verified ? (
    <div>
      {isPublished ? (
        <Tooltip title={"L'annonce a été republiée"}>
          <CheckOutlined style={{ color: 'green' }} />
        </Tooltip>
      ) : (
        <Tooltip title={"Republier l'annonce en tête de liste"}>
          <RocketOutlined onClick={() => verifyListingAction(props._id)} />
        </Tooltip>
      )}
    </div>
  ) : (
    <NotAllowedDiv>
      <Tooltip title={"L'annonce ne peut être republiée"}>
        <RocketOutlined style={{ color: theme.color.lightGrey }} />
      </Tooltip>
    </NotAllowedDiv>
  );
};
