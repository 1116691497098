import { Modal } from 'antd';
import { UserContext } from '../contexts/UserContext';
import axios from 'axios';
import dayjs from 'dayjs';
import i18next from 'i18next';
import jwt_decode from 'jwt-decode';
import { publicRoutes } from '../router/routes';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const USER_URL = 'user';

export const useAxiosMiddleware = () => {
  const { t } = useTranslation('axiosMid');
  const { authTokens, setCacheOnUpdateToken, signOut } = useContext(UserContext);
  const navigate = useNavigate();

  const axiosInstance = axios.create({
    headers: { Authorization: `Bearer ${authTokens?.access_token}` },
  });

  axiosInstance.interceptors.request.use(async (req) => {
    const user: { exp: number } = jwt_decode(authTokens?.access_token || '0000000000');
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;
    try {
      const res = await axios({
        method: 'PUT',
        baseURL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
        url: `${USER_URL}/refresh_access_token`,
        params: authTokens,
      });
      if (setCacheOnUpdateToken) {
        setCacheOnUpdateToken(res.data);
      }
      if (req.headers) {
        req.headers.Authorization = `Bearer ${res.data.access_token}`;
      }
      return req;
    } catch (error) {
      signOut();
      Modal.error({
        title: t('401title'),
        content: t('content'),
        onOk: () => {
          navigate(publicRoutes.signin(i18next.language));
          // refresh the source of the app on error as the client might be using an old version of the single page app
          window.location.reload();
        },
      });
    }
  });

  return axiosInstance;
};
