import { Link } from 'react-router-dom';
import React from 'react';
import fallback from './../../img/fallbackimg.webp';
import { getFranchiseCarousel } from '../../services/FranchiseService';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { useQuery } from '@tanstack/react-query';

export const FranchiseCarousel = () => {
  const NUMBER_OF_FRANCHISES = 4;

  const getCarouselQuery = useQuery({
    queryKey: ['getCarouselFranchises'],
    queryFn: () =>
      getFranchiseCarousel({
        nbOfFranchises: NUMBER_OF_FRANCHISES,
      }),
    refetchOnWindowFocus: false,
    retry: false,
  });

  return getCarouselQuery.isError ? (
    <></>
  ) : (
    <div>
      <FlexDiv>
        {getCarouselQuery.data?.map((franchise, index) => (
          <Link key={`${index}`} to={`../franchise/${franchise._id}`}>
            <Container>
              <StyledImg src={franchise.logo || fallback} height={240} width={240} />
              <FilterOnHover />
            </Container>
          </Link>
        ))}
      </FlexDiv>
    </div>
  );
};

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 16px;
  justify-content: space-between;
  justify-content: center;
  gap: 24px;
`;

const Container = styled.div<{ isHomePage?: boolean }>`
  width: 240px;
  display: block;
  position: relative;
`;

const StyledImg = styled.img`
  object-fit: contain;
  width: 100%;
  border-radius: 16px;
  border: 1px solid #d4d4d4;
`;

const FilterOnHover = styled.div`
  :hover {
    background: ${theme.color.darkBlue + '40'};
  }
  border-radius: 16px;

  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 100%;
`;
