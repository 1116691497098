import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  RocketOutlined,
  SaveOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { Button, Col, Divider, Row, Tag, Tooltip } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';

import { Image } from 'antd';
import React from 'react';
import fallback from './../../img/fallbackimg.webp';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const ListingCard = (props: {
  id: string;
  delete: { value: string; action: () => void };
  edit: { value: string; action: () => void };
  view: { value: string };
  title: string;
  location?: number | string;
  img: string;
  isPublished: boolean;
  isVerified: boolean;
  priorityExposure: boolean;
  featured: boolean;
  page: number;
}) => {
  const { t } = useTranslation(['myListings']);
  const setSearchParams = useSearchParams()[1];

  const onClick = () => {
    setSearchParams({
      prev_id: props.id || '',
      page: props.page.toString() || '',
    });
  };
  return (
    <div>
      <Row gutter={24}>
        <Col span={0} md={4}>
          <StyledImg width={128} height={128} src={props.img} fallback={fallback} preview={false} />
        </Col>
        <Col span={20}>
          <StyledDiv>
            <div>
              <h5>
                <Link onClick={onClick} to={`../fl/${props.id}`}>
                  {props.title}
                </Link>
              </h5>
              <FlexDiv>
                {!props.isPublished && (
                  <Tag icon={<SaveOutlined />} color="blue">
                    {t('myListings:listingCard.draft')}
                  </Tag>
                )}
                {props.isPublished && !props.isVerified && (
                  <Tag icon={<EditOutlined />} color="purple">
                    {t('myListings:listingCard.underReview')}
                  </Tag>
                )}
                {props.isPublished && props.isVerified && (
                  <Tag icon={<CheckCircleOutlined />} color="green">
                    {t('myListings:listingCard.published')}
                  </Tag>
                )}
                {props.priorityExposure ? (
                  <Tooltip title={t('myListings:listingCard.premiumDisplayHelp')}>
                    <Tag icon={<StarOutlined />} color={'gold'}>
                      {t('myListings:listingCard.premiumDisplay')}
                    </Tag>
                  </Tooltip>
                ) : (
                  <Tag color="default">{t('myListings:listingCard.standardDisplay')}</Tag>
                )}
                {props.featured && (
                  <Tooltip title={t('myListings:listingCard.featuredHelp')}>
                    <Tag icon={<RocketOutlined />} color={'volcano'}>
                      {t('myListings:listingCard.featured')}
                    </Tag>
                  </Tooltip>
                )}
              </FlexDiv>

              {props.location && <p>{props.location}</p>}
            </div>
            <Row justify={'end'} gutter={16}>
              <Col>
                <Tooltip title={props.delete.value}>
                  <Button type={'link'} icon={<DeleteOutlined />} onClick={props.delete.action} />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title={props.edit.value}>
                  <Button
                    type={'link'}
                    icon={<EditOutlined />}
                    onClick={() => {
                      props.edit.action();
                      onClick();
                      return;
                    }}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title={props.view.value} placement={'left'}>
                  <Link onClick={onClick} to={`../fl/${props.id}`}>
                    <Button type={'link'} icon={<ArrowRightOutlined />} />
                  </Link>
                </Tooltip>
              </Col>
            </Row>
          </StyledDiv>
        </Col>
      </Row>
      <Divider />
    </div>
  );
};

const StyledDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledImg = styled(Image)`
  object-fit: cover !important;
`;

const FlexDiv = styled.div`
  display: flex;
`;
