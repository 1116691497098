import React, { Suspense } from 'react';

import { App } from './App';
import { createRoot } from 'react-dom/client';
import { Didomi } from './components/header/Didomi';

const container = document.getElementById('root');

// eslint-disable-next-line
const root = createRoot(container!);

root.render(
  <Suspense fallback="loading">
    <Didomi />
    <App />
  </Suspense>,
);
