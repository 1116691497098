import { AxiosInstance } from 'axios';
import { MessageDTO } from '../dtos/myMessages';

export const sendMessage = async (params: {
  axiosMid: AxiosInstance;
  listingId: string;
  name: string;
  email: string;
  phone: string;
  message: string;
}): Promise<string[]> => {
  const res = await params.axiosMid({
    method: 'POST',
    baseURL: process.env.REACT_APP_NOTIFICATION_BASE_URL || 'http://127.0.0.1:/',
    url: 'message_notification/',
    data: {
      listing_id: params.listingId,
      full_name_contact: params.name,
      email: params.email,
      phone: params.phone,
      message: params.message,
    },
  });
  return res.data;
};

export const getMessages = async (params: {
  axiosMid: AxiosInstance;
  pageNumber: number;
  isSender: boolean;
}): Promise<{ messages: MessageDTO[]; total: number }> => {
  const res = await params.axiosMid({
    method: 'GET',
    baseURL: process.env.REACT_APP_NOTIFICATION_BASE_URL || 'http://127.0.0.1:/',
    url: 'message_notification/',
    params: { page: params.pageNumber, number_of_message: 20, is_sender: params.isSender },
  });
  return res.data;
};

export const deleteMessage = async (params: { axiosMid: AxiosInstance; message_id: string }): Promise<string> => {
  const res = await params.axiosMid({
    method: 'DELETE',
    baseURL: process.env.REACT_APP_NOTIFICATION_BASE_URL || 'http://127.0.0.1:/',
    url: 'message_notification/',
    params: { message_id: params.message_id },
  });
  return res.data;
};
