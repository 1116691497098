import { UseMutationResult } from '@tanstack/react-query';
import { UserAndTokens } from '../dtos/user';
import { createContext } from 'react';

type UserContextState = {
  userFavorites: undefined;
  userType: string | undefined;
  authTokens: { access_token: string; refresh_token: string } | undefined;
  signInMutation: UseMutationResult<UserAndTokens, unknown, string, unknown> | undefined;
  // eslint-disable-next-line
  signOut: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tokenErrorHandler: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUserTypeOnUpdate: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUserFavorites: React.Dispatch<any> | undefined;
  setCacheOnUpdateToken: ((result: { access_token: string; refresh_token: string }) => void) | undefined;
};

const initialState: UserContextState = {
  userFavorites: undefined,
  userType: undefined,
  authTokens: undefined,
  signInMutation: undefined,
  signOut: undefined,
  tokenErrorHandler: undefined,
  setUserTypeOnUpdate: undefined,
  setUserFavorites: undefined,
  setCacheOnUpdateToken: undefined,
};

export const UserContext = createContext(initialState);
