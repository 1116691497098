import * as userService from '../../../services/UserService';

import { ButtonSmall, ButtonStatus } from '../../../common/button/ButtonSmall';
import { Form, Input, Modal, Spin } from 'antd';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ErrorMessage } from '../../../common/ErrorMessage';
import { FieldLength } from '../../../dtos/fields';
import { HttpError } from '../../../dtos/error';
import { SignUpData } from '../../../dtos/user';
import { Store } from 'antd/lib/form/interface';
import { UserContext } from '../../../contexts/UserContext';
import { ViewCentered } from '../../../common/views/ViewCentered';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const UpdateUser = (): JSX.Element => {
  const { tokenErrorHandler } = useContext(UserContext);
  const axiosMiddleware = useAxiosMiddleware();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['profileForm', 'common']);

  const onSuccess = () => {
    Modal.success({
      content: t(`profileForm:update_user.success`),
      onOk: () => navigate(-1),
      okText: t('profileForm:back'),
    });
  };

  const updateUserMutation = useMutation(userService.updateUser, {
    onSuccess: onSuccess,
    onError: tokenErrorHandler,
  });

  const onFinish = (data: SignUpData) => {
    updateUserMutation.mutate({ data: data, axiosMid: axiosMiddleware });
  };

  return (
    <ViewCentered maxWidth="500px">
      <h4 style={{ marginTop: '32px' }}>{t('profileForm:update_user.title')}</h4>
      {updateUserMutation.isLoading ? (
        <Spin size="large" />
      ) : (
        <Form onFinish={onFinish} initialValues={location.state as Store} layout="vertical">
          <Form.Item
            name="first_name"
            label={<label>{t('profileForm:update_user.first_name.title')}</label>}
            rules={[
              {
                required: true,
                message: t('profileForm:first_name'),
              },
              {
                max: FieldLength.MEDIUM,
                message: t('common:rules.tooLong', { length: FieldLength.MEDIUM }),
              },
            ]}
          >
            <Input size="large" placeholder={t('profileForm:update_user.first_name.placeholder')} />
          </Form.Item>
          <Form.Item
            name="last_name"
            label={<label>{t('profileForm:update_user.last_name.title')}</label>}
            rules={[
              {
                required: true,
                message: t('profileForm:last_name'),
              },
              {
                max: FieldLength.MEDIUM,
                message: t('common:rules.tooLong', { length: FieldLength.MEDIUM }),
              },
            ]}
          >
            <Input size="large" placeholder={t('profileForm:update_user.last_name.placeholder')} />
          </Form.Item>

          <div style={{ marginTop: '24px', display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonSmall text={t(`profileForm:update_user.update_user`)} status={ButtonStatus.PRIMARY} />
          </div>
        </Form>
      )}

      <>
        {!updateUserMutation.isIdle && updateUserMutation.error && (
          <ErrorMessage error={updateUserMutation.error as HttpError} />
        )}
      </>
    </ViewCentered>
  );
};
