import { FileOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';

import { NavLink } from 'react-router-dom';
import React from 'react';
import { adminRoutes } from '../../router/routes';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const AdminMenu = () => {
  return (
    <>
      <NavLink to={adminRoutes.usersAdmin}>
        <h6>
          <SyledSpan>
            <UserOutlined />
          </SyledSpan>
          Utilisateurs
        </h6>
      </NavLink>
      <NavLink to={adminRoutes.listingsAdmin}>
        <h6>
          <SyledSpan>
            <FileOutlined />
          </SyledSpan>
          Annonces
        </h6>
      </NavLink>
      <NavLink to={adminRoutes.franchisesAdmin}>
        <h6>
          <SyledSpan>
            <ShopOutlined />
          </SyledSpan>
          Franchises
        </h6>
      </NavLink>
    </>
  );
};

const SyledSpan = styled.span`
  font-size: ${theme.fontsize.h5};
  padding-right: 8px;
`;
